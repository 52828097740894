import { useContext } from 'react'

import { CurrenciesContext } from '@percent/admin-dashboard/context/currencies'

export const useCurrencies = () => {
  const context = useContext(CurrenciesContext)

  if (context === undefined) {
    throw new Error('useCurrencies must be used within an CurrenciesContextController')
  }

  return context
}
