import React from 'react'
import { StringParam, useQueryParams, withDefault } from 'use-query-params'

import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { useDidMountEffect, useQueryList } from '@percent/admin-dashboard/common/hooks'
import { AgentVerificationTable } from './agentVerificationTable/AgentVerificationTable'
import { AgentVerificationState } from '@percent/admin-dashboard/api/types'

export function AgentVerificationContainer() {
  const { agentVerificationService } = useServices()
  const [queryParams, setQueryParams] = useQueryParams({
    direction: StringParam,
    sort: StringParam,
    startDate: StringParam,
    endDate: StringParam,
    state: withDefault(StringParam, undefined),
    query: StringParam
  })

  const [{ isLoading, totalResults, dataOrNull, errorMessageOrNull }, { query, nextPage, previousPage }] = useQueryList(
    agentVerificationService.getAgentVerifications,
    {
      ...queryParams,
      state: queryParams.state ? (queryParams.state as AgentVerificationState) : undefined
    }
  )

  useDidMountEffect(() => query(queryParams), [queryParams, query])

  return (
    <AgentVerificationTable
      isLoading={isLoading}
      totalResults={totalResults}
      nextPage={nextPage}
      previousPage={previousPage}
      validations={dataOrNull}
      errorMessage={errorMessageOrNull}
      queryParams={queryParams}
      setQueryParams={setQueryParams}
    />
  )
}
