import { useCallback, useEffect, useState } from 'react'
import { Box, FormControl, MenuItem, Select, Typography } from '@material-ui/core'
import { Stack } from '@mui/material'

import { Icon } from '@percent/lemonade'
import styles from './FieldDropDown.module.scss'
import { FieldDropDownProps } from './FieldDropDown.types'

export function FieldDropDown({
  initialValue,
  onClick,
  selectTitle,
  valueArray,
  placeholder,
  isClearable = false
}: FieldDropDownProps) {
  const [selectValue, setSelectValue] = useState(initialValue)
  const handleChange = (event: any) => {
    setSelectValue(event.target.value)

    if (onClick) {
      onClick(event)
    }
  }

  const handleClearClick = useCallback(
    (event: any) => {
      setSelectValue('')

      if (onClick) {
        onClick(event)
      }
    },
    [onClick]
  )

  const IconComponent = useCallback(
    () => (
      <Stack direction="row" gap={1} className={styles.buttons}>
        {isClearable && selectValue && (
          <Box className={styles.clear} onClick={handleClearClick}>
            <Icon name="close" size={4} color="gray600" />
          </Box>
        )}
        <Box>
          <Icon name="chevron-down" size={4} color="gray600" />
        </Box>
      </Stack>
    ),
    [selectValue]
  )

  useEffect(() => {
    if (selectValue && !valueArray.find(val => val.value === selectValue)) {
      setSelectValue(initialValue)
    }
  }, [valueArray, selectValue, initialValue, setSelectValue])

  return (
    <FormControl fullWidth variant="outlined" className={styles.container}>
      {selectTitle && <label className={styles.label}>{selectTitle}</label>}
      {placeholder && !selectValue.length && (
        <Typography variant="body2" className={styles.placeholder}>
          {placeholder}
        </Typography>
      )}
      <Select
        className={styles.select}
        value={selectValue}
        onChange={handleChange}
        color="secondary"
        placeholder={placeholder}
        IconComponent={IconComponent}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          getContentAnchorEl: null
        }}
      >
        <MenuItem key="emptyValue" value="" disabled className={styles.emptyOption} />
        {valueArray.map(({ title, value }) => (
          <MenuItem key={value} value={value}>
            {title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
