import { PBPGetResponse, PBPListResponse } from '@percent/admin-dashboard/services/pbpResponses'
import {
  CreateRegistryProps,
  GetRegistriesListProps,
  registriesServiceCapabilities,
  RegistriesType,
  CreateRegistryResponse
} from './registries.types'

export const registriesService = ({ percentClient }: registriesServiceCapabilities) => ({
  getRegistriesList: (params: GetRegistriesListProps) =>
    percentClient.get<PBPListResponse<RegistriesType>>('v1/admin/registries', { params }),
  createRegistry: (data: CreateRegistryProps) =>
    percentClient.post<CreateRegistryResponse>('v1/admin/registries', { ...data }),
  getRegistriesById: ({ id }: { id: string }) =>
    percentClient.get<PBPGetResponse<CreateRegistryProps>>(`v1/admin/registries/${id}`)
})
