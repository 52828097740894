import React from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useMutation } from '@percent/admin-dashboard/common/hooks'
import { AcknowledgeModal, ActionModal, Modal, Spacer, Text } from '@percent/lemonade'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'

type RemoveBankDetailsModalProps = {
  open: boolean
  onClose: VoidFunction
  refresh: VoidFunction
}

export function RemoveBankDetailsModal({ open, onClose, refresh }: RemoveBankDetailsModalProps) {
  const { id } = useParams<{ id: string }>()
  const { bankDetailsService } = useServices()

  const [{ errorMessage, success, isLoading }, { apiFunc }] = useMutation(bankDetailsService.removeBankDetails)
  const { t } = useTranslation()

  const handleRemoveBankDetails = () => {
    return apiFunc({ organisationId: id })
  }

  const handleCloseModal = () => {
    onClose()
    refresh()
  }

  const successModal = success && (
    <AcknowledgeModal
      result="positive"
      title={t('dialog.removeBankDetailsModal.success.title')}
      description={t('dialog.removeBankDetailsModal.success.description')}
      buttonText={t('button.close')}
      handleClose={handleCloseModal}
      viewTestId="success-modal"
      buttonTestId="close-acknowledge-modal"
    />
  )
  const errorModal = errorMessage && (
    <AcknowledgeModal
      result="negative"
      title={t('dialog.removeBankDetailsModal.error.title')}
      description={t('dialog.removeBankDetailsModal.error.description')}
      buttonText={t('button.close')}
      handleClose={handleCloseModal}
      viewTestId="error-modal"
      buttonTestId="close-acknowledge-modal"
    />
  )

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="remove-bank-details-modal">
      {successModal || errorModal || (
        <ActionModal
          title={t('typography.removeBankDetails')}
          primaryButtonText={t('button.remove')}
          secondaryButtonText={t('button.close')}
          type="submit"
          loading={isLoading}
          handleClose={handleCloseModal}
          handleSubmit={handleRemoveBankDetails}
          primaryBtnTestId="remove-bank-details-submit-button"
          secondaryBtnTestId="remove-bank-details-cancel-button"
        >
          <Text size="small">{t('dialog.removeBankDetailsModal.remove')}</Text>
          <Spacer axis="vertical" size={6} />
        </ActionModal>
      )}
    </Modal>
  )
}
