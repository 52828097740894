import React, { Dispatch, SetStateAction } from 'react'
import { DateRange } from 'react-date-range'

import { colors } from '@percent/admin-dashboard/identity'
import { dayJS } from '@percent/admin-dashboard/common/library/utility/date'

type DateRangeType = {
  selectionRange: { startDate: Date | undefined; endDate: Date | undefined; key: string }[]
  setSelectionRange: Dispatch<SetStateAction<{ startDate: Date; endDate: Date; key: string }[]>>
  endDate?: Date
  maxDate?: Date
}

export function CustomDateRange({ selectionRange, setSelectionRange, endDate, maxDate }: DateRangeType) {
  return (
    <DateRange
      onChange={(item: any) => setSelectionRange([item.selection])}
      showDateDisplay={false}
      shownDate={endDate}
      showMonthAndYearPickers
      rangeColors={[colors.primary]}
      moveRangeOnFirstSelection={false}
      months={2}
      ranges={selectionRange}
      direction="horizontal"
      maxDate={maxDate || dayJS().utc().toDate()}
      startDatePlaceholder="dd/mm/yyyy"
      endDatePlaceholder="dd/mm/yyyy"
      editableDateInputs
    />
  )
}
