import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { DeleteMemberDialog } from '../deleteMemberDialog/DeleteMemberDialog'

import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { useToast } from '@percent/admin-dashboard/containers/toast/ToastContext'
import { ReactComponent as HorizontalIcon } from '@percent/admin-dashboard/common/assets/images/horizontal_icon.svg'
import { useMutation } from '@percent/admin-dashboard/common/hooks'
import styles from './MemberTableMenu.module.scss'
import { Tooltip } from '@percent/lemonade'

type MemberTableMenuProps = {
  memberEmail: string
  memberId: string
  partnerId: string
  refresh?: () => void
  onAssignRoleClick?: () => void
  ssoLoginOnly?: boolean
}

export function MemberTableMenu({
  memberEmail,
  memberId,
  partnerId,
  onAssignRoleClick,
  ssoLoginOnly = false,
  refresh = () => {}
}: MemberTableMenuProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const { adminService } = useServices()
  const [{ success }, { apiFunc }] = useMutation(adminService.commands.createPartnerDeveloper)
  const { t } = useTranslation()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)

  const handleClose = () => setAnchorEl(null)

  const handleResend = () => {
    apiFunc({
      payload: {
        email: memberEmail
      },
      partnerId
    })
    setAnchorEl(null)
  }

  const addToast = useToast()

  useEffect(() => {
    if (success) {
      addToast(t('typography.invitationResent'), 'success')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success])

  const handleDeleteMember = () => {
    setAnchorEl(null)
    setOpenDeleteModal(true)
  }

  const handleCloseDeleteMember = () => {
    setAnchorEl(null)
    setOpenDeleteModal(false)
  }

  const handleAssignRole = () => {
    onAssignRoleClick?.()
  }

  const disableElementIfPartnerUsesSSO = (node: React.ReactNode) => {
    if (ssoLoginOnly) {
      const newElement = React.cloneElement(node as React.ReactElement, {
        disabled: true
      })

      return (
        <Tooltip placement="left" content={t('typography.partnerUsesSSO')}>
          <div>{newElement}</div>
        </Tooltip>
      )
    }

    return node
  }

  return (
    <div>
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} className={styles.menu}>
        <HorizontalIcon />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{
          display: 'flex',
          alignItems: 'flex-start'
        }}
      >
        <MenuItem onClick={handleAssignRole}>{t('menu.changeRole')}</MenuItem>
        {disableElementIfPartnerUsesSSO(<MenuItem onClick={handleResend}>{t('menu.resend')}</MenuItem>)}
        <MenuItem onClick={handleDeleteMember}>{t('menu.remove')}</MenuItem>
      </Menu>
      <DeleteMemberDialog
        onClose={handleCloseDeleteMember}
        openDeleteModal={openDeleteModal}
        memberEmail={memberEmail}
        memberId={memberId}
        partnerId={partnerId}
        refresh={refresh}
      />
    </div>
  )
}
