import React, { ReactNode } from 'react'

export function AgentVerificationDetailsListItem({ title, children }: { title?: string; children?: ReactNode }) {
  return (
    <li>
      <p>{title}</p>
      {children}
    </li>
  )
}
