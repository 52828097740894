import { TableCell, TableRow, Box, Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { CreateDisbursementModal } from '../createDisbursementModal/CreateDisbursementModal'
import { getDisbursementBatchType } from '../disbursementDetails/DisbursementFoundationDetails/DisbursementFoundationDetails'

import { Badge, Icon, Button } from '@percent/lemonade'
import { ErrorView, FoundationNameWithFlag, Table } from '@percent/admin-dashboard/common/components'
import { useFoundations } from '@percent/admin-dashboard/common/hooks'
import { DisbursementsTableProps } from './DisbursementsTable.types'
import styles from './DisbursementsTable.module.scss'
import { dayJS } from '@percent/admin-dashboard/common/library/utility/date'
import { Info } from '@percent/admin-dashboard/common/components/info/Info'
import { config } from '@percent/admin-dashboard/config'
import { DisbursementsFilters } from './disbursementsFilters/DisbursementsFilters'

const { disbursementPreviewUrl } = config.disbursement

export function DisbursementsTable({
  isLoading,
  totalResults,
  data,
  previousPage,
  nextPage,
  refreshToFirstPage,
  errorMessage,
  queryParams,
  setQueryParams
}: DisbursementsTableProps) {
  const { t } = useTranslation()
  const { foundations } = useFoundations()
  const { push } = useHistory()
  const [isCreateDisbursementModalOpen, setIsCreateDisbursementModalOpen] = useState(false)

  const cells = [
    { id: 'foundation', isSortable: false, props: { width: '152px' } },
    { id: 'disbursements', isSortable: false, props: { width: '140px' } },
    { id: 'pendingApproval', isSortable: false, props: { width: '150px' } },
    { id: 'pendingPayment', isSortable: false, props: { width: '160px' } },
    { id: 'sent', isSortable: false, props: { width: '90px' } },
    { id: 'paid', isSortable: false, props: { width: '90px' } },
    { id: 'removed', isSortable: false, props: { width: '110px' } },
    { id: 'donationsUpTo', isSortable: false, props: { width: '200px' } },
    { id: 'type', isSortable: false, props: { width: '100px' } }
  ]

  const tableHeader = (
    <div className={styles.tableHeader}>
      <Info>
        <Typography variant="body2" style={{ display: 'inline' }}>
          {t('typography.disbursementPreviewLink')}&nbsp;
        </Typography>
        <a
          style={{
            pointerEvents: !disbursementPreviewUrl ? 'none' : 'auto'
          }}
          target="_blank"
          rel="noopener noreferrer"
          href={disbursementPreviewUrl}
        >
          {t('typography.here')}
        </a>
      </Info>
      <Box className={styles.tableHeaderButton}>
        <DisbursementsFilters queryParams={queryParams} setQueryParams={setQueryParams} />
        <Button onPress={() => setIsCreateDisbursementModalOpen(true)} size="small">
          {t('button.createDisbursementBatch')}
        </Button>
      </Box>
    </div>
  )

  const handleRowClick = (id: string) => push(`/disbursements/${id}`)

  if (errorMessage) {
    return <ErrorView errorMessage={errorMessage} />
  }

  return (
    <>
      <Table
        isLoading={isLoading}
        totalResults={totalResults}
        title={tableHeader}
        data={data}
        previousPage={previousPage}
        nextPage={nextPage}
        columns={cells}
        emptyTableText={t('table.emptyDisbursements')}
      >
        {data?.map(row => (
          <TableRow className={styles.tableRow} key={row.id} onClick={() => handleRowClick(row.id)}>
            <TableCell>
              {foundations?.[row.foundationId] ? (
                <FoundationNameWithFlag
                  countryCode={foundations[row.foundationId].countryCode}
                  name={foundations[row.foundationId].name}
                />
              ) : (
                row.foundationId
              )}
            </TableCell>
            <TableCell>
              <Badge variant="default" icon="claims">
                {row.counts.total}
              </Badge>
            </TableCell>
            <TableCell>
              <Badge variant="informative" icon="clock">
                {row.counts.approvalRequired}
              </Badge>
            </TableCell>
            <TableCell>
              <Badge variant="default" icon="check-badge">
                {row.counts.approved}
              </Badge>
            </TableCell>
            <TableCell>
              <Badge variant="informative" icon="check-badge">
                {row.counts.inTransit}
              </Badge>
            </TableCell>
            <TableCell>
              <Badge variant="positive" icon="approve">
                {row.counts.paid}
              </Badge>
            </TableCell>
            <TableCell>
              <Badge variant="critical" icon="reject">
                {row.counts.cancelled}
              </Badge>
            </TableCell>
            <TableCell>{dayJS(row.endDate).format('DD MMM YYYY')}</TableCell>
            <TableCell>{getDisbursementBatchType(row.detailedCounts)}</TableCell>
            <TableCell align="right" style={{ padding: '16px 0' }}>
              <Icon name="chevron-right" size="s" />
            </TableCell>
          </TableRow>
        ))}
      </Table>
      <CreateDisbursementModal
        openModal={isCreateDisbursementModalOpen}
        onClose={() => setIsCreateDisbursementModalOpen(false)}
        refreshDisbursementsTable={refreshToFirstPage}
      />
    </>
  )
}
