import React from 'react'
import { Grid, Typography } from '@material-ui/core'

import { AuthBackgroundImage } from '../authBackgroundImage/AuthBackgroundImage'

import { Footer } from '@percent/admin-dashboard/common/components'
import { ReactComponent as PercentLogo } from '@percent/admin-dashboard/common/assets/images/percentlogo.svg'
import { ReactComponent as AdminDashboardLogo } from '@percent/admin-dashboard/common/assets/images/admin_dashboard_logo.svg'
import styles from './AuthView.module.scss'
import { AuthViewProps } from './AuthView.types'

export function AuthView({ heading = '', children, handleSubmit, adminLogo = false }: AuthViewProps) {
  return (
    <Grid className={styles.loginPageContainer}>
      <Grid item xs={12}>
        <div className={styles.formContainer}>
          <form onSubmit={handleSubmit} className={styles.form}>
            {adminLogo ? <AdminDashboardLogo /> : <PercentLogo />}
            {heading && (
              <Typography variant="h5" color="secondary" className={styles.loginText}>
                {heading}
              </Typography>
            )}
            {children}
          </form>
        </div>
      </Grid>
      <AuthBackgroundImage />
      <Footer />
    </Grid>
  )
}
