import React from 'react'
import ReactJson from 'react-json-view'
import { DialogTitle, Divider, Grid, Drawer } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { JsonViewDrawerProps, CopyProps } from './JsonViewDrawer.types'
import { Icon } from '@percent/lemonade'
import styles from './JsonViewDrawer.module.scss'
import { logger } from '@percent/admin-dashboard/common/utility/logger'

const handleCopy = async (copy: CopyProps) => {
  try {
    await navigator.clipboard.writeText(JSON.stringify(copy.src, null, '\t'))
  } catch (e) {
    logger(e)
  }
}

export function JsonViewDrawer({ isOpen, onClose, rawJsonData }: JsonViewDrawerProps) {
  const { t } = useTranslation()

  return (
    <Drawer anchor="right" open={isOpen} className={styles.drawer} ModalProps={{ onBackdropClick: onClose }}>
      <Grid item xs={12} className={styles.drawerHeader}>
        <DialogTitle className={styles.dialogTitle}>{t('typography.details')}</DialogTitle>
        <button type="button" onClick={onClose} className={styles.closeButton}>
          <Icon name="close" color="white" size={6} />
        </button>
      </Grid>
      <Divider />
      <ReactJson
        style={{
          paddingTop: '20px',
          wordBreak: 'break-all',
          fontSize: '14px'
        }}
        name={false}
        src={rawJsonData}
        theme="summerfruit"
        displayDataTypes={false}
        enableClipboard={handleCopy}
      />
    </Drawer>
  )
}
