import { SimilarOrganisation } from '@percent/admin-dashboard/api/types'

export type SimilarOrganisationFormValues = {
  action?: SimilarOrganisationActionValues | ''
  organisationId?: string
  organisation?: SimilarOrganisation
}

export type SimilarOrganisationsProps = {
  similarOrganisations?: SimilarOrganisation[] | null
  isLoading: boolean
  registryInformationNotFound?: boolean
  isApproved: boolean
}

export enum SimilarOrganisationActionValues {
  MERGE = 'merge',
  IGNORE = 'ignore'
}

export enum RelationshipValues {
  CHILD = 'child',
  PARENT = 'parent'
}

export type SimilarOrganisationDropdownOptions = { label: string; value: SimilarOrganisationActionValues }[]
