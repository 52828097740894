import { ActivityCheck, activityCheckServiceCapabilities } from '@percent/admin-dashboard/api/types'
import { GetActivityCheckProps, activityCheckServiceProps } from './activityCheck.types'
import { PBPListResponse } from '@percent/admin-dashboard/services/pbpResponses'
import { throttleAsync } from '@percent/utility'

export const activityCheckService = ({ percentClient }: activityCheckServiceCapabilities) => ({
  getActivityChecks: throttleAsync(
    (params: GetActivityCheckProps) =>
      percentClient.get<PBPListResponse<ActivityCheck>>('/v1/admin/activity-checks', { params }),
    1000,
    { leading: true, trailing: true }
  ),
  getActivityCheck: ({ id }: { id: string }) => percentClient.get(`/v1/admin/activity-checks/${id}`),
  postActivityCheck: ({ id, activity }: activityCheckServiceProps) =>
    percentClient.post(`/v1/admin/organisations/${id}/activity`, { activity })
})
