import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { Dialog, DialogDeleteBody } from '@percent/admin-dashboard/common/components'
import { useMutation } from '@percent/admin-dashboard/common/hooks'

type DeletePartnerDialogProps = {
  openDeleteModal?: boolean
  onClose: () => void
  partnerName: string
  query: { id: string }
}

export function DeletePartnerDialog({
  openDeleteModal = false,
  onClose,
  partnerName,
  query
}: DeletePartnerDialogProps) {
  const history = useHistory()
  const { adminService } = useServices()
  const { t } = useTranslation()

  const [{ errorMessage }, { apiFunc }] = useMutation(adminService.commands.deletePartner, () => {
    onClose()
    history.push('/partners')
  })

  const handleSubmit = () => apiFunc(query)

  return (
    <Dialog openModal={openDeleteModal} onClose={onClose} headerTitle={t('typography.removePartner')}>
      <DialogDeleteBody
        bodyText={
          <p>
            <span style={{ fontFamily: 'MarkProMedium' }}>{partnerName} </span>
            {t('typography.noAccessToPartnerDashboard')}
          </p>
        }
        errorMessage={errorMessage}
        handleSubmit={handleSubmit}
        buttonTitle={t('button.remove')}
        testId="delete-partner-button"
      />
    </Dialog>
  )
}
