import React from 'react'
import MaterialTooltip, { TooltipProps } from '@material-ui/core/Tooltip'

export function Tooltip(props: TooltipProps) {
  return <MaterialTooltip {...props} />
}

export function EmailTooltip(props: TooltipProps) {
  return <Tooltip {...props} interactive arrow={false} placement="top-start" />
}
