import { AxiosInstance } from 'axios'
import { Alpha3Code } from 'i18n-iso-countries'

export type BankDetailsServiceCapabilities = {
  percentClient: AxiosInstance
}

export enum BankDetailStatus {
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
}

export type BankDetailStatusTypes =
  | BankDetailStatus.PENDING
  | BankDetailStatus.APPROVED
  | BankDetailStatus.REJECTED

export enum BankAccountType {
  DOMESTIC = 'DOMESTIC',
  IBAN = 'IBAN',
  DOMESTICWITHIBAN = 'DOMESTIC_WITH_BIC',
}
export type BankAccountTypes =
  | BankAccountType.DOMESTIC
  | BankAccountType.IBAN
  | BankAccountType.DOMESTICWITHIBAN

export type BankAccountDetails = {
  version?: string
  holderName?: string
  wireTransferDetails: {
    type?: BankAccountTypes
    accountNumber?: string
    accountNumberLabel?: string
    bankCodeLabel?: string
    bankCode?: string
    iban?: string
    ibanLabel?: string
    bicLabel?: string
    bic?: string
    bicCodeLabel?: string
    bicCode?: string
    branchCode?: string
    branchCodeLabel?: string
  }
}

export type RequiredBankAccountDetailsFields = {
  [fieldName: string]: {
    errorMessage: string
    label: string
    regex: string
    required: boolean
    hint?: string
  }
}

export type BankDetail = {
  claimer?: {
    email: string
  }
  countryCode?: Alpha3Code
  organisationId?: string
  organisationName?: string
  status?: BankDetailStatusTypes
  requestDate?: string
  previousFilename?: string
  bankAccountDetails?: BankAccountDetails
  requiredBankAccountDetails?: {
    type: BankAccountType.DOMESTIC | BankAccountType.IBAN
    fields: RequiredBankAccountDetailsFields
  }
  addBankDetailsFormLabels?: {
    type: BankAccountType.DOMESTIC | BankAccountType.IBAN
    fields: RequiredBankAccountDetailsFields
  }
}

export type PostBankDetailsProps = {
  payload: {
    formData: object
    bankAccountDetails?: BankAccountDetails
  }
}
