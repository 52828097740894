import React from 'react'

import { AgentVerificationApproveModal } from './AgentVerificationApproveModal'
import { AgentVerificationApproveModalContainerProps } from './AgentVerificationApproveModal.types'

export function AgentVerificationApproveModalContainer({
  agentFullName,
  organisationName,
  refresh
}: AgentVerificationApproveModalContainerProps) {
  return (
    <AgentVerificationApproveModal
      agentFullName={agentFullName}
      organisationName={organisationName}
      refresh={refresh}
    />
  )
}
