export const colors = {
  secondary: '#1B034F',
  blueColorLight: '#E5EFFC',
  gray000: '#FFFFFF',
  gray010: '#F6F7F9',
  gray020: '#ECEFF3',
  gray030: '#ECF2FE',
  gray040: '#DADFE8',
  gray050: '#CED4DA',
  gray060: '#E5E5E5',
  gray080: '#B3BDCF',
  gray100: '#A0ADC3',
  gray120: '#8691A7',
  gray140: '#6B7588',
  gray160: '#505869',
  gray170: '#404858',
  gray180: '#404858',
  gray190: '#0E0F16',
  gray200: '#8691A7',
  primary: '#FF6672',
  primaryLight: '#fff0f1',
  red50: '#E9554E',
  red100: '#FCEBEA',
  white: '#FFFFFF',
  blue: '#6798F8',
  green100: '#ccf5e1',
  green150: '#42a274',
  success: '#69d4a1',
  successLight: '#ebf9f3',
  warningLight: '#FFF1E0',
  warning: '#E86825',
  warning400: '#E77309'
}
