import React, { EventHandler, KeyboardEvent, useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ValidationRow } from '../validationRow/ValidationRow'
import { ValidationPendingRow } from '../validationPendingRow/ValidationPendingRow'

import { ErrorView, SearchAndDateFilter, Table, TabPanel } from '@percent/admin-dashboard/common/components'
import { TableColumn, TableSort } from '@percent/admin-dashboard/common/components/table/Table.types'
import { useBadgeTotal } from '@percent/admin-dashboard/context/badgeTotal/useBadgeTotal/useBadgeTotal'
import { useGetQuery } from '@percent/admin-dashboard/common/hooks/useGetQuery/useGetQuery'
import { ValidationsTableProps } from './ValidationsTable.types'

export enum ValidationStatusFilter {
  pending,
  accepted,
  rejected
}

export function ValidationsTable({
  totalResults,
  validations,
  isLoading,
  previousPage,
  nextPage,
  queryParams,
  setQueryParams,
  errorMessage
}: ValidationsTableProps) {
  const { t } = useTranslation()
  const { totalNumber, setTotalNumber } = useBadgeTotal()
  const query = useGetQuery()
  const getQueryStatus = query.get('status')

  useEffect(() => {
    if (getQueryStatus === 'pending') {
      setTotalNumber({
        ...totalNumber,
        totalPendingValidations: totalResults ?? 0
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalResults])

  const tabs = [t('table.pending'), t('table.approved'), t('table.rejected'), t('table.all')]

  const columns = useMemo(() => {
    const unsortableColumn = (id: string): TableColumn => ({ id, isSortable: false })

    const commonColumns: Array<TableColumn> = [
      unsortableColumn('country'),
      { id: 'organization', isSortable: true },
      unsortableColumn('partner'),
      { id: 'requestDate', isSortable: true, props: { width: '100px' } }
    ]

    if (queryParams.status !== 'pending') {
      commonColumns.splice(2, 0, ...['registry', 'registryId'].map(unsortableColumn))
    }

    if (queryParams.status === 'pending') {
      commonColumns.push({ id: 'timeLeft', isSortable: false })
    }

    return commonColumns
  }, [queryParams.status])

  const mapTableHeaderToProperty = (tableHeaderName: string) =>
    ({ organization: 'name', requestDate: 'createdAt' }[tableHeaderName])

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) =>
    setQueryParams({
      direction: queryParams.direction === 'ASC' ? 'DESC' : 'ASC',
      sort: mapTableHeaderToProperty(property)
    })

  const handleChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    if (newValue >= 3) {
      setQueryParams({ status: undefined, sort: undefined, direction: undefined })
    } else {
      setQueryParams({ status: ValidationStatusFilter[newValue], sort: undefined, direction: undefined })
    }
  }

  const handleKeyPress: EventHandler<KeyboardEvent<HTMLInputElement>> = useCallback(
    event => {
      if (event.key === 'Enter') {
        setQueryParams({
          query: (event.target as HTMLInputElement).value
        })
      }
    },
    [setQueryParams]
  )

  const validationStatusValue = ValidationStatusFilter[queryParams.status as keyof typeof ValidationStatusFilter]

  const filters = useMemo(
    () => (
      <SearchAndDateFilter
        handleKeyPress={handleKeyPress}
        searchPhrase={queryParams.query ?? ''}
        placeholder={
          validationStatusValue === 0 ? t('table.adminVSearchPlaceholder') : t('table.adminVSearchPlaceholderTwo')
        }
      />
    ),
    [handleKeyPress, queryParams.query, t, validationStatusValue]
  )

  if (errorMessage) {
    return <ErrorView errorMessage={errorMessage} />
  }

  return (
    <Table
      isLoading={isLoading}
      totalResults={totalResults}
      data={validations}
      previousPage={previousPage}
      nextPage={nextPage}
      value={validationStatusValue ?? 3}
      handleChange={handleChange}
      handleRequestSort={handleRequestSort}
      orderBy="name"
      order={(queryParams.direction?.toLowerCase() as TableSort) ?? 'desc'}
      columns={columns}
      tabs={tabs}
      emptyTableText={t(!queryParams.query ? 'table.emptyValidations' : 'table.emptySearchResults')}
      emptyTableImageType="validations"
      filtersContent={filters}
    >
      {Array.from({ length: 4 }, (_, i) => i).map((number, index) => (
        <TabPanel key={number} value={validationStatusValue ?? 3} index={index}>
          {validations?.map(row =>
            index === 0 ? <ValidationPendingRow row={row} key={row.id} /> : <ValidationRow row={row} key={row.id} />
          )}
        </TabPanel>
      ))}
    </Table>
  )
}
