import React from 'react'
import { useTranslation } from 'react-i18next'

import { BankDetailStatus } from '@percent/admin-dashboard/api/actions/bankDetails/bankDetails.types'
import ApprovedStatusIcon from '@percent/admin-dashboard/common/assets/images/approved_status.svg'
import PendingStatusIcon from '@percent/admin-dashboard/common/assets/images/pending_status.svg'
import RejectedStatusIcon from '@percent/admin-dashboard/common/assets/images/rejected_status.svg'
import styles from '@percent/admin-dashboard/app/bankDetails/bankDetailsContainer/bankDetailsTable/BankDetailsTable.module.scss'

export function StatusBadge({ status }: { status: BankDetailStatus }) {
  const { t } = useTranslation()
  const icon = {
    approved: ApprovedStatusIcon,
    pending: PendingStatusIcon,
    rejected: RejectedStatusIcon,
    deleted: RejectedStatusIcon
  }

  return (
    <span className={styles[status]} data-testId="status-badge">
      <img src={icon[status]} alt="status-icon" />
      {t(`status.${status}`)}
    </span>
  )
}
