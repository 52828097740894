import { Button, FormField, Spacer, TextInput } from '@percent/lemonade'
import styled from 'styled-components'
import { FormikProvider } from 'formik'
import { BankDetailsViewProps } from './BankDetailsView.types'
import { UploadDocument } from '../uploadDocument/UploadDocument'

const StyledSubmitButtonContainer = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: end;
`

export function BankDetailsView({
  setSelectedFile,
  buttonFlag,
  uploadButtonFlag,
  disableUploadBtn,
  disabledField,
  bankDetailsData,
  selectedFile,
  fileFieldText,
  isPostLoading,
  translations,
  formik,
  firstField,
  secondField,
}: BankDetailsViewProps) {
  const {
    errors,
    values,
    touched,
    dirty,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
  } = formik

  return (
    <form onSubmit={handleSubmit}>
      <FormikProvider value={formik}>
        <Spacer size={4} axis="vertical" />
        <>
          <FormField
            label="Account holder name"
            status={
              touched.holderName && errors.holderName ? 'danger' : 'default'
            }
            statusMessage={errors.holderName}
            disabled={disabledField}
            description="Legal name associated with the bank account. Note we don’t accept personal bank accounts."
            data-testid="holderName"
            necessity="required"
          >
            <TextInput
              name="holderName"
              value={values.holderName}
              placeholder=""
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </FormField>
          <Spacer size={4} axis="vertical" />
          <FormField
            label={
              bankDetailsData?.requiredBankAccountDetails?.fields?.[firstField]
                ?.label
            }
            status={
              touched?.[firstField] && errors?.[firstField]
                ? 'danger'
                : 'default'
            }
            statusMessage={errors?.[firstField]}
            description={
              bankDetailsData?.requiredBankAccountDetails?.fields?.[firstField]
                ?.hint
            }
            disabled={disabledField}
            data-testid={firstField}
            necessity="required"
          >
            <TextInput
              name={firstField}
              value={values?.[firstField]}
              placeholder=""
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </FormField>
          <Spacer size={4} axis="vertical" />
          <FormField
            label={
              bankDetailsData?.requiredBankAccountDetails?.fields?.[secondField]
                ?.label
            }
            status={
              touched[secondField] && errors[secondField] ? 'danger' : 'default'
            }
            statusMessage={errors?.[secondField]}
            description={
              bankDetailsData?.requiredBankAccountDetails?.fields?.[secondField]
                ?.hint
            }
            disabled={disabledField}
            data-testid={secondField}
            necessity="required"
          >
            <TextInput
              name={secondField}
              value={values?.[secondField]?.toUpperCase()}
              placeholder=""
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </FormField>
        </>
      </FormikProvider>
      <Spacer axis="vertical" size={5} />
      <UploadDocument
        helperText={translations.helperBankAccountText}
        labelText={translations.proofBankAccount}
        uploadButton={translations.uploadDocument}
        fileFieldText={fileFieldText}
        setSelectedFile={setSelectedFile}
        buttonFlag={uploadButtonFlag}
        disableUploadBtn={disableUploadBtn}
        inputDisableStyle={disabledField}
      />
      {buttonFlag && (
        <StyledSubmitButtonContainer>
          <Button
            type="submit"
            variant={
              selectedFile && isValid && dirty && !isPostLoading
                ? 'primary'
                : 'secondary'
            }
            disabled={!(selectedFile && isValid && dirty && !isPostLoading)}
          >
            {translations.submitBankDocuments}
          </Button>
        </StyledSubmitButtonContainer>
      )}
    </form>
  )
}
