import * as Sentry from '@sentry/react'

import { environment } from '@percent/admin-dashboard/config'
import { environments } from '@percent/admin-dashboard/constants/environments'

// eslint-disable-next-line no-console
const consoleErr = (error: Error) => console.log(error)

export const logger = (error: Error) => {
  switch (environment) {
    case environments.STAGING:
    case environments.PREPROD:
    case environments.PRODUCTION:
      return Sentry.captureException(error)
    case environments.LOCAL:
    case environments.LOCAL_SERVER:
    default:
      return consoleErr(error)
  }
}
