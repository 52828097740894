import { rest } from 'msw'

import { config } from '@percent/admin-dashboard/config'

export const mockCountries = [
  {
    code: 'AUS',
    name: 'Australia',
    hasRegistry: true
  },
  {
    code: 'GBR',
    name: 'United Kingdom',
    hasRegistry: true
  },
  {
    code: 'USA',
    name: 'United States of America',
    hasRegistry: true
  }
]

export const countriesHandlers = [
  rest.get(`${config.urls.api}v1/countries`, (_, res, ctx) => res(ctx.status(200), ctx.json(mockCountries)))
]
