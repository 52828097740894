import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import { useTranslation } from 'react-i18next'

import { FraudFlagsAlert } from '../fraudFlagsAlert/FraudFlagsAlert'

import { useMutation } from '@percent/admin-dashboard/common/hooks'
import { ErrorView } from '@percent/admin-dashboard/common/components'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { useToast } from '@percent/admin-dashboard/containers/toast/ToastContext'
import { AgentVerificationStatus } from '@percent/admin-dashboard/api/types'
import { AgentDetailsProps } from './AgentDetails.types'
import { ButtonText, CardContent, CardDashboard } from '@percent/lemonade'
import styles from './AgentDetails.module.scss'

export function AgentDetails({ agentVerification, agentVerificationStatus, fraudFlags }: Readonly<AgentDetailsProps>) {
  const { t } = useTranslation()
  const { agentVerificationService } = useServices()
  const { id } = useParams<{ id: string }>()
  const addToast = useToast()

  const [{ errorMessage, success }, { apiFunc: resendEmailFunc }] = useMutation(
    agentVerificationService.resendAgentVerificationEmail
  )

  useEffect(() => {
    if (success) {
      addToast(t('typography.agentVerificationEmailSent'), 'success')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success])

  const isPending =
    agentVerificationStatus &&
    [AgentVerificationStatus.PENDING_USER_VERIFICATION, AgentVerificationStatus.PENDING].includes(
      agentVerificationStatus
    )

  if (errorMessage) {
    return <ErrorView errorMessage={errorMessage} />
  }

  return (
    <CardDashboard title={t('typography.agent')}>
      <FraudFlagsAlert title={t('typography.flaggedAgent', { count: fraudFlags.length })} flags={fraudFlags} />
      <CardContent title={t('typography.name')}>
        <span>{`${agentVerification?.firstName} ${agentVerification?.lastName}`}</span>
      </CardContent>
      <CardContent title={t('typography.email')}>
        <div className={styles.emailWrapper}>
          <span>{agentVerification?.email}</span>
          {isPending && (
            <ButtonText onPress={() => resendEmailFunc({ id })} icon="recurring">
              {t('typography.resendAgentVerificationEmail')}
            </ButtonText>
          )}
        </div>
      </CardContent>
      <CardContent title={t('typography.language')}>
        <span>{`${agentVerification?.language}`}</span>
      </CardContent>
    </CardDashboard>
  )
}
