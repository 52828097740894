import countries from 'i18n-iso-countries'

export const mockClaims = {
  data: [
    {
      id: 'claim_xxxxxxxxxxxxxxxxxxxxxxxxxxxxx',
      accountId: 'account_xxxxxxxxxxxxxxxxxxxxxxxxxxxxx',
      organisationId: 'organisation_xxxxxxxxxxxxxxxxxxxxxxxxxxxxx',
      validationSubmissionId: 'validation_submission_xxxxxxxxxxxxxxxxxxxxxxxxx',
      image: 'https://s3-eu-west-1.amazonaws.com/assets.poweredbypercent.com/image.png',
      description: 'This is a researchy place for cancer',
      createdAt: '2019-09-01 12:34:56',
      deletedAt: '2019-09-01 12:34:56',
      acceptedAt: '2019-09-01 12:34:56',
      rejectedAt: '2019-09-01 12:34:56',
      organisation: {
        id: 'test_organisation_xxxxxxxxxxxxxxxxxxxxxxxxxxxxx',
        name: 'test nonprofit',
        countryCode: 'GBR' as countries.Alpha3Code
      },
      account: {
        id: 'test_account_xxxxxxxxxxxxxxxxxxxxxxxxxxxxx',
        fullName: 'Test Name',
        email: 'testname@poweredbypercent.com',
        phoneNumber: 'string'
      }
    }
  ],
  object: 'claim_admin',
  totalResults: 1,
  _links: {
    current: 'https://.../v1/...?cursor=eyJ2Ijox...N3AifQ%3D%3D'
  }
}
