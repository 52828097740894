import { StringParam, useQueryParams } from 'use-query-params'

import { useDidMountEffect, useQueryList } from '@percent/admin-dashboard/common/hooks'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { AutoRejectionListTable } from './autoRejectionListTable/AutoRejectionListTable'

export function AutoRejectionListContainer() {
  const { autoRejectionListService } = useServices()
  const [queryParams, setQueryParams] = useQueryParams({
    query: StringParam
  })

  const [{ totalResults, isLoading, dataOrNull, errorMessageOrNull }, { nextPage, previousPage, query, refresh }] =
    useQueryList(autoRejectionListService.getAutoRejectionList, {
      ...queryParams
    })

  useDidMountEffect(() => query(queryParams), [queryParams, query])

  return (
    <AutoRejectionListTable
      data={dataOrNull}
      isLoading={isLoading}
      errorMessage={errorMessageOrNull}
      nextPage={nextPage}
      previousPage={previousPage}
      totalResults={totalResults}
      queryParams={queryParams}
      setQueryParams={setQueryParams}
      refresh={refresh}
    />
  )
}
