import { useState } from 'react'
import { Modal, Card, Divider } from '@material-ui/core'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
  ErrorView,
  Loader,
  PercentButton,
  FieldInput,
  UpdateModalInformation
} from '@percent/admin-dashboard/common/components'
import { useMutation } from '@percent/admin-dashboard/common/hooks'
import { ReactComponent as CloseIcon } from '@percent/admin-dashboard/common/assets/images/close.svg'
import styles from './UpdateWebsiteModal.module.scss'
import { UpdateWebsiteModalProps } from './UpdateWebsiteModal.types'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { PatchOrganisationParams } from '@percent/admin-dashboard/api/types'

export function UpdateWebsiteModal({
  organisation,
  refresh,
  agentVerificationId,
  isModalOpened,
  setModalOpened
}: UpdateWebsiteModalProps) {
  const { t } = useTranslation()
  const [isSent, setIsSent] = useState(false)
  const { adminService } = useServices()
  const { push } = useHistory()

  const { values, setFieldValue, handleBlur, touched, errors, dirty, isValid } = useFormik({
    initialValues: {
      website: organisation?.website
    },
    validationSchema: () =>
      yup.object().shape({
        website: yup
          .string()
          .url(t('errorMessage.validWebsite'))
          .max(255, t('errorMessage.shouldNotExceed255'))
          .required('Required')
      }),
    onSubmit: () => {}
  })

  const [{ isLoading, errorMessage }, { apiFunc: aproveFunc }] = useMutation(adminService.editOrganisation, () => {
    setIsSent(true)
  })

  const handleUpdate = () => {
    aproveFunc({ id: organisation?.id, payload: { website: values.website } } as PatchOrganisationParams)
  }

  const handleCloseModalAfterUpdate = () => {
    refresh()
    setModalOpened(false)
    setIsSent(false)
    push(`/agent-verification/${agentVerificationId}`)
  }

  return (
    <Modal open={isModalOpened} onClose={() => setModalOpened(false)}>
      <div className={styles.modal}>
        {isSent ? (
          <UpdateModalInformation
            handleClick={handleCloseModalAfterUpdate}
            setModalOpened={setModalOpened}
            title={t('dialog.updateWebsite.websiteUpdated')}
            subtitle={<p>{t('dialog.updateWebsite.websiteStored')}</p>}
            buttonTitle={t('button.goBackToDetails')}
          />
        ) : (
          <Card className={styles.wrapper}>
            <div className={styles.titleWrapper}>
              <span className={styles.title}>
                {t(`dialog.updateWebsite.${organisation?.website ? 'editWebsite' : 'addWebsite'}`)}
              </span>
              <CloseIcon onClick={() => setModalOpened(false)} />
            </div>
            <Divider className={styles.divider} />
            <div className={styles.textWrapper}>{t('dialog.updateWebsite.body')}</div>
            <FieldInput
              name="website"
              label={t('form.nonprofitWebsite')}
              placeHolder={t('form.website')}
              onChange={(value: string) => setFieldValue('website', value)}
              value={values.website}
              handleBlur={handleBlur}
              error={errors.website}
              touched={touched.website}
            />
            {errorMessage && <ErrorView errorMessage={errorMessage} />}
            {isLoading ? (
              <Loader />
            ) : (
              <div className={styles.buttonWrapper}>
                <PercentButton title={t('button.saveWebsite')} onClick={handleUpdate} disabled={!(isValid && dirty)} />
                <PercentButton
                  title={t('button.cancel')}
                  onClick={() => setModalOpened(false)}
                  textColour="secondaryTextColour"
                  outlined
                />
              </div>
            )}
          </Card>
        )}
      </div>
    </Modal>
  )
}
