import React, { ReactNode } from 'react'
import { I18nextProvider } from 'react-i18next'

import i18n from '@percent/admin-dashboard/i18n/config'
import { ToastContainer } from '@percent/admin-dashboard/containers/toast/ToastContainer'

type AppProvidersProps = {
  children: ReactNode
}

export function AppProviders({ children }: AppProvidersProps) {
  return (
    <I18nextProvider i18n={i18n}>
      <ToastContainer>{children}</ToastContainer>
    </I18nextProvider>
  )
}
