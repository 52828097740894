import React, { useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { Dialog, ErrorView, Loader } from '@percent/admin-dashboard/common/components'
import styles from './OverviewDetails.module.scss'
import { WireTransferType } from '@percent/admin-dashboard/api/actions/bankDetails/bankDetails.types'
import { Button, Spacer } from '@percent/lemonade'
import { useQuery } from '@percent/admin-dashboard/common/hooks'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { OverviewBankDetailsModalProps } from './OverviewDetails.types'
import { NonProfitDetailListItem } from '@percent/admin-dashboard/app/validations/nonprofitDetail/nonProfitDetailListItem/NonProfitDetailListItem'

function BankDetailsLabelAndValue({ labelName, value }: { labelName: string; value: string }) {
  return (
    <>
      <Typography className={styles.labelName}>{labelName}</Typography>
      <Typography className={styles.value}>{value}</Typography>
      <Spacer size={4} axis="vertical" />
    </>
  )
}

export function OverviewBankDetailsModal({ organizationName, organizationId }: OverviewBankDetailsModalProps) {
  const { bankDetailsService } = useServices()
  const { t } = useTranslation()
  const [openBankDetails, setOpenBankDetails] = useState(false)

  const [{ data: bankDetailsData, isLoading: bankDetailsLoading, errorMessage: bankDetailsError }] = useQuery(
    bankDetailsService.getBankDetails,
    {
      organisationId: organizationId
    }
  )

  const [{ data: bankDetailsDocumentData, error }, { refresh: refreshDocumentUrl }] = useQuery(
    bankDetailsService.getBankDetailsDocument,
    {
      organisationId: organizationId
    }
  )

  const handleOpenDocument = async () => {
    await refreshDocumentUrl()

    return window.open(bankDetailsDocumentData?.url, '_blank', 'noreferrer')
  }

  const wireTransferDetails = bankDetailsData?.bankAccountDetails?.wireTransferDetails

  if (bankDetailsLoading) {
    return <Loader />
  }

  if (bankDetailsError) {
    return <ErrorView errorMessage={bankDetailsError} />
  }

  return (
    <>
      <NonProfitDetailListItem title={t('table.bankDetailsHeader')}>
        {(bankDetailsData?.status === 'approved' && (
          <span className={styles.viewBankDetails} role="presentation" onClick={() => setOpenBankDetails(true)}>
            {t('typography.viewBankDetails')}
          </span>
        )) || <span>{t('typography.noneSupplied')}</span>}
      </NonProfitDetailListItem>
      <Dialog
        openModal={openBankDetails}
        onClose={() => setOpenBankDetails(false)}
        headerTitle={t('table.bankDetailsHeader')}
      >
        <Grid item xs={12} className={styles.modalBody}>
          <Typography className={styles.findBankDetails}>
            {t('dialog.overviewBankDetails.findTheBankDetails')} <span>{organizationName}</span>{' '}
            {t('dialog.overviewBankDetails.below')}
          </Typography>
          <Spacer size={4} axis="vertical" />
          <BankDetailsLabelAndValue
            labelName="Account holder name"
            value={bankDetailsData?.bankAccountDetails?.holderName || t('typography.infoNotAdded')}
          />
          {/* WireTransferType.DOMESTIC */}
          {wireTransferDetails?.type === WireTransferType.DOMESTIC && (
            <>
              <BankDetailsLabelAndValue
                labelName={wireTransferDetails?.bankCodeLabel}
                value={wireTransferDetails?.bankCode}
              />
              <BankDetailsLabelAndValue
                labelName={wireTransferDetails?.accountNumberLabel}
                value={wireTransferDetails?.accountNumber}
              />
            </>
          )}
          {/* WireTransferType.IBAN */}
          {wireTransferDetails?.type === WireTransferType.IBAN && (
            <>
              <BankDetailsLabelAndValue labelName={wireTransferDetails?.bicLabel} value={wireTransferDetails?.bic} />
              <BankDetailsLabelAndValue labelName={wireTransferDetails?.ibanLabel} value={wireTransferDetails?.iban} />
            </>
          )}
          {/* WireTransferType.DOMESTIC_WITH_BIC */}
          {wireTransferDetails?.type === WireTransferType.DOMESTIC_WITH_BIC && (
            <>
              <BankDetailsLabelAndValue
                labelName={wireTransferDetails?.accountNumberLabel || t('typography.accountNumber')}
                value={wireTransferDetails?.accountNumber || t('typography.infoNotAdded')}
              />

              <BankDetailsLabelAndValue
                labelName={wireTransferDetails?.bicLabel || t('typography.bicSwiftCode')}
                value={wireTransferDetails?.bic || t('typography.infoNotAdded')}
              />

              {wireTransferDetails?.bankCode && (
                <BankDetailsLabelAndValue
                  labelName={wireTransferDetails?.bankCodeLabel || t('typography.bankCodeLabel')}
                  value={wireTransferDetails?.bankCode || t('typography.infoNotAdded')}
                />
              )}
              {wireTransferDetails?.branchCode && (
                <BankDetailsLabelAndValue
                  labelName={wireTransferDetails?.branchCodeLabel || t('typography.branchCodeLabel')}
                  value={wireTransferDetails?.branchCode || t('typography.infoNotAdded')}
                />
              )}
            </>
          )}

          {/* Bank Document Proof */}
          <>
            <Typography className={styles.labelName}>{t('dialog.overviewBankDetails.proofDocument')}</Typography>
            <Typography
              className={styles.viewDocument}
              onClick={() => {
                if (!error) {
                  handleOpenDocument()
                }
              }}
            >
              {t('typography.viewDocument')}
            </Typography>
          </>
        </Grid>
        <Grid item xs={12} className={styles.dialogFooter}>
          <Button variant="secondary" size="medium" onPress={() => setOpenBankDetails(false)}>
            {t('button.close')}
          </Button>
        </Grid>
      </Dialog>
    </>
  )
}
