import React from 'react'

import { PercentButton } from '../percentButton/PercentButton'

import { ListItemButtonProps } from './ListItemButton.types'
import styles from './ListItemButton.module.scss'

export function ListItemButton({
  className = '',
  colour = 'primary',
  disabled,
  onClick,
  outlined = true,
  testId = '',
  textColour = 'primaryTextColour',
  title,
  underline = false,
  icon
}: ListItemButtonProps) {
  const classNames = `${className} ${styles.ListItemButton}`

  return (
    <PercentButton
      className={classNames}
      title={title}
      onClick={onClick}
      colour={colour}
      textColour={textColour}
      outlined={outlined}
      disabled={disabled}
      underline={underline}
      testId={testId}
    >
      {icon}
    </PercentButton>
  )
}
