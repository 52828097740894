import { Grid, Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { useMutation } from '@percent/admin-dashboard/common/hooks'
import { PercentButton, Dialog, Loader, ErrorView } from '@percent/admin-dashboard/common/components'
import { AgentVerificationDetailsListItem } from '@percent/admin-dashboard/app/agentVerification/agentVerificationDetails/components/agentVerificationDetailsListItem/AgentVerificationDetailsListItem'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import styles from './ActivityCheckSuccessModal.module.scss'
import { ActivityCheckSuccessModalProps } from './ActivityCheckSuccessModal.types'

export function ActivityCheckSuccessModal({
  isOpened,
  setIsOpened,
  primaryCode,
  subCode,
  primaryDescription,
  subDescription,
  updatedCode,
  orgName,
  orgId,
  refresh,
  setIsSuccess
}: ActivityCheckSuccessModalProps) {
  const { t } = useTranslation()
  const { activityCheckService } = useServices()

  const [{ isLoading, errorMessage }, { apiFunc: aproveFunc }] = useMutation(
    activityCheckService.postActivityCheck,
    () => {
      setIsOpened(false)
      setIsSuccess(true)
      refresh()
    }
  )

  const handleApprove = () => {
    aproveFunc({ id: orgId, activity: updatedCode.sub.substring(0, 3) })
  }

  return (
    <Dialog openModal={isOpened} onClose={() => setIsOpened(false)} headerTitle={t('dialog.changeCode')}>
      <>
        <Grid item xs={12} className={styles.dialogBody}>
          <Typography variant="subtitle1">
            {t('dialog.aboutToChange')} <span>{orgName}</span> {t('dialog.code')}
          </Typography>
          <ul className={styles.detailsList}>
            <AgentVerificationDetailsListItem title={t('dialog.from')} />
            <AgentVerificationDetailsListItem title={t('typography.primary')}>
              <PercentButton
                title={primaryCode && primaryDescription ? `${primaryCode} - ${primaryDescription}` : ''}
                disabled
              />
            </AgentVerificationDetailsListItem>
            <AgentVerificationDetailsListItem title={t('typography.sub')}>
              <PercentButton title={subCode && subDescription ? `${subCode} - ${subDescription}` : ''} disabled />
            </AgentVerificationDetailsListItem>
            <AgentVerificationDetailsListItem title={t('dialog.to')} />
            <AgentVerificationDetailsListItem title={t('typography.primary')}>
              <PercentButton title={updatedCode.primary || ''} disabled />
            </AgentVerificationDetailsListItem>
            <AgentVerificationDetailsListItem title={t('typography.sub')}>
              <PercentButton title={updatedCode.sub || ''} disabled />
            </AgentVerificationDetailsListItem>
          </ul>
          <Typography variant="subtitle1">{t('dialog.willBeChanged')}</Typography>
        </Grid>
        {errorMessage && <ErrorView errorMessage={errorMessage} />}
        {isLoading ? (
          <Loader />
        ) : (
          <Grid item xs={12} className={styles.dialogFooter}>
            <div className={styles.buttonWrapper}>
              <PercentButton
                title={t('dialog.changeCode')}
                onClick={() => {
                  handleApprove()
                }}
              />
              <PercentButton
                title={t('button.cancel')}
                onClick={() => setIsOpened(false)}
                textColour="secondaryTextColour"
                outlined
              />
            </div>
          </Grid>
        )}
      </>
    </Dialog>
  )
}
