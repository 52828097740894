import { useTranslation } from 'react-i18next'

import { useMutation } from '@percent/admin-dashboard/common/hooks'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { Modal, ConfirmationModal } from '@percent/lemonade'
import { RemoveFromAutoRejectionListProps } from './RemoveFromAutoRejectionListModal.types'

export function RemoveFromAutoRejectionListModal({
  autoRejectionEntry,
  setAutoRejectionEntry,
  refresh
}: Readonly<RemoveFromAutoRejectionListProps>) {
  const { t } = useTranslation()
  const { autoRejectionListService } = useServices()

  const [{ isLoading }, { apiFunc }] = useMutation(autoRejectionListService.removeFromAutoRejectionList, () => {
    refresh()
    setAutoRejectionEntry(undefined)
  })

  const removeFromAutoRejectionList = () => apiFunc({ id: autoRejectionEntry?.id || '' })

  return (
    <Modal open={!!autoRejectionEntry?.id}>
      <ConfirmationModal
        title={t('dialog.removeFromRejectionList.title')}
        loading={isLoading}
        primaryButtonText={t('button.remove')}
        primaryBtnTestId="remove-from-rejection-list"
        secondaryButtonText={t('button.cancel')}
        handleSubmit={removeFromAutoRejectionList}
        handleClose={() => setAutoRejectionEntry(undefined)}
      >
        {t('dialog.removeFromRejectionList.description', { agentEmail: autoRejectionEntry?.email })}
      </ConfirmationModal>
    </Modal>
  )
}
