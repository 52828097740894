import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { TabProps } from './tabs.types'

const INDICATOR_HEIGHT = '1px'

export const StyledTabsWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    position: relative;
    border-bottom: ${INDICATOR_HEIGHT} solid ${theme.colors.gray100};
  `}
`

const StyledTab = styled.div<{ $isActive: boolean }>`
  ${({ theme, $isActive }) => css`
    padding: ${theme.sizes.xs} ${theme.sizes.s};
    font-size: ${theme.fontSizes.bodySmall};
    cursor: default;
    user-select: none;
    z-index: 1;
    outline-color: ${theme.colors.primary};
    outline-width: 1px;
    color: ${theme.colors.gray600};
    &:hover {
      color: ${theme.colors.primary};
    }

    &:focus {
      color: ${theme.colors.primary};
    }

    ${$isActive &&
    css`
      color: ${theme.colors.primary};
    `}
  `}
`

const ActiveIndicator = styled.div`
  ${({ theme }) => css`
    height: ${INDICATOR_HEIGHT};
    background: ${theme.colors.primary};
    position: absolute;
    bottom: -${INDICATOR_HEIGHT};
    left: 0;
    transition: all 0.12s ease;
  `}
`

const HoverIndicator = styled.div`
  background: red;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background: ${({ theme }) => theme.colors.primary100};
  transition: all 0.12s ease;
`

export const Tabs = ({ items, activeTab, onChangeTab }: TabProps) => {
  const tabListRef = useRef<HTMLDivElement>(null)

  const [hoverIndicatorPosition, setHoverIndicatorPosition] = useState<{
    opacity: number
    left?: number
    width?: number
  }>({ opacity: 0 })

  const [activeIndicatorPosition, setActiveIndiciatorPosition] = useState<{
    left: number
    width: number
  } | null>(null)

  useEffect(() => {
    if (tabListRef.current && activeTab) {
      const wrapperPosition = tabListRef.current.getBoundingClientRect()
      const wrapperLeft = wrapperPosition.left

      const activeItem = document.getElementById(activeTab)

      if (activeItem) {
        const pos = activeItem.getBoundingClientRect()

        setActiveIndiciatorPosition({
          left: pos.left - wrapperLeft,
          width: pos.width,
        })
      }
    }
  }, [activeTab])

  const handleMouseOver = (e: React.MouseEvent<HTMLElement>, id: string) => {
    if (tabListRef.current && e.target) {
      const wrapperPosition = tabListRef.current.getBoundingClientRect()
      const wrapperLeft = wrapperPosition.left

      const node = e.target as HTMLElement
      const pos = node.getBoundingClientRect()

      setHoverIndicatorPosition({
        opacity: 1,
        left: pos.left - wrapperLeft,
        width: pos.width,
      })
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.code === 'Enter' || e.code === 'Space') {
      const node = e.target as HTMLElement
      onChangeTab(node.id)
    }
  }

  return (
    <StyledTabsWrapper
      ref={tabListRef}
      role="tablist"
      aria-orientation="horizontal"
    >
      {items.map((tab) => {
        const isActive = tab.key === activeTab

        return (
          <StyledTab
            key={tab.key}
            id={tab.key}
            role="tab"
            tabIndex={0}
            aria-selected={isActive}
            $isActive={isActive}
            onMouseOver={(e) => handleMouseOver(e, tab.key)}
            onMouseLeave={() =>
              setHoverIndicatorPosition((p) => ({ ...p, opacity: 0 }))
            }
            onKeyDown={handleKeyDown}
            onClick={() => onChangeTab(tab.key)}
            data-testid={`tab-${tab.key}`}
          >
            {tab.label}
          </StyledTab>
        )
      })}

      <HoverIndicator style={{ ...hoverIndicatorPosition }} />

      {activeIndicatorPosition ? (
        <ActiveIndicator style={activeIndicatorPosition} />
      ) : null}
    </StyledTabsWrapper>
  )
}
