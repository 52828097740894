/* eslint @typescript-eslint/no-var-requires: "off" */
import { capitalize, TableCell, TableRow } from '@material-ui/core'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useNonprofitsData } from '../../Layout/nonprofitsContext/useNonprofitsData/useNonprofitsData'

import { ReactComponent as DropdownSVG } from '@percent/admin-dashboard/common/assets/images/dropdown.svg'
import { getDate } from '@percent/admin-dashboard/common/utility/getDate'
import { Validation } from '@percent/admin-dashboard/api/types'
import { CountryCodeFlag } from '@percent/admin-dashboard/common/components/countryCodeFlag/CountryCodeFlag'
import style from './ValidationRow.module.scss'

type ValidationRowProps = {
  row: Validation
}

export function ValidationRow({ row }: ValidationRowProps) {
  const { t } = useTranslation()
  const { push } = useHistory()
  const { setValidation } = useNonprofitsData()
  const validationRowClick = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, validation: Validation) => {
    event.stopPropagation()

    if (!['A', 'SPAN'].includes((event.target as HTMLElement).tagName)) {
      setValidation(validation)
      push(`/validations/${validation.id}`)
    }
  }

  /* eslint-disable global-require */
  const countries = require('i18n-iso-countries')
  countries.registerLocale(require('i18n-iso-countries/langs/en.json'))
  /* eslint-enable global-require */

  const registryName = row.organisation?.registryData?.name ?? row.organisation?.registry ?? row.registryName

  return (
    <TableRow key={row.id} onClick={event => validationRowClick(event, row)} className={style.row}>
      <CountryCodeFlag countryCode={row.countryCode} />
      <TableCell>{row.name}</TableCell>
      <TableCell data-testid="validationRow-registry">
        {registryName ? capitalize(registryName) : t('status.na')}
      </TableCell>
      <TableCell data-testid="validationRow-registryId">
        {row.organisation?.registryId ?? row.registryId ?? t('status.na')}
      </TableCell>
      <TableCell>{row.partner.name}</TableCell>
      <TableCell align="right" className={style.date} style={{ padding: '16px 50px 16px 0', textAlign: 'left' }}>
        {getDate(row.createdAt)}
      </TableCell>
      <TableCell align="right" className={style.rowIconForward} style={{ padding: '0' }}>
        <DropdownSVG className={style.arrow} />
      </TableCell>
    </TableRow>
  )
}
