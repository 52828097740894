import { TableCell, TableRow } from '@material-ui/core'
import React, { useState } from 'react'
import getSymbolFromCurrency from 'currency-symbol-map'
import { useTranslation } from 'react-i18next'

import { DeleteMilestoneModal } from '../modal/deleteMilestone/DeleteMilestoneModal'
import { EditMilestoneModal } from '../modal/editMilestone/EditMilestoneModal'

import { ErrorView, Table } from '@percent/admin-dashboard/common/components'
import { Milestone } from '@percent/admin-dashboard/api/actions/milestones/milestonesService.types'
import { MilestonesTableProps } from './MilestonesTable.types'
import styles from './MilestonesTable.module.scss'
import { Button, Heading, IllustratedMessage, Menu } from '@percent/lemonade'

export const formatMoney = (num: number) => (num / 100).toFixed(2)

const cells = [
  { id: 'achieved', isSortable: false },
  { id: 'amount', isSortable: false },
  { id: 'actions', isSortable: false }
]

export function MilestonesTable({
  data,
  isLoading,
  errorMessage,
  totalResults,
  openAddMilestoneModal,
  nextPage,
  previousPage,
  refresh
}: MilestonesTableProps) {
  const { t } = useTranslation()
  const [selectedMilestone, setSelectedMilestone] = useState<Milestone | undefined>(undefined)
  const [openMilestoneModal, setOpenMilestoneModal] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [milestoneId, setMilestoneId] = useState('')
  const [currencyCode, setCurrencyCode] = useState('')

  const handleAddMilestone = () => {
    openAddMilestoneModal()
  }

  const handleEditMilestoneOpen = (id?: string) => {
    setOpenMilestoneModal(true)
    setSelectedMilestone(data?.find(item => item.id === id))
  }

  const handleCloseEditMilestone = () => setOpenMilestoneModal(prevState => !prevState)

  const handleOpenDeleteModal = (id?: string) => {
    setOpenDeleteModal(true)
    setSelectedMilestone(data?.find(item => item.id === id))
  }

  const handleCloseDeleteModal = () => setOpenDeleteModal(prevState => !prevState)

  const rowMilestoneClicked = (mId: string, cCode: string) => {
    setMilestoneId(mId)
    setCurrencyCode(cCode)
  }

  if (errorMessage) {
    return <ErrorView errorMessage={errorMessage} />
  }

  const header = (
    <div className={styles.tableHeaderContainer}>
      <div>
        <Heading level="h6">{t('typography.milestones')}</Heading>
      </div>
      <Button variant="secondary" onPress={handleAddMilestone} data-testId="container-add-item-button">
        {t('typography.addMilestone')}
      </Button>
    </div>
  )

  const emptyTableActions = (
    <IllustratedMessage
      illustration="no-milestones"
      title={t('typography.emptyMilestonesTitle')}
      description={t('typography.emptyMilestonesDescription')}
    />
  )

  return (
    <>
      <Table
        isLoading={isLoading}
        totalResults={totalResults}
        data={data}
        previousPage={previousPage}
        nextPage={nextPage}
        title={header}
        columns={cells}
        emptyTableImageType=""
        emptyTableChildren={emptyTableActions}
      >
        {data?.map(({ amount, reward, currencyCode: cCode, id }: Milestone) => (
          <TableRow key={`milestones-list-${id}`}>
            <TableCell className={styles.reward}>{reward}</TableCell>
            <TableCell>{`${getSymbolFromCurrency(cCode)}${formatMoney(amount)}`}</TableCell>
            <TableCell
              className={styles.menu}
              onClick={() => rowMilestoneClicked(id, cCode)}
              data-testid={`ellipses-${id}`}
            >
              <Menu
                title={t('button.more')}
                onSelect={key => {
                  if (key === 'edit') {
                    handleEditMilestoneOpen(id)
                  }

                  if (key === 'delete') {
                    handleOpenDeleteModal(id)
                  }
                }}
                sections={[
                  {
                    items: [
                      { key: 'edit', label: 'Edit' },
                      { key: 'delete', label: 'Delete' }
                    ]
                  }
                ]}
              />
            </TableCell>
          </TableRow>
        ))}
      </Table>
      <EditMilestoneModal
        milestoneId={selectedMilestone?.id || ''}
        milestoneAmount={selectedMilestone?.amount || 0}
        milestoneReward={selectedMilestone?.reward || ''}
        currencyCode={currencyCode}
        openModal={openMilestoneModal}
        onClose={handleCloseEditMilestone}
        refresh={refresh}
      />
      <DeleteMilestoneModal
        onClose={handleCloseDeleteModal}
        open={openDeleteModal}
        id={milestoneId}
        refresh={refresh}
        milestoneName={selectedMilestone?.reward || ''}
      />
    </>
  )
}
