import React from 'react'
import { Paper, Grid, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { NoResultTable } from '@percent/admin-dashboard/common/components'
import styles from './Eligibility.module.scss'
import { EligibilityProps } from './Eligibility.types'

export function Eligibility({ tagsData, event, organizationName }: EligibilityProps) {
  const { t } = useTranslation()

  return (
    <Grid container spacing={0} component={Paper} elevation={0} className={styles.eligibilityContainer}>
      <div className={styles.detailsHeader}>
        <Typography variant="h6">{t('typography.eligibility')}</Typography>
      </div>
      {event ? (
        <ul className={styles.tagsList}>
          {tagsData
            .filter(tag => tag.subTags.map(subTag => subTag.id).some(subTagId => event?.subTags?.includes(subTagId)))
            .map(tag => (
              <li key={tag.id}>
                <div className={styles.tagTitle}>
                  <p>{tag.name}</p>
                </div>
                <div className={styles.subTagsContainer}>
                  {tag.subTags
                    .filter(subTag => event?.subTags?.includes(subTag.id))
                    .map(subTag => (
                      <div key={subTag.id} className={styles.subTag}>
                        {subTag.name}
                      </div>
                    ))}
                </div>
              </li>
            ))}
        </ul>
      ) : (
        <div className={styles.emptyBox}>
          <NoResultTable text={`${organizationName} ${t('typography.noTagsYet')}`} type="partners" />
        </div>
      )}
    </Grid>
  )
}
