import { Box, DialogContent, Typography } from '@material-ui/core'
import { FormEvent, useMemo, useState } from 'react'
import { DatePicker } from '@material-ui/pickers'
import { useTranslation } from 'react-i18next'

import { Dialog, FoundationNameWithFlag } from '@percent/admin-dashboard/common/components'
import { useFoundations } from '@percent/admin-dashboard/common/hooks'
import { CreateDisbursementModalProps } from './CreateDisbursementModal.types'
import { Button, Icon } from '@percent/lemonade'
import { FieldDropDown } from '@percent/admin-dashboard/common/components/fieldDropDown/FieldDropDown'
import styles from './CreateDisbursementModal.module.scss'
import { dayJS } from '@percent/admin-dashboard/common/library/utility/date'
import { useToast } from '@percent/admin-dashboard/containers/toast/ToastContext'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { DisbursementType } from '@percent/admin-dashboard/api/actions/disbursements/disbursements.types'

const DATE_FORMAT = 'DD MMM YYYY'

type DisbursementMethod = 'bank_transfer' | 'direct_to_campaign' | 'card' | 'cheque'

export function CreateDisbursementModal({
  openModal,
  onClose,
  refreshDisbursementsTable
}: CreateDisbursementModalProps) {
  const { t } = useTranslation()
  const { foundations } = useFoundations()
  const { disbursementsService } = useServices()
  const addToast = useToast()
  const [batchEndDate, setBatchEndDate] = useState(dayJS().subtract(1, 'day').endOf('day'))
  const [selectedFoundationId, setSelectedFoundationId] = useState('')
  const [disbursementMethod, setDisbursementMethod] = useState<DisbursementMethod>('bank_transfer')

  const foundationsOptions = useMemo(() => {
    return Object.values(foundations || {}).map(foundation => ({
      title: <FoundationNameWithFlag countryCode={foundation.countryCode} name={foundation.name} />,
      value: foundation.id
    }))
  }, [foundations])

  const disbursementMethodOptions = useMemo<{ title: string; value: DisbursementMethod }[]>(() => {
    return [
      {
        title: t('dialog.disbursement.form.typeOfDisbursementOption.standard'),
        value: 'bank_transfer'
      },
      {
        title: t('dialog.disbursement.form.typeOfDisbursementOption.directToCampaign'),
        value: 'direct_to_campaign'
      },
      {
        title: t('dialog.disbursement.form.typeOfDisbursementOption.card'),
        value: 'card'
      },
      {
        title: t('dialog.disbursement.form.typeOfDisbursementOption.cheque'),
        value: 'cheque'
      }
    ]
  }, [t])

  const resetValues = () => {
    setBatchEndDate(dayJS().subtract(1, 'day').endOf('day'))
    setSelectedFoundationId('')
  }

  const handleClose = () => {
    resetValues()
    onClose()
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      const { type, paymentMethodTypes } =
        disbursementMethod === 'direct_to_campaign'
          ? { type: DisbursementType.EXTERNAL, paymentMethodTypes: undefined }
          : { type: DisbursementType.INTERNAL, paymentMethodTypes: [disbursementMethod] }

      await disbursementsService.createDisbursementBatch({
        type,
        paymentMethodTypes,
        foundationId: selectedFoundationId,
        endDate: batchEndDate.toISOString()
      })

      addToast(t('toast.createDisbursementBatchSuccess'), 'success')
      refreshDisbursementsTable?.()
      handleClose()
    } catch (e) {
      addToast(
        t(
          `toast.${
            e.response.status === 400 ? 'createDisbursementBatchNoOrganisationsError' : 'createDisbursementBatchError'
          }`
        ),
        'error'
      )
    }
  }

  return (
    <Dialog headerTitle={t('dialog.disbursement.header')} openModal={openModal} onClose={handleClose}>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Box className={styles.formFieldWrapper}>
            <FieldDropDown
              selectTitle={t('dialog.disbursement.form.typeOfDisbursement')}
              placeholder={t('dialog.disbursement.form.selectTypeOfDisbursement')}
              valueArray={disbursementMethodOptions}
              initialValue={disbursementMethod}
              onClick={e => {
                setDisbursementMethod(e.target.value)
              }}
            />
          </Box>
          <Box className={styles.formFieldWrapper}>
            {foundationsOptions && (
              <FieldDropDown
                selectTitle={t('dialog.disbursement.form.foundation')}
                placeholder={t('dialog.disbursement.form.selectFoundation')}
                valueArray={foundationsOptions}
                initialValue={selectedFoundationId}
                onClick={e => {
                  setSelectedFoundationId(e.target.value)
                }}
              />
            )}
          </Box>
          <Box className={styles.formFieldWrapper}>
            <label className={styles.label}>{t('dialog.disbursement.form.endDate')}</label>
            <Box className={styles.datePickerWrapper}>
              <DatePicker
                maxDate={dayJS().subtract(1, 'day')}
                className={styles.datePicker}
                format={DATE_FORMAT}
                value={batchEndDate.format(DATE_FORMAT)}
                onChange={date => setBatchEndDate(dayJS(date).endOf('day'))}
                disableFuture
                inputProps={{
                  'data-testid': 'date-picker'
                }}
              />
              <Icon name="chevron-down" size={4} color="gray600" />
            </Box>
          </Box>
          <Box className={styles.info}>
            <Icon name="info" color="gray500" size={6} />
            <Typography variant="body2">{t('dialog.disbursement.info')}</Typography>
          </Box>
          <Box className={styles.buttonsWrapper}>
            <Button
              size="large"
              type="submit"
              disabled={!selectedFoundationId.length || !disbursementMethod.length || !batchEndDate}
            >
              {t('button.create')}
            </Button>
            <Button onPress={handleClose} variant="secondary" size="large" type="button">
              {t('button.cancel')}
            </Button>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  )
}
