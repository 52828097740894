import { DecodedValueMap, SetQuery } from 'use-query-params'

import { MonitoringList } from '@percent/admin-dashboard/api/types'
import { TableQueryParams } from '@percent/admin-dashboard/common/queryParams/queryParams.types'

export enum ComplianceStatusFilter {
  pending,
  live,
  invalid
}

export type ComplianceTableProps = {
  subscriptions: MonitoringList[] | null
  totalResults: number
  nextPage: (() => void) | null
  previousPage: (() => void) | null
  isLoading: boolean
  errorMessage: string | null
  queryParams: DecodedValueMap<TableQueryParams>
  setQueryParams: SetQuery<TableQueryParams>
}
