import { API_ERROR_MESSAGE } from '@percent/admin-dashboard/constants/apiErrorMessage'

// TODO: error code and error messages need to be update with the new api errors
export const APIErrorHandler = (error: any) => {
  if (error) {
    switch (error?.code) {
      case 'auth/two_fa_authentication_failed':
        return API_ERROR_MESSAGE.twoFaInvalidToken
      case 'activity_event/eligibility_already_defined':
      case 'auth/two_fa_invalid_recovery_code':
      case 'forbidden':
      case 'accounts/invalid':
      case 'bad_request':
      case 'auth/invalid_credentials_1':
      case 'auth/invalid_credentials_2':
      case 'auth/invalid_credentials_3':
      case 'auth/invalid_credentials_4':
      case 'auth/password_unsafe_1':
      case 'auth/password_unsafe_2':
      case 'rate_exceeded':
      case 'validation_request/invalid_registry_id_format':
      case 'conflict':
      case 'upload/filetype_not_supported':
        return error?.reasons?.[0] || error?.message
      case 'unprocessable_entity':
        return error?.reasons[0]
      case 'auth/two_fa_invalid_otp_code':
        return API_ERROR_MESSAGE.invalidOTPKey
      case 'auth/forbidden_password':
        return API_ERROR_MESSAGE.forbiddenPassword
      case 'auth/too_many_change_password_requests':
        return API_ERROR_MESSAGE.tooManyChangePasswordRequests
      case 'sso/domain_in_use':
        return API_ERROR_MESSAGE.domainInUse
      case 'not_found':
      default:
        return API_ERROR_MESSAGE.genericError
    }
  }

  return API_ERROR_MESSAGE.genericError
}
