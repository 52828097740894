import { Listbox } from '@headlessui/react'
import { RefObject, useEffect, useMemo, useRef, useState } from 'react'
import { Option, OptionGroup } from '../option'
import { SelectOption, SelectOptionGroup } from '../option.types'

export const useMultiSelect = (data: {
  options: (SelectOption | SelectOptionGroup)[]
  placeholder?: string
  defaultValue?: Array<SelectOption>
  noResultsFoundText?: string
}) => {
  const {
    options,
    placeholder,
    defaultValue = [],
    noResultsFoundText = 'No results found',
  } = data

  const [selectedOptions, setSelectedOptions] =
    useState<SelectOption[]>(defaultValue)

  const inputRef = useRef<HTMLInputElement>() as RefObject<HTMLInputElement>

  useEffect(() => {
    if (defaultValue.length > 0) {
      setSelectedOptions(defaultValue)
    }
  }, [defaultValue])

  const renderElements = useMemo(() => {
    if (!options.length) {
      return <div style={{ padding: '1rem' }}>{noResultsFoundText}</div>
    }

    const renderOption = (option: SelectOption) => {
      return (
        <Listbox.Option
          key={option.value}
          value={option}
          style={{
            listStyle: 'none',
            marginLeft: 0,
            paddingLeft: 0,
            border: 'none',
          }}
        >
          {({ active }) => (
            <Option
              {...option}
              isActive={active}
              multiSelect
              isSelected={selectedOptions.includes(option)}
            />
          )}
        </Listbox.Option>
      )
    }

    const resultsMap = options
      .map((opt) => {
        if (Object.keys(opt).includes('title')) {
          const subOptionCollection = (opt as SelectOptionGroup).options

          if (subOptionCollection.length === 0) return null

          const optionTitle = (opt as SelectOptionGroup).title

          return (
            <OptionGroup key={optionTitle} title={optionTitle}>
              {subOptionCollection.map((subOpt) => {
                return renderOption(subOpt)
              })}
            </OptionGroup>
          )
        } else {
          return renderOption(opt as SelectOption)
        }
      })
      .filter((result) => result !== null)

    if (resultsMap.length === 0) {
      return <div style={{ padding: '1rem' }}>{noResultsFoundText}</div>
    }

    return resultsMap
  }, [options, noResultsFoundText, selectedOptions])

  const inputProps = {
    ref: inputRef,
    placeholder: placeholder,
  }

  const triggerProps = {
    onClick: () => {
      inputRef.current?.focus()
    },
  }

  return {
    triggerProps,
    value: selectedOptions,
    inputProps,
    renderElements,
    setValue: (value: SelectOption[]) => {
      setSelectedOptions(value)
    },
  }
}
