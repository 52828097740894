import { useState } from 'react'
import { ReactSpreadsheetImport } from 'react-spreadsheet-import'
import { useTranslation } from 'react-i18next'
import { Fields } from 'react-spreadsheet-import/types/types'

import { Button } from '@percent/lemonade'
import { OrganisationAddressRow } from '@percent/admin-dashboard/api/types'

export type UploadDonationsImportProps = {
  handleOrganisationsBatchUpdate: (payload: OrganisationAddressRow[]) => void
  isUploadInProgress: boolean
  maxRecords?: number
}

export function UploadBatchOrganisationsUpdate({
  handleOrganisationsBatchUpdate,
  isUploadInProgress,
  maxRecords = 1000
}: UploadDonationsImportProps) {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  const fields: Fields<keyof OrganisationAddressRow> = [
    {
      label: 'Organisation ID',
      key: 'organisationId',
      alternateMatches: ['organisation', 'organisationId', 'Organisation ID'],
      fieldType: {
        type: 'input'
      },
      example: 'organisation_xxxxxxxxxxxx',
      validations: [
        {
          rule: 'required',
          errorMessage: t('validation.organisationIdRequired'),
          level: 'error'
        },
        {
          rule: 'regex',
          value: '^((sandbox|staging|qa|development|test)_)?organisation_[A-z0-9]{29}$',
          errorMessage: t('validation.organisationIdInvalid'),
          level: 'error'
        }
      ]
    },
    {
      label: 'Address',
      key: 'address',
      alternateMatches: ['address_line1', 'addressLine1', 'Address'],
      fieldType: {
        type: 'input'
      },
      example: '123 Fake Street',
      validations: [
        {
          rule: 'required',
          errorMessage: t('validation.addressRequired'),
          level: 'error'
        }
      ]
    },
    {
      label: 'Zip Code',
      key: 'postalCode',
      alternateMatches: ['postal', 'postalCode', 'postal_code', 'Zip Code'],
      fieldType: {
        type: 'input'
      },
      example: '12345'
    },
    {
      label: 'City',
      key: 'city',
      alternateMatches: ['city', 'City'],
      fieldType: {
        type: 'input'
      },
      example: 'New York',
      validations: [
        {
          rule: 'required',
          errorMessage: t('validation.cityRequired'),
          level: 'error'
        }
      ]
    },
    {
      label: 'State',
      key: 'state',
      alternateMatches: ['state', 'State'],
      fieldType: {
        type: 'input'
      },
      example: 'New York',
      validations: [
        {
          rule: 'required',
          errorMessage: 'State is required',
          level: 'error'
        }
      ]
    }
  ] as const

  return (
    <>
      <Button
        variant="secondary"
        onPress={() => setOpen(!open)}
        disabled={isUploadInProgress}
        loading={isUploadInProgress}
        data-testid="upload-organisations-update"
      >
        {t('button.updateOrganisations')}
      </Button>
      <ReactSpreadsheetImport
        isOpen={open}
        onClose={() => setOpen(false)}
        onSubmit={data => {
          handleOrganisationsBatchUpdate(
            data.validData.map(row => ({
              organisationId: (row.organisationId as string).trim(),
              address: (row.address as string).trim(),
              postalCode: (row.postalCode as string | undefined)?.trim(),
              city: (row.city as string).trim(),
              state: (row.state as string).trim()
            }))
          )
        }}
        allowInvalidSubmit={false}
        maxRecords={maxRecords}
        fields={fields}
        maxFileSize={200 * 1024}
      />
    </>
  )
}
