import { Layout } from '../Layout/Layout'
import { DocumentPreview } from '../documentPreview/DocumentPreview'

import { AuthorisedRoute } from './authorisedRoute/AuthorisedRoute'

export function AuthorisedRoutes() {
  return [
    <AuthorisedRoute exact path="/document-preview" component={DocumentPreview} key="DocumentPreview" />,
    <AuthorisedRoute path="/" component={Layout} key="Layout" />
  ]
}
