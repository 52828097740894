import React from 'react'
import { StringParam, useQueryParams, withDefault } from 'use-query-params'

import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { useDidMountEffect, useQueryList } from '@percent/admin-dashboard/common/hooks'
import { EligibilityTable } from './eligibilityTable/EligibilityTable'
import { EligibilityStatus } from '@percent/admin-dashboard/api/types'

export function EligibilityContainer() {
  const { eligibilityService } = useServices()

  const [queryParams, setQueryParams] = useQueryParams({
    direction: StringParam,
    sort: StringParam,
    startDate: StringParam,
    endDate: StringParam,
    status: withDefault(StringParam, 'pending'),
    query: StringParam
  })

  const [{ totalResults, isLoading, dataOrNull, errorMessageOrNull }, { query, nextPage, previousPage }] = useQueryList(
    eligibilityService.getEligibilitySubscriptions,
    {
      ...queryParams,
      status: queryParams.status ? (queryParams.status as EligibilityStatus) : undefined
    }
  )

  useDidMountEffect(() => query(queryParams), [queryParams, query])

  return (
    <EligibilityTable
      isLoading={isLoading}
      totalResults={totalResults}
      nextPage={nextPage}
      previousPage={previousPage}
      subscriptions={dataOrNull}
      errorMessage={errorMessageOrNull}
      queryParams={queryParams}
      setQueryParams={setQueryParams}
    />
  )
}
