import React from 'react'

import { AgentVerificationRejectModal } from './AgentVerificationRejectModal'
import { AgentVerificationRejectModalContainerProps } from './AgentVerificationRejectModal.types'

export function AgentVerificationRejectModalContainer({
  agentFullName,
  organisationName,
  refresh,
  allowedOrganisations,
  partnerName
}: AgentVerificationRejectModalContainerProps) {
  return (
    <AgentVerificationRejectModal
      agentFullName={agentFullName}
      organisationName={organisationName}
      refresh={refresh}
      allowedOrganisations={allowedOrganisations}
      partnerName={partnerName}
    />
  )
}
