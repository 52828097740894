import { useHistory, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useQuery, useQueryList } from '@percent/admin-dashboard/common/hooks'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { ValidationSubmissionTopBar } from './validationSubmissionTopBar/ValidationSubmissionTopBar'
import { Loader } from '@percent/admin-dashboard/common/components'
import {
  ValidationRequestRejectionReasonCodes,
  VerificationAgentDetailsCard,
  VerificationDocumentsCard,
  VerificationMetadataCard,
  VerificationOrganisationDetailsCard,
  VerificationPartnerFieldsCard
} from '@percent/domain/verifications'
import styles from './ValidationSubmissionDetailsContainer.module.scss'
import { Button } from '@percent/lemonade'
import { getCountryFromAlpha3 } from '@percent/admin-dashboard/common/utility/getCountryFromCountryCode'

export function ValidationSubmissionDetailsContainer() {
  const [activeTab, setActiveTab] = useState('')

  const { validationSubmissionsService, adminService } = useServices()
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const { push } = useHistory()

  const [{ data: validationSubmission, isLoading: isValidationSubmissionLoading }] = useQuery(
    validationSubmissionsService.getValidationSubmission,
    { id }
  )
  const organisationId = validationSubmission?.organisation?.id

  const [{ dataOrNull: validationSubmissionDocuments, isLoading: isLoadingValidationSubmissionDocuments }] =
    useQueryList(adminService.getValidationSubmissionDocuments, {
      validationSubmissionId: id
    })

  const [{ data: organisation, isLoading: isOrganisationLoading }, { refresh: refreshOrganisation }] = useQuery(
    adminService.getOrganisation,
    { id: organisationId || '' },
    { skip: !organisationId }
  )

  useEffect(() => {
    if (organisationId) {
      refreshOrganisation()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisationId])

  const goToOrganisationPage = () => push(`/organizations/${organisationId}`)

  if (isValidationSubmissionLoading || isLoadingValidationSubmissionDocuments || isOrganisationLoading)
    return <Loader />

  const hasNoEligibleOrganisationType =
    !!organisation &&
    validationSubmission?.validationRequest?.rejectionReasonCode ===
      ValidationRequestRejectionReasonCodes.NotEligible &&
    !validationSubmission.configuration?.allowedOrganisations?.types?.some(item => organisation?.types.includes(item))
  const hasPartnerFields =
    validationSubmission.partnerFields && !!Object.keys(validationSubmission.partnerFields)?.length
  const hasMetadata = validationSubmission.metadata && !!Object.keys(validationSubmission.metadata).length

  return (
    <div>
      <ValidationSubmissionTopBar
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        id={validationSubmission.id}
        organizationName={validationSubmission?.organisation?.name ?? validationSubmission?.validationRequest?.name}
      />
      <div className={styles.applicationDetailsContainer}>
        {validationSubmission?.agentVerification?.id && (
          <VerificationAgentDetailsCard
            agentName={`${validationSubmission?.agentVerification?.firstName} ${validationSubmission?.agentVerification?.lastName}`}
            agentEmail={validationSubmission?.agentVerification?.email}
            language={validationSubmission?.agentVerification?.language}
          />
        )}
        <VerificationOrganisationDetailsCard
          organisation={organisation}
          isLoading={isValidationSubmissionLoading}
          validationRequest={validationSubmission.validationRequest}
          eligibility={validationSubmission?.eligibility}
          hasNoEligibleOrganisationType={hasNoEligibleOrganisationType}
          getCountryNameByCode={getCountryFromAlpha3}
          action={
            organisationId && (
              <Button variant="secondary" onPress={goToOrganisationPage}>
                {t('typography.viewOrganizationDetails')}
              </Button>
            )
          }
        />
        {hasPartnerFields && <VerificationPartnerFieldsCard partnerFields={validationSubmission.partnerFields} />}
        {hasMetadata && <VerificationMetadataCard metadata={validationSubmission.metadata!} />}
        <VerificationDocumentsCard
          documents={validationSubmissionDocuments || []}
          isLoading={isLoadingValidationSubmissionDocuments}
        />
      </div>
    </div>
  )
}
