import React from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { EditOrganizationDetailsModalProps } from '../OverviewDetails.types'

import { useMutation } from '@percent/admin-dashboard/common/hooks'
import { AcknowledgeModal, ActionModal, Modal, Spacer, Text } from '@percent/lemonade'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'

export function UnclaimOrganizationModal({ open, onClose, organization, refresh }: EditOrganizationDetailsModalProps) {
  const { id } = useParams<{ id: string }>()
  const { adminService } = useServices()

  const [{ errorMessage, success, isLoading }, { apiFunc }] = useMutation(adminService.commands.unclaimOrganization)
  const { t } = useTranslation()

  const handleRegistrationActive = () => {
    return apiFunc(id)
  }

  const handleCloseModal = () => {
    onClose()
    refresh()
  }

  const successModal = success && (
    <AcknowledgeModal
      result="positive"
      title={t('dialog.unclaim.success.title')}
      buttonText={t('button.close')}
      handleClose={handleCloseModal}
    >
      <Text size="small">
        <b>{organization.name} </b>
        {t('dialog.unclaim.success.description')}
      </Text>
    </AcknowledgeModal>
  )
  const errorModal = errorMessage && (
    <AcknowledgeModal
      result="negative"
      title={t('dialog.unclaim.error.title')}
      description={t('dialog.unclaim.error.description')}
      buttonText={t('button.close')}
      handleClose={handleCloseModal}
    />
  )

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="unclaim-organization-form-modal">
      {successModal || errorModal || (
        <ActionModal
          title={t('typography.unclaimOrganization')}
          primaryButtonText={t('typography.unclaimOrganization')}
          secondaryButtonText={t('button.cancel')}
          type="submit"
          loading={isLoading}
          handleClose={onClose}
          handleSubmit={handleRegistrationActive}
          primaryBtnTestId="unclaim-org"
        >
          <Text size="small">
            {t('dialog.unclaim.description.unclaimOrg')} <b> {organization.name}. </b>
            {t('dialog.unclaim.description.removingExistingMember')}
            <Spacer axis="vertical" size={6} />
            {t('typography.reversibleAction')}
          </Text>
        </ActionModal>
      )}
    </Modal>
  )
}
