import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { OrganizationDetailsProps } from '../OverviewDetails.types'

import { Avatar, Button, CardDashboard, CardContent, Spacer } from '@percent/lemonade'
import { EditBrandModal } from './EditBrandModal'
import { useFeatureFlag } from '@percent/admin-dashboard/common/hooks/useFeatureFlag/useFeatureFlag'

export function Brand({ organization, refresh }: OrganizationDetailsProps) {
  const [dialogState, setDialogState] = useState(false)
  const handleRegistriesDialog = () => {
    setDialogState(prevState => !prevState)
  }
  const { t } = useTranslation()
  const { editBrandDetailsFeatureFlag } = useFeatureFlag()

  return (
    <>
      <CardDashboard
        title={t('typography.brand')}
        action={
          editBrandDetailsFeatureFlag && (
            <Button onPress={handleRegistriesDialog} variant="secondary" data-testid="brand-details-edit-button">
              {t('typography.editBrand')}
            </Button>
          )
        }
      >
        <CardContent title={t('typography.logo')} testId="brand-details-logo">
          <Avatar image={organization?.logo || ''} alt={organization.name} size="large" />
        </CardContent>
        <CardContent title={t('typography.displayName')} testId="brand-details-name">
          {organization.displayName || t('typography.notAvailable')}
        </CardContent>
        <CardContent title={t('typography.mission')} testId="brand-details-mission">
          {organization.description || t('typography.notAvailable')}
        </CardContent>
      </CardDashboard>
      <Spacer size={6} axis="vertical" />
      <EditBrandModal
        open={dialogState}
        onClose={handleRegistriesDialog}
        organization={organization}
        refresh={refresh}
      />
    </>
  )
}
