import React from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { EditOrganizationDetailsModalProps } from '../OverviewDetails.types'

import { useMutation } from '@percent/admin-dashboard/common/hooks'
import { AcknowledgeModal, ActionModal, Modal, Spacer, Text } from '@percent/lemonade'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'

export function EditRegistrationStatusModal({
  open,
  onClose,
  organization,
  refresh
}: EditOrganizationDetailsModalProps) {
  const { id } = useParams<{ id: string }>()
  const { adminService } = useServices()

  const [{ errorMessage, success, isLoading }, { apiFunc }] = useMutation(adminService.editOrganisation)
  const { t } = useTranslation()

  const handleRegistrationActive = () => {
    return apiFunc({
      payload: { registrationActive: !organization.registrationActive },
      id
    })
  }

  const handleCloseModal = () => {
    onClose()
    refresh()
  }

  const registrationStatusText =
    organization.registrationActive === true
      ? t('typography.deactivateOrganization')
      : t('typography.activateOrganization')

  const orgRegistrationStatus =
    organization.registrationActive === true ? t('typography.deactivated') : t('typography.activated')

  const successModal = success && (
    <AcknowledgeModal
      result="positive"
      title={t('dialog.editRegistrationModal.success.title', {
        registrationStatus: orgRegistrationStatus
      })}
      description={`${organization.name} ${t('dialog.editRegistrationModal.success.description', {
        registrationStatus: orgRegistrationStatus
      })}`}
      buttonText={t('button.close')}
      handleClose={handleCloseModal}
    />
  )
  const errorModal = errorMessage && (
    <AcknowledgeModal
      result="negative"
      title={t('dialog.editRegistrationModal.error.title', {
        registrationStatus: orgRegistrationStatus
      })}
      description={t('dialog.editRegistrationModal.error.description', {
        registrationStatus: orgRegistrationStatus
      })}
      buttonText={t('button.close')}
      handleClose={handleCloseModal}
    />
  )

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="bank-details-form-modal">
      {successModal || errorModal || (
        <ActionModal
          title={registrationStatusText}
          primaryButtonText={registrationStatusText}
          secondaryButtonText={t('button.close')}
          type="submit"
          loading={isLoading}
          handleClose={handleCloseModal}
          handleSubmit={handleRegistrationActive}
          primaryBtnTestId="edit-registration-status-submit-button"
          secondaryBtnTestId="edit-registration-status-cancel-button"
        >
          <Text size="small">
            {organization.registrationActive === true
              ? t('dialog.editRegistrationModal.deactivate', {
                  organizationName: organization.name
                })
              : t('dialog.editRegistrationModal.activate', {
                  organizationName: organization.name
                })}
          </Text>
          <Spacer axis="vertical" size={6} />
        </ActionModal>
      )}
    </Modal>
  )
}
