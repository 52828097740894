import React from 'react'
import styled from 'styled-components'
import { styledVariant } from '../../util'
import { StyledTextAreaProps, TextAreaProps } from './text-area.types'
import { inputStatusStyles } from '../../shared/input-status'

export const StyledTextArea = styled.textarea<StyledTextAreaProps>`
  ${styledVariant('$status', inputStatusStyles)};

  ${({ theme }) =>
    `
    box-sizing: border-box;
    width: 100%;
    height: ${theme.sizes[20]};
    padding: ${theme.sizes[2]} ${theme.sizes[4]};
    border-radius: ${theme.borderRadii.medium};
    outline:none;
    resize: none;
    
    font-size:  ${theme.fontSizes.bodySmall};
    color: ${theme.colors.black};

    ::placeholder {
      color: ${theme.colors.gray600};
    }

    &:disabled {
      background-color: ${theme.colors.gray80};
      outline: none;
      border: none;
    }

    @media only screen and (max-device-width: 600px) {
      -webkit-appearance: none;
      font-size: ${theme.sizes[4]};
    },
  `}
`

export const TextArea = ({ status = 'default', ...props }: TextAreaProps) => {
  return <StyledTextArea type="text" {...props} $status={status} />
}
