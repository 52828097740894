import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { FieldInput, Loader, FieldError, PercentButton } from '@percent/admin-dashboard/common/components'
import { useMutation, useAuthDispatch, useAuthState } from '@percent/admin-dashboard/common/hooks'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { AuthView } from '@percent/admin-dashboard/app/auth/authview/AuthView'
import { SET_AUTHORISED } from '@percent/admin-dashboard/context/auth/authReducer/authReducer'
import styles from './Login.module.scss'
import { emailRegex } from '@percent/utility'

const REQUIRED_2FA_ERROR = 'auth/requires_two_fa_setup'
const REQUIRED_2FA_LOGIN = 'auth/requires_two_fa_login'

export function Login() {
  const { authService } = useServices()
  const { t } = useTranslation()
  const dispatch = useAuthDispatch()
  const { isAuthorised } = useAuthState()
  const { push } = useHistory()

  useEffect(() => {
    // check if that is required, authReducer should handle authorisation, so it can be redundant, but will have to check that after
    // releasing the API
    if (isAuthorised) {
      push('/organizations')
    }
  }, [isAuthorised, push])

  const loginAndUpdateUser = (loginDetails: { email: string; password: string; enforce?: boolean }) =>
    authService
      .login({
        ...loginDetails,
        enforce: true
      })
      .then(async ({ data }) => {
        localStorage.authState = JSON.stringify({
          isAuthorised: true,
          isAuthorising: false
        })

        return dispatch({
          type: SET_AUTHORISED,
          user: data.data
        })
      })
      .catch(error => {
        if (error.response.status === 400 && error.response.data.error.code === REQUIRED_2FA_ERROR) {
          push('/login/2fa-setup', {
            password: loginDetails.password
          })
        } else if (error.response.status === 400 && error.response.data.error.code === REQUIRED_2FA_LOGIN) {
          push('/login/2fa', {
            password: loginDetails.password
          })
        } else {
          throw error
        }
      })
  const [{ errorMessage, isLoading }, { apiFunc: loginUser }] = useMutation(loginAndUpdateUser)

  const { errors, values, setFieldValue, handleSubmit, handleBlur, touched, isValid, dirty } = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: () =>
      yup.object().shape({
        email: yup.string().required('Required').trim().matches(emailRegex, t('errorMessage.validEmail')),
        password: yup.string().min(8).max(64).required('Required')
      }),
    onSubmit: async loginDetails => loginUser(loginDetails)
  })
  const { email, password } = values

  return (
    <AuthView heading={t('typography.loginToAdmin')} handleSubmit={handleSubmit}>
      {isLoading ? (
        <Loader loaderStyle="authLoader" />
      ) : (
        <>
          <FieldInput
            name="email"
            label={t('form.email')}
            placeHolder={t('form.enterEmail')}
            value={email}
            onChange={(a: string) => setFieldValue('email', a)}
            handleBlur={handleBlur}
            error={errors.email}
            touched={touched.email}
          />
          <FieldInput
            name="password"
            label={t('form.password')}
            type="password"
            placeHolder={t('form.enterPassword')}
            value={password}
            onChange={(a: string) => setFieldValue('password', a)}
            handleBlur={handleBlur}
            error={errors.password}
            touched={touched.password}
            addonLabel
            addonLabelText="Forgot password"
            addonLabelLink="/forgot-password"
          />
          {errorMessage && <FieldError error={errorMessage} />}
          <PercentButton
            className={styles.loginButton}
            fullWidth
            data-testid="auth-active-button"
            title={t('button.logIn')}
            type="submit"
            disabled={!(isValid && dirty)}
          />
        </>
      )}
    </AuthView>
  )
}
