import { useEffect, useRef, useState } from 'react'
import { Grid, Paper, Divider, Card } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { SubmitModal } from '../SubmitModal/SubmitModal'

import { FlagAvatar } from '@percent/lemonade'
import { CellStatus, PercentButton, Loader, ErrorView } from '@percent/admin-dashboard/common/components'
import { WebsiteCopyLink } from '@percent/admin-dashboard/common/components/websiteCopyLink/WebsiteCopyLink'
import { getDate } from '@percent/admin-dashboard/common/utility'
import { OrganisationDetailsProps } from './OrganisationDetails.types'
import styles from './OrganisationDetails.module.scss'
import { getCountryFromAlpha3 } from '@percent/admin-dashboard/common/utility/getCountryFromCountryCode'
import { useActivityTags } from '@percent/admin-dashboard/common/hooks/useActivityTags/useActivityTags'

export function OrganisationDetails({
  subscription,
  organisation,
  subTagsForm,
  errorMessage,
  isLoading,
  isFlagged,
  refresh,
  isNoneOfTheAbove,
  partnerName
}: OrganisationDetailsProps) {
  const { t } = useTranslation()
  const ref = useRef<HTMLDivElement>(null)
  const [isSubmitModalOpened, setIsSubmitModalOpened] = useState(false)
  const [topBarHeight, setTopBarHeight] = useState<number | undefined>(undefined)

  const { activityTags } = useActivityTags({ filterByEducation: false })

  useEffect(() => {
    if (ref) {
      setTopBarHeight(ref.current?.getBoundingClientRect().height)
    }
  }, [isLoading])

  if (errorMessage) {
    return <ErrorView errorMessage={errorMessage} />
  }

  if (isLoading) {
    return <Loader />
  }

  return (
    <>
      <Card className={styles.wrapper} ref={ref}>
        <Divider flexItem className={styles.divider} />
        <div className={styles.contentWrapper}>
          <div className={styles.organisationInfoWrapper}>
            <span className={styles.text}>{organisation?.displayName}</span>
            <WebsiteCopyLink orgWebsite={organisation?.website} />
          </div>
          <div className={styles.buttonWrapper}>
            <PercentButton
              testId="submit-tags-button"
              title={t('button.submitTags')}
              onClick={() => setIsSubmitModalOpened(true)}
              disabled={isFlagged || isNoneOfTheAbove ? false : !subTagsForm?.some(subTag => subTag.meetsCriteria)}
            />
          </div>
        </div>
      </Card>
      <Grid
        container
        spacing={0}
        component={Paper}
        elevation={0}
        className={styles.generalInfoWrapper}
        style={{ marginTop: topBarHeight }}
        data-testid="eligibility-detail-view-top-bar"
      >
        <span className={styles.topBarText}>{t('typography.country')}</span>
        <FlagAvatar code3={subscription.countryCode} />
        <span className={styles.topBarSubtext}>{getCountryFromAlpha3(subscription.countryCode)}</span>
        <Divider orientation="vertical" flexItem className={styles.topBarDivider} />
        <span className={styles.topBarText}>{t('typography.partner')}</span>
        <span className={styles.topBarSubtext}>{partnerName}</span>
        <Divider orientation="vertical" flexItem className={styles.topBarDivider} />
        <span className={styles.topBarText}>{t('typography.status')}</span>
        {subscription?.status && <CellStatus status={subscription?.status} />}
        <Divider orientation="vertical" flexItem className={styles.topBarDivider} />
        <span className={styles.topBarText}>{t('typography.requestDate')}</span>
        <span className={styles.topBarSubtext}>{getDate(subscription?.createdAt)}</span>
      </Grid>
      {isSubmitModalOpened && (
        <SubmitModal
          isOpened={isSubmitModalOpened}
          setIsOpened={setIsSubmitModalOpened}
          organisation={organisation}
          subTagsForm={subTagsForm}
          isFlagged={isFlagged}
          refresh={refresh}
          tagsData={activityTags}
          isNoneOfTheAbove={isNoneOfTheAbove}
        />
      )}
    </>
  )
}
