import { rest } from 'msw'

import { config } from '@percent/admin-dashboard/config'
import { mockPartnerDetail } from '@percent/admin-dashboard/fixtures/api/partner/mockPartnerDetail'

export const partnerHandlers = [
  rest.get(`${config.urls.api}v1/admin/partners/:id`, async (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockPartnerDetail))
  })
]
