import { Box, DialogContent, Typography } from '@material-ui/core'
import { FormEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Dialog } from '@percent/admin-dashboard/common/components'
import { Button } from '@percent/lemonade'
import { MarkAsCancelledModalProps } from './MarkAsCancelledModal.types'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { useToast } from '@percent/admin-dashboard/containers/toast/ToastContext'
import styles from './MarkAsCancelledModal.module.scss'
import { getApiErrorMessage } from '@percent/admin-dashboard/common/library/getApiErrorMessage'

export function MarkAsCancelledModal({
  isOpen,
  onClose,
  donationRequestId,
  partnerName,
  onSuccess
}: MarkAsCancelledModalProps) {
  const { t } = useTranslation()
  const { donationRequestsService } = useServices()
  const addToast = useToast()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const markAsCancelled = async () => {
    setIsSubmitting(true)
    try {
      await donationRequestsService.cancel({ id: donationRequestId })
      addToast(t('toast.donationRequestCancelledSuccess'), 'success')
      onSuccess()
      onClose()
    } catch (e) {
      addToast(getApiErrorMessage(e) || t('errorMessage.genericError'), 'error')
    }
    setIsSubmitting(false)
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    markAsCancelled()
  }

  return (
    <Dialog headerTitle={t('dialog.cancelDonationRequest.header')} openModal={isOpen} onClose={onClose}>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Typography variant="body2">{t('dialog.cancelDonationRequest.info', { partnerName })}</Typography>
          <Box className={styles.buttonsWrapper}>
            <Button size="large" type="submit" disabled={isSubmitting} loading={isSubmitting}>
              {t('button.markAsCancelled')}
            </Button>
            <Button onPress={onClose} variant="secondary" size="large" type="button">
              {t('button.cancel')}
            </Button>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  )
}
