const mockMilestones = {
  id: 'staging_milestone_000000C8kSLF0NDkfaf0jCEvLHpjc',
  amount: 1000,
  reward: 'This is a reward',
  currencyCode: 'GBP',
  organisationId: 'string'
}

export const mockMilestonesCreate = {
  id: 'staging_milestone_000000C8kSLF0NDkfzf0jCEvLHpjc',
  amount: 4999,
  reward: 'This is a create reward',
  currencyCode: 'GBP',
  organisationId: 'string'
}

export const mockMilestonesUpdate = {
  data: {
    id: 'staging_milestone_000000C8kSLF0NDkfaf0jCEvLHpjd',
    amount: 1000,
    reward: 'This is an edited reward',
    currencyCode: 'EUR',
    organisationId: 'string'
  },
  object: 'milestone'
}

export const mockGetMilestones = {
  data: [
    {
      ...mockMilestones
    },
    {
      ...mockMilestones,
      id: 'staging_milestone_000000C8kSLF0NDkfaf0jCEvLHpjd',
      reward: 'This is a new reward',
      currencyCode: 'EUR'
    },
    {
      ...mockMilestones,
      id: 'staging_milestone_000000C8kSLF0NDkfaf0jCEvLHpjw',
      currencyCode: 'USD'
    }
  ],
  totalResults: 3,
  object: 'milestone',
  _links: {
    current:
      'https://staging-api.poweredbypercent.com/v1/organisations/staging_organisation_000000C2pVBVUN2XZriOszlwthfG3/milestones?pageSize=10'
  }
}

export const mockGetTwoMilestones = {
  data: [
    {
      ...mockMilestones
    },
    {
      ...mockMilestones,
      amount: 1239,
      id: 'staging_milestone_000000C8kSLF0NDkfaf0jCEvLHpjd',
      reward: 'This is a new reward',
      currencyCode: 'EUR'
    }
  ],
  totalResults: 2,
  object: 'milestone',
  _links: {
    current:
      'https://staging-api.poweredbypercent.com/v1/organisations/staging_organisation_000000C2pVBVUN2XZriOszlwthfG3/milestones?pageSize=10'
  }
}

export const mockGetMilestonesWithNewItem = {
  data: [
    {
      ...mockMilestones
    },
    {
      ...mockMilestones,
      id: 'staging_milestone_000000C8kSLF0NDkfaf0jCEvLHpjd',
      reward: 'This is a new reward',
      currencyCode: 'EUR'
    },
    {
      ...mockMilestones,
      id: 'staging_milestone_000000C8kSLF0NDkfaf0jCEvLHsjd',
      currencyCode: 'USD'
    },
    {
      ...mockMilestonesCreate
    }
  ],
  totalResults: 4,
  object: 'milestone',
  _links: {
    current:
      'https://staging-api.poweredbypercent.com/v1/organisations/staging_organisation_000000C2pVBVUN2XZriOszlwthfG3/milestones?pageSize=10'
  }
}

export const mockGetMilestonesOneItem = {
  data: [
    {
      ...mockMilestones
    }
  ],
  totalResults: 1,
  object: 'milestone',
  _links: {
    current:
      'https://staging-api.poweredbypercent.com/v1/organisations/staging_organisation_000000C2pVBVUN2XZriOszlwthfG3/milestones?pageSize=10'
  }
}

export const mockGetMilestonesWithUpdatedItem = {
  data: [
    {
      ...mockMilestones
    },
    {
      ...mockMilestones,
      id: 'staging_milestone_000000C8kSLF0NDkfaf0jCEvLHpjd',
      reward: 'This is an edited reward',
      currencyCode: 'EUR'
    }
  ],
  totalResults: 2,
  object: 'milestone',
  _links: {
    current:
      'https://staging-api.poweredbypercent.com/v1/organisations/staging_organisation_000000C2pVBVUN2XZriOszlwthfG3/milestones?pageSize=10'
  }
}
