import React from 'react'
import classNames from 'classnames/bind'

import { StatusProps, Variant } from './Status.types'
import styles from './Status.module.scss'

const cx = classNames.bind(styles)

const upperCaseFirstLetter = (word: string) => word.charAt(0).toUpperCase() + word.slice(1)

export function Status({ variant, text = variant, className }: StatusProps) {
  return (
    <div
      className={cx('status', className, {
        'status--approved': variant === Variant.APPROVED,
        'status--rejected': variant === Variant.REJECTED
      })}
    >
      {upperCaseFirstLetter(text)}
    </div>
  )
}

Status.variants = Variant
