import React from 'react'
import { useTranslation } from 'react-i18next'

import { Badge, CardDashboard, CardContent, Spacer } from '@percent/lemonade'
import { AccountStatusAndTagsProps } from './AccountStatusAndTags.types'
import { OrganisationTypes } from '@percent/admin-dashboard/constants/organisationTypes'

export function AccountStatusAndTags({ event, tagsData, organisationTypes }: AccountStatusAndTagsProps) {
  const { t } = useTranslation()

  const tags = tagsData
    .filter(tag => tag.subTags.map(subTag => subTag.id).some(subTagId => event?.subTags?.includes(subTagId)))
    .flatMap(tag => tag.subTags)

  const hasSubtags = event?.subTags !== null
  const subTags = event?.subTags?.map(subTag => tags.find(tag => tag.id === subTag))

  return (
    <>
      <CardDashboard title={t('typography.tags')}>
        <CardContent title={t('table.organisationType')} testId="account-and-tags-card">
          {organisationTypes?.map(type => (
            <React.Fragment key={type}>
              <Badge variant="informative" icon="categories">
                {t(`organisationTypes.${type as OrganisationTypes}`)}
              </Badge>
              <Spacer size={2} axis="horizontal" />
            </React.Fragment>
          ))}
        </CardContent>

        <CardContent title={t('typography.activity')} testId="account-and-tags-card">
          {event &&
            hasSubtags &&
            subTags?.map(subTag => (
              <React.Fragment key={subTag?.id}>
                <Badge variant="informative" icon="categories">
                  {subTag?.name}
                </Badge>
                <Spacer size={2} axis="horizontal" />
              </React.Fragment>
            ))}

          {event && !hasSubtags && (
            <Badge variant="default" icon="categories">
              {t('status.cantDefine')}
            </Badge>
          )}

          {!event && t('typography.notAvailable')}
        </CardContent>
      </CardDashboard>
      <Spacer size={6} axis="vertical" />
    </>
  )
}
