import { DecodedValueMap, SetQuery } from 'use-query-params'

import { TableQueryParams } from '@percent/admin-dashboard/common/queryParams/queryParams.types'
import { EligibilitySubscriptionList } from '@percent/admin-dashboard/api/types'

export enum EligibilityStatusFilter {
  pending,
  'cannot_define_eligibility',
  live,
  cancelled
}

export type EligibilityTableProps = {
  subscriptions: EligibilitySubscriptionList | null
  totalResults: number
  nextPage: (() => void) | null
  previousPage: (() => void) | null
  isLoading: boolean
  errorMessage: string | null
  queryParams: DecodedValueMap<TableQueryParams>
  setQueryParams: SetQuery<TableQueryParams>
}
