import { Grid } from '@material-ui/core'
import React from 'react'

import styles from './Card.module.scss'
import { CardProps } from './Card.types'

export function Card({ children, size = 6 }: CardProps) {
  return (
    <Grid item xs={12} sm={size}>
      <div className={styles.titleCardContainer}>{children}</div>
    </Grid>
  )
}
