import { useFormik } from 'formik'
import React, { useEffect } from 'react'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'

import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { useToast } from '@percent/admin-dashboard/containers/toast/ToastContext'
import { useMutation } from '@percent/admin-dashboard/common/hooks'
import { Dialog, DialogCreateBody, FieldInput } from '@percent/admin-dashboard/common/components'
import { emailRegex } from '@percent/utility'

const validationSchema = Yup.object().shape({
  email: Yup.string().matches(emailRegex, 'Invalid email').required('Required')
})

type CreateMemberDialogProps = {
  open: boolean
  onClose: () => void
  refresh: () => void
  partnerId: string
}

export function CreateMemberDialog({ open = false, onClose, refresh, partnerId }: CreateMemberDialogProps) {
  const { adminService } = useServices()
  const addToast = useToast()

  const { t } = useTranslation()
  const [{ errorMessage, isLoading, success }, { apiFunc }] = useMutation(
    adminService.commands.createPartnerDeveloper,
    () => {
      refresh()
      onClose()
    }
  )
  const { values, errors, handleBlur, handleSubmit, touched, setFieldValue } = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema,
    onSubmit: ({ email }: { email: string }) => {
      apiFunc({
        payload: {
          email
        },
        partnerId
      })
    }
  })

  useEffect(() => {
    if (success) {
      addToast(t('typography.invitationSent'), 'success')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success])

  return (
    <Dialog openModal={open} onClose={onClose} headerTitle={t('typography.addNewMember')}>
      <DialogCreateBody
        loading={isLoading}
        errorMessage={errorMessage}
        handleSubmit={handleSubmit}
        buttonTitle={t('button.sendInvite')}
        testId="create-member-button"
      >
        <FieldInput
          name="email"
          label={t('form.emailAddress')}
          placeHolder={t('form.exampleEmailAddress')}
          value={values.email}
          onChange={a => setFieldValue('email', a)}
          error={errors.email}
          touched={touched.email}
          handleBlur={handleBlur}
        />
      </DialogCreateBody>
    </Dialog>
  )
}
