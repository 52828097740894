export const mockSuccessResponse = <A>(data: A) => ({
  data,
  status: 200
})

export const mockStandardSuccessResponse = mockSuccessResponse({ message: 'success' })

export const mockSuccessResponse204 = <A>(data: A) => ({
  data,
  status: 204
})

export const emptyListResponse = {
  data: [],
  object: 'validation_request_admin',
  totalResults: 0,
  pageSize: 25,
  _links: {
    current: 'https://staging-api.poweredbypercent.com/v1/admin/validation-requests?status=pending'
  }
}
