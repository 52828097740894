import React, { useEffect, useState } from 'react'
import { SplitClient } from '@splitsoftware/splitio-react'

import { EnvironmentOverlay } from './Layout/environmentOverlay/EnvironmentOverlay'
import { UnprotectedRoutes } from './auth/UnprotectedRoutes'
import './App.scss'
import { AppProviders } from './providers/AppProviders'
import { splitSdkConfig } from './providers/SplitSdkProvider'
import { useAuthState } from '@percent/admin-dashboard/common/hooks'
import { getSplitKeyForUser } from '@percent/utility'

export function UnconnectedApp() {
  const { user } = useAuthState()

  const [configKey, setConfigKey] = useState(splitSdkConfig.core.key)

  useEffect(() => {
    if (user) {
      const key = getSplitKeyForUser(user)

      if (configKey !== key) {
        setConfigKey(key)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  return (
    <SplitClient splitKey={configKey} updateOnSdkTimedout>
      <AppProviders>
        <EnvironmentOverlay />
        <UnprotectedRoutes />
      </AppProviders>
    </SplitClient>
  )
}

export const App = UnconnectedApp
