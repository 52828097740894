import React from 'react'
import { useTranslation } from 'react-i18next'

import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { PartnerScopes } from '@percent/admin-dashboard/constants/scopes'
import { useMutation } from '@percent/admin-dashboard/common/hooks'
import { Dialog, DialogDeleteBody } from '@percent/admin-dashboard/common/components'

type PartnerModuleInactiveDialogProps = {
  openInactiveModal: boolean
  onClose: () => void
  refresh: () => void
  id: string
  scope: PartnerScopes
  moduleTitle: string
}

export function PartnerModuleInactiveDialog({
  openInactiveModal = false,
  onClose,
  id,
  scope,
  refresh,
  moduleTitle
}: PartnerModuleInactiveDialogProps) {
  const { adminService } = useServices()
  const [{ errorMessage }, { apiFunc: mutate }] = useMutation(adminService.commands.removePartnerScope, () => {
    onClose()
    refresh()
  })
  const { t } = useTranslation()

  const handleSubmit = () =>
    mutate({
      id,
      scope
    })

  return (
    <Dialog
      openModal={openInactiveModal}
      onClose={onClose}
      headerTitle={`${t('typography.deactivate')} ${moduleTitle}`}
    >
      <DialogDeleteBody
        bodyText={
          <p>
            <span style={{ fontFamily: 'MarkProMedium' }}>{moduleTitle} </span>
            {t('typography.willBeDeactivated')}
          </p>
        }
        errorMessage={errorMessage}
        handleSubmit={handleSubmit}
        buttonTitle={t('button.inactive')}
        testId="inactive-module-button"
      />
    </Dialog>
  )
}
