import { Dispatch, SetStateAction, useState } from 'react'
import { useFormikContext } from 'formik'
import { Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { AcknowledgeModal, ActionModal, Checkbox, Modal, Spacer } from '@percent/lemonade'
import { SubTagModalFormValues } from './SubTagsModal.types'
import { useFeatureFlag } from '@percent/admin-dashboard/common/hooks/useFeatureFlag/useFeatureFlag'
import { DecoratedActivitySubTag } from '@percent/admin-dashboard/common/hooks/useActivityTags/useActivityTags.types'
import styles from './SubTagsModal.module.scss'

type SubTagsModalProps = {
  isOpened: boolean
  setIsOpened: Dispatch<SetStateAction<boolean>>
  subTags: DecoratedActivitySubTag[]
  organisationName?: string
}
const nonProminentSubtags = [
  'administrative_offices_and_education_boards',
  'research_institutes',
  'teaching_hospitals',
  'certified_teaching_and_training'
]

export function SubTagsModal({ isOpened, setIsOpened, subTags, organisationName }: Readonly<SubTagsModalProps>) {
  const { t } = useTranslation()
  const { newEduStageSubtagFeatureFlag } = useFeatureFlag()
  const [success, setSuccess] = useState<boolean>(false)
  const { values, handleChange, setValues, errors } = useFormikContext<SubTagModalFormValues>()
  const { educationSubtagsNoneOfTheAboveFeatureFlag } = useFeatureFlag()

  const prominentSubTags: DecoratedActivitySubTag[] = []
  const notProminentSubTags: DecoratedActivitySubTag[] = []
  subTags.forEach(tag =>
    tag.humanReadableName && nonProminentSubtags.includes(tag.humanReadableName)
      ? notProminentSubTags.push(tag)
      : prominentSubTags.push(tag)
  )

  const handleClose = () => {
    setIsOpened(false)
    setSuccess(false)
  }

  const handleConfirm = () => {
    setSuccess(true)
  }

  const successModal = success && (
    <AcknowledgeModal
      result="positive"
      title={t('dialog.reviewEligibilityTags.success.title')}
      description={t('dialog.reviewEligibilityTags.success.description', { organisation: organisationName })}
      buttonText={t('button.close')}
      handleClose={handleClose}
    />
  )

  return (
    <Modal open={isOpened} onClose={handleClose} data-testid="validation-request-subtag-modal">
      {successModal || (
        <ActionModal
          title={t('dialog.reviewEligibilityTags.title')}
          primaryButtonText={t('button.updateInformation')}
          secondaryButtonText={t('button.cancel')}
          type="submit"
          variant="primary"
          disabled={!!errors?.insufficientInformation || !!errors?.subTags || !!errors?.notEducation}
          handleClose={handleClose}
          handleSubmit={handleConfirm}
          primaryBtnTestId="review-eligibility-tags-submit-button"
          secondaryBtnTestId="review-eligibility-tags-cancel-button"
          aria-labelledby="review-eligibility-tags-form-modal"
        >
          <Typography>
            <span>{t('dialog.reviewEligibilityTags.description', { organisation: organisationName })}</span>
          </Typography>
          <Spacer size={4} axis="vertical" />

          <div data-testid="prominent-education-sub-tags">
            {prominentSubTags?.map((tag: DecoratedActivitySubTag) => (
              <>
                <Checkbox
                  variant="default"
                  name="subTags"
                  value={tag.id}
                  key={tag.id}
                  onChange={handleChange}
                  disabled={values.insufficientInformation || values.notEducation}
                  active={values.subTags.includes(tag.id)}
                  label={tag.name}
                />
                <Spacer size={2} axis="vertical" />
              </>
            ))}
          </div>

          <div className={styles.divider} />
          <Checkbox
            variant="default"
            name="insufficientInformation"
            value="insufficientInformation"
            onChange={() => {
              setValues({ ...values, insufficientInformation: !values.insufficientInformation, subTags: [] })
            }}
            active={values.insufficientInformation}
            disabled={values.notEducation}
            label={t('typography.insufficientInformation')}
            helperText={t('typography.deselectsAndDisablesOtherOptions')}
          />
          <Spacer size={4} axis="vertical" />
          {newEduStageSubtagFeatureFlag && (
            <div data-testid="non-prominent-education-sub-tags">
              {notProminentSubTags?.map((tag: DecoratedActivitySubTag) => (
                <>
                  <Checkbox
                    variant="default"
                    name="subTags"
                    value={tag.id}
                    key={tag.id}
                    onChange={handleChange}
                    disabled={values.insufficientInformation || values.notEducation}
                    active={values.subTags.includes(tag.id)}
                    label={tag.name}
                  />
                  <Spacer size={2} axis="vertical" />
                </>
              ))}
            </div>
          )}

          {educationSubtagsNoneOfTheAboveFeatureFlag && (
            <>
              <Spacer size={4} axis="vertical" />

              <Checkbox
                variant="default"
                name="notEducation"
                value="notEducation"
                onChange={() => {
                  setValues({ ...values, notEducation: !values.notEducation, subTags: [] })
                }}
                disabled={values.insufficientInformation}
                active={values.notEducation}
                label={t('typography.noneOfTheAbove')}
                helperText={t('typography.deselectsAndDisablesOtherOptions')}
              />
            </>
          )}
        </ActionModal>
      )}
    </Modal>
  )
}
