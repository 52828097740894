import { PBPGetResponse } from '@percent/admin-dashboard/services/pbpResponses'
import { AccountDetailResponse, iamServiceCapabilities } from './iamService.types'

export const iamService = ({ percentClient }: iamServiceCapabilities) => ({
  getRolesForPartner: ({ partnerId }: { partnerId: string }) =>
    percentClient.get(`/v1/admin/iam/partner/${partnerId}/roles`),
  setRoleForAccount: ({ accountId, role }: { accountId: string; role: string }) =>
    percentClient.patch(`/v1/admin/iam/account/${accountId}/role`, { roleId: role }),
  getAccountDetail: ({ accountId }: { accountId: string }) =>
    percentClient.get<PBPGetResponse<AccountDetailResponse>>(`/v1/admin/iam/accounts/${accountId}`)
})
