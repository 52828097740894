import React from 'react'
import { useTranslation } from 'react-i18next'

import { Badge } from '@percent/lemonade'
import styles from './ActivityLogSubTags.module.scss'
import { ActivityLogSubTagsProps } from './ActivityLogSubTags.types'

export function ActivityLogSubTag({ activityEvent, tagsData }: ActivityLogSubTagsProps) {
  const { t } = useTranslation()

  const tags = tagsData
    .filter(tag => tag.subTags.map(subTag => subTag.id).some(subTagId => activityEvent?.subTags?.includes(subTagId)))
    .flatMap(tag => tag.subTags)

  const subTags = activityEvent?.subTags?.map(subTag => tags.find(tag => tag.id === subTag))

  return (
    <div className={styles.subTagsContainer}>
      {subTags?.map(subTag => (
        <React.Fragment key={subTag?.id}>
          <Badge variant="informative" icon="categories">
            {subTag?.name}
          </Badge>
        </React.Fragment>
      )) ?? (
        <Badge variant="default" icon="categories">
          {t('status.cantDefine')}
        </Badge>
      )}
    </div>
  )
}
