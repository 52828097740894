import React, { useEffect, useState } from 'react'
import { getNames, alpha2ToAlpha3 } from 'i18n-iso-countries'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'

import { useMutation } from '@percent/admin-dashboard/common/hooks'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { RegistriesDialogProps } from './RegistriesDialog.types'
import styles from './RegistriesDialog.module.scss'
import { AcknowledgeModal, ActionModal, FormField, Modal, Select, Spacer, Text, TextInput } from '@percent/lemonade'
import { CreateRegistryProps } from '@percent/admin-dashboard/api/actions/registries/registries.types'
import { SelectOption } from 'libs/shared/ui-lemonade/src/components/select/option.types'
import { FieldCheckBox } from '@percent/admin-dashboard/common/components'
import { useFeatureFlag } from '@percent/admin-dashboard/common/hooks/useFeatureFlag/useFeatureFlag'

const nameValidation = Yup.string()
  .trim()
  .min(2, 'Must have at least 2 characters')
  .max(255, 'Must be 255 characters or less')
  .required('Required')

const supportedTypes = ['nonprofit', 'education', 'social_impact'] as const

type SupportedTypes = typeof supportedTypes[number]

const validationSchema = (areTypesRequired: boolean) => {
  const supportedTypesSchema = Yup.array()
    .of(
      Yup.mixed<SupportedTypes>()
        // apparently oneOf doesn't like readonly tuples
        .oneOf([...supportedTypes])
        .required()
    )
    .min(1, 'At least one type must be selected')
    .required('Required')
    .test('Unique', 'Values need te be unique', values => {
      return new Set(values).size === values!.length
    })

  return Yup.object().shape({
    countryCode: Yup.string().trim().max(3, 'Must be 2 characters or less').required('Required'),
    name: nameValidation,
    englishName: nameValidation,
    code: Yup.string().trim().max(10, 'Must be 10 characters or less').required('Required'),
    ...(areTypesRequired && {
      supportedTypes: supportedTypesSchema
    })
  })
}

export function RegistriesDialog({ state, onClose, refresh }: RegistriesDialogProps) {
  const { t } = useTranslation()
  const { registriesService } = useServices()
  const [successDialogState, setSuccessDialogState] = useState(false)
  const [errorDialogState, setErrorDialogState] = useState(false)
  const countriesList = Object.entries(getNames('en')).map(country => {
    return { label: country[1], value: alpha2ToAlpha3(country[0]) }
  })
  const [selectedValue, setSelectedValue] = useState<SelectOption>({
    label: '',
    value: ''
  })
  const { registriesRequiredTypesFeatureFlag } = useFeatureFlag()

  function assertIsDefined<T>(x: T): asserts x is NonNullable<T> {
    if (x === undefined || x === null) {
      throw new Error('Expected value to be defined')
    }
  }

  useEffect(() => {
    if (state) {
      setSuccessDialogState(false)
      setErrorDialogState(false)
    }
  }, [state])

  const [{ isLoading, errorMessage }, { apiFunc }] = useMutation(
    registriesService.createRegistry,
    () => {
      refresh()
      setSuccessDialogState(true)
    },
    () => {
      setErrorDialogState(true)
    }
  )

  const {
    values,
    errors,
    handleSubmit,
    setFieldValue,
    touched,
    resetForm,
    handleBlur,
    handleChange,
    dirty,
    isValid,
    setFieldTouched
  } = useFormik({
    initialValues: {
      countryCode: '',
      name: '',
      englishName: '',
      code: '',
      supportedTypes: []
    },
    validationSchema: validationSchema(registriesRequiredTypesFeatureFlag),
    onSubmit: async (fieldValues: CreateRegistryProps) => {
      const castValues = validationSchema(registriesRequiredTypesFeatureFlag).validateSync(fieldValues, {
        stripUnknown: true
      })

      const payload: CreateRegistryProps = {
        countryCode: castValues.countryCode,
        name: castValues.name,
        englishName: castValues.englishName,
        code: castValues.code
      }

      if (registriesRequiredTypesFeatureFlag) {
        assertIsDefined(castValues.supportedTypes)
        payload.supportedTypes = castValues.supportedTypes
      }

      await apiFunc(payload)
    }
  })

  const handleOnClose = () => {
    onClose()
    resetForm()
    setSelectedValue({
      label: '',
      value: ''
    })
  }

  const handleOnErrorClose = () => {
    setErrorDialogState(false)
  }

  const successModal = successDialogState && (
    <AcknowledgeModal
      result="positive"
      title={t('dialog.addRegistry.successTitle')}
      description={t('dialog.addRegistry.successDescription', {
        name: values.name,
        country: countriesList.find(country => values.countryCode === country.value)?.label
      })}
      buttonText={t('button.done')}
      handleClose={handleOnClose}
    />
  )

  const errorModal = errorDialogState && (
    <AcknowledgeModal
      result="negative"
      title={t('dialog.addRegistry.errorTitle')}
      description={errorMessage}
      buttonText={t('button.done')}
      handleClose={handleOnErrorClose}
    />
  )

  return (
    <Modal open={state} onClose={onClose} aria-labelledby="add-registries-form-modal">
      <div className={styles.modalContentBox}>
        <div className={styles.modalContentWrapper}>
          {successModal || errorModal || (
            <ActionModal
              title={t('dialog.addRegistry.header')}
              primaryButtonText={t('button.addRegistry')}
              secondaryButtonText={t('button.cancel')}
              type="submit"
              disabled={!(dirty && isValid)}
              loading={isLoading}
              handleClose={() => {
                onClose()
                resetForm()
                setSelectedValue({
                  label: '',
                  value: ''
                })
              }}
              handleSubmit={handleSubmit}
              primaryBtnTestId="btn-add-registry"
              secondaryBtnTestId="btn-cancel-registry"
            >
              <Text size="small">{t('dialog.addRegistry.description')}</Text>
              <Spacer axis="vertical" size={2} />
              <form onSubmit={handleSubmit}>
                <FormField
                  label={t('dialog.addRegistry.countryLabel')}
                  status={errors.countryCode ? 'danger' : 'default'}
                  statusMessage={errors.countryCode}
                  necessity="required"
                >
                  <Select
                    placeholder={t('dialog.addRegistry.countryLabel')}
                    searchable={false}
                    options={countriesList}
                    onChange={event => {
                      setFieldValue('countryCode', event.value)
                      setSelectedValue({
                        label: event.label,
                        value: event.value
                      })
                    }}
                    defaultValue={selectedValue}
                  />
                </FormField>
                <Spacer axis="vertical" size={2} />
                <FormField
                  label={t('dialog.addRegistry.legalNameLabel')}
                  status={touched.name && errors.name ? 'danger' : 'default'}
                  statusMessage={errors.name}
                  description={t('dialog.addRegistry.descriptionLegalName')}
                  data-testid="registryName"
                  necessity="required"
                >
                  <TextInput
                    name="name"
                    value={values.name}
                    placeholder={t('dialog.addRegistry.placeholderLegalName')}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </FormField>
                <Spacer axis="vertical" size={2} />
                <FormField
                  label={t('dialog.addRegistry.englishNameLabel')}
                  status={touched.englishName && errors.englishName ? 'danger' : 'default'}
                  statusMessage={errors.englishName}
                  description={t('dialog.addRegistry.descriptionEnglishName')}
                  data-testid="registryEnglishName"
                  necessity="required"
                >
                  <TextInput
                    name="englishName"
                    value={values.englishName}
                    placeholder={t('dialog.addRegistry.placeholderEnglishName')}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </FormField>
                <Spacer axis="vertical" size={2} />
                <FormField
                  label={t('dialog.addRegistry.registryCodeNameLabel')}
                  status={touched.code && errors.code ? 'danger' : 'default'}
                  statusMessage={errors.code}
                  description={t('dialog.addRegistry.descriptionCode')}
                  data-testid="code"
                  necessity="required"
                >
                  <TextInput
                    name="code"
                    value={values.code}
                    placeholder={t('dialog.addRegistry.placeholderCodeName')}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </FormField>
                <Spacer axis="vertical" size={2} />
                {registriesRequiredTypesFeatureFlag && (
                  <FormField
                    label={t('registrySupportedTypes.label')}
                    status={touched.supportedTypes && errors.supportedTypes ? 'danger' : 'default'}
                    statusMessage={errors.supportedTypes}
                    data-testid="supportedTypes"
                    necessity="required"
                  >
                    <>
                      <Text size="small">{t('registrySupportedTypes.description')}</Text>

                      <Grid container spacing={3} direction="row" role="group">
                        {supportedTypes.map(key => {
                          return (
                            <Grid item xs key={key}>
                              <FieldCheckBox
                                name="supportedTypes"
                                key={key}
                                checkboxClass="supportedTypeCheckbox"
                                onChange={async (...p) => {
                                  await setFieldTouched('supportedTypes', true, false)
                                  handleChange(...p)
                                }}
                                value={key}
                              >
                                <Text size="small">{t(`registrySupportedType.label.${key}`)}</Text>
                              </FieldCheckBox>
                            </Grid>
                          )
                        })}
                      </Grid>
                    </>
                  </FormField>
                )}
              </form>
            </ActionModal>
          )}
        </div>
      </div>
    </Modal>
  )
}
