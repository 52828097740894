import React from 'react'
import { StringParam, useQueryParams, withDefault } from 'use-query-params'

import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { useDidMountEffect, useQueryList } from '@percent/admin-dashboard/common/hooks'
import { ActivityCheckTable } from './activityCheckTable/ActivityCheckTable'
import { ActivityCheckStatus } from '@percent/admin-dashboard/api/types'

export function ActivityCheckContainer() {
  const { activityCheckService } = useServices()
  const [queryParams, setQueryParams] = useQueryParams({
    direction: StringParam,
    sort: StringParam,
    startDate: StringParam,
    endDate: StringParam,
    status: withDefault(StringParam, ActivityCheckStatus.PENDING),
    query: StringParam
  })

  const [{ totalResults, dataOrNull, errorMessageOrNull, isLoading }, { query, nextPage, previousPage }] = useQueryList(
    activityCheckService.getActivityChecks,
    {
      ...queryParams,
      status: queryParams.status ? (queryParams.status as ActivityCheckStatus) : undefined
    }
  )

  useDidMountEffect(() => query(queryParams), [queryParams, query])

  return (
    <ActivityCheckTable
      activities={dataOrNull}
      totalResults={totalResults}
      nextPage={nextPage}
      previousPage={previousPage}
      isLoading={isLoading}
      errorMessage={errorMessageOrNull}
      queryParams={queryParams}
      setQueryParams={setQueryParams}
    />
  )
}
