import { Card, Divider } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'

import styles from './ActivityCheckSubmitCard.module.scss'
import { PercentButton } from '@percent/admin-dashboard/common/components'
import { WebsiteCopyLink } from '@percent/admin-dashboard/common/components/websiteCopyLink/WebsiteCopyLink'
import { ActivityCheckSubmitCardProps } from './ActivityCheckDetail.types'

export function ActivityCheckSubmitCard({ setIsOpened, orgWebsite, orgName }: ActivityCheckSubmitCardProps) {
  const { t } = useTranslation()

  return (
    <Card className={styles.wrapper}>
      <Divider flexItem className={styles.divider} />
      <div className={styles.contentWrapper}>
        <div className={styles.organisationInfoWrapper}>
          <span className={styles.text}>{orgName}</span>
          <WebsiteCopyLink orgWebsite={orgWebsite} />
        </div>
        <div className={styles.buttonWrapper}>
          <PercentButton title={t('button.submitCode')} onClick={() => setIsOpened(true)} />
        </div>
      </div>
    </Card>
  )
}
