import { rest } from 'msw'

import { config } from '@percent/admin-dashboard/config'
import { mockOrganisationResponse } from '@percent/admin-dashboard/fixtures/api/mockOrganisationResponse'

export const organizationsHandlers = [
  rest.patch(`${config.urls.api}v1/admin/organisations/:id`, (_, res, ctx) => {
    return res(ctx.status(200))
  }),
  rest.post(`${config.urls.api}v1/admin/organisations/:id/unclaim`, (_, res, ctx) => {
    return res(ctx.status(204), ctx.json({}))
  }),
  rest.get(`${config.urls.api}v1/admin/organisations/:id`, async (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockOrganisationResponse.data))
  })
]
