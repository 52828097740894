import { Alpha3Code } from 'i18n-iso-countries'

export enum BankDetailStatus {
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected'
}

type BankDetailStatusTypes = BankDetailStatus.PENDING | BankDetailStatus.APPROVED | BankDetailStatus.REJECTED

enum BankAccountType {
  DOMESTIC = 'DOMESTIC',
  IBAN = 'IBAN',
  DOMESTICWITHIBAN = 'DOMESTIC_WITH_BIC'
}
type BankAccountTypes = BankAccountType.DOMESTIC | BankAccountType.IBAN | BankAccountType.DOMESTICWITHIBAN

type BankAccountDetails = {
  version?: string
  holderName?: string
  wireTransferDetails: {
    type?: BankAccountTypes
    accountNumber?: string
    accountNumberLabel?: string
    bankCodeLabel?: string
    bankCode?: string
    iban?: string
    ibanLabel?: string
    bicLabel?: string
    bic?: string
    bicCodeLabel?: string
    bicCode?: string
    branchCode?: string
    branchCodeLabel?: string
  }
}

type RequiredBankAccountDetailsFields = {
  [fieldName: string]: {
    errorMessage: string
    label: string
    regex: string
    required: boolean
    hint?: string
  }
}

export type BankDetail = {
  claimer?: {
    email: string
  }
  countryCode?: Alpha3Code
  organisationId?: string
  organisationName?: string
  status?: BankDetailStatusTypes
  requestDate?: string
  previousFilename?: string
  bankAccountDetails?: BankAccountDetails
  requiredBankAccountDetails?: {
    type: BankAccountType.DOMESTIC | BankAccountType.IBAN
    fields: RequiredBankAccountDetailsFields
  }
}

export type BankDetailsFormDialogProps = {
  open: boolean
  organisationId: string
  organisationName: string
  onClose: VoidFunction
  refresh: VoidFunction
  bankDetailsData: BankDetail
}
