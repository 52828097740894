import { rest } from 'msw'

import { config } from '@percent/admin-dashboard/config'
import { mockVettingRequests } from '@percent/admin-dashboard/fixtures/api/mockVettingRequests'

export const vettingRequestsHandlers = [
  rest.get(`${config.urls.api}v1/admin/vetting-requests`, async (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockVettingRequests))
  })
]
