export const mockValidationSubmissionsResponse = {
  data: [
    {
      id: 'staging_validationsubmission_000000CxXywPAPvqoyZhF8NsJZnUh',
      createdAt: '2024-10-30T10:54:47.849Z',
      status: 'succeeded',
      partner: {
        id: 'staging_partner_XXXXXXXXXX',
        name: '[GS-INTERNAL] Causes Signup'
      },
      configuration: {
        id: 'staging_hostedconfiguration_000000CgDnN0eCPKOL9SHJNNRxHGW',
        name: '(Internal) Causes Existing Organisations'
      },
      agentVerification: {
        email: 'test.email+26255bfc-9dfd-4fbf-8aca-60a72bee18d6@16dn19xy.mailosaur.net'
      },
      organisation: {
        id: 'staging_organisation_000000CxXywIIxn1rnPXl2YZZbUtH',
        name: 'Natalia NM AUTOMATED TEST bd685eef-34cf-43d9-9369-1a1a5b8d7467',
        countryCode: 'GBR'
      },
      validationRequest: {}
    },
    {
      id: 'staging_validationsubmission_000000CxXywPAPvqoyZhF8NsJZnU4',
      createdAt: '2024-10-30T10:54:47.479Z',
      status: 'failed',
      partner: {
        id: 'staging_partner_XXXXXXXXXX',
        name: '[GS-INTERNAL] Causes Signup'
      },
      configuration: {
        id: 'staging_hostedconfiguration_000000CgDnN0eCPKOL9SHJNNRxHGW',
        name: '(Internal) Causes Existing Organisations'
      },
      agentVerification: {
        email: 'test.email+45b67486-26fb-48cb-8585-58558f339974@16dn19xy.mailosaur.net'
      },
      organisation: {
        id: 'staging_organisation_000000CxXywIK0PRpOd04o6vuJziS',
        name: 'Natalia NM AUTOMATED TEST c88e2f8e-b0db-4cc5-8c5a-7c7001d46fa5',
        countryCode: 'ISR'
      },
      validationRequest: {}
    },
    {
      id: 'staging_validationsubmission_000000CxXywBSYGcsDSqaiHdALh8X',
      createdAt: '2024-10-30T10:54:45.778Z',
      status: 'pending',
      partner: {
        id: 'staging_partner_XXXXXXXXXX',
        name: '[GS-INTERNAL] Causes Signup'
      },
      configuration: {
        id: 'staging_hostedconfiguration_000000CgDnN0eCPKOL9SHJNNRxHGW',
        name: '(Internal) Causes Existing Organisations'
      },
      agentVerification: {
        email: 'test.email+1ea8534d-d071-464f-9b0e-d67dd3e56364@16dn19xy.mailosaur.net'
      },
      organisation: {
        id: 'staging_organisation_000000CxXyw4b67nv2Ih6cSKQNOYB',
        name: 'Natalia NM AUTOMATED TEST 9716b5f0-6109-4649-b5e9-727c96ee1f41',
        countryCode: 'ISR'
      },
      validationRequest: {}
    }
  ],
  totalResults: 3,
  object: 'admin/validation-submissions',
  _links: {
    current: 'https://staging-api.poweredbypercent.com/v1/admin/validation-submissions?pageSize=25',
    next: 'https://staging-api.poweredbypercent.com/v1/admin/validation-submissions?pageSize=25&cursor=eyJ2IjoxLCJwIjoibmV4dCIsImlkIjoic3RhZ2luZ192YWxpZGF0aW9uc3VibWlzc2lvbl8wMDAwMDBDeFh5bW05dllMbUpLTzB4T1pueGliTiIsInZhbCI6InN0YWdpbmdfdmFsaWRhdGlvbnN1Ym1pc3Npb25fMDAwMDAwQ3hYeW1tOXZZTG1KS08weE9abnhpYk4iLCJmIjoiaWQiLCJkIjoiREVTQyJ9'
  }
}

export const mockValidationSubmissionsNoOrganisationResponse = {
  data: [
    {
      id: 'staging_validationsubmission_000000CxXywPAPvqoyZhF8NsJZnUh',
      createdAt: '2024-10-30T10:54:47.849Z',
      status: 'pending',
      partner: {
        id: 'staging_partner_XXXXXXXXXX',
        name: '[GS-INTERNAL] Causes Signup'
      },
      configuration: {
        id: 'staging_hostedconfiguration_000000CgDnN0eCPKOL9SHJNNRxHGW',
        name: '(Internal) Causes Existing Organisations'
      },
      agentVerification: {
        email: 'test.email+26255bfc-9dfd-4fbf-8aca-60a72bee18d6@16dn19xy.mailosaur.net'
      },
      organisation: {},
      validationRequest: {
        id: 'staging_organisation_000000CxXywIIxn1rnPXl2YZZbUtH',
        name: 'Natalia NM AUTOMATED ORGANISATION',
        countryCode: 'GBR'
      }
    }
  ],
  totalResults: 1,
  object: 'admin/validation-submissions',
  _links: {
    current: 'https://staging-api.poweredbypercent.com/v1/admin/validation-submissions?pageSize=25',
    next: 'https://staging-api.poweredbypercent.com/v1/admin/validation-submissions?pageSize=25&cursor=eyJ2IjoxLCJwIjoibmV4dCIsImlkIjoic3RhZ2luZ192YWxpZGF0aW9uc3VibWlzc2lvbl8wMDAwMDBDeFh5bW05dllMbUpLTzB4T1pueGliTiIsInZhbCI6InN0YWdpbmdfdmFsaWRhdGlvbnN1Ym1pc3Npb25fMDAwMDAwQ3hYeW1tOXZZTG1KS08weE9abnhpYk4iLCJmIjoiaWQiLCJkIjoiREVTQyJ9'
  }
}
