import { Box, DialogContent, Typography } from '@material-ui/core'
import { useMemo } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'

import { Dialog, FieldInputCurrency } from '@percent/admin-dashboard/common/components'
import { useCurrencies } from '@percent/admin-dashboard/common/hooks'
import { Button } from '@percent/lemonade'
import { FieldDropDown } from '@percent/admin-dashboard/common/components/fieldDropDown/FieldDropDown'
import styles from './MarkAsPaidModal.module.scss'
import { MarkAsPaidModalProps } from './MarkAsPaidModal.types'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { dayJS } from '@percent/admin-dashboard/common/library/utility/date'
import { useToast } from '@percent/admin-dashboard/containers/toast/ToastContext'
import { formatAmountFromMinorUnits } from '@percent/admin-dashboard/common/utility/formatAmount/formatAmount'
import { getCurrencyMinorUnits } from '@percent/admin-dashboard/common/utility/getCurrencyMinorUnits'

export function MarkAsPaidModal({ isOpen, onClose, disbursementDetails, refreshData }: MarkAsPaidModalProps) {
  const { t } = useTranslation()
  const { currencyInfo } = useCurrencies()
  const { disbursementsService } = useServices()
  const addToast = useToast()

  const currencyOptions = useMemo(() => {
    return currencyInfo?.map(currency => ({
      title: `${currency.name} (${currency.code})`,
      value: currency.code
    }))
  }, [currencyInfo])

  const { errors, values, setFieldValue, handleSubmit, resetForm, isValid, dirty, handleBlur, touched } = useFormik({
    initialValues: {
      amount: '',
      currency: disbursementDetails.foundationCurrencyCode
    },
    validationSchema: () =>
      Yup.object().shape({
        amount: Yup.string()
          .trim()
          .required('Required')
          .matches(
            getCurrencyMinorUnits(currency, currencyInfo) === 0
              ? /^[0-9]{1,11}?$/
              : new RegExp(`^[0-9]{1,11}(?:.[0-9]{1,${getCurrencyMinorUnits(currency, currencyInfo)}})?$`),
            `The maximum number of decimals is ${getCurrencyMinorUnits(currency, currencyInfo)}`
          ),
        currency: Yup.string().required('Required')
      }),
    onSubmit: async ({ amount, currency }) => {
      try {
        await disbursementsService.markDisbursementAsPaid({
          id: disbursementDetails?.id || '',
          paidAt: dayJS().toISOString(),
          paidAmount: Math.round(parseFloat(amount) * 10 ** getCurrencyMinorUnits(currency, currencyInfo)),
          paidCurrency: currency
        })

        addToast(t('toast.markDisbursementAsPaidSuccess'), 'success')
        refreshData?.()
        handleClose()
      } catch {
        addToast(t('toast.markDisbursementAsPaidError'), 'error')
      }
    }
  })

  const handleClose = () => {
    resetForm()
    onClose()
  }

  const { amount, currency } = values

  return (
    <Dialog headerTitle={t('dialog.markAsPaid.header')} openModal={isOpen} onClose={handleClose}>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Box className={styles.fieldsWrapper}>
            <FieldInputCurrency
              name="amount"
              label={t('dialog.markAsPaid.form.amount.label')}
              placeHolder={t('dialog.markAsPaid.form.amount.placeholder')}
              value={amount}
              onChange={value => setFieldValue('amount', value)}
              minorUnits={getCurrencyMinorUnits(currency, currencyInfo)}
              handleBlur={handleBlur}
              touched={touched.amount}
              error={errors.amount}
            />
            {currencyOptions && (
              <FieldDropDown
                selectTitle={t('dialog.markAsPaid.form.currency.label')}
                placeholder={t('dialog.markAsPaid.form.currency.placeholder')}
                valueArray={currencyOptions}
                initialValue={currency}
                onClick={event => {
                  setFieldValue('currency', event.target.value)
                }}
              />
            )}
          </Box>
          <Typography variant="body2">
            {t('dialog.markAsPaid.initialAmount')}{' '}
            {disbursementDetails &&
              formatAmountFromMinorUnits({
                currencyCode: disbursementDetails.foundationCurrencyCode,
                value: disbursementDetails.foundationAmount,
                currencies: currencyInfo
              })}{' '}
            {disbursementDetails?.foundationCurrencyCode}
          </Typography>
          <Box className={styles.buttonsWrapper}>
            <Button size="large" type="submit" disabled={!(isValid && dirty)} data-testid="submit-button">
              {t('button.markAsPaid')}
            </Button>
            <Button onPress={handleClose} variant="secondary" size="large" type="button">
              {t('button.cancel')}
            </Button>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  )
}
