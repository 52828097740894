import { Route, Switch } from 'react-router-dom'

import { LoginWithTwoFA } from '../login/TwoFactorAuthentication/LoginWithTwoFA/LoginWithTwoFA'
import { LoginWithRecoveryCodeContainer } from '../login/TwoFactorAuthentication/LoginWithRecoveryCode/LoginWithRecoveryCodeContainer'

import { Login } from '@percent/admin-dashboard/app/login/Login'
import { TwoFactorAuthenticationSetup } from '@percent/admin-dashboard/app/login/TwoFactorAuthentication/TwoFactorAuthenticationSetup'
import { ForgotPassword } from '@percent/admin-dashboard/app/forgotPassword/ForgotPassword'
import { SetPassword } from '@percent/admin-dashboard/app/setPassword/SetPassword'
import { ExpiredSessionHandle } from '@percent/admin-dashboard/app/expiredSessionHandle/ExpiredSessionHandle'
import { AuthorisedRoutes } from './AuthorisedRoutes'

export function UnprotectedRoutes() {
  return (
    <Switch>
      <Route component={Login} exact path="/login" />
      <Route component={LoginWithRecoveryCodeContainer} exact path="/login/2fa-recovery" />
      <Route component={ForgotPassword} path="/forgot-password" />
      <Route component={SetPassword} path="/set-password" />
      <Route component={LoginWithTwoFA} exact path="/login/2fa" />
      <Route component={TwoFactorAuthenticationSetup} exact path="/login/2fa-setup" />
      <Route component={ExpiredSessionHandle} exact path="/expired-session" />
      {AuthorisedRoutes()}
    </Switch>
  )
}
