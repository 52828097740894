import { AxiosResponse } from 'axios'

export type PBPLinks = {
  current: string
  next?: string
  prev?: string
}

export type PBPListResponse<A> = {
  data: A[]
  object: string
  totalResults: number
  pageSize: number
  _links: PBPLinks
}

export type PBPGetResponse<A> = {
  data: A
  object: string
}

export const getDataFromPBPResponse = <A>({ data }: AxiosResponse<PBPGetResponse<A>>) => data.data
