import { AxiosResponse } from 'axios'

import { PBPGetResponse, PBPListResponse } from '@percent/admin-dashboard/services/pbpResponses'
import {
  donationMatchRequestsServiceCapabilities,
  DonationMatchRequestReceipt,
  GetMatchRequestListProps,
  DonationMatchRequestEnhanced,
  DonationMatchRequest,
  PostDonationMatchRequestApproveProps,
  PostDonationMatchRequestRejectProps,
  PostDonationMatchRequestVerify,
  UpdateDonationMatchRequestOrganisationProps,
  EditDonationMatchRequestAmountProps
} from './donationMatchRequests.types'
import { Organisation, Partner } from '@percent/admin-dashboard/api/actions/admin/admin.types'
import { CurrencyCode, createMoney } from '@percent/utility'

export const donationMatchRequestsService = ({ percentClient }: donationMatchRequestsServiceCapabilities) => ({
  getMatchRequests: async (
    params: GetMatchRequestListProps
  ): Promise<AxiosResponse<PBPListResponse<DonationMatchRequestEnhanced>>> => {
    const matchRequests = await percentClient.get<PBPListResponse<DonationMatchRequest>>('v1/donation-match-requests', {
      params: {
        ...params,
        pageSize: 25
      }
    })

    if (matchRequests.data.data.length === 0 || matchRequests.status !== 200) {
      return matchRequests as AxiosResponse<PBPListResponse<DonationMatchRequestEnhanced>>
    }

    const partnerIds = Array.from(new Set(matchRequests.data.data.map(({ partnerId }) => partnerId)))
    const organisationIds = Array.from(
      new Set(matchRequests.data.data.map(({ organisationId }) => organisationId).filter(item => item !== null))
    )
    const [partners, organisations] = await Promise.all([
      percentClient.get<PBPListResponse<Partner>>('v1/admin/partners', { params: { partnerIds } }),
      organisationIds.length > 0 &&
        percentClient.get<PBPListResponse<Organisation>>('v1/admin/organisations', { params: { organisationIds } })
    ])

    if (partners.status !== 200) {
      return partners as unknown as AxiosResponse<PBPListResponse<DonationMatchRequestEnhanced>>
    }

    if (organisations && organisations.status !== 200) {
      return organisations as unknown as AxiosResponse<PBPListResponse<DonationMatchRequestEnhanced>>
    }

    const partnerMap = new Map(partners.data.data.map(props => [props.id, props]))
    const organisationsMap = new Map(organisations ? organisations.data.data.map(props => [props.id, props]) : [])

    return {
      ...matchRequests,
      data: {
        ...matchRequests.data,
        data: matchRequests.data.data.map(props => ({
          ...props,
          partner: partnerMap.get(props.partnerId)!,
          organisation: props.organisationId ? organisationsMap.get(props.organisationId)! : null
        }))
      }
    }
  },
  get: async ({ id }: { id: string }) => {
    const data = await percentClient.get<PBPGetResponse<DonationMatchRequest>>(`v1/donation-match-requests/${id}`)

    return data
  },
  getReceipt: async ({ id }: { id: string }) => {
    const data = await percentClient.get<PBPGetResponse<DonationMatchRequestReceipt>>(
      `v1/donation-match-requests/${id}/receipt`
    )

    return data
  },
  postDonationMatchRequestApprove: async ({ donationMatchRequestId }: PostDonationMatchRequestApproveProps) => {
    const data = await percentClient.post<PBPGetResponse<DonationMatchRequest>>(
      `v1/donation-match-requests/${donationMatchRequestId}/approve`
    )

    return data
  },
  postDonationMatchRequestReject: async ({ donationMatchRequestId, reason }: PostDonationMatchRequestRejectProps) => {
    const data = await percentClient.post<PBPGetResponse<DonationMatchRequest>>(
      `v1/donation-match-requests/${donationMatchRequestId}/reject`,
      {
        reason
      }
    )

    return data
  },
  verifyDonationMatchRequestOrganisation: async ({
    donationMatchRequestId,
    organisationDetails
  }: PostDonationMatchRequestVerify) => {
    const data = await percentClient.post<PBPGetResponse<DonationMatchRequest>>(
      `/v1/donation-match-requests/${donationMatchRequestId}/verify`,
      {
        organisationDetails
      }
    )

    return data
  },
  updateDonationMatchRequestOrganisation: async ({
    donationMatchRequestId,
    organisationId
  }: UpdateDonationMatchRequestOrganisationProps) => {
    const data = await percentClient.patch<PBPGetResponse<DonationMatchRequest>>(
      `v1/donation-match-requests/${donationMatchRequestId}`,
      {
        organisationId
      }
    )

    return data
  },
  editDonationMatchRequestAmount: async ({ donationMatchRequestId, amount }: EditDonationMatchRequestAmountProps) => {
    const updatedAmountToMinorUnits = createMoney(amount.amount, amount.currency as CurrencyCode)
    const data = await percentClient.patch<PBPGetResponse<DonationMatchRequest>>(
      `v1/donation-match-requests/${donationMatchRequestId}`,
      {
        amount: updatedAmountToMinorUnits
      }
    )

    return data
  }
})
