import { rest } from 'msw'

import { config } from '@percent/admin-dashboard/config'
import { mockMonitoringList } from '@percent/admin-dashboard/fixtures/api/monitoring/mockMonitoringList'

export const monitoringHandlers = [
  rest.get(`${config.urls.api}v1/admin/monitoring`, async (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockMonitoringList))
  })
]
