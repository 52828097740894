import { Alpha3Code } from 'i18n-iso-countries'

export const mockRegistriesList = {
  data: [
    {
      id: 'registry_000000C6zgRzfNPscnOldvVLXhkOX',
      code: 'MPFBH',
      name: 'Ministarstvo pravde FBiH',
      englishName: 'Ministry of Justice',
      countryCode: 'BIH' as Alpha3Code
    },
    {
      id: 'registry_000000C6zgRzfNPscnOldvVLXhkOY',
      code: 'GBR',
      name: 'Charity Commission',
      englishName: 'Charity Commission',
      exampleId: null,
      countryCode: 'GBR' as Alpha3Code,
      globalGivingCode: 'CHC',
      registryIdFormatRegex: '/^\\d{5,7}$/'
    },
    {
      id: 'staging_registry_000000CWpCgRc8mJXe53O6Eexsxpw',
      code: 'AUS',
      name: 'Testing Registry',
      englishName: 'Testing Registry',
      exampleId: null,
      countryCode: 'AUS' as Alpha3Code,
      globalGivingCode: 'TST'
    }
  ],
  totalResults: 2,
  pageSize: 10,
  _links: {
    current: 'https://.../v1/...?query=climate&page=4'
  },
  exhaustiveTotalResults: true,
  object: 'registry_admin'
}

export const mockRegistriesSearchList = {
  data: [mockRegistriesList.data[0]],
  totalResults: 1,
  pageSize: 10,
  _links: {
    current: 'https://.../v1/...?query=climate&page=4'
  },
  exhaustiveTotalResults: true,
  object: 'registry_admin'
}

export const mockGetRegistriesListEmpty = {
  data: [],
  totalResults: 0,
  _links: {
    current: 'https://staging-api.poweredbypercent.com/v1/admin/regsitries'
  },
  object: 'registry_admin'
}

export const mockRegistries = [
  {
    id: 'staging_registry_000000C6l0xkGyM1bREbtiQmIV9IM',
    code: 'CHC',
    name: 'Charity Commission',
    exampleId: null,
    countryCode: 'GBR',
    englishName: 'Charity Commission',
    globalGivingCode: 'CHC',
    deprecated: false,
    registryIdFormatRegex: '/^\\d{5,7}$/'
  }
]
