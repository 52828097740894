import { useTranslation } from 'react-i18next'

import { Alert } from '@percent/lemonade'
import { FraudulentFlagsAlertProps } from './FraudFlagsAlert.types'
import styles from './FraudFlagsAlert.module.scss'
import { useFeatureFlag } from '@percent/admin-dashboard/common/hooks/useFeatureFlag/useFeatureFlag'
import { LocaleKey } from '@percent/admin-dashboard/i18n/config'

export function FraudFlagsAlert({ title, flags }: Readonly<FraudulentFlagsAlertProps>) {
  const { agentVerificationManualFradulentRejectionFeatureFlag } = useFeatureFlag()
  const { t } = useTranslation()

  if (!agentVerificationManualFradulentRejectionFeatureFlag || !flags.length) return null

  return (
    <div className={styles.fraudAlertWrapper}>
      <Alert title={title} variant="info">
        {flags.map(flag => (
          <div key={flag.id}>{t(`fraudReason.label.${flag.code}` as LocaleKey)}</div>
        ))}
      </Alert>
    </div>
  )
}
