import { Grid, Paper, Typography } from '@material-ui/core'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { DonationsPerNonprofit } from './DonationsPerNonprofit/DonationsPerNonprofit'
import { IndividualDonations } from './IndividualDonations/IndividualDonations'
import { ErrorView, FoundationNameWithFlag } from '@percent/admin-dashboard/common/components'
import { useFoundations, useCSVDownloader, useCurrencies } from '@percent/admin-dashboard/common/hooks'
import { dayJS } from '@percent/admin-dashboard/common/library/utility/date'
import { formatAmountFromMinorUnits } from '@percent/admin-dashboard/common/utility/formatAmount/formatAmount'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { Badge, Button, Copy } from '@percent/lemonade'
import styles from './DonationRequestDetails.module.scss'
import { MarkAsCancelledModal } from './MarkAsCancelledModal/MarkAsCancelledModal'
import { MarkAsSettledModal } from './MarkAsSettledModal/MarkAsSettledModal'
import { DonationRequestDetailsProps } from './DonationRequestDetails.types'
import { DonationRequestStatus } from '@percent/admin-dashboard/api/actions/donationRequests/donationRequests.types'

export function DonationRequestsDetails({
  data: {
    id,
    end,
    start,
    amount,
    currency,
    subsidiaryName,
    partnerName,
    foundationId,
    status,
    settledAt,
    createdAt,
    reference,
    amounts
  },
  refresh
}: DonationRequestDetailsProps) {
  const [markAsSettledModalOpen, setMarkAsSettledModalOpen] = useState<boolean>(false)
  const [markAsCancelledModalOpen, setMarkAsCancelledModalOpen] = useState<boolean>(false)
  const { foundations } = useFoundations()

  const { t } = useTranslation()
  const { currencyInfo } = useCurrencies()
  const {
    donationRequestsService: { getReport }
  } = useServices()
  const { push } = useHistory()

  const [{ loading, errorCSVMessage }, { downloadCSV }] = useCSVDownloader({
    service: () => getReport({ id }),
    fileName: `donation_request_${dayJS(start).format('YYYY-MM-DD')}_${dayJS(end).format('YYYY-MM-DD')}_${id}`,
    successMessage: t('toast.donationRequestReportSuccess')
  })

  const handleReportDownload = () => {
    if (downloadCSV) {
      downloadCSV()
    }
  }

  if (errorCSVMessage) {
    return <ErrorView errorMessage={errorCSVMessage} />
  }

  const formattedAmount = formatAmountFromMinorUnits({
    value: amount,
    currencyCode: currency,
    currencies: currencyInfo
  })

  const foundation = foundations?.[foundationId]

  const companyName = subsidiaryName ? `${subsidiaryName} / ${partnerName}` : partnerName

  return (
    <>
      <MarkAsCancelledModal
        isOpen={markAsCancelledModalOpen}
        partnerName={companyName}
        donationRequestId={id}
        onSuccess={() => {
          push('/donation-requests')
        }}
        onClose={() => {
          setMarkAsCancelledModalOpen(false)
        }}
      />
      <MarkAsSettledModal
        isOpen={markAsSettledModalOpen}
        partnerName={companyName}
        donationRequest={{
          id,
          createdAt
        }}
        onSuccess={() => {
          refresh()
        }}
        onClose={() => {
          setMarkAsSettledModalOpen(false)
        }}
      />
      <Grid container spacing={0} component={Paper} elevation={0} className={styles.title}>
        <Grid item>
          <Typography variant="h6">{formattedAmount}</Typography>
        </Grid>
        <Grid item className={styles.titleBadge}>
          <Badge variant="default">{status}</Badge>
        </Grid>
        {status === DonationRequestStatus.pending && (
          <Grid item className={styles.titleButton}>
            <Button size="small" onPress={() => setMarkAsSettledModalOpen(true)}>
              {t('button.markAsSettled')}
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={0} component={Paper} elevation={0} className={styles.wrapper} style={{ marginTop: 25 }}>
        <ul className={styles.detailsList}>
          <li>
            <Typography variant="h6">{t('typography.donationRequestDetails')}</Typography>
            {[DonationRequestStatus.pending, DonationRequestStatus.settled].includes(status) && (
              <Grid item style={{ marginLeft: 'auto' }}>
                <Button size="small" variant="secondary" onPress={handleReportDownload} disabled={loading}>
                  {t(`button.${loading ? 'downloading' : 'downloadReport'}`)}
                </Button>
              </Grid>
            )}
          </li>
          <li>
            <Typography variant="body2">{t('typography.donationRequestReference')}</Typography>
            <Typography variant="body2">
              <Copy text={reference}>{reference}</Copy>
            </Typography>
          </li>
          <li>
            <Typography variant="body2">{t('typography.donationRequestId')}</Typography>
            <Typography variant="body2">
              <Copy text={id}>{id}</Copy>
            </Typography>
          </li>
          <li>
            <Typography variant="body2">{t('typography.totalDonationAmount')}</Typography>
            <Typography variant="body2">{formattedAmount}</Typography>
          </li>
          {amounts?.map(({ kind, amount: { amount: value, currency: currencyCode } }) => (
            <li className={styles.donationAmount}>
              <Typography variant="body2">{t(`typography.donationKind.${kind}`)}</Typography>
              <Typography variant="body2">
                {formatAmountFromMinorUnits({
                  value,
                  currencyCode,
                  currencies: currencyInfo
                })}
              </Typography>
            </li>
          ))}
          <li>
            <Typography variant="body2">{t('typography.partner')}</Typography>
            <Typography variant="body2">{companyName}</Typography>
          </li>
          <li>
            <Typography variant="body2">{t('typography.foundation')}</Typography>
            {foundation && (
              <Typography variant="body2">
                <FoundationNameWithFlag countryCode={foundation.countryCode} name={foundation.name} />
              </Typography>
            )}
          </li>
          <li>
            <Typography variant="body2">{t('typography.donationRequestDateRange')}</Typography>
            <Typography variant="body2">
              {`${dayJS.utc(start).format('DD MMM YYYY')} - ${dayJS.utc(end).format('DD MMM YYYY')}`}
            </Typography>
          </li>
          {status === DonationRequestStatus.settled && (
            <li>
              <Typography variant="body2">{t('typography.donationRequestSettled')}</Typography>
              <Typography variant="body2">{dayJS(settledAt).format('DD MMM YYYY')}</Typography>
            </li>
          )}
          {status === DonationRequestStatus.pending && (
            <li>
              <Button size="small" variant="tertiary" onPress={() => setMarkAsCancelledModalOpen(true)}>
                {t('button.markAsCancelled')}
              </Button>
            </li>
          )}
        </ul>
        <DonationsPerNonprofit donationRequestId={id} />
        <IndividualDonations donationRequestId={id} />
      </Grid>
    </>
  )
}
