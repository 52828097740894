export const mockPartnerDetail = {
  data: {
    agentVerificationRedirectUrl: null,
    allowedActivitySubTags: [],
    createdAt: '2023-11-17T10:36:53.273Z',
    currencyCode: 'AUD',
    deletedAt: null,
    id: 'staging_partner_000000CjaEDvxVBvj5UJ9CvcKovGC',
    legalName: null,
    logo: 'https://static.poweredbypercent.com/partnerlogo/partnerlogo_082c1387-fd55-4428-b28e-0b7f90449da6.png',
    name: 'Goodstack Workplace Giving',
    primaryColor: null,
    programWebsite: null,
    publicKey: 'staging_pk_d9234539-3352-4749-8808-46c97e220475',
    redactPii: false
  },
  object: 'partner'
}
