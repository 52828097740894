import { Divider, Grid, Paper } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'

import { Badge, Button, ButtonText, ConfirmationModal, Loader, Modal } from '@percent/lemonade'
import { ReactComponent as Close } from '@percent/admin-dashboard/common/assets/images/close.svg'
import { ComplianceModalProps } from './ComplianceModal.types'
import { getRegion } from '@percent/admin-dashboard/common/utility/getRegion'
import { getProperHref } from '@percent/admin-dashboard/common/utility/getProperHref'
import { useMutation, useQuery } from '@percent/admin-dashboard/common/hooks'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { VettingEventCheck } from './vettingEventCheck/VettingEventCheck'
import styles from './ComplianceModal.module.scss'
import { dayJS } from '@percent/admin-dashboard/common/library/utility/date'
import { sortVettingEvents, getComplianceCheckLink } from './utils'
import { ErrorView } from '@percent/admin-dashboard/common/components'
import { VettingEventScope, VettingStatus } from '@percent/admin-dashboard/api/actions/admin/admin.types'
import { LocaleKey } from '@percent/admin-dashboard/i18n/config'
import { getGoogleSearchLink } from '@percent/admin-dashboard/common/utility/getGoogleSearchLink'

export function ComplianceModal({
  organisationId,
  isOpen,
  setIsOpen,
  refreshVettingEventsResults,
  refreshVettingEvents,
  vettingEventsResults,
  vettingEvents
}: Readonly<ComplianceModalProps>) {
  const { t } = useTranslation()
  const { adminService } = useServices()
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const [initialValues, setInitialValues] = useState<Record<string, string>>({})

  const { results: vettingResults, createdAt, complyAdvantageSearchId } = vettingEventsResults

  const [{ data: organisation, isLoading: isOrganisationLoading }] = useQuery(adminService.getOrganisation, {
    id: organisationId
  })

  const formattedVettingEventsAsResults: Record<
    string,
    {
      status: VettingStatus
    }
  > = useMemo(
    () =>
      vettingEvents
        ?.filter(({ scope }) => (vettingResults ? !Object.keys(vettingResults).includes(scope) : true))
        .reduce((obj, item) => Object.assign(obj, { [item.scope]: { status: item.status } }), {}) || {},
    [vettingResults, vettingEvents]
  )

  const vettingEventsAndResults: Record<
    string,
    {
      status: VettingStatus
    }
  > = useMemo(
    () => ({ ...formattedVettingEventsAsResults, ...vettingResults }),
    [vettingResults, formattedVettingEventsAsResults]
  )

  const [{ isLoading: isSubmissionLoading, errorMessage }, { apiFunc: submitFunc }] = useMutation(
    adminService.submitVettingEvents,
    () => {
      refreshVettingEventsResults()
      refreshVettingEvents()
      setIsOpen(false)
    }
  )

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: () => {
      return Yup.object().shape(
        Object.fromEntries(Object.keys(initialValues).map(key => [key, Yup.string().required()]))
      )
    },
    onSubmit: () => {}
  })

  const { values, setFieldValue, isValid, validateForm } = formik

  useEffect(() => {
    if (vettingEventsAndResults) {
      setInitialValues(
        Object.fromEntries(
          Object.keys(vettingEventsAndResults)
            .filter(
              key => vettingEventsAndResults[key]?.status !== 'pass' && vettingEventsAndResults[key]?.status !== 'fail'
            )
            .map(key => [key, ''])
        )
      )
    }
  }, [validateForm, vettingEventsAndResults])

  useEffect(() => {
    validateForm()
  }, [initialValues, validateForm])

  const handleSubmit = () => {
    const flags = Object.keys(values).map(key => ({
      scope: key as VettingEventScope,
      status: values[key]
    }))

    submitFunc({ organisationId: organisation?.id, payload: { flags } })
  }

  const vettingEventsChecks = useMemo(
    () => vettingEventsAndResults && Object.keys(vettingEventsAndResults).sort(sortVettingEvents),
    [vettingEventsAndResults]
  )

  const oracleValidationLink = `oracleValidationLink.${organisation?.countryCode}` as LocaleKey
  const closeModal = () => setIsOpen(false)
  const openConfirmationModal = () => setIsConfirmationModalOpen(true)
  const closeConfirmationModal = () => setIsConfirmationModalOpen(false)

  return (
    <>
      <Modal isFullscreen open={isOpen} aria-labelledby="compliance-check-modal">
        <div className={styles.modalHeaderWrapper}>
          <button
            className={styles.closeButton}
            type="button"
            onClick={closeModal}
            data-testid="close-compliance-modal"
          >
            <Close />
          </button>
          <div>
            <h3>{organisation?.name}</h3>
            <Button disabled={!isValid} onPress={openConfirmationModal} data-testid="open-confirmation-modal-button">
              {t('button.submit')}
            </Button>
          </div>
        </div>

        <div className={styles.modalContentContainer} data-testid="compliance-modal">
          <Grid container spacing={0} component={Paper} elevation={0} className={styles.generalInfoWrapper}>
            <h5>{t('typography.status')}</h5>
            <Badge variant="warning" icon="warning" testId="compliance-overall-status">
              {t(`status.reviewNeeded`)}
            </Badge>
            {createdAt && (
              <>
                <Divider orientation="vertical" flexItem />
                <h5>{t('typography.requestDate')}</h5>
                <span>{dayJS(createdAt).format('D MMM YYYY, HH:mm')}</span>
              </>
            )}
          </Grid>

          <div className={styles.modalContentWrapper}>
            <div className={styles.modalContent}>
              {vettingEventsChecks?.map(key => (
                <VettingEventCheck
                  key={key}
                  name={key}
                  status={vettingEventsAndResults![key].status}
                  setFieldValue={setFieldValue}
                  isFieldValid={!!values[key]}
                  complianceCheckLink={getComplianceCheckLink(
                    key as VettingEventScope,
                    organisation?.name || '',
                    organisation?.website || '',
                    complyAdvantageSearchId
                  )}
                />
              ))}
            </div>
            <div className={styles.detailsBox}>
              {isOrganisationLoading && <Loader />}
              {!isOrganisationLoading && (
                <>
                  <h6>{organisation?.name}</h6>
                  <div className={styles.linksWrapper}>
                    <a href={getGoogleSearchLink(organisation?.name)} target="_blank" rel="noopener noreferrer">
                      <ButtonText icon="expand">{t('typography.openInNewTab')}</ButtonText>
                    </a>
                    <a href={t(oracleValidationLink)} target="_blank" rel="noopener noreferrer">
                      <ButtonText icon="expand">{t('typography.seeOracle')}</ButtonText>
                    </a>
                  </div>
                  <div className={styles.infoWrapper}>
                    {organisation?.address && <p>{organisation?.address}</p>}
                    {organisation?.city && <p>{organisation?.city}</p>}
                    {organisation?.postcode && <p>{organisation?.postcode}</p>}
                    {organisation?.countryCode && <p>{getRegion(organisation?.countryCode)}</p>}
                  </div>
                  <div className={styles.infoWrapper}>
                    <p>
                      {t('typography.registry')}: {organisation?.registry}
                    </p>
                    <p>
                      {t('typography.registrationId')}: {organisation?.registryId}
                    </p>
                  </div>

                  <a href={getProperHref(organisation?.website) ?? '_blank'} target="_blank" rel="noopener noreferrer">
                    {organisation?.website}
                  </a>
                </>
              )}
            </div>
          </div>
        </div>
      </Modal>

      {isConfirmationModalOpen && (
        <Modal
          open={isConfirmationModalOpen}
          onClose={closeConfirmationModal}
          aria-labelledby="compliance-check-confirmation-modal"
        >
          <ConfirmationModal
            title={t('dialog.complianceModal.confirmationModal.title')}
            loading={isSubmissionLoading}
            primaryButtonText={t('dialog.complianceModal.confirmationModal.submitReview')}
            primaryBtnTestId="submit-vetting-events-button"
            secondaryButtonText={t('button.cancel')}
            handleSubmit={handleSubmit}
            handleClose={closeConfirmationModal}
          >
            <p>
              {t('dialog.complianceModal.confirmationModal.description')}
              <span>
                <b> {organisation?.name}</b>.{' '}
              </span>
              {t('dialog.complianceModal.confirmationModal.continue')}
            </p>
            {errorMessage && <ErrorView errorMessage={errorMessage} />}
          </ConfirmationModal>
        </Modal>
      )}
    </>
  )
}
