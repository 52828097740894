import { ArrayParam, StringParam, useQueryParams, withDefault } from 'use-query-params'

import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { DonationMatchRequestsTable } from '@percent/admin-dashboard/app/donationMatchRequest/donationMatchRequestsList/donationMatchRequestsTable/DonationMatchRequestsTable'
import { useDidMountEffect, useQueryList } from '@percent/admin-dashboard/common/hooks'

const DirectionParam = withDefault(StringParam, 'ASC')
const StatusFilterParam = withDefault(ArrayParam, ['processing', 'validating'])

export function DonationMatchRequestsListContainer() {
  const { donationMatchingRequestService } = useServices()

  const [queryParams, setQueryParams] = useQueryParams({
    direction: DirectionParam,
    status: StatusFilterParam
  })

  const [
    { totalResults, isLoading, dataOrNull, errorMessageOrNull },
    { nextPage, query, previousPage, refreshToFirstPage }
  ] = useQueryList(donationMatchingRequestService.getMatchRequests, {
    ...queryParams,
    pageSize: 25
  })

  useDidMountEffect(() => query(queryParams), [queryParams, query])

  return (
    <DonationMatchRequestsTable
      isLoading={isLoading}
      totalResults={totalResults}
      data={dataOrNull}
      previousPage={previousPage}
      nextPage={nextPage}
      errorMessage={errorMessageOrNull}
      refreshToFirstPage={refreshToFirstPage}
      query={query}
      queryParams={queryParams}
      setQueryParams={setQueryParams}
    />
  )
}
