import { Button, DialogTitle, Divider, Grid } from '@material-ui/core'
import React from 'react'

import { ReactComponent as CloseLogo } from '@percent/admin-dashboard/common/assets/images/close.svg'
import style from './Dialog.module.scss'

type DialogHeaderProps = {
  onClose: () => void
  headerTitle?: string
}

export function DialogHeader({ headerTitle, onClose }: DialogHeaderProps) {
  return (
    <>
      <Grid item xs={12} id={style.dialogHeader}>
        <DialogTitle className={style.headerTitle} id={style.dialogTitle}>
          {headerTitle}
        </DialogTitle>
        <Button id={style.btnCloseDialog} onClick={onClose} type="button" data-testid="close-button">
          <CloseLogo />
        </Button>
      </Grid>
      <Divider className={style.dialogMargin} />
    </>
  )
}
