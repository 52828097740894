import { useContext } from 'react'

import { BadgeTotalContext } from '../BadgeTotalContext'

export const useBadgeTotal = () => {
  const context = useContext(BadgeTotalContext)

  if (context === undefined) {
    throw new Error('useBadgeTotal must be used within an BadgeTotalContextController')
  }

  return context
}
