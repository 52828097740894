import { Button, FormField, Spacer, TextInput } from '@percent/lemonade'
import { ChangeEvent } from 'react'
import { BankDetailsUploadViewProps } from './BankDetailsView.types'
import { UploadDocument } from '../uploadDocument/UploadDocument'
import { BankDetailStatus } from '../../types/bankDetails.types'
import styled from 'styled-components'

const StyledSubmitButtonForm = styled.form`
  margin-top: 24px;
  display: flex;
  justify-content: end;
`

const StyledUploadDocumentContainer = styled.div`
  margin-top: 24px;
`

export function BankDetailsUploadView({
  setSelectedFile,
  buttonFlag,
  uploadButtonFlag,
  disableUploadBtn,
  disabledField,
  selectedFile,
  apiFunc,
  fileFieldText,
  isPostLoading,
  bankDetailsData,
  translations,
}: BankDetailsUploadViewProps) {
  const handleSubmit = (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault()
    const formData = new FormData()
    if (selectedFile) {
      formData.append('proofOfBankDetails', selectedFile)
    }

    apiFunc({
      payload: {
        formData,
      },
    })
  }

  return (
    <>
      <StyledUploadDocumentContainer>
        {bankDetailsData?.bankAccountDetails?.holderName &&
          bankDetailsData.status === BankDetailStatus.APPROVED && (
            <>
              <FormField
                label="Account holder name"
                disabled
                data-testid="holderName"
                necessity="required"
              >
                <TextInput
                  name="holderName"
                  value={bankDetailsData?.bankAccountDetails?.holderName}
                  placeholder=""
                />
              </FormField>
              <Spacer axis="vertical" size={5} />
            </>
          )}
        {bankDetailsData?.bankAccountDetails?.wireTransferDetails?.bic &&
          bankDetailsData.status === BankDetailStatus.APPROVED && (
            <>
              <FormField
                label={
                  bankDetailsData?.bankAccountDetails?.wireTransferDetails
                    ?.bicLabel
                }
                disabled
                data-testid="bic"
                necessity="required"
              >
                <TextInput
                  name="bic"
                  value={
                    bankDetailsData?.bankAccountDetails?.wireTransferDetails
                      ?.bic
                  }
                />
              </FormField>
              <Spacer axis="vertical" size={5} />
            </>
          )}
        {bankDetailsData?.bankAccountDetails?.wireTransferDetails
          ?.accountNumber &&
          bankDetailsData.status === BankDetailStatus.APPROVED && (
            <>
              <FormField
                label={
                  bankDetailsData?.bankAccountDetails?.wireTransferDetails
                    ?.accountNumberLabel
                }
                disabled
                data-testid="accountNumber"
                necessity="required"
              >
                <TextInput
                  name="accountNumber"
                  value={
                    bankDetailsData?.bankAccountDetails?.wireTransferDetails
                      ?.accountNumber
                  }
                />
              </FormField>
              <Spacer axis="vertical" size={5} />
            </>
          )}
        {bankDetailsData?.bankAccountDetails?.wireTransferDetails
          ?.bankCodeLabel &&
          bankDetailsData.status === BankDetailStatus.APPROVED && (
            <>
              <FormField
                label={
                  bankDetailsData?.bankAccountDetails?.wireTransferDetails
                    ?.bankCodeLabel
                }
                disabled
                data-testid="bankCode"
              >
                <TextInput
                  name="bankCode"
                  value={
                    bankDetailsData?.bankAccountDetails?.wireTransferDetails
                      ?.bankCode
                  }
                />
              </FormField>
              <Spacer axis="vertical" size={5} />
            </>
          )}
        {bankDetailsData?.bankAccountDetails?.wireTransferDetails?.branchCode &&
          bankDetailsData.status === BankDetailStatus.APPROVED && (
            <>
              <FormField
                label={
                  bankDetailsData?.bankAccountDetails?.wireTransferDetails
                    ?.branchCodeLabel
                }
                disabled
                data-testid="branchCode"
              >
                <TextInput
                  name="branchCode"
                  value={
                    bankDetailsData?.bankAccountDetails?.wireTransferDetails
                      ?.branchCode
                  }
                />
              </FormField>
              <Spacer axis="vertical" size={5} />
            </>
          )}
        <UploadDocument
          helperText={translations.helperBankAccountText}
          labelText={translations.proofBankAccount}
          uploadButton={translations.uploadDocument}
          fileFieldText={fileFieldText}
          setSelectedFile={setSelectedFile}
          buttonFlag={uploadButtonFlag}
          disableUploadBtn={disableUploadBtn}
          inputDisableStyle={disabledField}
        />
      </StyledUploadDocumentContainer>
      {buttonFlag && (
        <StyledSubmitButtonForm onSubmit={handleSubmit}>
          {
            <Button
              type="submit"
              variant={selectedFile && !isPostLoading ? 'primary' : 'secondary'}
              disabled={selectedFile && !isPostLoading ? false : true}
            >
              {translations.submitBankDocuments}
            </Button>
          }
        </StyledSubmitButtonForm>
      )}
    </>
  )
}
