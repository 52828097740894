import styled from 'styled-components'
import { RadioProps } from './radio.types'

const RadioWrapper = styled.div`
  ${({ theme }) =>
    `
    display: flex;
    align-items: center;
    gap: ${theme.sizes[2]};
  `}
`

const RadioLabel = styled.label<{ $isDisabled?: boolean }>`
  ${({ theme, $isDisabled }) =>
    `
    cursor: ${$isDisabled ? 'default' : 'pointer'};
    font-weight: ${theme.fontWeights.medium};
    font-size: ${theme.fontSizes.buttonText};
    line-height: ${theme.sizes[6]};
    color: ${$isDisabled ? theme.colors.gray600 : theme.colors.black}
  `}
`

const StyledRadio = styled.input`
  ${({ theme }) =>
    `
    position:relative;
    margin: 0;
    cursor: pointer;
    appearance: none;
    width: 1.125rem;
    height: 1.125rem;
    border: 2px solid #b6b4b9;
    border-radius: 50%;
    outline: none;

    &:hover {
      transition: all ${theme.transitionTime};
      border-color: ${theme.colors.primary};
      box-shadow: ${theme.colors.primary100} 0px 0px 0px ${theme.sizes[1]};  
    }

    &:checked {
      background-color: ${theme.colors.primary};
      border-color: ${theme.colors.primary};

      &::after {
        content: '';
        position: absolute;
        width: 6px;
        height: 6px;
        background: ${theme.colors.white};
        border-radius: ${theme.borderRadii.large};
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    &:disabled, &:disabled:hover {
      box-shadow: none;
      border: 2px solid #b6b4b9;
      background-color: ${theme.colors.gray70};

      &:checked {
        background-color: ${theme.colors.gray300};

        &::after {
          box-shadow: none;
          background-color: ${theme.colors.gray70};
          border-color: ${theme.colors.gray300};
        }
      }
    }
  `}
`

export const Radio = ({ label, ...props }: RadioProps) => (
  <RadioWrapper>
    <StyledRadio type="radio" {...props} />
    <RadioLabel htmlFor={props.id} $isDisabled={props.disabled}>
      {label}
    </RadioLabel>
  </RadioWrapper>
)
