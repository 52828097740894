import React from 'react'
import { StringParam, useQueryParams, withDefault } from 'use-query-params'

import { useDidMountEffect, useQueryList } from '@percent/admin-dashboard/common/hooks'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { BankDetailsTable } from '@percent/admin-dashboard/app/bankDetails/bankDetailsContainer/bankDetailsTable/BankDetailsTable'

export function BankDetailsContainer() {
  const { bankDetailsService } = useServices()

  const [queryParams, setQueryParams] = useQueryParams({
    statusFilter: withDefault(StringParam, 'pending')
  })

  const [{ isLoading, totalResults, dataOrNull, errorMessageOrNull }, { query, nextPage, previousPage }] = useQueryList(
    bankDetailsService.getBankDetailsList,
    {
      ...queryParams
    }
  )

  useDidMountEffect(() => query(queryParams), [queryParams, query])

  return (
    <BankDetailsTable
      data={dataOrNull}
      isLoading={isLoading}
      errorMessage={errorMessageOrNull}
      nextPage={nextPage}
      previousPage={previousPage}
      totalResults={totalResults}
      queryParams={queryParams}
      setQueryParams={setQueryParams}
    />
  )
}
