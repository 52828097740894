import { TableCell, TableRow } from '@material-ui/core'

import styles from '../../DonationRequestDetails.module.scss'

import { Table } from '@percent/admin-dashboard/common/components'
import { useCurrencies } from '@percent/admin-dashboard/common/hooks'
import { formatAmountFromMinorUnits } from '@percent/admin-dashboard/common/utility/formatAmount/formatAmount'
import { IndividualDonationsTableProps } from './IndividualDonationsTable.types'
import { getDate } from '@percent/admin-dashboard/common/utility/getDate'

const cells = [
  { id: 'donation', isSortable: false, props: { width: '14%' } },
  { id: 'currency', isSortable: false, props: { width: '16%' } },
  { id: 'organization', isSortable: false, props: { width: '40%' } },
  { id: 'donationCreated', isSortable: false, props: { width: '30%', align: 'right' } }
]

export function IndividualDonationsTable({
  totalResults,
  data,
  previousPage,
  nextPage
}: IndividualDonationsTableProps) {
  const { currencyInfo } = useCurrencies()

  return (
    <Table
      isLoading={false}
      totalResults={totalResults}
      data={data}
      previousPage={previousPage}
      nextPage={nextPage}
      columns={cells}
      emptyTableText=""
      noWrapper
    >
      {data?.map(({ currencyCode, amount, organisationName, id, createdAt }) => (
        <TableRow key={id} className={styles.tableRow}>
          <TableCell>
            {formatAmountFromMinorUnits({
              currencyCode,
              value: amount,
              currencies: currencyInfo
            })}
          </TableCell>
          <TableCell>{currencyCode}</TableCell>
          <TableCell>{organisationName}</TableCell>
          <TableCell align="right">{getDate(createdAt)}</TableCell>
        </TableRow>
      ))}
    </Table>
  )
}
