export enum MutuallyExclusiveOrganisationTypes {
  Nonprofit = 'nonprofit',
  SocialImpact = 'social_impact'
}

export enum NonConflictingOrganisationTypes {
  Education = 'education'
}

export const OrganisationTypes = { ...MutuallyExclusiveOrganisationTypes, ...NonConflictingOrganisationTypes }

type TOrganisationTypesKeys = keyof typeof OrganisationTypes

export type OrganisationTypes = typeof OrganisationTypes[TOrganisationTypesKeys]

export const mutuallyExclusiveOrganisationTypesArray: OrganisationTypes[] = Object.values(
  MutuallyExclusiveOrganisationTypes
)

export const nonConflictingOrganisationTypesArray: NonConflictingOrganisationTypes[] = Object.values(
  NonConflictingOrganisationTypes
)
