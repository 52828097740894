import { useParams } from 'react-router-dom'
import { useState } from 'react'

import { Loader, Spacer } from '@percent/lemonade'
import { useQuery } from '@percent/admin-dashboard/common/hooks'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { ErrorView } from '@percent/admin-dashboard/common/components'
import { DonationMatchRequestDetailsHeader } from './DonationMatchRequestDetailsHeader/DonationMatchRequestDetailsHeader'
import { DonationMatchRequestDetailsOrganisationDetails } from './DonationMatchRequestOrganisationDetails/DonationMatchRequestDetailsOrganisationDetails'
import { DonationMatchRequestDetails } from './DonationMatchRequestDetails/DonationMatchRequestDetails'
import { DonationMatchRequestModalApprove } from './donationMatchRequestModal/donationMatchRequestModalApprove/DonationMatchRequestModalApprove'
import { DonationMatchRequestDetailsOrganisationDetailsView } from './DonationMatchRequestOrganisationDetails/DonationMatchRequestDetailsOrganisationDetailsView'
import { DonationMatchRequestModalReject } from './donationMatchRequestModal/donationMatchRequestModalReject/DonationMatchRequestModalReject'
import { DonationMatchRequestModalMissingOrgInfo } from './donationMatchRequestModal/donationMatchRequestModalMissingOrgInfo/DonationMatchRequestModalMissingOrgInfo'
import { VerifyUnverifiedSelectedOrganizationForm } from './VerifyOrganizationModal/VerifyUnverifiedOrganizationForm'

export function DonationMatchRequestDetailsContainer() {
  const { donationMatchingRequestService } = useServices()
  const [modalApproveState, setModalApproveState] = useState(false)
  const [modalMissingOrgDetailsState, setModalMissingOrgDetailsState] = useState(false)
  const [modalRejectState, setModalRejectState] = useState(false)

  const handleModalApproveOrMissingDetailsState = () => {
    data?.organisationId
      ? setModalApproveState(prevState => !prevState)
      : setModalMissingOrgDetailsState(prevState => !prevState)
  }

  const handleModalRejectState = () => {
    setModalRejectState(prevState => !prevState)
  }

  const { donationMatchRequestId } = useParams<{ donationMatchRequestId: string }>()

  const [{ data, isLoading, errorMessage }, { refresh }] = useQuery(donationMatchingRequestService.get, {
    id: donationMatchRequestId
  })

  const [modalOpenState, setModalOpenState] = useState(false)
  const handleModalOpenState = () => {
    setModalOpenState(prevState => !prevState)
  }

  if (isLoading) {
    return <Loader />
  }

  if (errorMessage) {
    return <ErrorView errorMessage={errorMessage} />
  }

  return (
    <>
      <DonationMatchRequestDetailsHeader
        id={donationMatchRequestId}
        partnerId={data.partnerId}
        status={data.status}
        createdAt={data.createdAt}
        showApproveModal={handleModalApproveOrMissingDetailsState}
        showRejectModal={handleModalRejectState}
      />
      <Spacer size={6} axis="vertical" />
      <DonationMatchRequestDetails
        accountId={data.accountId}
        id={donationMatchRequestId}
        amount={data.amount}
        status={data.status}
        externalMatchingUrl={data.externalMatchingUrl}
        refresh={refresh}
      />
      <Spacer size={6} axis="vertical" />
      {data.organisationId ? (
        <DonationMatchRequestDetailsOrganisationDetails
          status={data.status}
          organisationId={data.organisationId}
          donationMatchRequestId={donationMatchRequestId}
          refresh={refresh}
        />
      ) : (
        <>
          <DonationMatchRequestDetailsOrganisationDetailsView
            donationMatchRequestId={donationMatchRequestId}
            data={{ type: 'match_request_organisation_details', data: data.organisationDetails }}
            status={data.status}
            refresh={refresh}
            handleModalOpenState={handleModalOpenState}
          />
          {modalOpenState && (
            <VerifyUnverifiedSelectedOrganizationForm
              refresh={refresh!}
              open={modalOpenState}
              onClose={handleModalOpenState}
              organisationName={data?.organisationDetails?.name || ''}
              organisationWebsite={data?.organisationDetails?.websiteUrl}
              donationMatchRequestId={donationMatchRequestId}
            />
          )}
        </>
      )}
      <DonationMatchRequestModalApprove
        onClose={handleModalApproveOrMissingDetailsState}
        refresh={refresh}
        open={modalApproveState}
        donationMatchRequestState={{
          donationMatchRequestId
        }}
      />
      <DonationMatchRequestModalMissingOrgInfo
        open={modalMissingOrgDetailsState}
        onClose={handleModalApproveOrMissingDetailsState}
      />
      <DonationMatchRequestModalReject
        onClose={handleModalRejectState}
        refresh={refresh}
        open={modalRejectState}
        donationMatchRequestId={donationMatchRequestId}
      />
    </>
  )
}
