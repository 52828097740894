export const orgSubActivities = {
  119: {
    primaryActivityDescription: 'Cultural, historic, related education',
    subActivityDescription: 'Other cultural, historical activities',
    primaryActivityCode: '03',
    subActivityCode: '119',
    definition: 'Organisations that promote and preserve cultural or historical beliefs, traditions, etc.'
  },
  120: {
    primaryActivityDescription: 'Instructional training: other',
    subActivityDescription: 'Publishing activities',
    primaryActivityCode: '04',
    subActivityCode: '120',
    definition: 'Organisations that produce materials on a variety of topics, except university publishers'
  },
  121: {
    primaryActivityDescription: 'Instructional training: other',
    subActivityDescription: 'Radio or television broadcasting',
    primaryActivityCode: '04',
    subActivityCode: '121',
    definition:
      'Organisations that publish or produce communications using various media formats, like radio, television and/or the internet'
  },
  122: {
    primaryActivityDescription: 'Instructional training: other',
    subActivityDescription: 'Film production',
    primaryActivityCode: '04',
    subActivityCode: '122',
    definition: 'Organisations that produce films and/or videos for a variety of purposes'
  },
  124: {
    primaryActivityDescription: 'Instructional training: other',
    subActivityDescription: 'Research (non-scientific)',
    primaryActivityCode: '04',
    subActivityCode: '124',
    definition:
      'Organisations that conduct research to provide public information in all major areas except for scientific research'
  },
  125: {
    primaryActivityDescription: 'Instructional training: other',
    subActivityDescription: 'Information',
    primaryActivityCode: '04',
    subActivityCode: '125',
    definition:
      'Organisations that provide information to the public on a variety of topics for the purpose of influencing opinions on that topic'
  },
  126: {
    primaryActivityDescription: 'Instructional training: other',
    subActivityDescription: 'Apprentice training',
    primaryActivityCode: '04',
    subActivityCode: '126',
    definition: 'Organisations that provide apprenticeships for specific industries'
  },
  149: {
    primaryActivityDescription: 'Instructional training: other',
    subActivityDescription: 'Instruction, training: other',
    primaryActivityCode: '04',
    subActivityCode: '149',
    definition: 'Organisations that provide instruction in various topics'
  },
  150: {
    primaryActivityDescription: 'Health services',
    subActivityDescription: 'Hospital',
    primaryActivityCode: '05',
    subActivityCode: '150',
    definition:
      'Hospitals that offer diagnostic and treatment services, as well as emergency and critical care services for people who have illnesses or injuries which require care'
  },
  151: {
    primaryActivityDescription: 'Health services',
    subActivityDescription: 'Hospital auxiliary',
    primaryActivityCode: '05',
    subActivityCode: '151',
    definition: 'Organisations that provide voluntary services to hospitals and patients'
  },
  152: {
    primaryActivityDescription: 'Health services',
    subActivityDescription: 'Nursing, convalescent home',
    primaryActivityCode: '05',
    subActivityCode: '152',
    definition: 'Facilities that provide skilled nursing and personal care for those with extensive medical needs'
  },
  153: {
    primaryActivityDescription: 'Health services',
    subActivityDescription: 'Care and housing for the aged',
    primaryActivityCode: '05',
    subActivityCode: '153',
    definition:
      'Organisations that provide housing for senior citizens for independent living, and not provide extensive medical assistance'
  },
  154: {
    primaryActivityDescription: 'Health services',
    subActivityDescription: 'Health clinic',
    primaryActivityCode: '05',
    subActivityCode: '154',
    definition: 'Health facility focused on outpatient services and care, or clinic registered with the HRSA'
  },
  155: {
    primaryActivityDescription: 'Health services',
    subActivityDescription: 'Rural medical facility',
    primaryActivityCode: '05',
    subActivityCode: '155',
    definition: 'Health facility that serves rural communities'
  },
  156: {
    primaryActivityDescription: 'Health services',
    subActivityDescription: 'Blood bank',
    primaryActivityCode: '05',
    subActivityCode: '156',
    definition:
      'Organisations that receive blood and plasma, store them and provide them when patients are in need of these'
  },
  157: {
    primaryActivityDescription: 'Health services',
    subActivityDescription: 'Cooperative hospital service organization',
    primaryActivityCode: '05',
    subActivityCode: '157',
    definition:
      'Organisations comprised of several member hospitals that collaborate on ways to provide health services to their communities/regions'
  },
  158: {
    primaryActivityDescription: 'Health services',
    subActivityDescription: 'Rescue and emergency service',
    primaryActivityCode: '05',
    subActivityCode: '158',
    definition:
      'Organisations that provide pre-hospital care, plan to help prevent disasters and those that provide search and rescue services'
  },
  159: {
    primaryActivityDescription: 'Health services',
    subActivityDescription: 'Nurses register or bureau',
    primaryActivityCode: '05',
    subActivityCode: '159',
    definition: 'Organisations that certify and keep records of fully registered nurses'
  },
  160: {
    primaryActivityDescription: 'Health services',
    subActivityDescription: 'Aid to persons with disabilities',
    primaryActivityCode: '05',
    subActivityCode: '160',
    definition:
      'Organisations that provide any of the following; (i) provide treatment and/or education services to individuals that have temporary or permanent disabilities, (ii) are specifically focused on prevention or treatment of down syndrome, (iii) organisations that are helping with job training/placement if the services are exclusively for those with disabilities, or (iv) organisations that provide housing or have centres for individuals with disabilities '
  },
  161: {
    primaryActivityDescription: 'Health services',
    subActivityDescription: 'Scientific research (diseases)',
    primaryActivityCode: '05',
    subActivityCode: '161',
    definition: 'Organisations that conduct research in the area of diseases and public health'
  },
  162: {
    primaryActivityDescription: 'Health services',
    subActivityDescription: 'Medical research: other',
    primaryActivityCode: '05',
    subActivityCode: '162',
    definition: 'Organisations that conduct research in health, not including diseases'
  },
  163: {
    primaryActivityDescription: 'Health services',
    subActivityDescription: 'Health insurance',
    primaryActivityCode: '05',
    subActivityCode: '163',
    definition: 'Organisations that provide health and medical insurance to individuals or groups'
  },
  165: {
    primaryActivityDescription: 'Health services',
    subActivityDescription: 'Community health planning',
    primaryActivityCode: '05',
    subActivityCode: '165',
    definition:
      'Organisations that focus on community health issues and issues that are prevalent in a specific community, providing early detection, treatment and/or rehabilitation'
  },
  166: {
    primaryActivityDescription: 'Health services',
    subActivityDescription: 'Mental health care',
    primaryActivityCode: '05',
    subActivityCode: '166',
    definition:
      'Organisations that provide preventative, diagnostic, and treatment for mental health issues. Also can include hotlines and crisis intervention groups, and therapeutic living facilities. Includes organizations focused on helping individuals with eating disorders, and those that provide therapy services.'
  },
  167: {
    primaryActivityDescription: 'Health services',
    subActivityDescription: 'Group medical practice',
    primaryActivityCode: '05',
    subActivityCode: '167',
    definition:
      'Medicine practised by a group of associated physicians or dentists working as partners and sharing resources '
  },
  179: {
    primaryActivityDescription: 'Health services',
    subActivityDescription: 'Health services: other',
    primaryActivityCode: '05',
    subActivityCode: '179',
    definition:
      'Organisations such as general hospital, speciality hospitals, community health systems, health financing. Including health services in areas such as ophthalmology (eye health), otolaryngology (head, neck, ear, jaw), cardiovascular, internal medicine, lung, brain, orthopedic.  Also includes organisations where nurses provide care on a travelling basis.'
  },
  180: {
    primaryActivityDescription: 'Scientific research',
    subActivityDescription: 'Scientific research for industry',
    primaryActivityCode: '06',
    subActivityCode: '180',
    definition: 'Organisations that are used to provide research for a specific industry'
  },
  181: {
    primaryActivityDescription: 'Scientific research',
    subActivityDescription: 'Scientific research for government',
    primaryActivityCode: '06',
    subActivityCode: '181',
    definition: 'Organisations that are contracted by the government to conduct scientific research.'
  },
  182: {
    primaryActivityDescription: 'Scientific research',
    subActivityDescription: 'Scientific research activities: other',
    primaryActivityCode: '06',
    subActivityCode: '182',
    definition:
      'Organisations that conduct scientific research in the areas of food/agriculture, physical sciences, astronomy, chemistry, mathematics, computers, but not medical research'
  },
  200: {
    primaryActivityDescription: 'Business, professional support',
    subActivityDescription: 'Business promotion',
    primaryActivityCode: '07',
    subActivityCode: '200',
    definition: 'Organisations that promote business activities '
  },
  201: {
    primaryActivityDescription: 'Business, professional support',
    subActivityDescription: 'Real estate association',
    primaryActivityCode: '07',
    subActivityCode: '201',
    definition:
      'Organisations whose members are in the real estate business who have affiliated for the purpose of promoting the interests of their trade'
  },
  202: {
    primaryActivityDescription: 'Business, professional support',
    subActivityDescription: 'Board of trade',
    primaryActivityCode: '07',
    subActivityCode: '202',
    definition:
      'Organisations that are involved in regulating, promoting or conducting commodities and commercial investment exchanges e.g. 501c6 in USA'
  },
  203: {
    primaryActivityDescription: 'Business, professional support',
    subActivityDescription: 'Regulating business',
    primaryActivityCode: '07',
    subActivityCode: '203',
    definition: 'Organisations that regulate the activities of businesses '
  },
  204: {
    primaryActivityDescription: 'Business, professional support',
    subActivityDescription: 'Fair business practices',
    primaryActivityCode: '07',
    subActivityCode: '204',
    definition: 'Organisations that focus on the promotion of fair business practices'
  },
  205: {
    primaryActivityDescription: 'Business, professional support',
    subActivityDescription: 'Professional association',
    primaryActivityCode: '07',
    subActivityCode: '205',
    definition:
      'Organisations that seek to further a particular profession and the interests of individuals and organisations engaged in that profession e.g. professional associations or institutions'
  },
  207: {
    primaryActivityDescription: 'Business, professional support',
    subActivityDescription: 'Industry trade shows',
    primaryActivityCode: '07',
    subActivityCode: '207',
    definition:
      'Organisations that host events to bring together members of a particular industry to display, demonstrate and discuss latest product/services'
  },
  209: {
    primaryActivityDescription: 'Business, professional support',
    subActivityDescription: 'Research, development, testing',
    primaryActivityCode: '07',
    subActivityCode: '209',
    definition:
      'Organisations focused on innovative ways of developing new services or products, and improving existing ones'
  },
  213: {
    primaryActivityDescription: 'Business, professional support',
    subActivityDescription: 'Tourist bureau',
    primaryActivityCode: '07',
    subActivityCode: '213',
    definition: 'Organisations focused on promoting areas of interests and supplying information to visiting people  '
  },
  229: {
    primaryActivityDescription: 'Business, professional support',
    subActivityDescription: 'Business, professional group',
    primaryActivityCode: '07',
    subActivityCode: '229',
    definition: 'Organisations that focus on business and professional groups and do not fit into the other categories'
  },
  230: {
    primaryActivityDescription: 'Farming, agriculture',
    subActivityDescription: 'Farming',
    primaryActivityCode: '08',
    subActivityCode: '230',
    definition: 'Organisations undertaking actual farming e.g. breeding animals or focused on food/plant cultivation'
  },
  231: {
    primaryActivityDescription: 'Farming, agriculture',
    subActivityDescription: 'Farm Bureau',
    primaryActivityCode: '08',
    subActivityCode: '231',
    definition: 'Organisations comprised of farmers or ranchers to advocate for the farming communities '
  },
  232: {
    primaryActivityDescription: 'Farming, agriculture',
    subActivityDescription: 'Agricultural group',
    primaryActivityCode: '08',
    subActivityCode: '232',
    definition: 'Organisations focused on improving food resources and procurement'
  },
  233: {
    primaryActivityDescription: 'Farming, agriculture',
    subActivityDescription: 'Horticultural group',
    primaryActivityCode: '08',
    subActivityCode: '233',
    definition: 'Organisations that are involved in the cultivation of plant life for a variety of purposes.'
  },
  234: {
    primaryActivityDescription: 'Farming, agriculture',
    subActivityDescription: 'Farmers cooperative',
    primaryActivityCode: '08',
    subActivityCode: '234',
    definition: 'Organisations providing a market for individuals to access food or making available land for farming'
  },
  237: {
    primaryActivityDescription: 'Farming, agriculture',
    subActivityDescription: 'Breeders association',
    primaryActivityCode: '08',
    subActivityCode: '237',
    definition: 'Organisations focused on breeding a particularly species of animal e.g. farm animals '
  },
  249: {
    primaryActivityDescription: 'Farming, agriculture',
    subActivityDescription: 'Farming: related activities',
    primaryActivityCode: '08',
    subActivityCode: '249',
    definition:
      'Organisations that are focused on food, agriculture, or nutrition and do not fit into the other categories'
  },
  251: {
    primaryActivityDescription: 'Mutual organizations',
    subActivityDescription: 'Credit union',
    primaryActivityCode: '09',
    subActivityCode: '251',
    definition:
      'Organisations that use money deposited by a group of people (e.g., fellow employees) and lend it out again to people in the same group at favorable interest rates'
  },
  254: {
    primaryActivityDescription: 'Mutual organizations',
    subActivityDescription: 'Corporation organized under an Act of Congress',
    primaryActivityCode: '09',
    subActivityCode: '254',
    definition:
      'Corporations organised under acts of Congress such as Federal Credit Unions - these are typically 501(c)1 orgs'
  },
  259: {
    primaryActivityDescription: 'Mutual organizations',
    subActivityDescription: 'Other mutual organization',
    primaryActivityCode: '09',
    subActivityCode: '259',
    definition:
      'Organisations owned exclusively by its customers/members and is run for their benefit e.g. unions, social clubs'
  },
  260: {
    primaryActivityDescription: 'Employee, membership benefit services',
    subActivityDescription: 'Fraternal association',
    primaryActivityCode: '10',
    subActivityCode: '260',
    definition:
      'Organisations that provide services for the mutual aid and benefit of their members; that ordinarily have a lodge system whereby activities are carried out under a form of organisation that comprises local branches, chartered by a parent organization and are largely self-governing'
  },
  261: {
    primaryActivityDescription: 'Employee, membership benefit services',
    subActivityDescription: 'Improvement of worker conditions',
    primaryActivityCode: '10',
    subActivityCode: '261',
    definition: 'Organisations that advocate for fair employee rights and safe working conditions'
  },
  263: {
    primaryActivityDescription: 'Employee, membership benefit services',
    subActivityDescription: 'Association of employees',
    primaryActivityCode: '10',
    subActivityCode: '263',
    definition:
      'Organisations that support laws and other social measures that protect and promote the rights of groups of workers and employees'
  },
  264: {
    primaryActivityDescription: 'Employee, membership benefit services',
    subActivityDescription: 'Employee or member welfare association',
    primaryActivityCode: '10',
    subActivityCode: '264',
    definition:
      'Organisations that provide low cost insurance on a local basis, help with unemployment issues around compensation, help reimburse workers for compensation due to workplace occurrences, and organizations that maintain pension trusts. Also include organizations that help with benefits for employees and/or dependents/beneficiaries'
  },
  268: {
    primaryActivityDescription: 'Employee, membership benefit services',
    subActivityDescription: 'Pension or retirement benefits',
    primaryActivityCode: '10',
    subActivityCode: '268',
    definition: 'Organisations that maintain retirement funds, and disability funds.'
  },
  279: {
    primaryActivityDescription: 'Employee, membership benefit services',
    subActivityDescription: 'Services or benefits to members, employees',
    primaryActivityCode: '10',
    subActivityCode: '279',
    definition:
      'Organisations that provide services or benefits to members/employees and do not fit into the other categories'
  },
  281: {
    primaryActivityDescription: 'Sports, recreational, social',
    subActivityDescription: 'Hobby club',
    primaryActivityCode: '11',
    subActivityCode: '281',
    definition:
      'Organisations whose members join together to focus on a specific special interest as a leisure activity'
  },
  284: {
    primaryActivityDescription: 'Sports, recreational, social',
    subActivityDescription: 'Dog club',
    primaryActivityCode: '11',
    subActivityCode: '284',
    definition:
      'Organisations whose members are focused on canine affairs, including breeding, showing and promoting a breed of dog '
  },
  286: {
    primaryActivityDescription: 'Sports, recreational, social',
    subActivityDescription: 'Hunting, fishing club',
    primaryActivityCode: '11',
    subActivityCode: '286',
    definition:
      'Organisations that allow people to learn archery, fly or bait fishing; game bird, game fowl, or game animal hunting; fox hunting; and/or the use of firearms on the target range'
  },
  287: {
    primaryActivityDescription: 'Sports, recreational, social',
    subActivityDescription: 'Swimming, tennis club',
    primaryActivityCode: '11',
    subActivityCode: '287',
    definition:
      'Organisations that provide opportunities for people to learn and become competitive in swimming, water sports e.g. snorkeling, surfing, water skiing or tennis/racket sports. Including coaching, access to facilities, equipment, and officials for competitions are included.'
  },
  288: {
    primaryActivityDescription: 'Sports, recreational, social',
    subActivityDescription: 'Other sports club',
    primaryActivityCode: '11',
    subActivityCode: '288',
    definition: "Organisations that coordinate children's sporting activities "
  },
  296: {
    primaryActivityDescription: 'Sports, recreational, social',
    subActivityDescription: 'Community center',
    primaryActivityCode: '11',
    subActivityCode: '296',
    definition:
      'Neighborhood-based multipurpose centres that offer a wide variety of services and activities that are structured to meet the needs of the entire community through different programs for different age and interest groups.'
  },
  297: {
    primaryActivityDescription: 'Sports, recreational, social',
    subActivityDescription: 'Community recreational facilities',
    primaryActivityCode: '11',
    subActivityCode: '297',
    definition:
      'Organisations focused on providing facilities for a brand range of recreational and fitness activities, sports and games for the local communities '
  },
  298: {
    primaryActivityDescription: 'Sports, recreational, social',
    subActivityDescription: 'Training in sports',
    primaryActivityCode: '11',
    subActivityCode: '298',
    definition: 'Organisations focused on providing specific sports coaching'
  },
  300: {
    primaryActivityDescription: 'Sports, recreational, social',
    subActivityDescription: 'Amateur athletic association',
    primaryActivityCode: '11',
    subActivityCode: '300',
    definition:
      'Organisations that provide opportunities for people of all ages to learn, become competitive in and enjoy the organised recreational activity, sport or game of their choice'
  },
  301: {
    primaryActivityDescription: 'Sports, recreational, social',
    subActivityDescription: 'Fundraising: athletic event',
    primaryActivityCode: '11',
    subActivityCode: '301',
    definition: 'Organisations focused on creating fundraising opportunities and events for sports clubs/team/centres'
  },
  317: {
    primaryActivityDescription: 'Sports, recreational, social',
    subActivityDescription: 'Athletic activities',
    primaryActivityCode: '11',
    subActivityCode: '317',
    definition: 'Organisations focused on sports and athletic activities that do not fit into the other categories'
  },
  318: {
    primaryActivityDescription: 'Sports, recreational, social',
    subActivityDescription: 'Recreational activities',
    primaryActivityCode: '11',
    subActivityCode: '318',
    definition: 'Organisations focused on recreational activities that do do not fit into the other categories'
  },
  320: {
    primaryActivityDescription: 'Youth services',
    subActivityDescription: 'Scouting (not Boy Scouts, Girl Scouts)',
    primaryActivityCode: '12',
    subActivityCode: '320',
    definition:
      'Organisations for youth to learn a variety of leadership skills from recreational activities, under the supervision of adult volunteers - except Boys & Girls Club (in the US)'
  },
  321: {
    primaryActivityDescription: 'Youth services',
    subActivityDescription: 'Youth club, Little League',
    primaryActivityCode: '12',
    subActivityCode: '321',
    definition:
      'Organisations specifically designated as Boys and Girls Clubs or Little Leagues that provide a wide range of supervised activities, with a specific focus on prevention services and building character with an emphasis on disadvantaged youth'
  },
  322: {
    primaryActivityDescription: 'Youth services',
    subActivityDescription: 'FFA, FHA, 4-H club',
    primaryActivityCode: '12',
    subActivityCode: '322',
    definition: 'Organisations running youth programs with an emphasis on agriculture'
  },
  323: {
    primaryActivityDescription: 'Youth services',
    subActivityDescription: 'Key club',
    primaryActivityCode: '12',
    subActivityCode: '323',
    definition:
      'Organisations specifically designated as Key Clubs, an international service organisation for high school students'
  },
  324: {
    primaryActivityDescription: 'Youth services',
    subActivityDescription: 'YMCA, YWCA',
    primaryActivityCode: '12',
    subActivityCode: '324',
    definition: 'Organisations specifically designated as YMCA or YWCAs sites/groups'
  },
  325: {
    primaryActivityDescription: 'Youth services',
    subActivityDescription: 'Camp',
    primaryActivityCode: '12',
    subActivityCode: '325',
    definition: 'Organisations that operate facilities to run camps for children/youth groups'
  },
  326: {
    primaryActivityDescription: 'Youth services',
    subActivityDescription: 'Care and housing of children',
    primaryActivityCode: '12',
    subActivityCode: '326',
    definition:
      'Organisations that coordinate permanent housing for children in situations where the birth parents are unable to care for the children. Also includes organisations that provide alternative living arrangements for agency-supervised situations'
  },
  327: {
    primaryActivityDescription: 'Youth services',
    subActivityDescription: 'Prevention of cruelty to children',
    primaryActivityCode: '12',
    subActivityCode: '327',
    definition: 'Organisations that focus on the prevention of child abuse. '
  },
  328: {
    primaryActivityDescription: 'Youth services',
    subActivityDescription: 'Juvenile delinquency',
    primaryActivityCode: '12',
    subActivityCode: '328',
    definition:
      'Organisations provide activities to youth that have demonstrated at risk behavior, and/or have been in the juvenile justice system'
  },
  349: {
    primaryActivityDescription: 'Youth services',
    subActivityDescription: 'Youth organization',
    primaryActivityCode: '12',
    subActivityCode: '349',
    definition: 'Other organisations focused on serving youth through any other activity that is otherwise not defined.'
  },
  350: {
    primaryActivityDescription: 'Conservation, environment, beautification',
    subActivityDescription: 'Natural resource conservation',
    primaryActivityCode: '13',
    subActivityCode: '350',
    definition:
      'Organisations focused on protecting natural resources from waste and exploitation, including nature centres that provide informal education around the environment.'
  },
  351: {
    primaryActivityDescription: 'Conservation, environment, beautification',
    subActivityDescription: 'Pollution prevention',
    primaryActivityCode: '13',
    subActivityCode: '351',
    definition: 'Organisations that are working to have their communities be clean and free from pollution'
  },
  352: {
    primaryActivityDescription: 'Conservation, environment, beautification',
    subActivityDescription: 'Land acquisition for preservation',
    primaryActivityCode: '13',
    subActivityCode: '352',
    definition: 'Organisations that preserve and protect land resources'
  },
  353: {
    primaryActivityDescription: 'Conservation, environment, beautification',
    subActivityDescription: 'Soil, water conservation',
    primaryActivityCode: '13',
    subActivityCode: '353',
    definition:
      'Organisations that preserve water resources, including managing shorelines and other land that affects waterways.'
  },
  354: {
    primaryActivityDescription: 'Conservation, environment, beautification',
    subActivityDescription: 'Preservation of scenic beauty',
    primaryActivityCode: '13',
    subActivityCode: '354',
    definition:
      'Organisations focused on forest land protection, including organisations that plant and maintain flowers and trees'
  },
  355: {
    primaryActivityDescription: 'Conservation, environment, beautification',
    subActivityDescription: 'Wildlife sanctuary, refuge',
    primaryActivityCode: '13',
    subActivityCode: '355',
    definition:
      'Organisations such as animal shelters that provide for the humane care, protection and control of animals and which investigate instances of cruelty to animals'
  },
  356: {
    primaryActivityDescription: 'Conservation, environment, beautification',
    subActivityDescription: 'Garden club',
    primaryActivityCode: '13',
    subActivityCode: '356',
    definition:
      'Organisations that provide organised opportunities for individuals to pursue their interest in gardening e.g. planting, flowers and vegetables '
  },
  357: {
    primaryActivityDescription: 'Conservation, environment, beautification',
    subActivityDescription: 'Computer refurbishing',
    primaryActivityCode: '13',
    subActivityCode: '357',
    definition:
      'Organisations that receive donated hardware and fix the devices to ensure they are in good quality to provide to those that need access to hardware'
  },
  358: {
    primaryActivityDescription: 'Conservation, environment, beautification',
    subActivityDescription: 'Computer refurbishing: other',
    primaryActivityCode: '13',
    subActivityCode: '358',
    definition: 'Organisations focused on computer refurbishing that do not fit into the other categories '
  },
  379: {
    primaryActivityDescription: 'Conservation, environment, beautification',
    subActivityDescription: 'Conservation or environment activities: other',
    primaryActivityCode: '13',
    subActivityCode: '379',
    definition:
      'Organisations focused on the protection and preservation of the environment, nature, land, resources or animals'
  },
  380: {
    primaryActivityDescription: 'Housing',
    subActivityDescription: 'Low income housing',
    primaryActivityCode: '14',
    subActivityCode: '380',
    definition: 'Organisations that build, maintain, or provide housing for low income individuals and their families'
  },
  381: {
    primaryActivityDescription: 'Housing',
    subActivityDescription: 'Low and moderate income housing',
    primaryActivityCode: '14',
    subActivityCode: '381',
    definition: 'Organisations that develop, manage, or provide housing with a focus on those that qualify financially.'
  },
  382: {
    primaryActivityDescription: 'Housing',
    subActivityDescription: 'Housing for the aged',
    primaryActivityCode: '14',
    subActivityCode: '382',
    definition:
      'Organisations providing housing options for older adults, that require some support in daily activities, but does not include any medical support'
  },
  398: {
    primaryActivityDescription: 'Housing',
    subActivityDescription: 'Housing support',
    primaryActivityCode: '14',
    subActivityCode: '398',
    definition:
      'Organisations that assist and provide information to individuals in finding housing, either buying or renting'
  },
  399: {
    primaryActivityDescription: 'Housing',
    subActivityDescription: 'Housing activities: other',
    primaryActivityCode: '14',
    subActivityCode: '399',
    definition:
      "Organisations providing information or focused on influencing public policy in the issue area of housing. Including all other housing organisations if they don't fit into another housing category."
  },
  400: {
    primaryActivityDescription: 'Inner city, community engagement',
    subActivityDescription: 'Area development',
    primaryActivityCode: '15',
    subActivityCode: '400',
    definition:
      'Organisations that rehabilitate old housing and other structures e.g. abandoned buildings. Includes organisations that help train others on how to restore structures and those that focus on reviving communities to help with economic improvement'
  },
  401: {
    primaryActivityDescription: 'Inner city, community engagement',
    subActivityDescription: 'Homeowners association',
    primaryActivityCode: '15',
    subActivityCode: '401',
    definition: 'Organisations providing services to residents of a specific community'
  },
  402: {
    primaryActivityDescription: 'Inner city, community engagement',
    subActivityDescription: 'Community rehabilitation',
    primaryActivityCode: '15',
    subActivityCode: '402',
    definition: "Organisations focused on community revitalization, that aren't completely economic focused"
  },
  403: {
    primaryActivityDescription: 'Inner city, community engagement',
    subActivityDescription: 'Industry growth',
    primaryActivityCode: '15',
    subActivityCode: '403',
    definition:
      'Organisations focused on providing assistance, either technical or financial, to help establish businesses in a community'
  },
  404: {
    primaryActivityDescription: 'Inner city, community engagement',
    subActivityDescription: 'Community promotion',
    primaryActivityCode: '15',
    subActivityCode: '404',
    definition:
      'Organisations focused on increasing citizen participation in a community, formed neighborhood coalitions to help with community conditions, and/or other activities to help a community'
  },
  405: {
    primaryActivityDescription: 'Inner city, community engagement',
    subActivityDescription: 'Minority business support',
    primaryActivityCode: '15',
    subActivityCode: '405',
    definition:
      'Organisations providing monetary support to help individuals/groups that are establishing or expanding their business.'
  },
  406: {
    primaryActivityDescription: 'Inner city, community engagement',
    subActivityDescription: 'Crime prevention',
    primaryActivityCode: '15',
    subActivityCode: '406',
    definition:
      'Organisations providing information to the public to raise awareness about a crime, or crime prevention. Including organisations that provide mediation services for conflicts, as well as groups that help provide educational interventions for specific crime areas, ie domestic violence'
  },
  407: {
    primaryActivityDescription: 'Inner city, community engagement',
    subActivityDescription: 'Voluntary fire department',
    primaryActivityCode: '15',
    subActivityCode: '407',
    definition: 'Organisations that are volunteer fire departments '
  },
  408: {
    primaryActivityDescription: 'Inner city, community engagement',
    subActivityDescription: 'Community service organization',
    primaryActivityCode: '15',
    subActivityCode: '408',
    definition: 'Organisation comprising of people dedicated to making improvements to their communities.'
  },
  429: {
    primaryActivityDescription: 'Inner city, community engagement',
    subActivityDescription: 'Community benefit group: other',
    primaryActivityCode: '15',
    subActivityCode: '429',
    definition:
      'Organisations focused on supporting and benefiting the community that do not fit into the other categories'
  },
  430: {
    primaryActivityDescription: 'Civil rights',
    subActivityDescription: 'Human and civil rights defense',
    primaryActivityCode: '16',
    subActivityCode: '430',
    definition:
      'Organisations that work to promote civil rights. This includes organisations focused on court rights, criminal justice issues, promotion of democracy, promoting peaceful international conflicts, promotion of the rights and interests of specific ethnic groups and/or individuals, seniors, women, LGBTQ+, and others.'
  },
  431: {
    primaryActivityDescription: 'Civil rights',
    subActivityDescription: 'Discrimination prevention',
    primaryActivityCode: '16',
    subActivityCode: '431',
    definition: 'Organisations focused on increasing harmony and understanding among diverse groups.'
  },
  432: {
    primaryActivityDescription: 'Civil rights',
    subActivityDescription: 'Improve neighborhood',
    primaryActivityCode: '16',
    subActivityCode: '432',
    definition:
      'Organisations focused on increasing harmony and understanding among diverse groups in a specific community.'
  },
  449: {
    primaryActivityDescription: 'Civil rights',
    subActivityDescription: 'Civil rights: other',
    primaryActivityCode: '16',
    subActivityCode: '449',
    definition: "Organisations promoting freedom of expression, in other areas that aren't otherwise defined."
  },
  460: {
    primaryActivityDescription: 'Litigation, legal aid',
    subActivityDescription: 'Public interest litigation',
    primaryActivityCode: '17',
    subActivityCode: '460',
    definition:
      'Organisations handling class action litigation and represent the interests of the public in general vs. a specific person.'
  },
  461: {
    primaryActivityDescription: 'Litigation, legal aid',
    subActivityDescription: 'Legal support: other',
    primaryActivityCode: '17',
    subActivityCode: '461',
    definition:
      'Organisations providing legal aid regarding criminal and/or civil matters for little to no cost for those that need services. .'
  },
  462: {
    primaryActivityDescription: 'Litigation, legal aid',
    subActivityDescription: 'Legal aid for homeless',
    primaryActivityCode: '17',
    subActivityCode: '462',
    definition: 'Organisations providing legal aid to those that are economically disadvantaged.'
  },
  480: {
    primaryActivityDescription: 'Legislative, political',
    subActivityDescription: 'Legislation',
    primaryActivityCode: '18',
    subActivityCode: '480',
    definition: 'Organisations that propose, support or oppose legislation'
  },
  481: {
    primaryActivityDescription: 'Legislative, political',
    subActivityDescription: 'Voter information',
    primaryActivityCode: '18',
    subActivityCode: '481',
    definition: 'Organisations providing information that is necessary to make informed decision at the ballet box  '
  },
  482: {
    primaryActivityDescription: 'Legislative, political',
    subActivityDescription: 'Voter polling education',
    primaryActivityCode: '18',
    subActivityCode: '482',
    definition:
      'Organisations providing individuals with the basic information regarding the mechanics of voting e.g. how to register, how to complete ballot paper or the electoral system'
  },
  509: {
    primaryActivityDescription: 'Legislative, political',
    subActivityDescription: 'Legislative and political: other',
    primaryActivityCode: '18',
    subActivityCode: '509',
    definition: 'Organisations focused on other legislative and political activities not already defined'
  },
  543: {
    primaryActivityDescription: 'Public opinion advocacy',
    subActivityDescription: 'Legalised abortion',
    primaryActivityCode: '19',
    subActivityCode: '543',
    definition: 'Organisations focused on supporting legalised abortion'
  },
  559: {
    primaryActivityDescription: 'Public opinion advocacy',
    subActivityDescription: 'Public opinion advocacy',
    primaryActivityCode: '19',
    subActivityCode: '559',
    definition:
      'Organisations focused on influencing public policy or opinion within political, economic and social institutions'
  },
  560: {
    primaryActivityDescription: 'Other support for individuals',
    subActivityDescription: 'Supplying grants, goods, or services to low income individuals',
    primaryActivityCode: '20',
    subActivityCode: '560',
    definition:
      'Organistions that provide a wide range of activities that help disadvantaged people, including those supporting: Health facilities serving the poor, Sexual assault victims if housing is provided, LGBTQ+/AIDS/HIV centers providing a range of non medical services, Food programs/banks providing free or low-cost meals, Homeless shelters or temporary housing, Personal services, Transportation services, Emergency assistance, Services to human trafficking victims, Senior, women, immigrant, and homeless center'
  },
  561: {
    primaryActivityDescription: 'Other support for individuals',
    subActivityDescription: 'Gifts or grants to individuals (not scholarships)',
    primaryActivityCode: '20',
    subActivityCode: '561',
    definition:
      'Organisation that provide and coordinate donations for gift distribution programs, including those that provide aid to travelers that are stranded and need assistance.'
  },
  562: {
    primaryActivityDescription: 'Other support for individuals',
    subActivityDescription: 'Loans to individuals',
    primaryActivityCode: '20',
    subActivityCode: '562',
    definition: 'Organistions providing loans for individuals purchasing a home or doing renovations'
  },
  563: {
    primaryActivityDescription: 'Other support for individuals',
    subActivityDescription: 'Marriage counseling',
    primaryActivityCode: '20',
    subActivityCode: '563',
    definition: 'Organisations providing marriage counseling'
  },
  565: {
    primaryActivityDescription: 'Other support for individuals',
    subActivityDescription: 'Credit counseling, assistance',
    primaryActivityCode: '20',
    subActivityCode: '565',
    definition: 'Organisations providing financial advice and guidance to individuals who need assistance'
  },
  568: {
    primaryActivityDescription: 'Other support for individuals',
    subActivityDescription: 'Vocational counseling',
    primaryActivityCode: '20',
    subActivityCode: '568',
    definition: 'Organisations helping individuals make decisions about future job/work placements'
  },
  569: {
    primaryActivityDescription: 'Other support for individuals',
    subActivityDescription: 'Referral service (social agencies)',
    primaryActivityCode: '20',
    subActivityCode: '569',
    definition: 'Organisations that provide referrals to access personal services'
  },
  572: {
    primaryActivityDescription: 'Other support for individuals',
    subActivityDescription: 'Rehabilitating prisoners, ex-prisoners',
    primaryActivityCode: '20',
    subActivityCode: '572',
    definition:
      'Organisations provide housing, counseling, support groups or job seeking assistance that help previous offenders reintegrate into the community'
  },
  573: {
    primaryActivityDescription: 'Other support for individuals',
    subActivityDescription: 'Rehabilitation: other',
    primaryActivityCode: '20',
    subActivityCode: '573',
    definition:
      'Organisations providing preventative programs, groups or residential facilities to those with an addiction such as alcohol, drugs or gambling.'
  },
  574: {
    primaryActivityDescription: 'Other support for individuals',
    subActivityDescription: 'Day care center',
    primaryActivityCode: '20',
    subActivityCode: '574',
    definition: 'Organisations providing daycare for some portion of the day for children or adults'
  },
  600: {
    primaryActivityDescription: 'Activities: general',
    subActivityDescription: 'Community Chest, United Way',
    primaryActivityCode: '21',
    subActivityCode: '600',
    definition:
      "Organisations considered 'Federated Giving Programs' that administer centralised funding and then disburse the funds to other nonprofit entities e.g. United Way, Community Chests"
  },
  601: {
    primaryActivityDescription: 'Activities: general',
    subActivityDescription: 'Booster club',
    primaryActivityCode: '21',
    subActivityCode: '601',
    definition: 'Organisations in the education sector that raise funds for their specific institution'
  },
  602: {
    primaryActivityDescription: 'Activities: general',
    subActivityDescription: 'Gifts, grants, loans to organizations',
    primaryActivityCode: '21',
    subActivityCode: '602',
    definition: 'Organisations that create grants, or distribute gifts to other organisations '
  },
  603: {
    primaryActivityDescription: 'Activities: general',
    subActivityDescription: 'Non-financial services of facilities to organizations',
    primaryActivityCode: '21',
    subActivityCode: '603',
    definition:
      'Organisations providing trainings, allow use of facilities, or provide any other non-financial services to other organisations'
  },
  900: {
    primaryActivityDescription: 'Other',
    subActivityDescription: 'Cemetery, burial activities',
    primaryActivityCode: '22',
    subActivityCode: '900',
    definition: 'Organisations maintaining cemeteries and perform burial/funeral services.'
  },
  902: {
    primaryActivityDescription: 'Other',
    subActivityDescription: 'Emergency, disaster aid fund',
    primaryActivityCode: '22',
    subActivityCode: '902',
    definition:
      'Organisations providing relief services in response to a major disaster/large-scale emergency or those providing support to public safety agencies'
  },
  903: {
    primaryActivityDescription: 'Other',
    subActivityDescription: 'Community trust',
    primaryActivityCode: '22',
    subActivityCode: '903',
    definition: 'Organisations making grants for charitable purposes in a specific community or region'
  },
  906: {
    primaryActivityDescription: 'Other',
    subActivityDescription: 'Consumer interest group',
    primaryActivityCode: '22',
    subActivityCode: '906',
    definition: 'Organisations providing education and protection for consumers who use specific products and services'
  },
  907: {
    primaryActivityDescription: 'Other',
    subActivityDescription: 'Veterans activities',
    primaryActivityCode: '22',
    subActivityCode: '907',
    definition:
      'Organisations focused on promoting the interests of Service Personnel and Military Veterans, either retired or active'
  },
  908: {
    primaryActivityDescription: 'Other',
    subActivityDescription: 'Patriotic activities',
    primaryActivityCode: '22',
    subActivityCode: '908',
    definition: 'Organisations that recognize and memorialize specific historical events'
  },
  913: {
    primaryActivityDescription: 'Other',
    subActivityDescription: 'Prevention of cruelty to animals',
    primaryActivityCode: '22',
    subActivityCode: '913',
    definition:
      'Organisations such as animal shelters that provide for the humane care, protection and control of animals and which investigate instances of cruelty to animals'
  },
  914: {
    primaryActivityDescription: 'Other',
    subActivityDescription: 'Achievement prizes, awards',
    primaryActivityCode: '22',
    subActivityCode: '914',
    definition: 'Organisations that reward some type of award of prize to participants'
  },
  915: {
    primaryActivityDescription: 'Other',
    subActivityDescription: 'Public works development',
    primaryActivityCode: '22',
    subActivityCode: '915',
    definition: 'Organisations providing transportation, communication services, or public utilities for the public'
  },
  916: {
    primaryActivityDescription: 'Other',
    subActivityDescription: 'Food services',
    primaryActivityCode: '22',
    subActivityCode: '916',
    definition:
      'Establishments engaged in providing food services at varying locations for organisations that have a contractual agreement with '
  },
  917: {
    primaryActivityDescription: 'Other',
    subActivityDescription: 'Thrift, retail',
    primaryActivityCode: '22',
    subActivityCode: '917',
    definition:
      'Organisations that offer new or second-hand items such as clothing or furniture at lower than retail prices, with proceeds supporting good causes'
  },
  918: {
    primaryActivityDescription: 'Other',
    subActivityDescription: 'Book, gift, supply store',
    primaryActivityCode: '22',
    subActivityCode: '918',
    definition: 'Book shops that feature writers to participate in events'
  },
  921: {
    primaryActivityDescription: 'Other',
    subActivityDescription: 'Loans or credit reporting',
    primaryActivityCode: '22',
    subActivityCode: '921',
    definition: 'Organisations providing credit reporting to specific communities'
  },
  922: {
    primaryActivityDescription: 'Other',
    subActivityDescription: 'Endowment fund, financial services',
    primaryActivityCode: '22',
    subActivityCode: '922',
    definition:
      'An investment portfolio where the initial money is provided by donations to a foundation. These types of Funds are established to fund charitable and nonprofit institutions such as churches, hospital and universities.'
  },
  923: {
    primaryActivityDescription: 'Other',
    subActivityDescription: 'Tribal entity',
    primaryActivityCode: '22',
    subActivityCode: '923',
    definition: 'Organisations focused on supporting and promoting indigenous tribes'
  },
  927: {
    primaryActivityDescription: 'Other',
    subActivityDescription: 'Fundraising',
    primaryActivityCode: '22',
    subActivityCode: '927',
    definition: 'Organisations that are raising funds for an organisation, or group of organisations.'
  },
  990: {
    primaryActivityDescription: 'Other',
    subActivityDescription: 'Child care: 501(k)',
    primaryActivityCode: '22',
    subActivityCode: '990',
    definition: 'US specific child care organisation that is 501k entity'
  },
  '001': {
    primaryActivityDescription: 'Religious activities',
    subActivityDescription: 'Religious organization',
    primaryActivityCode: '01',
    subActivityCode: '001',
    definition: 'Buildings dedicated to religious worship e.g. church, synagogue, mosque'
  },
  '002': {
    primaryActivityDescription: 'Religious activities',
    subActivityDescription: 'Association of churches',
    primaryActivityCode: '01',
    subActivityCode: '002',
    definition: 'Collective associations of churches'
  },
  '004': {
    primaryActivityDescription: 'Religious activities',
    subActivityDescription: 'Church auxiliary',
    primaryActivityCode: '01',
    subActivityCode: '004',
    definition: 'Organisations that act a supportive secondary group within a church e.g. administrative function'
  },
  '006': {
    primaryActivityDescription: 'Religious activities',
    subActivityDescription: 'Missionary activity',
    primaryActivityCode: '01',
    subActivityCode: '006',
    definition:
      "Organisations associated with a specific religious group whose focus is to promote it's faith through various activities"
  },
  '008': {
    primaryActivityDescription: 'Religious activities',
    subActivityDescription: 'Publishing',
    primaryActivityCode: '01',
    subActivityCode: '008',
    definition:
      'Organisations with religious affiliations that are engaged in publishing, production or programming that has religious content'
  },
  '029': {
    primaryActivityDescription: 'Religious activities',
    subActivityDescription: 'Other religious',
    primaryActivityCode: '01',
    subActivityCode: '029',
    definition: 'Organisations associated with religious activity not otherwise defined'
  },
  '030': {
    primaryActivityDescription: 'School, college, education',
    subActivityDescription: 'College, trade school',
    primaryActivityCode: '02',
    subActivityCode: '030',
    definition:
      'Organisations that provide formal education to children from preschool onwards (including charter schools)'
  },
  '031': {
    primaryActivityDescription: 'School, college, education',
    subActivityDescription: 'Disability-related school',
    primaryActivityCode: '02',
    subActivityCode: '031',
    definition:
      "Organisations that provide educational services, including special placement and individualised programming based on individual's needs e.g. for pupils with disabilities"
  },
  '032': {
    primaryActivityDescription: 'School, college, education',
    subActivityDescription: 'Nursery school',
    primaryActivityCode: '02',
    subActivityCode: '032',
    definition:
      'Organisations set up separately to a formal school and that provide foundation level learning and care for children aged 0 - 5 years old'
  },
  '033': {
    primaryActivityDescription: 'School, college, education',
    subActivityDescription: 'Faculty group',
    primaryActivityCode: '02',
    subActivityCode: '033',
    definition:
      'Organisations comprised by educators, may be from a specific school or district or faculty members within a specific disciplinary field'
  },
  '034': {
    primaryActivityDescription: 'School, college, education',
    subActivityDescription: 'Alumni group',
    primaryActivityCode: '02',
    subActivityCode: '034',
    definition:
      'Organisations comprised of graduates from a specific educational institute, and they support the institute through financial support, recruiting students, or other promotional activities.'
  },
  '035': {
    primaryActivityDescription: 'School, college, education',
    subActivityDescription: 'Parent association',
    primaryActivityCode: '02',
    subActivityCode: '035',
    definition:
      'Organisations comprised of parents, and/or parents and teachers and others in the community who are involved with helping the school district with the end goal of helping better conditions for the kids attending the school(s).'
  },
  '036': {
    primaryActivityDescription: 'School, college, education',
    subActivityDescription: 'Fraternity, sorority',
    primaryActivityCode: '02',
    subActivityCode: '036',
    definition:
      'Associations of students that are chapters of national or international organisations, and as part of their activities they may participate in community improvement or charitable projects. Sororities, fraternities, and other honorary societies'
  },
  '037': {
    primaryActivityDescription: 'School, college, education',
    subActivityDescription: 'Student group',
    primaryActivityCode: '02',
    subActivityCode: '037',
    definition:
      'Organisations that provide services to students to support student needs e.g. helps foster learning, personal development, creates a healthy and diverse community and enrich student life.'
  },
  '038': {
    primaryActivityDescription: 'School, college, education',
    subActivityDescription: 'School athletics',
    primaryActivityCode: '02',
    subActivityCode: '038',
    definition:
      'School athletic groups that compete against other school athletic groups. Can also be private athletic schools.'
  },
  '039': {
    primaryActivityDescription: 'School, college, education',
    subActivityDescription: 'Scholarships: children of employees',
    primaryActivityCode: '02',
    subActivityCode: '039',
    definition:
      'Organisations that provide financial support in the forms of scholarships for students whose parents fall under a specific profession'
  },
  '040': {
    primaryActivityDescription: 'School, college, education',
    subActivityDescription: 'Scholarships: other',
    primaryActivityCode: '02',
    subActivityCode: '040',
    definition:
      'Organisations that help students that demonstrate a financial need obtain financial assistance for educational and living expenses while in school'
  },
  '041': {
    primaryActivityDescription: 'School, college, education',
    subActivityDescription: 'Student loans',
    primaryActivityCode: '02',
    subActivityCode: '041',
    definition:
      'Organisations that provide students with financial assistance in the forms of loans, many times they are low interest or focus on helping students resolve outstanding student loans. '
  },
  '042': {
    primaryActivityDescription: 'School, college, education',
    subActivityDescription: 'Student housing',
    primaryActivityCode: '02',
    subActivityCode: '042',
    definition: 'A division of a university foundation specifically focused on housing for students'
  },
  '043': {
    primaryActivityDescription: 'School, college, education',
    subActivityDescription: 'Student aid: other',
    primaryActivityCode: '02',
    subActivityCode: '043',
    definition: 'A targeted type of scholarships assistance'
  },
  '044': {
    primaryActivityDescription: 'School, college, education',
    subActivityDescription: 'Student foreign exchange',
    primaryActivityCode: '02',
    subActivityCode: '044',
    definition: 'Organisations that have foreign exchange student programs'
  },
  '045': {
    primaryActivityDescription: 'School, college, education',
    subActivityDescription: 'Student business',
    primaryActivityCode: '02',
    subActivityCode: '045',
    definition: 'Organisations that are student run businesses '
  },
  '046': {
    primaryActivityDescription: 'School, college, education',
    subActivityDescription: 'Private school',
    primaryActivityCode: '02',
    subActivityCode: '046',
    definition: 'Organisations that charge a fees to provide a formal education to children from preschool onwards'
  },
  '059': {
    primaryActivityDescription: 'School, college, education',
    subActivityDescription: 'School activities',
    primaryActivityCode: '02',
    subActivityCode: '059',
    definition:
      'Organisations that provide formal education to children from preschool onwards, specifically  Charter, Elementary, Primary, Secondary, High, Technical & Vocational schools, Higher Education, Two-Year & Undergrad colleges, Universities and Graduate & Professional Schools'
  },
  B90: {
    primaryActivityDescription: 'School, college, education',
    subActivityDescription: 'Educational services',
    primaryActivityCode: '02',
    subActivityCode: 'B90',
    definition:
      "Organisations that aren't formal educational setting such as school, but do focus on educational development"
  },
  B92: {
    primaryActivityDescription: 'School, college, education',
    subActivityDescription: 'Remedial education',
    primaryActivityCode: '02',
    subActivityCode: 'B92',
    definition: 'Organisations that provide youth literacy programs'
  },
  '009': {
    primaryActivityDescription: 'Cultural, historic, related education',
    subActivityDescription: 'Library non-501(c)(3)',
    primaryActivityCode: '03',
    subActivityCode: '009',
    definition: 'Libraries without nonprofit status'
  },
  '060': {
    primaryActivityDescription: 'Cultural, historic, related education',
    subActivityDescription: 'Museum, zoo, planetarium',
    primaryActivityCode: '03',
    subActivityCode: '060',
    definition:
      'Organisations that acquire, conserve, research, educate and exhibit the heritage of humanity and its environment '
  },
  '061': {
    primaryActivityDescription: 'Cultural, historic, related education',
    subActivityDescription: 'Library',
    primaryActivityCode: '03',
    subActivityCode: '061',
    definition: 'Libraries with nonprofit status'
  },
  '062': {
    primaryActivityDescription: 'Cultural, historic, related education',
    subActivityDescription: 'Historical site, records',
    primaryActivityCode: '03',
    subActivityCode: '062',
    definition: 'Organisations that preserve a historical site, event, and/or re-enacts that historical event'
  },
  '064': {
    primaryActivityDescription: 'Cultural, historic, related education',
    subActivityDescription: 'Commemorative event',
    primaryActivityCode: '03',
    subActivityCode: '064',
    definition:
      'Organisations that recreate events that had important significance, or that promote and/or produce community and public events '
  },
  '065': {
    primaryActivityDescription: 'Cultural, historic, related education',
    subActivityDescription: 'Fair, large event',
    primaryActivityCode: '03',
    subActivityCode: '065',
    definition: 'Organisations that organise fairs, parades, and/or pageants'
  },
  '088': {
    primaryActivityDescription: 'Cultural, historic, related education',
    subActivityDescription: 'Community theatre',
    primaryActivityCode: '03',
    subActivityCode: '088',
    definition: 'Organisations formed by local community groups to produce plays'
  },
  '089': {
    primaryActivityDescription: 'Cultural, historic, related education',
    subActivityDescription: 'Singing group',
    primaryActivityCode: '03',
    subActivityCode: '089',
    definition: 'Organisations that focus on producing singing performances, choir groups.'
  },
  '090': {
    primaryActivityDescription: 'Cultural, historic, related education',
    subActivityDescription: 'Cultural performances',
    primaryActivityCode: '03',
    subActivityCode: '090',
    definition:
      'Organisations that provide access to arts, such as performing arts and/or operate facilities including that allow these arts to occur'
  },
  '092': {
    primaryActivityDescription: 'Cultural, historic, related education',
    subActivityDescription: 'Literary activities',
    primaryActivityCode: '03',
    subActivityCode: '092',
    definition: 'Organisations that promote reading'
  },
  '093': {
    primaryActivityDescription: 'Cultural, historic, related education',
    subActivityDescription: 'Cultural exchanges with foreign country',
    primaryActivityCode: '03',
    subActivityCode: '093',
    definition:
      'Organisations that provide international travel experiences for individuals and/or families for the purpose of cultural exchange and/or international living or work experiences'
  },
  '094': {
    primaryActivityDescription: 'Cultural, historic, related education',
    subActivityDescription: 'Genealogical activities',
    primaryActivityCode: '03',
    subActivityCode: '094',
    definition: 'Organisations focused on documenting and preserving genealogical information, history and culture'
  },
  A20: {
    primaryActivityDescription: 'Cultural, historic, related education',
    subActivityDescription: 'Arts and culture',
    primaryActivityCode: '03',
    subActivityCode: 'A20',
    definition: 'Organisations providing access to all types of arts'
  },
  A25: {
    primaryActivityDescription: 'Cultural, historic, related education',
    subActivityDescription: 'Arts education',
    primaryActivityCode: '03',
    subActivityCode: 'A25',
    definition: 'Organisations that provide informal art education'
  },
  A40: {
    primaryActivityDescription: 'Cultural, historic, related education',
    subActivityDescription: 'Visual arts',
    primaryActivityCode: '03',
    subActivityCode: 'A40',
    definition: 'Organisations that promote or provide access to a variety of visual arts'
  },
  A6E: {
    primaryActivityDescription: 'Cultural, historic, related education',
    subActivityDescription: 'Performing arts school',
    primaryActivityCode: '03',
    subActivityCode: 'A6E',
    definition: 'Organisations that provide classes for performing arts, but not formal educational degrees.'
  },
  A70: {
    primaryActivityDescription: 'Cultural, historic, related education',
    subActivityDescription: 'Humanities',
    primaryActivityCode: '03',
    subActivityCode: 'A70',
    definition:
      'Organisations that promote the study, teaching, or appreciation of the humanities/social sciences e.g art, history, philosophy  '
  },
  A80: {
    primaryActivityDescription: 'Cultural, historic, related education',
    subActivityDescription: 'Historical organizations',
    primaryActivityCode: '03',
    subActivityCode: 'A80',
    definition: 'Organisations that promote awareness of history and historical artifacts'
  },
  A90: {
    primaryActivityDescription: 'Cultural, historic, related education',
    subActivityDescription: 'Arts services',
    primaryActivityCode: '03',
    subActivityCode: 'A90',
    definition: 'Organisations providing tours and facilities for other art organisations'
  },
  J20: {
    primaryActivityDescription: 'Instructional training: other',
    subActivityDescription: 'Employment resources',
    primaryActivityCode: '04',
    subActivityCode: 'J20',
    definition: 'Organisations that help people prepare for, find, secure and retain suitable employment.'
  },
  J32: {
    primaryActivityDescription: 'Instructional training: other',
    subActivityDescription: 'Goodwill Industries',
    primaryActivityCode: '04',
    subActivityCode: 'J32',
    definition: 'Organisations that are specifically part of the nonprofit Goodwill Industries International '
  },
  E32: {
    primaryActivityDescription: 'Health services',
    subActivityDescription: 'Community clinic (non-HRSA)',
    primaryActivityCode: '05',
    subActivityCode: 'E32',
    definition: "Health facility focused on outpatient services and care that aren't registered with the HRSA"
  },
  E40: {
    primaryActivityDescription: 'Health services',
    subActivityDescription: 'Reproductive health care',
    primaryActivityCode: '05',
    subActivityCode: 'E40',
    definition:
      'Organisations that provide medical, educational, and counseling services related to reproductive health care.'
  },
  E42: {
    primaryActivityDescription: 'Health services',
    subActivityDescription: 'Family planning',
    primaryActivityCode: '05',
    subActivityCode: 'E42',
    definition: 'Organisations that provide assistance to individuals wanting assistance with family planning'
  },
  E86: {
    primaryActivityDescription: 'Health services',
    subActivityDescription: 'Patient and family support',
    primaryActivityCode: '05',
    subActivityCode: 'E86',
    definition:
      'Organisations providing support services to patients and families, including social, recreational support and general support groups'
  },
  P47: {
    primaryActivityDescription: 'Health services',
    subActivityDescription: 'Pregnancy center',
    primaryActivityCode: '05',
    subActivityCode: 'P47',
    definition:
      'Organisations that provide free and confidential services to women and families facing unplanned pregnancies. Services can include pregnancy testing and physician exams, counseling on pregnancy-related issues, information on STDS and help with material goods such as maternity and baby items. These organisations will NOT provide information on or referrals for abortions'
  },
  P74: {
    primaryActivityDescription: 'Health services',
    subActivityDescription: 'Hospice',
    primaryActivityCode: '05',
    subActivityCode: 'P74',
    definition: 'Facility designed to provide palliative care to the terminally ill in a homelike setting'
  },
  K25: {
    primaryActivityDescription: 'Farming, agriculture',
    subActivityDescription: 'Farmland preservation',
    primaryActivityCode: '08',
    subActivityCode: 'K25',
    definition:
      'Organisations such as land trusts that work to ensure that farmland is preserved for farming purposes rather than freed for development or environmental quality purposes.'
  },
  N72: {
    primaryActivityDescription: 'Sports, recreational, social',
    subActivityDescription: 'Special Olympics',
    primaryActivityCode: '11',
    subActivityCode: 'N72',
    definition:
      'Organisations that prepare people to participate in the Special Olympics, an international program of sports training and athletic competition for children and adults with disabilities which features a variety of sports and games, or in other similar competitions'
  },
  O20: {
    primaryActivityDescription: 'Youth services',
    subActivityDescription: 'Youth center',
    primaryActivityCode: '12',
    subActivityCode: 'O20',
    definition:
      'Organisations that provide supervised recreational and social activities for children and youth of all ages and backgrounds, but particularly for disadvantaged youth, except Boys and Girls Club USA'
  },
  O31: {
    primaryActivityDescription: 'Youth services',
    subActivityDescription: 'Big Brothers Big Sisters',
    primaryActivityCode: '12',
    subActivityCode: 'O31',
    definition:
      'Organisations specifically designated as Big Brothers or Big Sisters that provide male or female adult companionship, guidance and/or role models for young men or women.'
  },
  O41: {
    primaryActivityDescription: 'Youth services',
    subActivityDescription: 'Boy Scouts',
    primaryActivityCode: '12',
    subActivityCode: 'O41',
    definition: 'Organisations specifically designated as Boy Scouts USA or internationally'
  },
  O42: {
    primaryActivityDescription: 'Youth services',
    subActivityDescription: 'Girls Scouts',
    primaryActivityCode: '12',
    subActivityCode: 'O42',
    definition: 'Organisations specifically designated as Girls Scouts USA or internationally'
  },
  O50: {
    primaryActivityDescription: 'Youth services',
    subActivityDescription: 'Youth development',
    primaryActivityCode: '12',
    subActivityCode: 'O50',
    definition: 'Youth development programs, mentorship programs or youth service organisations'
  },
  O53: {
    primaryActivityDescription: 'Youth services',
    subActivityDescription: 'Youth development: business',
    primaryActivityCode: '12',
    subActivityCode: 'O53',
    definition: 'Job training organizations for youth, or youth programs geared towards specific professions'
  },
  O54: {
    primaryActivityDescription: 'Youth services',
    subActivityDescription: 'Youth development: citizenship',
    primaryActivityCode: '12',
    subActivityCode: 'O54',
    definition:
      'Organisations providing youth programs that provide opportunities for understanding the government and citizenship responsibilities'
  },
  L81: {
    primaryActivityDescription: 'Housing',
    subActivityDescription: 'Home improvement, repairs',
    primaryActivityCode: '14',
    subActivityCode: 'L81',
    definition:
      'Organisations providing assistance to individuals needing to make home upgrades for safety, cosmetic, energy efficiency, and/or accessibility reasons'
  },
  T20: {
    primaryActivityDescription: 'Other',
    subActivityDescription: 'Private grants foundation',
    primaryActivityCode: '22',
    subActivityCode: 'T20',
    definition:
      'Organisations with funds - from a single source, such as an individual, family or corporation - and programs managed by its own trustees or directors, established to support charitable activities serving the common welfare, primarily through grantmaking.'
  },
  T22: {
    primaryActivityDescription: 'Other',
    subActivityDescription: 'Private independent foundation',
    primaryActivityCode: '22',
    subActivityCode: 'T22',
    definition:
      "An independent legal organisation with charitable purposes, which receive its funding from a 'private' means e.g. single individual, a family, or a corporation versus the public"
  },
  T23: {
    primaryActivityDescription: 'Other',
    subActivityDescription: 'Private operating foundation',
    primaryActivityCode: '22',
    subActivityCode: 'T23',
    definition:
      'Organisations that use the majority of their resources to provide charitable services or run charitable programs of their own. They make few, if any, grants to outside organisations and, like private independent foundations, they generally do not raise funds from the public.'
  },
  Z99: {
    primaryActivityDescription: 'Other',
    subActivityDescription: 'Unknown',
    primaryActivityCode: '22',
    subActivityCode: 'Z99',
    definition: 'Organisations that do not fit into any other category'
  }
}

export const orgSubActivityCodes = Object.keys(orgSubActivities)
