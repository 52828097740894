/* eslint @typescript-eslint/no-var-requires: "off" */

/* eslint-disable global-require */
const countries = require('i18n-iso-countries')
countries.registerLocale(require('i18n-iso-countries/langs/en.json'))
/* eslint-enable global-require */

export const getRegion = (countryCode: string) => {
  return countries.getName(countryCode, 'en', { select: 'official' })
}
