import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import { MyAccount } from '../../myAccount/MyAccount'
import { ComplianceContainer } from '../../compliance/ComplianceContainer'
import { AgentVerificationDetailsContainer } from '../../agentVerification/agentVerificationDetails/AgentVerificationDetailsContainer'
import { DonationRequestDetailContainer } from '../../donationRequests/donationRequestDetail/DonationRequestDetailContainer'
import { OrganizationsContainer } from '../../organizations/OrganizationsContainer'
import { OrganizationsDetail } from '../../organizations/organizationsDetail/OrganizationsDetail'
import { NonprofitDetail } from '../../validations/nonprofitDetail/NonprofitDetail'
import { MilestonesContainer } from '../../organizations/organizationsDetail/milestones/MilestonesContainer'
import { DonationMatchRequestDetailsContainer } from '../../donationMatchRequest/donationMatchRequest/DonationMatchRequestDetailsContainer'
import { AutoRejectionListContainer } from '../../autoRejectionList/AutoRejectionListContainer'
import { ValidationSubmissionsContainer } from '../../validationSubmissions/ValidationSubmissionsContainer'
import { ValidationSubmissionDetailsContainer } from '../../validationSubmissions/validationSubmissionDetails/ValidationSubmissionDetailsContainer'

import { BankDetailsView } from '@percent/admin-dashboard/app/bankDetails/bankDetailsView/BankDetailsView'
import { ClaimsContainer } from '@percent/admin-dashboard/app/claims/ClaimsContainer'
import { ValidationsContainer } from '@percent/admin-dashboard/app/validations/ValidationsContainer'
import { PartnerDetail } from '@percent/admin-dashboard/app/partner/partnerDetail/PartnerDetail'
import { PartnerListTable } from '@percent/admin-dashboard/app/partner/partnerListTable/PartnerListTable'
import { AgentVerificationContainer } from '@percent/admin-dashboard/app/agentVerification/AgentVerificationContainer'
import { ActivityCheckContainer } from '@percent/admin-dashboard/app/activityCheck/ActivityCheckContainer'
import { ActivityCheckDetailView } from '@percent/admin-dashboard/app/activityCheck/ActivityCheckDetail/ActivityCheckDetailView'
import { EligibilityContainer } from '@percent/admin-dashboard/app/eligibility/EligibilityContainer'
import { EligibilityDetailsContainer } from '@percent/admin-dashboard/app/eligibility/eligibilityDetail/EligibilityDetailsContainer'
import { useFeatureFlag } from '@percent/admin-dashboard/common/hooks/useFeatureFlag/useFeatureFlag'
import { RegistriesContainer } from '@percent/admin-dashboard/app/registries/RegistriesContainer'
import { BankDetailsContainer } from '@percent/admin-dashboard/app/bankDetails/bankDetailsContainer/BankDetailsContainer'
import { DisbursementsContainer } from '@percent/admin-dashboard/app/disbursements/DisbursementsContainer'
import { DisbursementDetailsContainer } from '@percent/admin-dashboard/app/disbursements/disbursementDetails/DisbursementDetailsContainer'
import { DonationRequestsContainer } from '@percent/admin-dashboard/app/donationRequests/DonationRequestsContainer'
import { DonationsContainer } from '@percent/admin-dashboard/app/donations/DonationsContainer'
import { DonationMatchRequestsListContainer } from '@percent/admin-dashboard/app/donationMatchRequest/donationMatchRequestsList/DonationMatchRequestsListContainer'

function RoutesWithOutSplitFlag() {
  return [
    <Route path="/my-account" exact component={MyAccount} key="MyAccount" />,
    <Route path="/organizations" exact component={OrganizationsContainer} key="OrganizationsContainer" />,
    <Route path="/organizations/:id" component={OrganizationsDetail} key="OrganizationsDetail" />,
    <Route
      path="/nonprofits/:id"
      exact
      key="RedirectRouteToOrganization"
      render={({ match }) => <Redirect to={{ pathname: `/organizations/${match?.params?.id}`, data: 'nonprofits' }} />}
    />,
    <Route path="/claims" exact component={ClaimsContainer} key="ClaimsContainer" />,
    <Route path="/validations" exact component={ValidationsContainer} key="ValidationsContainer" />,
    <Route path="/partners" exact component={PartnerListTable} key="PartnerListTable" />,
    <Route path="/partners/partner/:id" component={PartnerDetail} key="PartnerDetail" />,
    <Route path="/milestones" component={MilestonesContainer} key="MilestonesContainer" />
  ]
}

export function Routes() {
  const {
    registriesFeatureFlag,
    disbursementsFeatureFlag,
    bankDetailsReadFeatureFlag,
    donationRequestsFeatureFlag,
    donationsFeatureFlag,
    donationMatchRequestsFeatureFlag,
    autoRejectionListFeatureFlag,
    validationSubmissionTabFeatureFlag,
    isReady,
    isTimedout
  } = useFeatureFlag()

  if (isReady || isTimedout) {
    return (
      <Switch>
        {RoutesWithOutSplitFlag()}
        <Route path="/validations/:id" component={NonprofitDetail} key="NonprofitDetail" />
        <Route path="/eligibility" exact component={EligibilityContainer} />
        <Route path="/eligibility/:id" component={EligibilityDetailsContainer} />
        <Route path="/compliance" exact component={ComplianceContainer} />
        <Route path="/agent-verification" exact component={AgentVerificationContainer} />
        <Route path="/agent-verification/:id" exact component={AgentVerificationDetailsContainer} />
        <Route path="/activity/:id" exact component={ActivityCheckDetailView} />
        <Route path="/activity" exact component={ActivityCheckContainer} />
        {registriesFeatureFlag && <Route path="/registries" component={RegistriesContainer} />}
        {bankDetailsReadFeatureFlag && <Route path="/bank-details/:id" exact component={BankDetailsView} />}
        {bankDetailsReadFeatureFlag && <Route path="/bank-details" exact component={BankDetailsContainer} />}
        {disbursementsFeatureFlag && <Route path="/disbursements" exact component={DisbursementsContainer} />}
        {disbursementsFeatureFlag && <Route path="/disbursements/:id" component={DisbursementDetailsContainer} />}
        {donationsFeatureFlag && <Route path="/donations" component={DonationsContainer} />}
        {donationMatchRequestsFeatureFlag && (
          <Route path="/donation-match-requests" exact component={DonationMatchRequestsListContainer} />
        )}
        {donationMatchRequestsFeatureFlag && (
          <Route
            path="/donation-match-requests/:donationMatchRequestId"
            component={DonationMatchRequestDetailsContainer}
          />
        )}
        {donationRequestsFeatureFlag && <Route path="/donation-requests" exact component={DonationRequestsContainer} />}
        {donationRequestsFeatureFlag && (
          <Route path="/donation-requests/:id" component={DonationRequestDetailContainer} />
        )}
        {autoRejectionListFeatureFlag && (
          <Route path="/auto-rejection-list" exact component={AutoRejectionListContainer} />
        )}
        {validationSubmissionTabFeatureFlag && (
          <Route path="/validation-submissions" exact component={ValidationSubmissionsContainer} />
        )}
        {validationSubmissionTabFeatureFlag && (
          <Route path="/validation-submissions/:id" component={ValidationSubmissionDetailsContainer} />
        )}
        <Redirect to="/organizations" />
      </Switch>
    )
  }

  return <Switch>{RoutesWithOutSplitFlag()}</Switch>
}
