import { PBPGetResponse, PBPListResponse } from '@percent/admin-dashboard/services/pbpResponses'
import {
  AddToAutoRejectionListProps,
  AutoRejectionListServiceCapabilities,
  AutoRejectionType,
  GetAutoRejectionListProps
} from './autoRejectionList.types'

const autoRejectionListUrl = 'v1/admin/agent-verifications-auto-rejection-list'

export const autoRejectionListService = ({ percentClient }: AutoRejectionListServiceCapabilities) => ({
  getAutoRejectionList: (params: GetAutoRejectionListProps) =>
    percentClient.get<PBPListResponse<AutoRejectionType>>(autoRejectionListUrl, { params }),
  addToAutoRejectonList: (params: AddToAutoRejectionListProps) =>
    percentClient.put<PBPGetResponse<AutoRejectionType>>(autoRejectionListUrl, { ...params }),
  removeFromAutoRejectionList: ({ id }: { id: string }) => percentClient.delete(`${autoRejectionListUrl}/${id}`)
})
