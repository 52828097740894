import { getCurrencyMinorUnits } from '../getCurrencyMinorUnits'

import { FormatAmountFromMinorUnitsProps, FormatAmountProps } from './formatAmount.types'

export const formatAmount = ({ value, currencyCode, language = 'en-us', hideCurrencySymbol }: FormatAmountProps) => {
  const formattedValue = new Intl.NumberFormat(language, {
    style: 'currency',
    currency: currencyCode,
    currencyDisplay: hideCurrencySymbol ? 'code' : 'symbol'
  }).format(value)

  return hideCurrencySymbol ? formattedValue.replace(currencyCode, '') : formattedValue
}

export const formatAmountFromMinorUnits = ({
  value,
  currencyCode,
  currencies,
  language = 'en-us',
  hideCurrencySymbol
}: FormatAmountFromMinorUnitsProps) => {
  const minorUnits = getCurrencyMinorUnits(currencyCode, currencies)

  const formattedValue = new Intl.NumberFormat(language, {
    style: 'currency',
    currency: currencyCode,
    currencyDisplay: hideCurrencySymbol ? 'code' : 'symbol'
  }).format(value / 10 ** minorUnits)

  return hideCurrencySymbol ? formattedValue.replace(currencyCode, '') : formattedValue
}
