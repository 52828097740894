import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { Dialog, Loader, PercentButton } from '@percent/admin-dashboard/common/components'
import { ReactComponent as CheckMark } from '@percent/admin-dashboard/common/assets/images/check_mark.svg'
import { ReactComponent as Close } from '@percent/admin-dashboard/common/assets/images/close.svg'
import { useMutation } from '@percent/admin-dashboard/common/hooks'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { SaveModalProps } from './SaveModal.types'
import styles from './SaveModal.module.scss'

export function SaveModal({
  isOpened,
  setIsOpened,
  partner,
  subTagsForm,
  refreshApi,
  refreshPartnerApi
}: SaveModalProps) {
  const { t } = useTranslation()
  const { adminService } = useServices()

  const [{ isLoading, success, errorMessage }, { apiFunc }] = useMutation(adminService.commands.editPartnerDetail)
  const handleSubmit = () => {
    apiFunc({
      payload: {
        allowedActivitySubTags: subTagsForm
      },
      partnerId: partner.id
    })
  }

  const closeModal = () => {
    refreshApi()
    refreshPartnerApi()
    setIsOpened(false)
  }

  return (
    <Dialog
      openModal={isOpened}
      onClose={() => setIsOpened(false)}
      withoutHeader={success || !!errorMessage}
      headerTitle={t('dialog.updateSettings.updateSettings')}
    >
      {isLoading ? (
        <Loader />
      ) : errorMessage ? (
        <div className={styles.dialogBody}>
          <div className={styles.close}>
            <Close />
          </div>
          <Typography variant="h5">{t('dialog.updateSettings.settingsNotUpdated')}</Typography>
          <Typography>
            {t('dialog.updateSettings.settingsFor')}
            <span style={{ fontFamily: 'MarkProMedium' }}> {partner.name} </span>
            {t('dialog.updateSettings.couldntBeUpdated')}
          </Typography>
          <PercentButton title={t('button.done')} onClick={() => setIsOpened(false)} className={styles.centerButton} />
        </div>
      ) : success ? (
        <div className={styles.dialogBody}>
          <div className={styles.checkMark}>
            <CheckMark />
          </div>
          <Typography variant="h5">{t('dialog.updateSettings.settingsUpdated')}</Typography>
          <Typography>
            {t('dialog.updateSettings.settingsFor')}
            <span style={{ fontFamily: 'MarkProMedium' }}> {partner.name} </span>
            {t('dialog.updateSettings.haveBeenUpdated')}
          </Typography>
          <PercentButton title={t('button.done')} onClick={closeModal} className={styles.centerButton} />
        </div>
      ) : (
        <>
          <Grid item xs={12} className={styles.modalBody}>
            <Typography>
              {t('dialog.updateSettings.updateEligibility')}
              <span style={{ fontFamily: 'MarkProMedium' }}> {partner.name}. </span>
              {t('dialog.updateSettings.sureToContinue')}
            </Typography>
          </Grid>
          <Grid item xs={12} className={styles.dialogFooter}>
            <PercentButton title={t('button.updateSettings')} onClick={handleSubmit} />
            <PercentButton
              title={t('button.cancel')}
              onClick={() => setIsOpened(false)}
              textColour="secondaryTextColour"
              outlined
            />
          </Grid>
        </>
      )}
    </Dialog>
  )
}
