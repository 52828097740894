import { createContext, useContext } from 'react'

export type Severity = 'success' | 'info' | 'warning' | 'error'

export type Toast = {
  id: string
  message: string
  severity: Severity
}

export const ToastContext = createContext<undefined | ((_: string, __: Severity) => void)>(undefined)

export const useToast = () => {
  const ctx = useContext(ToastContext) as (_: string, __: Severity) => void

  return ctx
}
