import { Snackbar } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { Severity, Toast, ToastContext } from './ToastContext'

export function ToastContainer({ children }: { children: ReactNode }) {
  const [toasts, setToasts] = useState<Toast[]>([])

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (toasts.length > 0) {
      const timer = setTimeout(() => setToasts(ts => ts.slice(1)), 4000)

      return () => clearTimeout(timer)
    }

    return () => {}
  }, [toasts])

  const addToast = useCallback(
    (message: string, severity: Severity) => {
      setToasts(ts => [...ts, { message, severity, id: uuidv4() }])
    },
    [setToasts]
  )

  return (
    <ToastContext.Provider value={addToast}>
      {toasts.map(({ message, severity, id }, i) => (
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          open
          style={{ marginTop: 60 * i }}
          key={id}
        >
          <MuiAlert elevation={6} variant="filled" icon={false} severity={severity}>
            {message}
          </MuiAlert>
        </Snackbar>
      ))}
      {children}
    </ToastContext.Provider>
  )
}
