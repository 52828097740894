import React from 'react'
import { DialogContent, Typography } from '@material-ui/core'

import { ReactComponent as SuccessIcon } from '@percent/admin-dashboard/common/assets/images/success.svg'
import { ReactComponent as RejectedIcon } from '@percent/admin-dashboard/common/assets/images/rejected.svg'
import styles from './ActionDialog.module.scss'
import { Button, Spacer } from '@percent/lemonade'
import { ActionDialogActionProps } from './ActionDialog.types'

export function ActionsDialogAction({
  handleOnClose,
  description,
  title,
  buttonTitle,
  successIcon
}: ActionDialogActionProps) {
  return (
    <DialogContent className={styles.actionDialogContent}>
      <div className={styles.svg}>
        {(successIcon && <SuccessIcon className={styles.icon} />) || <RejectedIcon className={styles.icon} />}
      </div>
      <Spacer size={4} axis="vertical" />
      <Typography variant="h5" align="center">
        {title}
      </Typography>
      <Typography variant="body1" align="center">
        {description}
      </Typography>
      <div className={styles.singleButtonWrapper}>
        <Button onPress={handleOnClose} variant="primary">
          {buttonTitle}
        </Button>
      </div>
    </DialogContent>
  )
}
