/* eslint-disable */
import React from 'react'

import { TabPanelProps } from './TabPanel.types'

export function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props

  return <>{value === index && <>{children}</>}</>
}
