export const mockValidationSubmissionDetailsResponse = {
  data: {
    id: 'staging_validationsubmission_000000CxaBvsStR2P5dRbnUqgWOhq',
    status: 'succeeded',
    createdAt: '2024-10-31T08:13:46.937Z',
    partnerId: 'staging_partner_000000CiJ8pz5hageQdgbzOBZZxaa',
    configuration: {
      id: 'staging_hostedconfiguration_000000CgDnN0eCPKOL9SHJNNRxHGW',
      name: '(Internal) Causes Existing Organisations'
    },
    organisation: {
      id: 'staging_organisation_000000CxaBvlbRIDRuTI7hfXwY677',
      name: 'Natalia NM AUTOMATED TEST 55eb7e8c-e7d8-46ac-85b6-23b5ded2907d',
      countryCode: 'ISR'
    },
    agentVerification: {
      id: 'staging_agentverification_000000CxaBvsStR2P5dRbnUqgWOhV',
      email: 'test.email+855bce89-f3f6-4d20-bc0d-b2d6c1fc4613@16dn19xy.mailosaur.net',
      firstName: 'no_firstname',
      lastName: 'no_lastname',
      status: 'approved',
      rejectionReasonCode: null
    },
    eligibilitySubscription: {
      id: 'staging_eligibilitysubscription_000000CxaBvsStR2P5dRbnUqgWOhm',
      status: 'live',
      results: {
        eligibilityStatus: 'pass',
        rejectedActivitySubTags: [],
        confirmedActivitySubTags: [
          'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZH',
          'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZN'
        ]
      }
    },
    monitoringSubscription: {
      id: 'staging_monitoringsubscription_000000CxaBvsStR2P5dRbnUqgWOhf',
      status: 'live',
      results: 'pass'
    },
    validationRequest: {
      id: null,
      name: null,
      acceptedAt: null,
      rejectedAt: null,
      countryCode: null
    }
  }
}
