import React from 'react'
import { OutlinedInput, InputAdornment, FormControl } from '@material-ui/core'
import { Search } from '@material-ui/icons'

import styles from './SearchField.module.scss'
import { SearchFieldProps } from './SearchField.types'

export function SearchField({
  value,
  handleChange,
  handleKeyPress,
  placeholder,
  testId = 'search-outlined-input'
}: SearchFieldProps) {
  return (
    <FormControl variant="outlined" className={styles.searchFieldContainer}>
      <OutlinedInput
        inputProps={{
          'data-testid': testId
        }}
        id="outlined-adornment-password"
        type="text"
        value={value}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
        className={styles.searchField}
        startAdornment={
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        }
        placeholder={placeholder}
      />
    </FormControl>
  )
}
