import { useTranslation } from 'react-i18next'

import { RelationshipDropdownValues } from '../relatedOrganisations/RelatedOrganisations.types'
import { FlagBadge } from '../flagBadge/FlagBadge'

const createBranchBadgeText = (relationshipType: string) => {
  switch (relationshipType) {
    case RelationshipDropdownValues.MERGE:
      return 'dialog.relatedOrganisations.badge.merge'

    case RelationshipDropdownValues.BRANCH_CHILD:
      return 'dialog.relatedOrganisations.badge.child'

    case RelationshipDropdownValues.FISCAL_SPONSORSHIP_CHILD:
      return 'dialog.relatedOrganisations.badge.fiscalSponsorshipChild'

    case RelationshipDropdownValues.FISCAL_SPONSORSHIP_PARENT:
      return 'dialog.relatedOrganisations.badge.fiscalSponsorshipParent'

    default:
      return 'dialog.relatedOrganisations.badge.parent'
  }
}

export function RelationshipBadge({ relationshipType }: { relationshipType: RelationshipDropdownValues }) {
  const { t } = useTranslation()

  return <FlagBadge variant="default">{t(createBranchBadgeText(relationshipType))}</FlagBadge>
}
