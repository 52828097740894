import { OrganisationRelationship, ValidationRelationship } from '@percent/admin-dashboard/api/types'

export type RelationshipFormValues = {
  type?: RelationshipDropdownValues | ''
  organisationId?: string
  organisation?: OrganisationRelationship
}

export type RelatedOrganisationsProps = {
  relatedOrganisations?: OrganisationRelationship[] | ValidationRelationship[] | null
  isLoading: boolean
  hasCorrectRegistryId: boolean
  isApproved: boolean
  registry?: string
  registryId?: string
}

export enum RelationshipDropdownValues {
  MERGE = 'merge',
  BRANCH_CHILD = 'branch_child',
  BRANCH_PARENT = 'branch_parent',
  FISCAL_SPONSORSHIP_CHILD = 'fiscal_sponsorship_child',
  FISCAL_SPONSORSHIP_PARENT = 'fiscal_sponsorship_parent'
}

export enum RelationshipTypeValues {
  MERGE = 'merge',
  BRANCH = 'branch',
  FISCAL_SPONSORSHIP = 'fiscal_sponsorship'
}

export enum RelationshipValues {
  CHILD = 'child',
  PARENT = 'parent'
}

export type RelationshipDropdownOptions = { label: string; value: RelationshipDropdownValues }[]
