import { Box, DialogContent, Typography } from '@material-ui/core'
import { FormEvent } from 'react'
import { useTranslation } from 'react-i18next'

import { Dialog } from '@percent/admin-dashboard/common/components'
import { Button } from '@percent/lemonade'
import { MarkAsSentModalProps } from './MarkAsSentModal.types'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { useToast } from '@percent/admin-dashboard/containers/toast/ToastContext'
import styles from './MarkAsSentModal.module.scss'

export function MarkAsSentModal({
  isOpen,
  onClose,
  disbursementId,
  organisationName,
  refreshData
}: MarkAsSentModalProps) {
  const { t } = useTranslation()
  const { disbursementsService } = useServices()
  const addToast = useToast()

  const markAsSent = async () => {
    try {
      await disbursementsService.markDisbursementAsSent(disbursementId)
      addToast(t('toast.sentDisbursementSuccess'), 'success')
      refreshData?.()
      onClose()
    } catch {
      addToast(t('toast.sentDisbursementError'), 'error')
    }
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    markAsSent()
  }

  return (
    <Dialog
      headerTitle={t(`dialog.markAsSent.header`)}
      openModal={isOpen}
      onClose={onClose}
      data-testid="mark-as-sent-modal"
    >
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Typography variant="body2">
            {t(`dialog.markAsSent.info`, {
              foundationName: organisationName
            })}
          </Typography>
          <Box className={styles.buttonsWrapper}>
            <Button size="large" type="submit" data-testid="mark-as-sent-modal-button">
              {t(`button.markAsSent`)}
            </Button>
            <Button onPress={onClose} variant="secondary" size="large" type="button">
              {t('button.cancel')}
            </Button>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  )
}
