import { environment } from '@percent/admin-dashboard/config'
import { environments } from '@percent/admin-dashboard/constants/environments'

const getSplitKey = () => {
  if ([environments.PRODUCTION].includes(environment as string)) {
    return process.env.NX_SPLIT_PRODUCTION
  }

  if ([environments.SANDBOX].includes(environment as string)) {
    return process.env.NX_SPLIT_SANDBOX
  }

  if ([environments.PREPROD].includes(environment as string)) {
    return process.env.NX_SPLIT_PREPROD
  }

  if ([environments.STAGING, environments.QA].includes(environment as string)) {
    return process.env.NX_SPLIT_STAGING
  }

  return process.env.NX_SPLIT_STAGING
}

/*
  Split SDK Config should reflect whats on the split.io production feature flag values,
  the above splitSdkConfig features values are set as default values for feature flags
  when the split.io has timedout.
*/
export const splitSdkConfig = {
  core: {
    authorizationKey: getSplitKey(),
    key: 'key'
  },
  features: {
    ad_eligibility_partner: 'off',
    ad_update_email: 'on',
    ad_registries: 'off',
    ad_disbursements: 'off',
    ad_bank_details_read: 'off',
    ad_bank_details_submit: 'off',
    ad_bank_details_review_approval: 'off',
    ad_bank_details_review_delete: 'off',
    ad_payment_methods_read: 'off',
    ad_payment_methods_submit: 'off',
    ad_mark_organisation_unpayable: 'off',
    ad_donation_requests: 'off',
    ad_donation_match_requests: 'on',
    ad_donations_table: 'off',
    ad_reassign_donation: 'on',
    ad_organization_view: 'off',
    ad_org_brand_details: 'off',
    ad_edit_registration_status: 'off',
    ad_registries_required_types: 'off',
    ad_edit_official_details: 'off',
    ad_edit_brand_details: 'off',
    ad_edit_contacts_details: 'off',
    ad_edit_location_details: 'off',
    ad_add_org: 'off',
    ad_edit_unclaim_org: 'off',
    ad_add_org_file: 'off',
    ad_claims_domain_matching_temp_fix: 'off',
    ad_agent_verification_manual_fraudlent_rejection: 'off',
    ad_none_of_the_above_edu_subtags: 'off',
    ad_agent_verification_triggered_docs: 'off',
    ad_unreject_claim: 'off',
    ad_edit_partner: 'off',
    ad_matching_documents_details: 'off',
    ad_batch_update_organisations: 'off',
    ad_auto_rejection_list: 'off',
    ad_validation_submission_tab: 'off',
    ad_new_edu_subtags: 'off'
  },
  scheduler: {
    offlineRefreshRate: 15
  }
}

export const eligibilityPartner = 'ad_eligibility_partner' // should this be on on off as it off in production
export const updateEmailFlag = 'ad_update_email'
export const registriesFlag = 'ad_registries'
export const disbursementsFlag = 'ad_disbursements'
export const bankDetailsReadFlag = 'ad_bank_details_read'
export const bankDetailsSubmitFlag = 'ad_bank_details_submit'
export const bankDetailsReviewApprovalFlag = 'ad_bank_details_review_approval'
export const bankDetailsReviewDeleteFlag = 'ad_bank_details_review_delete'
export const paymentMethodsReadFlag = 'ad_payment_methods_read'
export const paymentMethodsSubmitFlag = 'ad_payment_methods_submit'
export const markOrganisationAsUnableToPayFlag = 'ad_mark_organisation_unpayable'
export const donationRequestsFlag = 'ad_donation_requests'
export const donationMatchRequestsFlag = 'ad_donation_match_requests'
export const donationsFlag = 'ad_donations_table'
export const reassignDonationFlag = 'ad_reassign_donation'
export const organizationViewFlag = 'ad_organization_view'
export const orgBrandDetailsFlag = 'ad_org_brand_details'
export const addOrgFlag = 'ad_add_org'
export const editRegistrationStatusFlag = 'ad_edit_registration_status'
export const registriesRequiredTypesFlag = 'ad_registries_required_types'
export const editOfficialDetailsFlag = 'ad_edit_official_details'
export const editBrandDetailsFlag = 'ad_edit_brand_details'
export const editContactstionDetailsFlag = 'ad_edit_contacts_details'
export const editLocationDetailsFlag = 'ad_edit_location_details'
export const unclaimOrgFlag = 'ad_edit_unclaim_org'
export const addOrgFileFlag = 'ad_add_org_file'
export const claimsDomainMatchingTemporaryFixFlag = 'ad_claims_domain_matching_temp_fix'
export const agentVerificationManualFradulentRejectionFlag = 'ad_agent_verification_manual_fraudlent_rejection'
export const educationSubtagsNoneOfTheAboveFlag = 'ad_none_of_the_above_edu_subtags'
export const agentVerificationTriggeredDocsFlag = 'ad_agent_verification_triggered_docs'
export const unrejectClaimFlag = 'ad_unreject_claim'
export const editPartnerFlag = 'ad_edit_partner'
export const matchingDocumentsDetailsFlag = 'ad_matching_documents_details'
export const batchUpdateOrganisationsFlag = 'ad_batch_update_organisations'
export const autoRejectionListFlag = 'ad_auto_rejection_list'
export const validationSubmissionTabFlag = 'ad_validation_submission_tab'
export const newEduStageSubtagFlag = 'ad_new_edu_subtags'
