import React from 'react'
import { Typography } from '@material-ui/core'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useMutation } from '@percent/admin-dashboard/common/hooks'
import { AuthView } from '@percent/admin-dashboard/app/auth/authview/AuthView'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { FieldError, FieldInput, Loader, PercentButton } from '@percent/admin-dashboard/common/components'
import { AuthSuccessText } from '@percent/admin-dashboard/app/auth/authSuccessText/AuthSuccessText'
import styles from './ForgotPassword.module.scss'
import { emailRegex } from '@percent/utility'

export function ForgotPassword() {
  const { t } = useTranslation()
  const { authService } = useServices()
  const [{ success, error, errorMessage, isLoading }, { apiFunc: forgotPassword }] = useMutation(
    authService.forgotPassword
  )

  const { errors, values, setFieldValue, handleSubmit, handleBlur, touched } = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: () =>
      Yup.object().shape({
        email: Yup.string().required('Required').matches(emailRegex, t('errorMessage.validEmail'))
      }),
    onSubmit: ({ email }) => {
      forgotPassword(email)
    }
  })

  if (success) {
    return (
      <AuthView>
        <AuthSuccessText text={t('typography.footerForgetPasswordSuccess')} />
      </AuthView>
    )
  }

  const { email } = values

  return (
    <AuthView adminLogo heading={t('typography.footerForgetPasswordHeading')} handleSubmit={handleSubmit}>
      {isLoading ? (
        <Loader loaderStyle="authLoader" />
      ) : (
        <>
          <Typography className={styles.passwordText} variant="body2">
            {t('typography.footerForgetPasswordTitle')}
          </Typography>
          <FieldInput
            name="email"
            label={t('form.email')}
            placeHolder={t('form.enterEmail')}
            value={email}
            onChange={(a: string) => setFieldValue('email', a)}
            handleBlur={handleBlur}
            error={errors.email}
            touched={touched.email}
          />
          {error && <FieldError error={errorMessage} />}
          <PercentButton
            className={styles.emailButton}
            fullWidth
            testId="auth-active-button"
            title={t('button.sendEmail')}
            type="submit"
          />
          <Typography variant="body2" className={styles.footerText}>
            {t('typography.footerForgetPasswordOne')}{' '}
            <span className={styles.clickHere}>
              <Link to="/login">{t('typography.footerForgetPasswordTwo')}</Link>
            </span>
          </Typography>
        </>
      )}
    </AuthView>
  )
}
