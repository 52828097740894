import React, { useState } from 'react'
import { FormikProvider, useFormik } from 'formik'
import * as Yup from 'yup'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { EditOrganizationDetailsModalProps } from '../OverviewDetails.types'

import { useMutation, useQueryList } from '@percent/admin-dashboard/common/hooks'
import { AcknowledgeModal, ActionModal, FormField, Modal, Select, Spacer, Text, TextInput } from '@percent/lemonade'
import { sanitizeStringToRegex } from '@percent/utility'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { RegistriesType } from '@percent/admin-dashboard/api/types'

export function EditOfficialDetailsModal({ open, onClose, organization, refresh }: EditOrganizationDetailsModalProps) {
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const { adminService } = useServices()
  const [errorDialogState, setErrorDialogState] = useState(false)

  const [{ isLoading, success }, { apiFunc }] = useMutation(adminService.editOrganisation, undefined, () => {
    setErrorDialogState(true)
  })

  const [{ dataOrNull }] = useQueryList(adminService.getRegistries, {
    countryCode: organization.countryCode,
    pageSize: undefined
  })

  const mapRegistries = (registries?: RegistriesType[] | null) => {
    return registries
      ? registries?.map(registry => {
          return {
            label: registry.englishName || registry.name,
            value: registry.id
          }
        })
      : []
  }

  const options = mapRegistries(dataOrNull)

  const formik = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: {
      registry: organization.registry,
      registryId: organization.registryId,
      name: organization.name
    },
    validationSchema: () =>
      Yup.lazy(value => {
        const newRegex =
          value.registry && dataOrNull?.find(registry => registry.id === value?.registry)?.registryIdFormatRegex

        return Yup.object().shape({
          name: Yup.string()
            .max(255, t('errorMessage.orgFieldCharacterLimit', { fieldName: 'Legal name' }))
            .min(1, t('errorMessage.orgFieldCharacterLimit', { fieldName: 'Legal name' }))
            .trim()
            .required(t('errorMessage.required')),
          registry: Yup.string().required(t('errorMessage.required')),
          registryId: Yup.string()
            .max(255, t('errorMessage.orgFieldCharacterLimit', { fieldName: 'Registry ID' }))
            .min(1, t('errorMessage.orgFieldCharacterLimit', { fieldName: 'Registry ID' }))
            .trim()
            .required(t('errorMessage.required'))
            .matches(sanitizeStringToRegex(newRegex || ''), t('errorMessage.wrongFormatRegistryId'))
        })
      }),
    onSubmit: async ({ registry, registryId, name }) => {
      await apiFunc({
        payload: { registry, registryId, name, featureFlagDelphi92: true },
        id
      })
    }
  })

  const { errors, values, touched, dirty, handleChange, setFieldValue, handleBlur, handleSubmit, isValid, resetForm } =
    formik

  const handleCloseModal = () => {
    onClose()
    refresh()
    resetForm()
  }

  const handleOnErrorClose = () => {
    setErrorDialogState(false)
  }

  const successOrErrorModal =
    success && !errorDialogState ? (
      <AcknowledgeModal
        result="positive"
        title={t('dialog.editOrganizationDetails.success.title', { organizationCard: 'Official details' })}
        description={t('dialog.editOrganizationDetails.success.description', { organizationCard: 'official details' })}
        buttonText={t('button.close')}
        handleClose={handleCloseModal}
      />
    ) : (
      <AcknowledgeModal
        result="negative"
        title={t('dialog.editOrganizationDetails.error.title', { organizationCard: 'Official details' })}
        description={t('dialog.editOrganizationDetails.error.description', { organizationCard: 'official details' })}
        buttonText={t('button.close')}
        handleClose={handleOnErrorClose}
      />
    )

  return (
    <Modal open={open} onClose={onClose}>
      {!success && !errorDialogState ? (
        <ActionModal
          title={t('dialog.editOfficialDetails.title')}
          primaryButtonText={t('button.saveChanges')}
          secondaryButtonText={t('button.cancel')}
          type="submit"
          variant={isValid && dirty ? 'primary' : 'secondary'}
          disabled={!(isValid && dirty && !isLoading)}
          loading={isLoading}
          handleClose={() => {
            onClose()
            resetForm()
          }}
          handleSubmit={handleSubmit}
          primaryBtnTestId="official-details-submit-button"
          secondaryBtnTestId="official-details-cancel-button"
          aria-labelledby="official-details-form-modal"
        >
          <Text size="small">
            {t('dialog.editOfficialDetails.description')}
            <b> {organization.name}</b>
          </Text>
          <Spacer size={4} axis="vertical" />
          <form>
            <FormikProvider value={formik}>
              <>
                <FormField
                  label={t('dialog.addRegistry.legalNameLabel')}
                  status={touched.name && errors.name ? 'danger' : 'default'}
                  statusMessage={errors.name}
                  data-testid="legalName"
                  necessity="required"
                >
                  <TextInput
                    name="name"
                    value={values.name}
                    placeholder="Enter legal name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </FormField>
                <Spacer size={4} axis="vertical" />
                <FormField
                  label={t('dialog.approveValdiationRequest.selectRegistry')}
                  status={errors.registry && touched.registry ? 'danger' : 'default'}
                  statusMessage={errors.registry}
                  data-testid="selectRegistry"
                >
                  <Select
                    placeholder={t('dialog.approveValdiationRequest.selectRegistry')}
                    searchable={false}
                    options={options}
                    defaultValue={options.find(
                      ({ value, label }) =>
                        value === values?.registry && {
                          label,
                          value
                        }
                    )}
                    onChange={event => {
                      setFieldValue('registry', event.value)
                    }}
                  />
                </FormField>
                <Spacer size={4} axis="vertical" />
                <FormField
                  label={t('typography.registryId')}
                  data-testid="registryName"
                  status={errors.registryId && touched.registryId ? 'danger' : 'default'}
                  statusMessage={errors.registryId}
                >
                  <TextInput
                    name="registryId"
                    value={values.registryId}
                    placeholder="Enter registry id"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </FormField>
              </>
            </FormikProvider>
          </form>
        </ActionModal>
      ) : (
        successOrErrorModal
      )}
    </Modal>
  )
}
