import { useEffect, useState } from 'react'

import { FoundationsContext } from '../foundationsContext/FoundationsContext'

import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { FoundationsContextControllerProps } from './FoundationsContextController.types'
import { logger } from '@percent/admin-dashboard/common/utility/logger'
import { FoundationType } from '@percent/admin-dashboard/api/actions/foundations/foundations.types'
import { useAuthState } from '@percent/admin-dashboard/common/hooks'

export function FoundationsContextController({ children }: FoundationsContextControllerProps) {
  const [foundations, setFoundations] = useState<Record<string, FoundationType> | undefined>(undefined)
  const { foundationsService } = useServices()
  const { isAuthorised } = useAuthState()

  useEffect(() => {
    const getFoundations = async () => {
      try {
        const response = await foundationsService.getFoundationsList()

        if (response && response.status === 200) {
          setFoundations(
            response.data.data.reduce(
              (obj, foundation) => ({
                [foundation.id]: foundation,
                ...obj
              }),
              {}
            )
          )
        }
      } catch (e) {
        logger(e)
      }
    }

    if (isAuthorised) {
      getFoundations()
    }
  }, [foundationsService, isAuthorised])

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  return <FoundationsContext.Provider value={{ foundations }}>{children}</FoundationsContext.Provider>
}
