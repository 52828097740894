import { createContext, useContext } from 'react'

import { Services } from '@percent/admin-dashboard/api/actions'

export const ServiceContext = createContext<Services | undefined>(undefined)

export const useServices = () => {
  const ctx = useContext(ServiceContext)

  if (!ctx) {
    throw new Error(`${ServiceContext} context not found`)
  }

  return ctx
}
