import { TableCell, TableRow } from '@material-ui/core'

import styles from '../../DonationRequestDetails.module.scss'

import { Table } from '@percent/admin-dashboard/common/components'
import { useCurrencies } from '@percent/admin-dashboard/common/hooks'
import { formatAmountFromMinorUnits } from '@percent/admin-dashboard/common/utility/formatAmount/formatAmount'
import { DonationsPerNonprofitTableProps } from './DonationsPerNonprofitTable.types'

const cells = [
  { id: 'donation', isSortable: false, props: { width: '14%' } },
  { id: 'currency', isSortable: false, props: { width: '16%' } },
  { id: 'organization', isSortable: false, props: { width: '70%' } }
]

export function DonationsPerNonprofitTable({
  totalResults,
  data,
  previousPage,
  nextPage
}: DonationsPerNonprofitTableProps) {
  const { currencyInfo } = useCurrencies()

  return (
    <Table
      isLoading={false}
      totalResults={totalResults}
      data={data}
      previousPage={previousPage}
      nextPage={nextPage}
      columns={cells}
      emptyTableText=""
      noWrapper
    >
      {data?.map(row => (
        <TableRow key={row.organisationId} className={styles.tableRow}>
          <TableCell>
            {formatAmountFromMinorUnits({
              currencyCode: row.currency,
              value: row.amount,
              currencies: currencyInfo
            })}
          </TableCell>
          <TableCell>{row.currency}</TableCell>
          <TableCell>{row.organisationName}</TableCell>
        </TableRow>
      ))}
    </Table>
  )
}
