import React, { useState } from 'react'
import { Grid, Paper, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { PercentButton } from '@percent/admin-dashboard/common/components'
import styles from './MyAccount.module.scss'
import { ChangeTwoFactorDeviceContainer } from './ChangeTwoFactorDevice/ChangeTwoFactorDeviceContainer'

export function MyAccount() {
  const { t } = useTranslation()
  const [isChangeTwoFactorDeviceModalOpened, setIsChangeTwoFactorDeviceModalOpened] = useState(false)

  return (
    <Grid container spacing={0} component={Paper} elevation={0} className={styles.container}>
      <Grid item xs={12} className={styles.title}>
        <Typography variant="h6">{t('typography.accountSettings')}</Typography>
      </Grid>
      <div className={styles.optionContainer}>
        <div className={styles.textContainer}>
          <p className={styles.text}>{t('typography.2faOption')}</p>
          <p className={styles.subtext}>{t('typography.2faSubtext')}</p>
        </div>
        <div className={styles.buttonContainer}>
          <PercentButton
            outlined
            textColour="secondaryTextColour"
            title={t('button.change2faDevice')}
            onClick={() => setIsChangeTwoFactorDeviceModalOpened(true)}
          />
        </div>
      </div>
      <ChangeTwoFactorDeviceContainer
        isOpened={isChangeTwoFactorDeviceModalOpened}
        onClose={() => setIsChangeTwoFactorDeviceModalOpened(false)}
      />
    </Grid>
  )
}
