import { Grid, Paper, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import {
  DetailedDisbursementsCount,
  DisbursementBatch,
  DisbursementPaymentMethod
} from '@percent/admin-dashboard/api/actions/disbursements/disbursements.types'
import { ErrorView, FoundationNameWithFlag } from '@percent/admin-dashboard/common/components'
import { useFoundations, useCSVDownloader } from '@percent/admin-dashboard/common/hooks'
import { dayJS } from '@percent/admin-dashboard/common/library/utility/date'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { Button, Copy } from '@percent/lemonade'
import styles from './DisbursementFoundationDetails.module.scss'

const apiDisbursementBatchTypeToDisplayName: Record<DisbursementPaymentMethod, string> = {
  bank_transfer: 'Bank transfer',
  card: 'Card',
  cheque: 'Cheque'
}

export const getDisbursementBatchType = (detailedCounts: DetailedDisbursementsCount[]) => {
  const disbursementBatchTypeCount = detailedCounts.reduce(
    (acc, item) => {
      acc[item.type] += item.count

      return acc
    },
    {
      bank_transfer: 0,
      card: 0,
      cheque: 0
    }
  )

  return Object.entries(disbursementBatchTypeCount)
    .sort((a, b) => b[1] - a[1])
    .filter(count => count[1])
    .map(count => apiDisbursementBatchTypeToDisplayName[count[0] as DisbursementPaymentMethod])
    .join('/')
}

export function DisbursementFoundationDetails({ id, foundationId, endDate, detailedCounts }: DisbursementBatch) {
  const { t } = useTranslation()
  const { foundations } = useFoundations()
  const {
    reportingService: { getDisbursementBatchTrusteeReport }
  } = useServices()

  const foundationName = foundations?.[foundationId]?.name
  const foundationCountryCode = foundations?.[foundationId]?.countryCode

  const [{ loading, errorCSVMessage }, { downloadCSV }] = useCSVDownloader({
    service: getDisbursementBatchTrusteeReport(id),
    fileName: `${foundationCountryCode}_${foundationName}_disbursement_end_${dayJS(endDate).format('YYYY-MM-DD')}`,
    successMessage: t('toast.trusteeReportSuccess')
  })

  const handleReportDownload = () => {
    if (downloadCSV) {
      downloadCSV()
    }
  }

  if (errorCSVMessage) {
    return <ErrorView errorMessage={errorCSVMessage} />
  }

  return (
    <Grid container spacing={0} component={Paper} elevation={0} className={styles.wrapper}>
      <Grid item xs={12} className={styles.title}>
        <Typography variant="h6">{t('typography.disbursementBatchDetails')}</Typography>
        <Button size="small" onPress={handleReportDownload}>
          {t(`button.${loading ? 'downloading' : 'downloadReport'}`)}
        </Button>
      </Grid>
      <ul className={styles.detailsList}>
        <li>
          <Typography variant="body2">{t('typography.disbursementBatchId')}</Typography>
          <Typography variant="body2">
            <Copy text={id}>{id}</Copy>
          </Typography>
        </li>
        <li>
          <Typography variant="body2">{t('typography.foundation')}</Typography>
          <Typography variant="body2">
            {foundations?.[foundationId] ? (
              <FoundationNameWithFlag
                countryCode={foundations[foundationId].countryCode}
                name={foundations[foundationId].name}
              />
            ) : (
              foundationId
            )}
          </Typography>
        </li>
        <li>
          <Typography variant="body2">{t('typography.donationsUpTo')}</Typography>
          <Typography variant="body2">{dayJS(endDate).format('DD MMM YYYY')}</Typography>
        </li>
        <li>
          <Typography variant="body2">{t('typography.disbursementBatchType')}</Typography>
          <Typography variant="body2">{getDisbursementBatchType(detailedCounts)}</Typography>
        </li>
      </ul>
    </Grid>
  )
}
