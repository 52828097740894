/* eslint react/jsx-no-useless-fragment: 0 */
import React from 'react'

import style from './FieldError.module.scss'
import { FieldErrorProps } from './FieldError.types'

export function FieldError({ error = '' }: FieldErrorProps) {
  return (
    <small className={style.fieldError}>
      <>
        &nbsp;
        {error}
      </>
    </small>
  )
}
