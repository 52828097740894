import { useState } from 'react'

import { APIErrorHandler } from '@percent/admin-dashboard/common/library/APIErrorHandler'

export function useMutation<A, B>(
  api: (_: A) => Promise<B>,
  onSuccess?: (_: B) => void,
  onError?: (_: Error, statusCode?: number) => void
) {
  const [data, setData] = useState<B | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<any>('')
  const [errorMessage, setErrorMessage] = useState('')
  const [success, setSuccess] = useState(false)

  const apiFunc = async (payload: A) => {
    let didCancel = false

    setIsLoading(true)
    setSuccess(false)

    const callApi = async () => {
      try {
        const result = await api(payload)
        setSuccess(true)

        if (!didCancel) {
          setIsLoading(false)
          setData(result)
          setIsLoading(false)
          setErrorMessage('')
        }

        if (onSuccess) {
          onSuccess(result)
        }
      } catch (err) {
        if (!didCancel) {
          setErrorMessage(APIErrorHandler(err?.response?.data?.error))
          setError(err?.response?.data?.error)
          setIsLoading(false)
        }

        if (onError) {
          onError(err, err.response.status)
        }
      }
    }
    await callApi()

    return () => {
      didCancel = true
    }
  }

  return [
    {
      data,
      isLoading,
      error,
      errorMessage,
      success
    },
    {
      apiFunc,
      setErrorMessage,
      setError
    }
  ] as const
}
