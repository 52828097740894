import React from 'react'
import { StringParam, useQueryParams } from 'use-query-params'

import { useDidMountEffect, useQueryList } from '@percent/admin-dashboard/common/hooks'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { RegistriesTable } from './RegistriesTable/RegistriesTable'

export function RegistriesContainer() {
  const { registriesService } = useServices()
  const [queryParams, setQueryParams] = useQueryParams({
    search: StringParam
  })

  const [{ totalResults, isLoading, dataOrNull, errorMessageOrNull }, { nextPage, previousPage, query, refresh }] =
    useQueryList(registriesService.getRegistriesList, {
      ...queryParams
    })

  useDidMountEffect(() => query(queryParams), [queryParams, query])

  return (
    <RegistriesTable
      data={dataOrNull}
      isLoading={isLoading}
      errorMessage={errorMessageOrNull}
      queryParams={queryParams}
      setQueryParams={setQueryParams}
      nextPage={nextPage}
      previousPage={previousPage}
      totalResults={totalResults}
      refresh={refresh}
    />
  )
}
