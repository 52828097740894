import React from 'react'
import { useFormik } from 'formik'
import { useLocation, useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'

import { useMutation } from '@percent/admin-dashboard/common/hooks'
import { PercentButton, FieldPassword, FieldError } from '@percent/admin-dashboard/common/components'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { AuthSuccessText } from '@percent/admin-dashboard/app/auth/authSuccessText/AuthSuccessText'
import { AuthView } from '@percent/admin-dashboard/app/auth/authview/AuthView'
import { passwordRequirementRegExp } from '@percent/admin-dashboard/common/utility/validation'
import styles from './SetPassword.module.scss'

export function SetPassword() {
  const { authService } = useServices()
  const { search } = useLocation()
  const hash = new URLSearchParams(search).get('userHash')
  const [{ success, error, errorMessage }, { apiFunc: setPassword }] = useMutation(authService.setPassword)
  const { t } = useTranslation()
  const history = useHistory()

  const { errors, values, setFieldValue, handleSubmit, handleBlur, touched } = useFormik({
    initialValues: {
      password: ''
    },
    validationSchema: () =>
      Yup.object().shape({
        password: Yup.string()
          .min(8, t('errorMessage.minimum8Characters'))
          .matches(passwordRequirementRegExp, t('errorMessage.passwordRequirement'))
          .max(64)
          .required('Required')
      }),
    onSubmit: ({ password }: { password: string }) => {
      if (!hash) {
        history.push('/login')
      } else {
        setPassword({ password, hash })
      }
    }
  })

  const { password } = values

  if (success) {
    return (
      <AuthView>
        <AuthSuccessText text="Password set successfully" />
      </AuthView>
    )
  }

  return (
    <AuthView heading="Set Password" handleSubmit={handleSubmit}>
      <FieldPassword
        password={password}
        setFieldValue={setFieldValue}
        handleBlur={handleBlur}
        errors={errors}
        touched={touched}
        error={error}
        errorMessage={errorMessage}
      />
      {errorMessage && <FieldError error={errorMessage} />}
      <PercentButton
        className={styles.setPasswordButton}
        fullWidth
        testId="auth-active-button"
        title="Set Password"
        type="submit"
      />
    </AuthView>
  )
}
