import React, { ChangeEvent, useEffect, useState } from 'react'
import { TextField, ListSubheader } from '@material-ui/core'
import Autocomplete, { AutocompleteRenderGroupParams } from '@material-ui/lab/Autocomplete'
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'

import { orgSubActivities } from '@percent/admin-dashboard/constants/organisationActivities'
import { colors } from '@percent/admin-dashboard/identity'
import styles from './ActivityCheckSearchDropdown.module.scss'
import { ActivityCheckSearchDropdownProps } from './ActivityCheckDetail.types'

const renderGroup = (params: AutocompleteRenderGroupParams) => [
  <ListSubheader className={styles.renderGroup} key={params.key}>
    {params.group}
  </ListSubheader>,
  params.children
]

export function ActivityCheckSearchDropdown({
  subCode,
  subDescription,
  primaryCode,
  primaryDescription,
  setUpdatedCode,
  definition
}: ActivityCheckSearchDropdownProps) {
  const [value, setValue] = useState<{ primary: string; sub: string; definition: string } | null | undefined>({
    primary: `${primaryCode} - ${primaryDescription}`,
    sub: `${subCode} - ${subDescription}`,
    definition
  })
  const options = Object.keys(orgSubActivities).map((key: string) => ({
    primary: `${orgSubActivities[key as keyof typeof orgSubActivities].primaryActivityCode} - ${
      orgSubActivities[key as keyof typeof orgSubActivities].primaryActivityDescription
    }`,
    sub: `${orgSubActivities[key as keyof typeof orgSubActivities].subActivityCode} - ${
      orgSubActivities[key as keyof typeof orgSubActivities].subActivityDescription
    }`,
    definition: orgSubActivities[key as keyof typeof orgSubActivities].definition
  }))

  useEffect(() => {
    if (value) {
      setUpdatedCode({
        primary: value?.primary,
        sub: value?.sub,
        definition: value.definition
      })
    }
  }, [value, setUpdatedCode])

  return (
    <Autocomplete
      value={value}
      onChange={(event: ChangeEvent<{}>, newValue: { primary: string; sub: string; definition: string } | null) => {
        setValue(newValue)
      }}
      options={options}
      groupBy={option => option.primary}
      getOptionLabel={option => option.sub}
      style={{ width: 464 }}
      renderInput={params => (
        <TextField {...params} label={value?.primary} variant="outlined" className={styles.autoCompleteFieldSet} />
      )}
      renderGroup={renderGroup}
      renderOption={(option, { inputValue }) => {
        const matches = match(option.sub, inputValue)
        const parts = parse(option.sub, matches)

        const activityTagName = parts.map(part => (
          <div
            style={{
              display: 'inline-block'
            }}
          >
            <span
              style={{
                fontWeight: part.highlight ? 700 : 400,
                color: part.highlight ? colors.blue : colors.secondary
              }}
              className={styles.options}
            >
              {part.text}
            </span>
          </div>
        ))

        return (
          <div className={styles.dropdownContainer}>
            <div>{activityTagName}</div>
            <div className={styles.definition}>{option.definition}</div>
          </div>
        )
      }}
    />
  )
}
