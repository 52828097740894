import React, { useReducer, useEffect } from 'react'

import { AuthDispatchContext, AuthStateContext } from '../authContext/AuthContext'
import { authReducer, SET_AUTHORISED, SET_UNAUTHORISED, initialState, initializer } from '../authReducer/authReducer'

import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { AuthContextControllerProps } from './AuthContextController.types'

export function AuthContextController({ children }: AuthContextControllerProps) {
  const [state, dispatch] = useReducer(authReducer, initialState, initializer)
  const { authService } = useServices()

  useEffect(() => {
    const getUserData = async () => {
      try {
        const {
          data: { data }
        } = await authService.getAccount()

        return dispatch({
          type: SET_AUTHORISED,
          user: state.user ?? data
        })
      } catch {
        return dispatch({
          type: SET_UNAUTHORISED
        })
      }
    }

    if (state.isAuthorised && !state.isAuthorising) {
      getUserData()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, state.isAuthorised, state.isAuthorising, authService])

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>{children}</AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  )
}
