import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { TableMenu } from '../partnerModuleMenu/PartnerModuleMenu'
import { DeletePartnerDialog } from '../deletePartnerDialog/DeletePartnerDialog'

import { ErrorView, Loader, PercentButton } from '@percent/admin-dashboard/common/components'
import { PartnerScope } from '@percent/admin-dashboard/api/types'
import styles from './PartnerModuleListTable.module.scss'
import { rowModuleInfo } from './rowModuleInfo'

const mapPartnersScopesToAllScopes = (data: PartnerScope[], moduleInfo: typeof rowModuleInfo) =>
  moduleInfo.map(a => ({
    ...a,
    active: data?.some(({ scopeName }) => scopeName === a.name)
  }))

export function PartnerModuleListTable({
  partnerId,
  partnerName,
  data,
  isLoading,
  errorMessage,
  refreshApi
}: {
  partnerId: string
  partnerName: string
  data: any
  isLoading: boolean
  errorMessage: string
  refreshApi: VoidFunction
}) {
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [scopeSorted, setScopeSorted] = useState<typeof rowModuleInfo>([])
  const handleDeletePartner = () => setOpenDeleteModal(true)
  const handleCloseDeletePartner = () => setOpenDeleteModal(false)
  const { t } = useTranslation()

  useEffect(() => {
    if (!isLoading && data) {
      setScopeSorted(mapPartnersScopesToAllScopes(data as PartnerScope[], rowModuleInfo))
    }
  }, [data, isLoading])

  if (isLoading) {
    return <Loader />
  }

  if (!data && errorMessage) {
    return <ErrorView errorMessage={errorMessage} />
  }

  return (
    <>
      <TableContainer className={styles.tableContainer}>
        <Table className={styles.table}>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="h6">{t('typography.modules')}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6">{t('typography.status')}</Typography>
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {scopeSorted?.map(module => (
              <TableRow key={`partner-module-${module.name}`}>
                <TableCell className={styles.moduleDescriptionCell}>
                  {module.logoComponent}
                  <p className={styles.moduleTitle} data-testid={module.name}>
                    {module.title}
                  </p>
                </TableCell>
                <TableCell className={styles.logoTableCell}>
                  <p>{module.active ? t('typography.active') : t('typography.inactive')}</p>
                </TableCell>
                <TableCell className={styles.statusSwitchTableCell}>
                  <TableMenu
                    refresh={() => {
                      refreshApi()
                    }}
                    moduleName={module.name}
                    partnerId={partnerId}
                    moduleTitle={module.title}
                  />
                </TableCell>
              </TableRow>
            ))}
            <TableRow className={styles.footer}>
              <TableCell className={styles.infoText}>
                {t('typography.deletePartner')}
                <br />
                <span>{t('typography.deletingPartner')}</span>
              </TableCell>
              <TableCell />
              <TableCell className={styles.statusSwitchTableCell}>
                <PercentButton
                  title={t('button.delete')}
                  onClick={handleDeletePartner}
                  className={styles.deleteButton}
                  outlined
                  textColour="secondaryTextColour"
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {openDeleteModal && (
        <DeletePartnerDialog
          onClose={handleCloseDeletePartner}
          openDeleteModal={openDeleteModal}
          partnerName={partnerName}
          query={{ id: partnerId }}
        />
      )}
    </>
  )
}
