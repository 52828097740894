import { Grid } from '@material-ui/core'

import { ErrorView, Loader } from '@percent/admin-dashboard/common/components'
import { useQuery, useQueryList } from '@percent/admin-dashboard/common/hooks'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { DisbursementDetailsTable } from './DisbursementDetailsTable/DisbursementDetailsTable'
import { DisbursementFoundationDetails } from './DisbursementFoundationDetails/DisbursementFoundationDetails'
import { QueryStatus } from '@percent/admin-dashboard/common/hooks/useQueryList/useQueryList.types'
import {
  DisbursementStatusAndTotal,
  DisbursementSummaryRow
} from './DisbursementDetailsTable/DisbursementDetailsTable.types'

export function DisbursementDetailsContainer({ match }: { match: { params: { id: string } } }) {
  const { disbursementsService } = useServices()
  const { id } = match.params
  const [disbursementBatchDetailsQuery, { refresh }] = useQuery(disbursementsService.getDisbursementBatchDetails, {
    id
  })

  const [
    { errorMessageOrNull, queryStatus, dataOrNull, totalResults },
    { nextPage, previousPage, refresh: refreshDisbursements }
  ] = useQueryList(disbursementsService.getDisbursementBatchEnhancedDisbursementsList(id), { pageSize: 100 })

  if (errorMessageOrNull) {
    return <ErrorView errorMessage={errorMessageOrNull} />
  }

  if (disbursementBatchDetailsQuery.errorMessage) {
    return <ErrorView errorMessage={disbursementBatchDetailsQuery.errorMessage} />
  }

  if (disbursementBatchDetailsQuery.isLoading || queryStatus === QueryStatus.LOADING) {
    return <Loader />
  }

  const { counts, detailedCounts, endDate, foundationId } = disbursementBatchDetailsQuery.data
  const summary: DisbursementSummaryRow[] = Object.entries(counts).map(([status, count]) => ({
    status: status as DisbursementStatusAndTotal,
    count: count as number
  }))

  const refreshData = () => {
    refresh()
    refreshDisbursements()
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <DisbursementFoundationDetails {...disbursementBatchDetailsQuery.data} />
      </Grid>
      <Grid item xs={12}>
        {dataOrNull ? (
          <DisbursementDetailsTable
            disbursementBatchId={id}
            disbursementBatchEndDate={endDate}
            disbursementBatchFoundationId={foundationId}
            summary={summary}
            detailedCounts={detailedCounts}
            data={dataOrNull}
            totalResults={totalResults}
            previousPage={previousPage}
            nextPage={nextPage}
            refreshData={refreshData}
          />
        ) : null}
      </Grid>
    </Grid>
  )
}
