import { useEffect, useState } from 'react'

import { CurrenciesContext } from '../currenciesContext/CurrenciesContext'

import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { CurrenciesContextControllerProps } from './CurrenciesContextController.types'
import { logger } from '@percent/admin-dashboard/common/utility/logger'
import { useAuthState } from '@percent/admin-dashboard/common/hooks'
import { CurrencyInfo } from '@percent/admin-dashboard/api/actions/currencies/currencies.types'

export function CurrenciesContextController({ children }: CurrenciesContextControllerProps) {
  const [currencyInfo, setCurrencyInfo] = useState<CurrencyInfo[]>([])
  const { currenciesService } = useServices()
  const { isAuthorised } = useAuthState()

  useEffect(() => {
    const getCurrencies = async () => {
      try {
        const response = await currenciesService.getCurrencyInfo()

        if (response && response.status === 200) {
          setCurrencyInfo(response.data.data.sort((a, b) => a.name.localeCompare(b.name)))
        }
      } catch (e) {
        logger(e)
      }
    }

    if (isAuthorised) {
      getCurrencies()
    }
  }, [currenciesService, isAuthorised])

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  return <CurrenciesContext.Provider value={{ currencyInfo }}>{children}</CurrenciesContext.Provider>
}
