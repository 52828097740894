import { useState } from 'react'
import { Modal, Card, Divider } from '@material-ui/core'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
  ErrorView,
  Loader,
  PercentButton,
  FieldInput,
  UpdateModalInformation
} from '@percent/admin-dashboard/common/components'
import { useMutation } from '@percent/admin-dashboard/common/hooks'
import { ReactComponent as CloseIcon } from '@percent/admin-dashboard/common/assets/images/close.svg'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { PatchOrganisationParams } from '@percent/admin-dashboard/api/types'
import styles from './UpdateEmailModal.module.scss'
import { UpdateEmailModalProps } from './UpdateEmailModal.types'
import { ReactComponent as AlertIcon } from '@percent/admin-dashboard/common/assets/images/alert.svg'
import { emailRegex } from '@percent/utility'

export function UpdateEmailModal({
  organisation,
  refresh,
  agentVerificationId,
  isModalOpened,
  setModalOpened
}: UpdateEmailModalProps) {
  const { t } = useTranslation()
  const [isSent, setIsSent] = useState(false)
  const { adminService } = useServices()
  const { push } = useHistory()

  const { values, setFieldValue, handleBlur, touched, errors, dirty, isValid } = useFormik({
    initialValues: {
      email: organisation?.email
    },
    validationSchema: () =>
      yup.object().shape({
        email: yup.string().required('Required').matches(emailRegex, t('errorMessage.validEmail')).max(255).nullable()
      }),
    onSubmit: () => {}
  })

  const [{ isLoading, errorMessage }, { apiFunc: aproveFunc }] = useMutation(adminService.editOrganisation, () => {
    setIsSent(true)
  })

  const handleUpdate = () => {
    aproveFunc({ id: organisation?.id, payload: { email: values.email } } as PatchOrganisationParams)
  }

  const handleCloseModalAfterUpdate = () => {
    refresh()
    setModalOpened(false)
    setIsSent(false)
    push(`/agent-verification/${agentVerificationId}`)
  }

  return (
    <Modal open={isModalOpened} onClose={() => setModalOpened(false)}>
      <div className={styles.modal}>
        {isSent ? (
          <UpdateModalInformation
            handleClick={handleCloseModalAfterUpdate}
            setModalOpened={setModalOpened}
            title={t('dialog.updateEmail.emailSent')}
            subtitle={
              <>
                {t('dialog.updateEmail.requestMoved')}
                <strong> {t('dialog.updateEmail.waiting')} </strong>
                {t('dialog.updateEmail.until')}
              </>
            }
            buttonTitle={t('button.goBackToDetails')}
          />
        ) : (
          <Card className={styles.wrapper}>
            <div className={styles.titleWrapper}>
              <span className={styles.title}>
                {t(`dialog.updateEmail.${organisation?.email ? 'editEmail' : 'addEmail'}`)}
              </span>
              <CloseIcon onClick={() => setModalOpened(false)} />
            </div>
            <Divider className={styles.divider} />
            <div className={styles.textWrapper}>{t('dialog.updateEmail.body')}</div>
            <div className={styles.alertWrapper}>
              <span>
                <AlertIcon />
              </span>
              <span>{t('dialog.updateWebsite.bodyMore')}</span>
            </div>
            <FieldInput
              name="email"
              label={t('form.nonprofitGeneralEmail')}
              placeHolder={t('form.email')}
              onChange={(value: string) => setFieldValue('email', value)}
              value={values.email}
              handleBlur={handleBlur}
              error={errors.email}
              touched={touched.email}
            />
            {errorMessage && <ErrorView errorMessage={errorMessage} />}
            {isLoading ? (
              <Loader />
            ) : (
              <div className={styles.buttonWrapper}>
                <PercentButton
                  title={t('button.submitAndSendEmail')}
                  onClick={handleUpdate}
                  disabled={!(isValid && dirty)}
                />
                <PercentButton
                  title={t('button.cancel')}
                  onClick={() => setModalOpened(false)}
                  textColour="secondaryTextColour"
                  outlined
                />
              </div>
            )}
          </Card>
        )}
      </div>
    </Modal>
  )
}
