import styles from './FoundationNameWithFlag.module.scss'
import { FoundationNameWithFlagProps } from './FoundationNameWithFlag.types'
import { FlagAvatar } from '@percent/lemonade'

export function FoundationNameWithFlag({ countryCode, name }: FoundationNameWithFlagProps) {
  return (
    <div className={styles.wrapper}>
      <FlagAvatar code3={countryCode} />
      <span>{name}</span>
    </div>
  )
}
