import { rest } from 'msw'

import { config } from '@percent/admin-dashboard/config'
import {
  mockRegistriesList,
  mockRegistriesSearchList
} from '@percent/admin-dashboard/fixtures/api/registries/mockRegistriesList'
import { mockSuccessResponse } from '@percent/admin-dashboard/fixtures/api/mockSuccessResponse'

export const registriesHandlers = [
  rest.get(`${config.urls.api}v1/admin/registries`, async (req, res, ctx) => {
    if (!req.url.searchParams.get('search')) {
      return res(ctx.status(200), ctx.json(mockRegistriesList))
    }

    return res(ctx.status(200), ctx.json(mockRegistriesSearchList))
  }),
  rest.get(`${config.urls.api}v1/admin/registries/:id`, async (req, res, ctx) =>
    res(ctx.status(200), ctx.json(mockSuccessResponse(mockRegistriesList.data.find(({ id }) => id === req.params.id))))
  ),
  rest.post(`${config.urls.api}v1/admin/registries`, async (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockRegistriesSearchList))
  })
]
