import React from 'react'
import { Grid } from '@material-ui/core'
import { FormikErrors } from 'formik'

import { FieldError } from '../fieldError/FieldError'

import styles from './FieldMeta.module.scss'

type CharacterCountProps = {
  charCount: number
  countLimit: number
}

type FieldMetaProps = {
  characterCount?: CharacterCountProps
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined
}

export function FieldMeta({ characterCount, error }: FieldMetaProps) {
  const justify = characterCount ? 'space-between' : 'flex-start'

  return (
    <Grid container className={styles.textAreaDetail} justifyContent={justify}>
      <Grid item>
        <FieldError error={error} />
      </Grid>
      {characterCount && (
        <Grid item className={styles.counter}>
          <span>
            {characterCount.charCount}/{characterCount.countLimit}
          </span>
        </Grid>
      )}
    </Grid>
  )
}
