import { Box } from '@material-ui/core'
import { useCallback, useEffect, useMemo, useState } from 'react'
import debounce from 'lodash/debounce'
import { useTranslation } from 'react-i18next'
import { isEqual } from 'lodash'

import countries from '../../../../i18n/data/countries'

import { Button } from '@percent/lemonade'
import styles from './DonationsFiltersAndActions.module.scss'
import { useQueryList } from '@percent/admin-dashboard/common/hooks'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { DonationsFiltersAndActionsProps } from './DonationsFiltersAndActions.types'
import { dayJS } from '@percent/admin-dashboard/common/library/utility/date'
import { UploadDonationsCsv as UploadDonationsImport } from './UploadDonationsImport/UploadDonationsImport'
import { SearchableSelect } from '@percent/admin-dashboard/common/components/searchableSelect/SearchableSelect'
import { createShortLink } from '@percent/utility'

const THROTTLING_TIME = 500

type Option = {
  label: string
  value: string
}

const arraysEqual = (options1: Option[], options2: Option[]) =>
  options1.length === options2.length && options1.every((o, idx) => isEqual(o, options2[idx]))

export function DonationsFiltersAndActions({
  queryParams,
  setQueryParams,
  handleDateClick,
  handleDonationBatchCreation: handleDonationCreation,
  isUploadInProgress
}: DonationsFiltersAndActionsProps) {
  const { t, i18n } = useTranslation()
  const { adminService } = useServices()

  const defaultOption = useMemo(
    () => ({
      label: t('status.all'),
      value: ''
    }),
    [t]
  )

  const [partnersOptions, setPartnersOptions] = useState([defaultOption])
  const [organisationOptionsFilter, setOrganisationOptionsFilter] = useState('')
  const [organisationsOptions, setOrganisationsOptions] = useState([defaultOption])
  const [partnerOptionsFilter, setPartnerOptionsFilter] = useState('')

  const [{ dataOrNull: partnersData, error: partnersError, isLoading: isPartnersLoading }, { query: partnersQuery }] =
    useQueryList(adminService.queries.getPartnerList, {
      pageSize: 25
    })

  const [
    { dataOrNull: organisationsData, error: organisationsError, isLoading: isOrganisationsLoading },
    { query: organisationsQuery }
  ] = useQueryList(adminService.getOrganisations, {
    pageSize: 25
  })

  const selectedPartnerOption = useMemo(() => {
    return partnersOptions.find(option => option.value === queryParams.partnerId) || partnersOptions[0]
  }, [partnersOptions, queryParams.partnerId])

  const selectedOrganisationOption = useMemo(() => {
    return organisationsOptions.find(option => option.value === queryParams.organisationId) || organisationsOptions[0]
  }, [organisationsOptions, queryParams.organisationId])

  const shortLanguage = i18n.language.split('-')[0]

  useEffect(() => {
    if (!isPartnersLoading && !partnersError && partnersData && (partnerOptionsFilter || queryParams.partnerId)) {
      const newOptions = partnersData.map(({ name, id }) => ({
        label: name,
        value: id
      }))

      const uniqueOptions = [defaultOption, selectedPartnerOption, ...newOptions].reduce(
        (accumulator: { label: string; value: string }[], current) => {
          if (!accumulator.find(item => item.value === current.value)) {
            accumulator.push(current)
          }

          return accumulator
        },
        []
      )

      if (!arraysEqual(uniqueOptions, partnersOptions)) {
        setPartnersOptions(uniqueOptions)
      }
    }
  }, [
    defaultOption,
    partnersData,
    partnersError,
    isPartnersLoading,
    partnerOptionsFilter,
    selectedPartnerOption,
    partnersOptions,
    queryParams.partnerId
  ])

  useEffect(() => {
    if (
      !isOrganisationsLoading &&
      !organisationsError &&
      organisationsData &&
      (organisationOptionsFilter || queryParams.organisationId)
    ) {
      const newOptions = organisationsData.map(({ name, id, registryId, website, countryCode }) => {
        const extras = [
          countries.getName(countryCode, shortLanguage),
          `${t('typography.id')}: ${registryId}`,
          ...(website ? [createShortLink(website)] : [])
        ]

        return {
          label: `${name} (${extras.join(', ')})`,
          value: id
        }
      })

      const uniqueOptions = [defaultOption, selectedOrganisationOption, ...newOptions].reduce(
        (accumulator: { label: string; value: string }[], current) => {
          if (!accumulator.find(item => item.value === current.value)) {
            accumulator.push(current)
          }

          return accumulator
        },
        []
      )

      if (!arraysEqual(uniqueOptions, organisationsOptions)) {
        setOrganisationsOptions(uniqueOptions)
      }
    }
  }, [
    defaultOption,
    organisationsData,
    organisationsError,
    isOrganisationsLoading,
    organisationOptionsFilter,
    selectedOrganisationOption,
    shortLanguage,
    t,
    organisationsOptions,
    queryParams.organisationId
  ])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedPartnersQuery = useCallback(
    debounce<any>((filter: string) => {
      partnersQuery({ query: filter })
    }, THROTTLING_TIME),
    []
  )
  useEffect(() => {
    if (partnerOptionsFilter) {
      debouncedPartnersQuery(partnerOptionsFilter)
    }
  }, [partnerOptionsFilter, debouncedPartnersQuery])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOrganisationsQuery = useCallback(
    debounce<any>((filter: string) => {
      organisationsQuery({ query: filter })
    }, THROTTLING_TIME),
    []
  )
  useEffect(() => {
    if (organisationOptionsFilter) {
      debouncedOrganisationsQuery(organisationOptionsFilter)
    }
  }, [organisationOptionsFilter, debouncedOrganisationsQuery])

  useEffect(() => {
    if (queryParams.organisationId) {
      organisationsQuery({ query: queryParams.organisationId })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (queryParams.partnerId) {
      partnersQuery({ partnerIds: [queryParams.partnerId] })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box className={styles.wrapper}>
      {organisationsOptions ? (
        <SearchableSelect
          selectId="organisation"
          loading={isOrganisationsLoading}
          label={t('dropdown.organisation')}
          value={selectedOrganisationOption}
          selectOptions={organisationsOptions}
          onInputChange={(event, newValue) => setOrganisationOptionsFilter(newValue.trim())}
          onChange={(event, newValue) =>
            setQueryParams({
              ...queryParams,
              organisationId: newValue?.value?.length ? newValue.value : undefined
            })
          }
          styleAsFilter
        />
      ) : null}

      <span className={styles.spacer} />

      {partnersOptions ? (
        <SearchableSelect
          selectId="partner"
          loading={isPartnersLoading}
          label={t('dropdown.partner')}
          value={selectedPartnerOption}
          selectOptions={partnersOptions}
          onInputChange={(event, newValue) => setPartnerOptionsFilter(newValue.trim())}
          onChange={(event, newValue) =>
            setQueryParams({
              ...queryParams,
              partnerId: newValue?.value?.length ? newValue.value : undefined
            })
          }
          styleAsFilter
        />
      ) : null}

      <Button
        size="small"
        endIcon="dropdown-arrow-down"
        variant={queryParams.startDate ? 'primary' : 'secondary'}
        onPress={handleDateClick}
      >
        {queryParams.startDate
          ? `${dayJS(queryParams.startDate).format('ll')} - ${dayJS(queryParams.endDate).format('ll')}`
          : t('dropdown.date')}
      </Button>
      {queryParams.startDate || queryParams.endDate || queryParams.organisationId || queryParams.partnerId ? (
        <Button
          size="small"
          variant="tertiary"
          onPress={() => {
            setQueryParams({
              ...queryParams,
              partnerId: undefined,
              organisationId: undefined,
              startDate: undefined,
              endDate: undefined
            })
          }}
        >
          {t('button.clear')}
        </Button>
      ) : null}
      <span className={styles.separator} />
      <UploadDonationsImport
        handleDonationBatchCreation={handleDonationCreation}
        isUploadInProgress={isUploadInProgress}
      />
    </Box>
  )
}
