import { ListItem, ListItemIcon } from '@material-ui/core'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import classNames from 'classnames/bind'

import styles from './PCTMenuItem.module.scss'
import { PCTMenuItemProps } from './PCTMenuItem.types'

const cx = classNames.bind(styles)

export function PCTMenuItem({ Logo, name, to, totalNumber }: PCTMenuItemProps) {
  const { pathname } = useLocation()
  const isActive = to.split('?')[0].match(pathname.split('/')[1])

  return (
    <Link to={to}>
      <ListItem
        button
        className={cx({
          active: isActive,
          default: !isActive,
          withLogo: Logo
        })}
      >
        <div className={cx('box')}>
          <ListItemIcon>
            {!!Logo && <Logo width="20px" height="20px" className={isActive ? styles.svgActive : styles.svgDefault} />}
          </ListItemIcon>
          {name}
        </div>
        {totalNumber && totalNumber >= 1 ? (
          <div className={styles.totalNumber}>{totalNumber >= 99 ? '99+' : totalNumber}</div>
        ) : (
          ''
        )}
      </ListItem>
    </Link>
  )
}
