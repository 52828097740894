import React, { useState, useEffect } from 'react'
import { Grid, Paper } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { ActivityTags } from './components/ActivityTags/ActivityTags'
import { OrganisationDetails } from './components/OrganisationDetails/OrganisationDetails'
import { Loader, ErrorView, FieldCheckBox } from '@percent/admin-dashboard/common/components'
import { EligibilityDetailsViewProps } from './EligibilityDetailsView.types'
import styles from './EligibilityDetailsView.module.scss'
import { OrganisationTypes } from '@percent/admin-dashboard/constants/organisationTypes'
import { ActivityEventSubTag, EligibilityStatus } from '@percent/admin-dashboard/api/types'

export function EligibilityDetailsView({
  errorMessage,
  isLoading,
  orgData,
  subscription,
  refresh,
  partnerName
}: Readonly<EligibilityDetailsViewProps>) {
  const { t } = useTranslation()
  const [subTagsForm, setSubTagsForm] = useState<ActivityEventSubTag[]>(subscription?.organisationSubTags ?? [])
  const [isFlagged, setIsFlagged] = useState(false)
  const [isNoneOfTheAbove, setIsNoneOfTheAbove] = useState(false)

  useEffect(() => {
    if (subscription?.status === 'cannot_define_eligibility') {
      setIsFlagged(true)
    }
  }, [subscription])

  useEffect(() => {
    if (subscription) {
      setSubTagsForm(subscription.organisationSubTags || [])
    }
  }, [subscription])

  const onSubTagChange = (subTagId: string) => {
    const subTagExists = subTagsForm.findIndex(subTag => subTag.id === subTagId) !== -1

    if (subTagExists) {
      setSubTagsForm(prevState => prevState?.filter(el => el.id !== subTagId))
    } else {
      setSubTagsForm(prevState => {
        if (prevState) {
          return [...prevState, { id: subTagId, meetsCriteria: true }]
        }

        return prevState
      })
    }
  }

  const onEducationSubTagChange = (educationSubTags: ActivityEventSubTag[], subTagId?: string) => {
    setSubTagsForm(
      educationSubTags.map(subTag => ({
        id: subTag.id,
        meetsCriteria: subTag.id === subTagId ? !subTag.meetsCriteria : subTag.meetsCriteria
      }))
    )
  }

  const setNotDeprecatedSubTags = (notDeprecatedSubTags: ActivityEventSubTag[]) => {
    setSubTagsForm(notDeprecatedSubTags)
  }

  const handleFlagOrg = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsFlagged(event.target.checked)
    setSubTagsForm([])
  }

  if (errorMessage) {
    return <ErrorView errorMessage={errorMessage} />
  }

  if (isLoading) {
    return <Loader />
  }

  const isOnlyEducationOrganisation = orgData?.type === OrganisationTypes.Education
  const isSubscriptionLive = subscription.status === EligibilityStatus.LIVE

  return (
    <>
      <OrganisationDetails
        organisation={orgData}
        subscription={subscription}
        subTagsForm={subTagsForm}
        errorMessage={errorMessage}
        isLoading={isLoading}
        isFlagged={isFlagged}
        refresh={refresh}
        isNoneOfTheAbove={isNoneOfTheAbove}
        partnerName={partnerName}
      />
      <Grid container spacing={0} component={Paper} elevation={0} className={styles.flagWrapper}>
        <span className={styles.headerText}>{t('typography.insufficientInformation')}</span>
        <FieldCheckBox
          onChange={handleFlagOrg}
          active={isFlagged}
          name="sanction"
          disabled={isNoneOfTheAbove || isSubscriptionLive}
          checkboxClass={isFlagged ? 'flagCheckbox' : 'checkbox'}
        >
          <span className={styles.checkboxLabel}>{t('typography.notEnoughInfo')}</span>
        </FieldCheckBox>
      </Grid>

      <Grid container spacing={0} component={Paper} elevation={0} className={styles.algoliaContainer}>
        <ActivityTags
          subTagsForm={subTagsForm}
          onSubTagChange={onSubTagChange}
          isFlagged={isFlagged}
          isOnlyEducationOrganisation={isOnlyEducationOrganisation}
          setNotDeprecatedSubTags={setNotDeprecatedSubTags}
          onEducationSubTagChange={onEducationSubTagChange}
          isNoneOfTheAbove={isNoneOfTheAbove}
          setIsNoneOfTheAbove={setIsNoneOfTheAbove}
        />
      </Grid>
    </>
  )
}
