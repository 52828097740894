import { rest } from 'msw'

import { config } from '@percent/admin-dashboard/config'
import { mockValidations, mockValidationResponse } from '@percent/admin-dashboard/fixtures/api/mockValidations'

export const validationRequestsHandlers = [
  rest.get(`${config.urls.api}v1/admin/validation-requests`, async (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockValidations))
  }),
  rest.get(`${config.urls.api}v1/admin/validation-requests/:id`, async (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockValidationResponse))
  }),
  rest.post(`${config.urls.api}v1/admin/validation-requests/:id/accept`, async (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockValidations))
  })
]
