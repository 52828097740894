import { rest } from 'msw'

import { config } from '@percent/admin-dashboard/config'
import { mockOrganisationDonationsList } from '@percent/admin-dashboard/fixtures/api/organisation/mockOrganisationDonationsList'

export const donationsHandlers = [
  rest.get(`${config.urls.api}v1/admin/organisations/donated`, async (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockOrganisationDonationsList))
  })
]
