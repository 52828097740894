import { TableCell, TableRow } from '@material-ui/core'
import { ChangeEvent, EventHandler, useCallback, useMemo, useState, KeyboardEvent } from 'react'
import { useTranslation } from 'react-i18next'

import { AddToAutoRejectionListModal } from '../addToAutoRejectionListModal/AddToAutoRejectionListModal'
import { RemoveFromAutoRejectionListModal } from '../removeFromAutoRejectionListModal/RemoveFromAutoRejectionListModal'

import { Table } from '@percent/admin-dashboard/common/components'
import { Badge, Button, ButtonText, SearchInput, Spacer } from '@percent/lemonade'
import { AutoRejectionListTableProps } from './AutoRejectionListTable.types'
import { AutoRejectionType } from '@percent/admin-dashboard/api/actions/autoRejectionList/autoRejectionList.types'

export function AutoRejectionListTable({
  data,
  isLoading,
  nextPage,
  previousPage,
  totalResults,
  errorMessage,
  queryParams,
  setQueryParams,
  refresh
}: Readonly<AutoRejectionListTableProps>) {
  const { t } = useTranslation()

  const [isAddToRejectionListModalOpen, setIsAddToRejectionListModalOpen] = useState(false)
  const [selectedAutoRejectionEntry, setSelectedAutoRejectionEntry] = useState<AutoRejectionType>()
  const [searchValue, setSearchValue] = useState<string>(queryParams.query || '')

  const cells = [
    { id: 'email', isSortable: false },
    { id: 'code', isSortable: false },
    { id: 'actions', isSortable: false }
  ]

  const handleKeyChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value)
  }

  const handleKeyPress: EventHandler<KeyboardEvent<HTMLInputElement>> = useCallback(
    event => {
      const searchTerm = (event.target as HTMLInputElement).value

      return event.key === 'Enter'
        ? searchTerm.trim() === ''
          ? setQueryParams({ query: undefined })
          : setQueryParams({
              query: searchTerm
            })
        : null
    },
    [setQueryParams]
  )

  const handleClearValue = () => {
    setSearchValue('')
    setQueryParams({ query: undefined })
  }

  const openAddToAutoRejectionListModal = () => setIsAddToRejectionListModalOpen(true)

  const filters = useMemo(
    () => (
      <>
        <SearchInput
          value={searchValue ?? ''}
          handleClearValue={handleClearValue}
          onKeyDown={handleKeyPress}
          onChange={handleKeyChange}
          placeholder={t('table.autoRejectionListSearchPlaceholder')}
        />
        <Spacer size={4} axis="horizontal" />
        <Button onPress={openAddToAutoRejectionListModal}>+ {t('button.addToAutoRejectionList')}</Button>
      </>
    ),
    [t, handleKeyPress, searchValue]
  )

  return (
    <>
      <Table
        isLoading={isLoading}
        totalResults={errorMessage ? 0 : totalResults || 0}
        data={data}
        previousPage={previousPage}
        nextPage={nextPage}
        columns={cells}
        filtersContent={filters}
        emptyTableText={t('table.emptyAutoRejectionList')}
      >
        {data?.map(row => (
          <TableRow key={row.id}>
            <TableCell>{row.email}</TableCell>
            <TableCell>
              <Badge variant="default">{t(`dropdown.reject.${row.rejectionReasonCode}`)}</Badge>
            </TableCell>
            <TableCell>
              <ButtonText icon="reject" onPress={() => setSelectedAutoRejectionEntry(row)}>
                {t('button.remove')}
              </ButtonText>
            </TableCell>
          </TableRow>
        ))}
      </Table>
      <AddToAutoRejectionListModal
        isOpen={isAddToRejectionListModalOpen}
        setIsOpen={setIsAddToRejectionListModalOpen}
        refresh={refresh}
      />
      <RemoveFromAutoRejectionListModal
        autoRejectionEntry={selectedAutoRejectionEntry}
        setAutoRejectionEntry={setSelectedAutoRejectionEntry}
        refresh={refresh}
      />
    </>
  )
}
