import { useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Loader } from '@percent/admin-dashboard/common/components'
import {
  AcknowledgeModal,
  Alert,
  Button,
  CardDashboard,
  ConfirmationModal,
  DocumentsGallery,
  Modal
} from '@percent/lemonade'
import { AgentVerificationDocumentsProps } from './AgentVerificationDocuments.types'
import styles from './AgentVerificationDocuments.module.scss'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { useMutation } from '@percent/admin-dashboard/common/hooks'

export function AgentVerificationDocuments({
  agentVerificationId,
  agentVerificationLogs,
  isLoadingAgentVerificationLogs,
  refreshAgentVerificationLogs,
  isLoading,
  documents,
  agentEmail
}: Readonly<AgentVerificationDocumentsProps>) {
  const [isRequestDocumentModalOpened, setIsRequestDocumentModalOpened] = useState(false)
  const [documentRequestStatus, setDocumentRequestStatus] = useState<'success' | 'error'>()
  const { agentVerificationService } = useServices()
  const { t } = useTranslation()

  const [{ isLoading: isDocumentRequestLoading }, { apiFunc: requestDocument }] = useMutation(
    agentVerificationService.requestAgentVerificationDocument,
    () => setDocumentRequestStatus('success'),
    () => setDocumentRequestStatus('error')
  )

  const hasDocuments = useMemo(() => Array.isArray(documents) && documents?.length > 0, [documents])
  const requestDocumentationText = t('typography.requestDocumentation')
  const documentRequestSent = agentVerificationLogs?.find(
    log => log.type === 'agent_verification_document_request_email_sent'
  )
  const documentUploaded = agentVerificationLogs?.find(log => log.type === 'agent_verification_document_uploaded')

  const toggleRequestDocumentModal = () => setIsRequestDocumentModalOpened(!isRequestDocumentModalOpened)
  const onSubmit = () => requestDocument({ id: agentVerificationId })
  const onClose = () => {
    refreshAgentVerificationLogs()
    toggleRequestDocumentModal()
    setDocumentRequestStatus(undefined)
  }

  return (
    <CardDashboard
      title={t('typography.documents')}
      action={
        !isLoadingAgentVerificationLogs && (
          <div className={styles.buttonsWrapper}>
            {!documentRequestSent && (
              <Button onPress={toggleRequestDocumentModal} data-testid="open-request-documentation-modal">
                {requestDocumentationText}
              </Button>
            )}
          </div>
        )
      }
      data-testid="request-documentation-documents"
    >
      {(isLoading || isLoadingAgentVerificationLogs) && <Loader />}
      {!isLoading && !isLoadingAgentVerificationLogs && (
        <div className={styles.messageWrapper}>
          {!hasDocuments && <>{t('typography.noDocumentsApplication')} </>}
          {documentRequestSent && !documentUploaded && (
            <Alert variant="info" title={t('typography.documentationRequestSent', { email: agentEmail })} />
          )}
          {!documentRequestSent && !documentUploaded && (
            <Trans
              i18nKey="typography.moreDocumentsInformationNeeded"
              components={{ b: <b /> }}
              values={{
                buttonText: requestDocumentationText
              }}
            />
          )}
        </div>
      )}
      {!isLoading && hasDocuments && (
        <DocumentsGallery
          files={documents.map(d => ({ url: d.url, variant: d.type === 'agent_verification' ? 'green' : 'default' }))}
          withPreview
          previewUrl="/document-preview"
        />
      )}

      {isRequestDocumentModalOpened && (
        <Modal
          open={isRequestDocumentModalOpened}
          onClose={toggleRequestDocumentModal}
          data-testid="request-documentation-modal"
          aria-labelledby="request-documentation-modal"
        >
          {!documentRequestStatus && (
            <ConfirmationModal
              title={requestDocumentationText}
              loading={isDocumentRequestLoading}
              primaryButtonText={requestDocumentationText}
              primaryBtnTestId="request-documentation-button"
              secondaryButtonText={t('button.cancel')}
              handleSubmit={onSubmit}
              handleClose={toggleRequestDocumentModal}
            >
              {t('dialog.requestDocumentation.description', { agentEmail })}
            </ConfirmationModal>
          )}
          {documentRequestStatus === 'success' && (
            <AcknowledgeModal
              result="positive"
              title={t('dialog.requestDocumentation.success.title')}
              buttonText={t('button.close')}
              handleClose={onClose}
            />
          )}
          {documentRequestStatus === 'error' && (
            <AcknowledgeModal
              result="negative"
              title={t('dialog.requestDocumentation.error.title')}
              description={t('dialog.requestDocumentation.error.description')}
              buttonText={t('button.close')}
              handleClose={onClose}
            />
          )}
        </Modal>
      )}
    </CardDashboard>
  )
}
