import { ReactNode } from 'react'

export function NonProfitDetailListItem({ title, children }: { title: string; children: ReactNode }) {
  return (
    <li>
      <p>{title}</p>
      {children}
    </li>
  )
}
