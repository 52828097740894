import React from 'react'
import { useTranslation } from 'react-i18next'

import { AcknowledgeModal, Modal } from '@percent/lemonade'

type VerifySelectedOrganizationSuccessProps = {
  onClose: VoidFunction
  open: boolean
  organisationName: string
  handleOnSuccess: VoidFunction
}
export function VerifySelectedOrganizationSuccess({
  onClose,
  open,
  organisationName,
  handleOnSuccess
}: VerifySelectedOrganizationSuccessProps) {
  const { t } = useTranslation()

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="verify-selected-organization-success">
      <AcknowledgeModal
        result="positive"
        title={t('dialog.verifySelectedOrganisation.success.title')}
        description={t('dialog.verifySelectedOrganisation.success.description', { orgName: organisationName })}
        buttonText={t('button.done')}
        handleClose={handleOnSuccess}
        buttonTestId="success-modal-btn"
      />
    </Modal>
  )
}
