import { Grid } from '@material-ui/core'
import { useFormik } from 'formik'
import React, { useEffect } from 'react'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'

import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { useToast } from '@percent/admin-dashboard/containers/toast/ToastContext'
import { useMutation } from '@percent/admin-dashboard/common/hooks'
import { ErrorView, Loader, FieldInput, PercentButton } from '@percent/admin-dashboard/common/components'
import styles from './PartnerNameForm.module.scss'

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required').max(50, 'Too Long!')
})

type PartnerNameFormType = {
  partnerId: string
  partnerName: string
  refreshApi: () => void
  editEnabled: boolean
}

export function PartnerNameForm({ partnerId, partnerName, refreshApi, editEnabled }: Readonly<PartnerNameFormType>) {
  const { adminService } = useServices()
  const [{ isLoading, errorMessage, success }, { apiFunc }] = useMutation(
    adminService.commands.editPartnerDetail,
    () => {
      refreshApi()
    }
  )
  const { t } = useTranslation()

  const { values, errors, handleBlur, handleSubmit, touched, setFieldValue } = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: partnerName || ''
    },
    validationSchema,
    onSubmit: ({ name }) => {
      apiFunc({
        payload: {
          name
        },
        partnerId
      })
    }
  })

  const addToast = useToast()

  useEffect(() => {
    if (success) {
      addToast(t('typography.partnerNameUpdated'), 'success')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success])

  if (isLoading) {
    return <Loader />
  }

  if (errorMessage) {
    return <ErrorView errorMessage={errorMessage} />
  }

  return (
    <Grid>
      <Grid item xs={12}>
        <form style={{ margin: '24px 0 0' }} onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={6} className={styles.input}>
              <FieldInput
                name="name"
                label={t('form.organisationName')}
                placeHolder={t('form.enterOrganisationName')}
                value={values.name}
                onChange={a => setFieldValue('name', a)}
                error={errors?.name || ''}
                touched={touched.name}
                handleBlur={handleBlur}
                disabled={!editEnabled}
              />
            </Grid>
            <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
              <PercentButton
                type="submit"
                disabled={partnerName === values.name}
                testId="save-changes-button"
                title={t('button.saveChanges')}
                className={styles.saveButton}
              />
            </Grid>
          </Grid>
        </form>
        {errorMessage && <ErrorView errorMessage={errorMessage} />}
      </Grid>
    </Grid>
  )
}
