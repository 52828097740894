export const mockVettingRequests = {
  data: [
    {
      id: 'vettingrequest_xxxxxxxxxxxxxxxxxxxxxxxxxxxxx',
      partnerId: 'partner_xxxxxxxxxxxxxxxxxxxxxxxxxxxxx',
      organisationId: 'organisation_xxxxxxxxxxxxxxxxxxxxxxxxxxxxx',
      validationRequestId: 'validationrequest_xxxxxxxxxxxxxxxxxxxxxxxxxxxxx',
      scopes: ['religious'],
      results: {},
      status: 'pending',
      updatedAt: '2020-10-13T17:46:54.000Z',
      completedAt: '2020-10-13T17:46:54.000Z',
      createdAt: '2020-10-13T17:46:54.000Z',
      deletedAt: '2020-10-13T17:46:54.000Z',
      acceptedAt: '2020-10-13T17:46:54.000Z',
      name: 'Natalia NM AUTOMATED TEST ab324924-2e9a-4201-a1a7-cbc2a790e156 TC004 (ActivityChecks)'
    },
    {
      id: 'vettingrequest2_xxxxxxxxxxxxxxxxxxxxxxxxxxxxx',
      partnerId: 'partner2_xxxxxxxxxxxxxxxxxxxxxxxxxxxxx',
      organisationId: null,
      validationRequestId: 'validationrequest2_xxxxxxxxxxxxxxxxxxxxxxxxxxxxx',
      scopes: ['religious'],
      results: { religious: { status: 'pending' } },
      status: 'pending',
      updatedAt: '2020-10-13T17:46:54.000Z',
      completedAt: '2020-10-13T17:46:54.000Z',
      createdAt: '2020-10-13T17:46:54.000Z',
      deletedAt: '2020-10-13T17:46:54.000Z',
      acceptedAt: '2020-10-13T17:46:54.000Z',
      name: 'Natalia NM AUTOMATED TEST ab324924-2e9a-4201-a1a7-cbc2a790e156 TC005 (ActivityChecks)'
    }
  ],
  object: 'vetting_request',
  totalResults: 33,
  pageNumber: 1,
  pageSize: 25,
  _links: {
    current: 'https://.../v1/...?cursor=eyJ2Ijox...N3AifQ%3D%3D',
    next: 'https://.../v1/...?cursor=eyJ2Ijox...N3AifQ%3D%3D',
    prev: 'https://.../v1/...?cursor=eyJ2Ijox...N3AifQ%3D%3D'
  }
}
