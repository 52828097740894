import React, { useState, useEffect } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'

import { Dialog, Loader, PercentButton, CellStatus } from '@percent/admin-dashboard/common/components'
import { useMutation } from '@percent/admin-dashboard/common/hooks'
import { FieldDropDown } from '@percent/admin-dashboard/common/components/fieldDropDown/FieldDropDown'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { ReactComponent as CheckMark } from '@percent/admin-dashboard/common/assets/images/check_mark.svg'
import { useToast } from '@percent/admin-dashboard/containers/toast/ToastContext'
import { ComplianceEventType, VettingEventScope, VettingStatus } from '@percent/admin-dashboard/api/types'
import styles from './EditComplianceModal.module.scss'
import { EditComplianceModalProps } from './EditComplianceModal.types'
import { LocaleKey } from '@percent/admin-dashboard/i18n/config'

const complianceEvents: ComplianceEventType[] = [
  'adverseMedia',
  'sanction',
  'warning',
  'commercial',
  'controversial',
  'hateSpeech'
]

const eventStatuses: { title: LocaleKey; value: VettingStatus }[] = [
  {
    title: 'status.yes',
    value: VettingStatus.YES
  },
  {
    title: 'status.no',
    value: VettingStatus.NO
  }
]

export function EditComplianceModal({
  isOpened,
  setIsOpened,
  vettingEventsResults,
  refresh,
  refreshVettingEventsResults,
  organisationId
}: EditComplianceModalProps) {
  const { t } = useTranslation()
  const { adminService } = useServices()
  const [isError, setIsError] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const addToast = useToast()

  useEffect(() => {
    if (isError) {
      addToast(errorMessage, 'error')
    } else if (!isError && isSuccess) {
      addToast(successMessage, 'success')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage, successMessage])

  const onSuccess = () => {
    setIsSuccess(true)
    setSuccessMessage(t('typography.changesSaved'))
    setIsOpened(false)
    refresh()
    refreshVettingEventsResults?.()
  }

  const onError = () => {
    setIsError(true)
    setErrorMessage(t('errorMessage.genericError'))
    setIsOpened(false)
    refresh()
    refreshVettingEventsResults?.()
  }

  const [{ isLoading: isSubmissionLoading }, { apiFunc: submitFunc }] = useMutation(
    adminService.submitVettingEvents,
    onSuccess,
    onError
  )

  const { values, setFieldValue, isValid, dirty } = useFormik({
    initialValues: {
      event: '',
      status: ''
    },
    validationSchema: () =>
      yup.object().shape({
        event: yup.mixed().oneOf(complianceEvents).required('Required').defined(),
        status: yup.string().required('Required')
      }),
    onSubmit: () => {}
  })

  const { event, status } = values

  const handleSubmit = async () =>
    submitFunc({
      organisationId,
      payload: { flags: [{ scope: event as VettingEventScope, status: status as VettingStatus }] }
    })

  const isErroView = errorMessage ? <div className={styles.modalBody}>{errorMessage}</div> : null

  const isSuccessView = isSuccess ? (
    <div className={styles.dialogBody}>
      <div className={styles.checkMark}>
        <CheckMark />
      </div>
      <Typography variant="h5">{t('dialog.eventHasBeenEdited')}</Typography>
      <PercentButton title={t('button.done')} onClick={() => setIsOpened(false)} className={styles.centerButton} />
    </div>
  ) : null

  const complianceModalView = (
    <>
      <Grid item xs={12} className={styles.modalForm}>
        <FieldDropDown
          selectTitle={t('form.selectEvent')}
          placeholder={t('form.selectEventPlaceholder')}
          initialValue={event}
          onClick={(
            e: React.ChangeEvent<{
              name?: string
              value: unknown
            }>
          ) => {
            setFieldValue('event', e.target.value)
            setFieldValue('status', '')
          }}
          valueArray={complianceEvents.map(complianceEvent => {
            return { title: t(`form.${complianceEvent}`), value: complianceEvent }
          })}
        />
      </Grid>
      {event && (
        <Grid item xs={12} className={styles.modalForm}>
          <div className={styles.currentStatus}>
            <span>{t('form.currentStatus')}</span>
            <CellStatus status={vettingEventsResults.results?.[event]?.status || VettingStatus.PENDING} />
          </div>
        </Grid>
      )}
      <Grid item xs={12} className={styles.modalForm}>
        <FieldDropDown
          selectTitle={t('form.selectNewStatus')}
          placeholder={t('form.selectStatus')}
          initialValue={status}
          onClick={(
            e: React.ChangeEvent<{
              name?: string
              value: unknown
            }>
          ) => setFieldValue('status', e.target.value)}
          valueArray={eventStatuses.map(eventStatus => {
            return { title: t(eventStatus.title), value: eventStatus.value }
          })}
        />
      </Grid>
      <Grid item xs={12} className={styles.dialogFooter}>
        <PercentButton
          disabled={!(isValid && dirty)}
          title={t('button.edit')}
          onClick={handleSubmit}
          testId="submit-vetting-events-button"
        />
        <PercentButton
          title={t('button.cancel')}
          onClick={() => setIsOpened(false)}
          textColour="secondaryTextColour"
          outlined
        />
      </Grid>
    </>
  )

  return (
    <Dialog
      openModal={isOpened}
      onClose={() => setIsOpened(false)}
      withoutHeader={isSuccess}
      headerTitle={t('dialog.editComplianceEvent')}
    >
      {isSubmissionLoading ? <Loader /> : isErroView || isSuccessView || complianceModalView}
    </Dialog>
  )
}
