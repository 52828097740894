import classNames from 'classnames/bind'
import { useTranslation } from 'react-i18next'

import { VettingEventCheckProps } from './VettingEventCheck.types'
import styles from './VettingEventCheck.module.scss'
import { Alert, Radio } from '@percent/lemonade'
import { VettingStatus, YesNo } from '@percent/admin-dashboard/api/types'
import { AlertVariant } from 'libs/shared/ui-lemonade/src/components/alert/alert.types'
import { LocaleKey } from '@percent/admin-dashboard/i18n/config'

const cx = classNames.bind(styles)

const getAlertVariant = (status: VettingStatus | null): AlertVariant => {
  if (status === VettingStatus.PASS) return 'success'

  if (status === VettingStatus.FAIL) return 'error'

  return 'warning'
}

export function VettingEventCheck({
  name,
  status,
  setFieldValue,
  isFieldValid,
  complianceCheckLink
}: Readonly<VettingEventCheckProps>) {
  const { t } = useTranslation()

  const checkName = t(`form.${name}` as LocaleKey)
  const passedChecks = status === VettingStatus.PASS
  const failedChecks = status === VettingStatus.FAIL

  const isReviewRequired = !passedChecks && !failedChecks

  return (
    <div
      className={cx({
        vettingEventCheckWrapper: true,
        checkWrapperCustomBackground: isReviewRequired && !isFieldValid
      })}
      data-testid={`${name}-vetting-event-check`}
    >
      <h5>{checkName}</h5>
      <Alert variant={getAlertVariant(status)}>
        {passedChecks && t('dialog.complianceModal.passedChecks', { checkName })}
        {failedChecks && t('dialog.complianceModal.failedChecks', { checkName })}
        {status === 'pending_review' && t('dialog.complianceModal.pendingReview', { checkName })}
        {status === null && t('dialog.complianceModal.manualCheckNeeded', { checkName })}
        {complianceCheckLink &&
          t(isReviewRequired ? 'dialog.complianceModal.pleaseReview' : 'dialog.complianceModal.seeResults')}
        {complianceCheckLink && (
          <a href={complianceCheckLink} target="_blank" rel="noopener noreferrer">
            {t('dialog.complianceModal.link')}
          </a>
        )}
      </Alert>

      {passedChecks && <p>{t('dialog.complianceModal.nothingFound')}</p>}
      {failedChecks && <p>{t('dialog.complianceModal.flagFound', { checkName })}</p>}
      {isReviewRequired && (
        <div className={styles.radioWrapper}>
          <Radio
            name={name}
            id={`${name}-found`}
            value={YesNo.YES}
            label={t('dialog.complianceModal.radio.flagFound')}
            onChange={e => setFieldValue(name, e.target.value)}
          />
          <Radio
            name={name}
            id={`${name}-notFound`}
            value={YesNo.NO}
            label={t('dialog.complianceModal.radio.nothingFound')}
            onChange={e => setFieldValue(name, e.target.value)}
          />
        </div>
      )}
    </div>
  )
}
