import React, { useCallback, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'

import { ActionModal, FormField, Modal, Select, Spacer, TextInput } from '@percent/lemonade'
import { RegistriesType } from '@percent/admin-dashboard/api/types'
import { useCountries, useDidMountEffect, useMutation } from '@percent/admin-dashboard/common/hooks'
import { APIErrorHandler } from '@percent/admin-dashboard/common/library/APIErrorHandler'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { SelectOption } from 'libs/shared/ui-lemonade/src/components/select/option.types'
import { isValidWebsiteURL, sanitizeStringToRegex } from '@percent/utility'
import { ErrorView } from '@percent/admin-dashboard/common/components'
import { VerifySelectedOrganizationSuccess } from './VerifySelectedOrganizationSuccess'
import { VerifySelectedOrganizationError } from './VerifySelectedOrganizationError'
import { mapCountriesToSelectOptions } from '@percent/admin-dashboard/common/utility/mapCountriesToSelectOptions'

type VerifyUnverifiedSelectedOrganizationFormProps = {
  onClose: VoidFunction
  refresh: VoidFunction
  open: boolean
  organisationName: string
  organisationWebsite?: string | null
  donationMatchRequestId: string
}
export function VerifyUnverifiedSelectedOrganizationForm({
  onClose,
  refresh,
  open,
  organisationName,
  organisationWebsite,
  donationMatchRequestId
}: VerifyUnverifiedSelectedOrganizationFormProps) {
  const { t } = useTranslation()
  const { registriesService, donationMatchingRequestService } = useServices()
  const [errorDialogState, setErrorDialogState] = useState(false)
  const [successDialogState, setSuccessDialogState] = useState(false)
  const countries = useCountries()
  const [registryData, setRegistryData] = useState<RegistriesType[] | null>(null)
  const [isRegistryDataError, setIsRegistryDataError] = useState('')
  const [selectedValue, setSelectedValue] = useState<SelectOption>({
    label: '',
    value: ''
  })

  const [{ isLoading }, { apiFunc }] = useMutation(
    donationMatchingRequestService.verifyDonationMatchRequestOrganisation,
    () => {
      setSuccessDialogState(true)
    },
    () => {
      setErrorDialogState(true)
    }
  )

  const getRegistryData = useCallback(
    (countryCode: string) =>
      registriesService
        .getRegistriesList({ countryCode })
        .then(regData => {
          setRegistryData(regData?.data?.data)
        })
        .catch(error => {
          setIsRegistryDataError(APIErrorHandler(error?.response?.data?.error))
        }),
    [registriesService]
  )

  const mapRegistries = (registries?: RegistriesType[] | null) => {
    return registries
      ? registries?.map(registry => {
          return {
            label: registry.englishName || registry.name,
            value: registry.name
          }
        })
      : []
  }

  const formik = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: {
      registryName: '',
      registryId: '',
      name: organisationName || '',
      website: organisationWebsite || '',
      countryCode: null
    },
    validationSchema: () =>
      Yup.lazy(value => {
        const newRegex =
          value.registryName &&
          registryData?.find(registry => registry.name === value?.registryName)?.registryIdFormatRegex

        return Yup.object().shape({
          name: Yup.string()
            .max(255, t('errorMessage.orgFieldCharacterLimit', { fieldName: 'Legal name' }))
            .min(1, t('errorMessage.orgFieldCharacterLimit', { fieldName: 'Legal name' }))
            .trim()
            .required(t('errorMessage.required')),
          countryCode: Yup.string().required(),
          registryName: Yup.string().required(t('errorMessage.required')),
          registryId: Yup.string()
            .max(255, t('errorMessage.orgFieldCharacterLimit', { fieldName: 'Registry ID' }))
            .min(1, t('errorMessage.orgFieldCharacterLimit', { fieldName: 'Registry ID' }))
            .trim()
            .required(t('errorMessage.required'))
            .matches(sanitizeStringToRegex(newRegex || ''), t('errorMessage.wrongFormatRegistryId')),
          website: isValidWebsiteURL()
            .trim()
            .required(t('errorMessage.required'))
            .min(1, t('errorMessage.orgFieldCharacterLimit', { fieldName: 'Website' }))
            .max(255, t('errorMessage.orgFieldCharacterLimit', { fieldName: 'Website' }))
        })
      }),
    onSubmit: async ({ registryName, registryId, name, website, countryCode }) => {
      await apiFunc({
        donationMatchRequestId,
        organisationDetails: {
          registryName,
          registryId,
          name,
          websiteUrl: (!/^https?:\/\//i.test(website) && `https://${website}`) || website,
          countryCode
        }
      })
    }
  })

  const registriesOptions = mapRegistries(registryData)
  const countriesOptions = mapCountriesToSelectOptions(countries)

  const { errors, values, touched, dirty, handleChange, setFieldValue, handleBlur, handleSubmit, isValid, resetForm } =
    formik

  const handleOnSuccessClose = () => {
    onClose()
    refresh()
    resetForm()
    setSuccessDialogState(false)
  }

  const handleOnErrorClose = () => {
    onClose()
    resetForm()
  }

  const successModal = successDialogState && (
    <VerifySelectedOrganizationSuccess
      onClose={onClose}
      handleOnSuccess={handleOnSuccessClose}
      open={open}
      organisationName={values.name}
    />
  )

  const errorModal = errorDialogState && <VerifySelectedOrganizationError onClose={handleOnErrorClose} open={open} />

  useDidMountEffect(() => {
    if (values.countryCode) {
      getRegistryData(values?.countryCode)
      setSelectedValue({
        label: '',
        value: ''
      })
      setFieldValue('registryName', '')
    }
  }, [values.countryCode])

  if (isRegistryDataError) {
    return <ErrorView errorMessage={isRegistryDataError} />
  }

  return (
    successModal ||
    errorModal || (
      <Modal open={open} onClose={onClose} aria-labelledby="verify-selected-organization-success">
        <ActionModal
          title={t('dialog.donationMatchRequestVerifyUnverifiedOrg.title')}
          primaryButtonText={t('dialog.donationMatchRequestVerifyUnverifiedOrg.title')}
          secondaryButtonText={t('button.cancel')}
          type="submit"
          variant={isValid && dirty ? 'primary' : 'secondary'}
          disabled={!(isValid && dirty && !isLoading)}
          loading={isLoading}
          handleClose={onClose}
          handleSubmit={handleSubmit}
          primaryBtnTestId="verify-organization"
          secondaryBtnTestId="cancel-verify-organization"
          aria-labelledby="verify-organization-modal"
        >
          <form>
            <FormField
              label={t('dialog.donationMatchRequestVerifyUnverifiedOrg.form.country.label')}
              status={errors.countryCode && touched.countryCode ? 'danger' : 'default'}
              statusMessage={errors.countryCode}
              data-testid="selectCountry"
              necessity="required"
            >
              <Select
                placeholder={t('dialog.donationMatchRequestVerifyUnverifiedOrg.form.country.placeholder')}
                searchable={false}
                options={countriesOptions}
                defaultValue={countriesOptions.find(
                  ({ value, label }) =>
                    value === values?.countryCode && {
                      label,
                      value
                    }
                )}
                onChange={event => {
                  setFieldValue('countryCode', event.value)
                }}
              />
            </FormField>
            <Spacer size={4} axis="vertical" />
            <FormField
              label={t('dialog.donationMatchRequestVerifyUnverifiedOrg.form.registry.label')}
              status={errors.registryName && touched.registryName ? 'danger' : 'default'}
              statusMessage={errors.registryName}
              data-testid="selectRegistry"
              necessity="required"
            >
              <Select
                placeholder={t('dialog.donationMatchRequestVerifyUnverifiedOrg.form.registry.placeholder')}
                onChange={e => {
                  setFieldValue('registryName', e.value)
                  setSelectedValue({
                    label: e.label,
                    value: e.value
                  })
                }}
                options={registriesOptions}
                defaultValue={selectedValue}
              />
            </FormField>
            <Spacer size={4} axis="vertical" />
            <FormField
              label={t('typography.registryId')}
              data-testid="registryId"
              status={errors.registryId && touched.registryId ? 'danger' : 'default'}
              statusMessage={errors.registryId}
              necessity="required"
            >
              <TextInput
                name="registryId"
                value={values.registryId}
                placeholder={t('dialog.donationMatchRequestVerifyUnverifiedOrg.form.registryId.placeholder')}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </FormField>
            <Spacer size={4} axis="vertical" />
            <FormField
              label={t('dialog.donationMatchRequestVerifyUnverifiedOrg.form.legalName.label')}
              status={touched.name && errors.name ? 'danger' : 'default'}
              statusMessage={errors.name}
              data-testid="legalName"
              necessity="required"
            >
              <TextInput
                name="name"
                value={values.name}
                placeholder={t('dialog.donationMatchRequestVerifyUnverifiedOrg.form.legalName.placeholder')}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </FormField>
            <Spacer size={4} axis="vertical" />
            <FormField
              label={t('dialog.donationMatchRequestVerifyUnverifiedOrg.form.website.label')}
              status={touched.website && errors.website ? 'danger' : 'default'}
              statusMessage={errors.website}
              data-testid="website"
              necessity="required"
            >
              <TextInput
                name="website"
                value={values.website}
                placeholder={t('dialog.donationMatchRequestVerifyUnverifiedOrg.form.website.placeholder')}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </FormField>
          </form>
        </ActionModal>
      </Modal>
    )
  )
}
