import { Box, DialogContent, Typography } from '@material-ui/core'
import { FormEvent } from 'react'
import { useTranslation } from 'react-i18next'

import { DisbursementActionModalKind } from '../disbursementDetails/DisbursementDetailsTable/DisbursementDetailsTable'

import { Dialog } from '@percent/admin-dashboard/common/components'
import { Button } from '@percent/lemonade'
import { MarkAsApprovedOrRemoveFromBatchModalProps } from './MarkAsApprovedOrRemoveFromBatchModal.types'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { useToast } from '@percent/admin-dashboard/containers/toast/ToastContext'
import styles from './MarkAsApprovedOrRemoveFromBatchModal.module.scss'

export function MarkAsApprovedOrRemoveFromBatchModal({
  isOpen,
  onClose,
  disbursementId,
  organisationName,
  refreshData,
  actionType
}: MarkAsApprovedOrRemoveFromBatchModalProps) {
  const { t } = useTranslation()
  const { disbursementsService } = useServices()
  const addToast = useToast()

  const markAsApproved = async () => {
    try {
      await disbursementsService.markDisbursementAsApproved(disbursementId)
      addToast(t('toast.approveDisbursementSuccess'), 'success')
      refreshData?.()
      onClose()
    } catch {
      addToast(t('toast.approveDisbursementError'), 'error')
    }
  }

  const removeFromBatch = async () => {
    try {
      await disbursementsService.cancelDisbursement(disbursementId)
      addToast(t('toast.removeFromBatchSuccess'), 'success')
      refreshData?.()
      onClose()
    } catch {
      addToast(t('toast.removeFromBatchError'), 'error')
    }
  }

  const getAction = (type: DisbursementActionModalKind) => {
    switch (type) {
      case DisbursementActionModalKind.MARK_AS_APPROVED:
        return markAsApproved()
      default:
        return removeFromBatch()
    }
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    getAction(actionType)
  }

  const getActionTypeName = (type: DisbursementActionModalKind) => {
    switch (type) {
      case DisbursementActionModalKind.MARK_AS_APPROVED:
        return 'markAsApproved'
      default:
        return 'removeFromBatch'
    }
  }

  return (
    <Dialog headerTitle={t(`dialog.${getActionTypeName(actionType)}.header`)} openModal={isOpen} onClose={onClose}>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Typography variant="body2">
            {t(`dialog.${getActionTypeName(actionType)}.info`, {
              foundationName: organisationName
            })}
          </Typography>
          <Box className={styles.buttonsWrapper}>
            <Button size="large" type="submit">
              {t(`button.${getActionTypeName(actionType)}`)}
            </Button>
            <Button onPress={onClose} variant="secondary" size="large" type="button">
              {t('button.cancel')}
            </Button>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  )
}
