import { Icon } from '@percent/lemonade'
import { SearchableSelectIconProps } from './SearchableSelectIcon.types'
import styles from './SearchableSelectIcon.module.scss'

export function SearchableSelectIcon({ isActive, useFilterIcon }: SearchableSelectIconProps) {
  return (
    <span
      className={styles.iconWrapper}
      style={{
        marginTop: useFilterIcon ? 0 : '-4px',
        marginRight: useFilterIcon ? 0 : '6px'
      }}
    >
      <Icon
        name={useFilterIcon ? 'dropdown-arrow-down' : 'chevron-down'}
        size={useFilterIcon ? 6 : 4}
        color={!useFilterIcon ? 'gray600' : isActive ? 'white' : 'primary'}
      />
    </span>
  )
}
