import React from 'react'
import { Typography } from '@material-ui/core'

import NoResultTableImage from '@percent/admin-dashboard/common/assets/images/noResultTable.png'
import NoResultsFromSearchImage from '@percent/admin-dashboard/common/assets/images/noResultsFromSearch.png'
import NoPartnersTableImage from '@percent/admin-dashboard/common/assets/images/noPartnersTable.png'
import NoVettingEventsTableImage from '@percent/admin-dashboard/common/assets/images/noVettingEventsTable.png'
import styles from './NoResultTable.module.scss'
import { NoResultTableProps } from './NoResultTable.types'

const tableTypes = [
  {
    type: 'default',
    image: NoResultTableImage
  },
  {
    type: 'validations',
    image: NoResultsFromSearchImage
  },
  {
    type: 'partners',
    image: NoPartnersTableImage
  },
  {
    type: 'vettingEvents',
    image: NoVettingEventsTableImage
  }
]

export function NoResultTable({ text, type, actionsRow }: NoResultTableProps) {
  return (
    <div className={styles.noResultTableContainer}>
      <div className={styles.innerContainer}>
        {(type && <img alt="no result" src={tableTypes.find(tableType => tableType.type === type)?.image} />) || null}
        <Typography variant="h6" color="secondary" className={styles.title}>
          {text}
        </Typography>
        {actionsRow}
      </div>
    </div>
  )
}
