import { useCallback, useEffect, useState } from 'react'
import { AxiosResponse } from 'axios'
import { useTranslation } from 'react-i18next'

import { APIErrorHandler } from '@percent/admin-dashboard/common/library/APIErrorHandler'
import { getQueryParamsFromUrl } from '@percent/admin-dashboard/common/utility/getQueryParamsFromUrl'
import { PBPLinks, PBPListResponse } from '@percent/admin-dashboard/services/pbpResponses'
import {
  UseQueryListReturnType,
  UseQueryListActionsReturnType,
  PaginationProps,
  QueryStatus
} from './useQueryList.types'

export const useQueryList = <A extends PaginationProps, B>(
  api: (_: A) => Promise<AxiosResponse<PBPListResponse<B>>>,
  initialQuery?: A,
  mount = true
): [UseQueryListReturnType<B>, UseQueryListActionsReturnType] => {
  const { t } = useTranslation()
  const [body, setBody] = useState<PBPListResponse<B> | null>(null)
  const data = body?.data || null
  const [queryStatus, setQueryStatus] = useState<QueryStatus>(QueryStatus.LOADING)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [status, setStatus] = useState<null | number>(null)
  const [paginationLinks, setPaginationLinks] = useState<PBPLinks | null>(null)
  const [totalPages, setTotalPages] = useState(0)
  const [totalResults, setTotalResultsNumber] = useState(0)
  const [onMount, setOnMount] = useState(mount)
  const [queryParams, setQueryParams] = useState<A>(initialQuery as A)
  const isLoading = queryStatus === QueryStatus.LOADING
  const error = queryStatus === QueryStatus.ERROR

  const query = useCallback((newQueryParams: A) => {
    setOnMount(true)
    setQueryParams(newQueryParams)
  }, [])
  const refresh = useCallback(() => {
    setQueryParams({ ...queryParams })
  }, [queryParams])
  const refreshToFirstPage = useCallback(() => {
    const { cursor, page, ...refreshParams } = queryParams as { [key: string]: string | number }
    setQueryParams(refreshParams as A)
  }, [queryParams])

  const nextPage = useCallback(() => {
    if (paginationLinks?.next) {
      setQueryParams({ ...queryParams, ...getQueryParamsFromUrl(paginationLinks.next) })
    }
  }, [paginationLinks?.next, queryParams])
  const previousPage = useCallback(() => {
    if (paginationLinks?.prev) {
      setQueryParams({ ...queryParams, ...getQueryParamsFromUrl(paginationLinks.prev) })
    }
  }, [paginationLinks?.prev, queryParams])

  const fetchData = async () => {
    let didCancel = false
    setQueryStatus(QueryStatus.LOADING)
    try {
      const { data: responseBody, status: apiStatus } = await api(queryParams)

      if (!didCancel) {
        setTotalPages(responseBody.totalResults ? Math.ceil(responseBody.totalResults / responseBody.pageSize) - 1 : 0)
        setTotalResultsNumber(responseBody?.totalResults)

        if (apiStatus === 204 || responseBody?.data?.length === 0) {
          setErrorMessage(t('errorMessage.noResult'))
        } else {
          // eslint-disable-next-line no-underscore-dangle
          setPaginationLinks(responseBody._links)
        }
        setBody(responseBody)
        setStatus(apiStatus)
        setQueryStatus(QueryStatus.SUCCESS)
        setErrorMessage(null)
      }
    } catch (err: any) {
      if (!didCancel) {
        setErrorMessage(t(APIErrorHandler(err?.response?.data?.error)))
        setQueryStatus(QueryStatus.ERROR)
      }
    }

    return () => {
      didCancel = true
    }
  }

  useEffect(() => {
    if (onMount) {
      fetchData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams])

  const responseResultsInfo = {
    queryStatus,
    isLoading,
    error,
    queryParams,
    totalPages,
    status,
    totalResults,
    errorMessage,
    data,
    dataOrNull: data || null,
    errorMessageOrNull: errorMessage || null
  } as UseQueryListReturnType<B>

  const responseActions = {
    query,
    nextPage: paginationLinks?.next ? nextPage : null,
    previousPage: paginationLinks?.prev ? previousPage : null,
    refresh,
    refreshToFirstPage
  } as UseQueryListActionsReturnType

  return [responseResultsInfo, responseActions]
}
