import { DecodedValueMap, SetQuery } from 'use-query-params'

import { Services } from '@percent/admin-dashboard/api/actions'
import { DonationEnhanced } from '@percent/admin-dashboard/api/actions/donations/donations.types'
import { DonationsTableQueryParams } from '@percent/admin-dashboard/common/queryParams/queryParams.types'

export enum DonationsStatusFilter {
  ALL,
  ACTIVE,
  REQUESTED_PAYMENT,
  RECEIVED_PAYMENT,
  SETTLED,
  DISBURSED,
  CANCELLED
}

export type DonationsTableProps = {
  isLoading: boolean
  totalResults: number
  data: DonationEnhanced[] | null
  previousPage: (() => void) | null
  nextPage: (() => void) | null
  refreshToFirstPage: () => void
  errorMessage: string | null
  query: (..._: Parameters<Services['donationsService']['getDonations']>) => void
  queryParams: DecodedValueMap<DonationsTableQueryParams>
  setQueryParams: SetQuery<DonationsTableQueryParams>
}

export type UploadError = {
  message: string
  reasons: string[]
}
