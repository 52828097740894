import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useDownloadStringFile } from '@percent/admin-dashboard/common/hooks/useDownloadStringFile/useDownloadStringFile'
import { ReactComponent as Download } from '@percent/admin-dashboard/common/assets/images/download.svg'
import { PercentButton } from '@percent/admin-dashboard/common/components'
import { useAuthState } from '@percent/admin-dashboard/common/hooks'
import styles from './SaveBackupCode.module.scss'
import { SaveBackupCodeStepProps } from './SaveBackupCode.types'
import { Copy } from '@percent/lemonade'

export function SaveBackupCode({ otpCode = '' }: SaveBackupCodeStepProps) {
  const { isAuthorised } = useAuthState()
  const { push } = useHistory()
  const { t } = useTranslation()
  const downloadCode = useDownloadStringFile(otpCode, 'otp-backup.txt')

  const handleDoneClick = () => {
    if (isAuthorised) {
      push('/organizations')
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <h1 className={styles.header}>{t('2fa.saveBackupCode')}</h1>
        <p className={styles.subtext}>{t('2fa.saveBackupCodeSubtext')}</p>
        <PercentButton className={styles.button} title={otpCode} disabled />
        <div className={styles.actionWrapper}>
          <div className={styles.copyWrapper}>
            <Copy text={otpCode} />
            <p className={styles.actionText}>{t('typography.copy')}</p>
          </div>
          <div className={styles.downloadWrapper}>
            <Download className={styles.download} onClick={downloadCode} />
            <p className={styles.actionText}>{t('typography.download')}</p>
          </div>
        </div>
        <div className={styles.separator} />
        <PercentButton className={styles.doneButton} onClick={handleDoneClick} title={t('button.done')} type="button" />
      </div>
    </div>
  )
}
