import { PBPListResponse, PBPGetResponse } from '../../../services/pbpResponses'

import {
  CreateMilestoneProps,
  PatchMilestoneProps,
  Milestone,
  MilestonesCapabilities,
  GetMilestonesProps,
  DeleteMilestoneProps
} from './milestonesService.types'

export const milestonesService = ({ percentClient }: MilestonesCapabilities) => ({
  getMilestones: (id: string) => (params: GetMilestonesProps) =>
    percentClient.get<PBPListResponse<Milestone>>(`/v1/organisations/${id}/milestones`, {
      params
    }),
  createMilestone: ({ id, ...postParams }: CreateMilestoneProps) =>
    percentClient.post<PBPGetResponse<Milestone>>(`/v1/organisations/${id}/milestones`, postParams),
  patchMilestone: ({ organisationId, id, ...patchParams }: PatchMilestoneProps) =>
    percentClient.patch<PBPGetResponse<Milestone>>(`/v1/organisations/${organisationId}/milestones/${id}`, patchParams),
  deleteMilestone: ({ organisationId, id }: DeleteMilestoneProps) =>
    percentClient.delete<PBPGetResponse<Milestone>>(`/v1/organisations/${organisationId}/milestones/${id}`)
})
