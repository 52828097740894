import { TableCell, TableRow } from '@material-ui/core'
import React from 'react'
import { useHistory } from 'react-router-dom'

import { useNonprofitsData } from '../../Layout/nonprofitsContext/useNonprofitsData/useNonprofitsData'

import { ReactComponent as DropdownSVG } from '@percent/admin-dashboard/common/assets/images/dropdown.svg'
import { getDate } from '@percent/admin-dashboard/common/utility/getDate'
import { Validation } from '@percent/admin-dashboard/api/types'
import { TimeLeft } from '@percent/admin-dashboard/common/components'
import { CountryCodeFlag } from '@percent/admin-dashboard/common/components/countryCodeFlag/CountryCodeFlag'
import styles from './ValidationPendingRow.module.scss'

type ValidationPendingRowProps = {
  row: Validation
}

export function ValidationPendingRow({ row }: ValidationPendingRowProps) {
  const { push } = useHistory()
  const { setValidation } = useNonprofitsData()
  const validationRowClick = (validation: Validation) => {
    setValidation(validation)
    push(`/validations/${validation.id}`)
  }

  return (
    <TableRow key={row.id} onClick={() => validationRowClick(row)} className={styles.row}>
      <CountryCodeFlag countryCode={row.countryCode} />
      <TableCell>{row.name}</TableCell>
      <TableCell>{row.partner.name ?? ''}</TableCell>
      <TableCell align="right" className={styles.rowNoWrap} style={{ padding: '16px 50px 16px 0', textAlign: 'left' }}>
        {getDate(row.createdAt)}
      </TableCell>
      <TableCell align="right" className={styles.rowNoWrap} style={{ padding: '16px 50px 16px 0', textAlign: 'left' }}>
        <TimeLeft requestedDate={row.createdAt} />
      </TableCell>
      <TableCell align="right" className={styles.rowIconForward} style={{ padding: '0' }}>
        <DropdownSVG className={styles.arrow} />
      </TableCell>
    </TableRow>
  )
}
