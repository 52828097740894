import React, { useCallback, useState } from 'react'
import { FormikProvider, useFormik } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'

import { useCountries, useDidMountEffect, useMutation } from '@percent/admin-dashboard/common/hooks'
import {
  AcknowledgeModal,
  FileInput,
  FormField,
  Select,
  Spacer,
  TextInput,
  Modal,
  ActionModal,
  Text
} from '@percent/lemonade'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { RegistriesType } from '@percent/admin-dashboard/api/types'
import { APIErrorHandler } from '@percent/admin-dashboard/common/library/APIErrorHandler'
import { ErrorView } from '@percent/admin-dashboard/common/components'
import { AddOrganizationDetailsModalProps } from './AddOrganizationModal.types'
import { isValidWebsiteURL, sanitizeStringToRegex } from '@percent/utility'
import { SelectOption } from 'libs/shared/ui-lemonade/src/components/select/option.types'
import { useFeatureFlag } from '@percent/admin-dashboard/common/hooks/useFeatureFlag/useFeatureFlag'
import { mapCountriesToSelectOptions } from '@percent/admin-dashboard/common/utility/mapCountriesToSelectOptions'

export function AddOrganizationModal({ open, onClose, refresh }: AddOrganizationDetailsModalProps) {
  const { t } = useTranslation()
  const { adminService, registriesService } = useServices()
  const [errorDialogState, setErrorDialogState] = useState(false)
  const [successDialogState, setSuccessDialogState] = useState(false)
  const countries = useCountries()
  const [registryData, setRegistryData] = useState<RegistriesType[] | null>(null)
  const [isRegistryDataError, setIsRegistryDataError] = useState('')
  const [selectedValue, setSelectedValue] = useState<SelectOption>({
    label: '',
    value: ''
  })
  const [docProofErrorMessage, setDocProofErrorMessage] = useState('')
  const { addOrgFileFeatureFlag } = useFeatureFlag()

  const getRegistryData = useCallback(
    (countryCode: string) =>
      registriesService
        .getRegistriesList({ countryCode })
        .then(regData => {
          setRegistryData(regData?.data?.data)
        })
        .catch(error => {
          setIsRegistryDataError(APIErrorHandler(error?.response?.data?.error))
        }),
    [registriesService]
  )

  const [{ isLoading }, { apiFunc }] = useMutation(
    adminService.commands.createOrganization,
    () => {
      setSuccessDialogState(true)
    },
    () => {
      setErrorDialogState(true)
    }
  )

  const mapRegistries = (registries?: RegistriesType[] | null) => {
    return registries
      ? registries?.map(registry => {
          return {
            label: registry.englishName || registry.name,
            value: registry.name
          }
        })
      : []
  }

  const formik = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: {
      registryName: '',
      registryId: '',
      name: '',
      website: '',
      countryCode: '',
      documentProof: null
    },
    validationSchema: () =>
      Yup.lazy(value => {
        const newRegex =
          value.registryName &&
          registryData?.find(registry => registry.name === value?.registryName)?.registryIdFormatRegex

        return Yup.object().shape({
          name: Yup.string()
            .max(255, t('errorMessage.orgFieldCharacterLimit', { fieldName: 'Legal name' }))
            .min(1, t('errorMessage.orgFieldCharacterLimit', { fieldName: 'Legal name' }))
            .trim()
            .required(t('errorMessage.required')),
          countryCode: Yup.string().required(),
          registryName: Yup.string().required(t('errorMessage.required')),
          registryId: Yup.string()
            .max(255, t('errorMessage.orgFieldCharacterLimit', { fieldName: 'Registry ID' }))
            .min(1, t('errorMessage.orgFieldCharacterLimit', { fieldName: 'Registry ID' }))
            .trim()
            .required(t('errorMessage.required'))
            .matches(sanitizeStringToRegex(newRegex || ''), t('errorMessage.wrongFormatRegistryId')),
          website: isValidWebsiteURL()
            .nullable()
            .trim()
            .required(t('errorMessage.required'))
            .min(1, t('errorMessage.orgFieldCharacterLimit', { fieldName: 'Website' }))
            .max(255, t('errorMessage.orgFieldCharacterLimit', { fieldName: 'Website' }))
        })
      }),
    onSubmit: async ({ registryName, registryId, name, website, countryCode, documentProof }) => {
      const validationRequest = {
        registryName,
        registryId,
        name,
        website: (!/^https?:\/\//i.test(website) && `https://${website}`) || website,
        countryCode
      }

      const formData = new FormData()

      if (documentProof) {
        const imageData = documentProof
        formData.append('file', imageData)
      }
      formData.append('validationRequest', JSON.stringify(validationRequest))

      await apiFunc({
        payload: {
          formData
        }
      })
    }
  })

  const { errors, values, touched, dirty, handleChange, setFieldValue, handleBlur, handleSubmit, isValid, resetForm } =
    formik

  const handleCloseBtn = () => {
    onClose()
    refresh()
    resetForm()
    setSelectedValue({
      label: '',
      value: ''
    })
    setSuccessDialogState(false)
    setDocProofErrorMessage('')
  }

  const handleCloseModal = () => {
    onClose()
    resetForm()
    setSelectedValue({
      label: '',
      value: ''
    })
    setDocProofErrorMessage('')
  }

  const handleOnErrorClose = () => {
    setErrorDialogState(false)
  }

  const registriesOptions = mapRegistries(registryData)
  const countriesOptions = mapCountriesToSelectOptions(countries)

  const successModal = successDialogState && (
    <AcknowledgeModal
      result="positive"
      title={t('dialog.addOrganization.success.title')}
      buttonText={t('button.close')}
      handleClose={handleCloseBtn}
    >
      <Text size="small">
        {t('dialog.addOrganization.success.text.addedValidation')}
        <b> {values.name} </b>
        {t('dialog.addOrganization.success.text.validationTab')}
      </Text>
    </AcknowledgeModal>
  )

  const errorModal = errorDialogState && (
    <AcknowledgeModal
      result="negative"
      title={t('dialog.addOrganization.error.title')}
      description={t('dialog.addOrganization.error.description')}
      buttonText={t('button.close')}
      handleClose={handleOnErrorClose}
    />
  )

  useDidMountEffect(() => {
    if (values.countryCode) {
      getRegistryData(values?.countryCode)
      setSelectedValue({
        label: '',
        value: ''
      })
      setFieldValue('registryName', '')
    }
  }, [values.countryCode])

  if (isRegistryDataError) {
    return <ErrorView errorMessage={isRegistryDataError} />
  }

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="add-organization-form-modal">
      {successModal || errorModal || (
        <ActionModal
          title={t('button.addOrganization')}
          primaryButtonText={t('button.saveChanges')}
          secondaryButtonText={t('button.cancel')}
          type="submit"
          variant={isValid && dirty && !docProofErrorMessage ? 'primary' : 'secondary'}
          disabled={!(isValid && dirty && !isLoading && !docProofErrorMessage)}
          loading={isLoading}
          handleClose={handleCloseModal}
          handleSubmit={handleSubmit}
          primaryBtnTestId="add-organisation-submit-button"
          secondaryBtnTestId="add-organisation-cancel-button"
        >
          <form onSubmit={handleSubmit}>
            <FormikProvider value={formik}>
              <Spacer size={4} axis="vertical" />
              <>
                <FormField
                  label={t('dialog.addRegistry.legalNameLabel')}
                  status={touched.name && errors.name ? 'danger' : 'default'}
                  statusMessage={errors.name}
                  data-testid="legalName"
                  necessity="required"
                >
                  <TextInput
                    name="name"
                    value={values.name}
                    placeholder={t('dialog.addOrganization.form.name.placeholder')}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </FormField>
                <Spacer size={4} axis="vertical" />
                <FormField
                  label={t('typography.country')}
                  status={errors.countryCode && touched.countryCode ? 'danger' : 'default'}
                  statusMessage={errors.countryCode}
                  data-testid="selectCountry"
                  necessity="required"
                >
                  <Select
                    placeholder={t('dialog.addOrganization.form.country.placeholder')}
                    searchable={false}
                    options={countriesOptions}
                    defaultValue={countriesOptions.find(
                      ({ value, label }) =>
                        value === values?.countryCode && {
                          label,
                          value
                        }
                    )}
                    onChange={event => {
                      setFieldValue('countryCode', event.value)
                    }}
                  />
                </FormField>
                <Spacer size={4} axis="vertical" />
                <FormField
                  label={t('dialog.approveValdiationRequest.selectRegistry')}
                  status={errors.registryName && touched.registryName ? 'danger' : 'default'}
                  statusMessage={errors.registryName}
                  data-testid="selectRegistry"
                  necessity="required"
                >
                  <Select
                    placeholder={t('dialog.approveValdiationRequest.selectRegistry')}
                    onChange={e => {
                      setFieldValue('registryName', e.value)
                      setSelectedValue({
                        label: e.label,
                        value: e.value
                      })
                    }}
                    options={registriesOptions}
                    defaultValue={selectedValue}
                  />
                </FormField>
                <Spacer size={4} axis="vertical" />
                <FormField
                  label={t('typography.registryId')}
                  data-testid="registryId"
                  status={errors.registryId && touched.registryId ? 'danger' : 'default'}
                  statusMessage={errors.registryId}
                  necessity="required"
                >
                  <TextInput
                    name="registryId"
                    value={values.registryId}
                    placeholder={t('dialog.addOrganization.form.registryId.placeholder')}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </FormField>
                <Spacer size={4} axis="vertical" />
                <FormField
                  label={t('typography.website')}
                  status={touched.website && errors.website ? 'danger' : 'default'}
                  statusMessage={errors.website}
                  data-testid="website"
                  necessity="required"
                >
                  <TextInput
                    name="website"
                    value={values.website}
                    placeholder={t('dialog.addOrganization.form.website.placeholder')}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </FormField>
                {addOrgFileFeatureFlag && (
                  <>
                    <Spacer size={4} axis="vertical" />
                    <FileInput
                      dataTestId="fileUpload"
                      label="Document proof"
                      errorMessage={docProofErrorMessage}
                      file={values.documentProof}
                      onChange={file => {
                        setFieldValue('documentProof', file)
                        setDocProofErrorMessage('')

                        if (!/(png|jpeg|pdf)/i.exec(file?.type)) {
                          setDocProofErrorMessage(t('errorMessage.invalidFileFormat'))
                        }

                        if (file?.size > 5 * 1024 * 1024) {
                          setDocProofErrorMessage(t('errorMessage.fileSizeTooLarge', { number: '5' }))
                        }
                      }}
                    />
                  </>
                )}
              </>
            </FormikProvider>
          </form>
        </ActionModal>
      )}
    </Modal>
  )
}
