import { Alpha3Code } from 'i18n-iso-countries'

export const mockOrganisation = {
  address: '90 Test Avenue',
  addressLine1: '90 Test Avenue',
  addressLine2: '',
  city: 'London',
  state: '',
  country: 'United Kingdom',
  categories: [],
  claimedAt: null,
  countryCode: 'GBR' as Alpha3Code,
  createdAt: '2021-09-28T07:55:10.752Z',
  currencyCode: 'GBR' as Alpha3Code,
  description: 'ORGANISATION NAME SUMMER WINTER',
  displayName: null,
  id: 'staging_organisation_000000CEHXJesS3T2OZFS7ESLR9aj',
  images: ['logo.png'],
  logo: null,
  milestones: [],
  name: 'ORGANISATION NAME SUMMER WINTER',
  postcode: 'E20 1JQ',
  registrationActive: true,
  registrationDate: null,
  registry: 'staging_registry_000000CWpCgRc8mJXe53O6Eexsxpw',
  registryName: 'Test Reg',
  registryId: 'nm_ri524',
  trustees: null,
  type: 'nonprofit',
  website: 'https://www.poweredbypercent.com',
  websites: ['https://www.poweredbypercent.com'],
  email: null,
  donated: 0
}

export const mockOrganisationRegistrationDeactivate = {
  ...mockOrganisation,
  registrationActive: false
}

export const mockOrganisationResponse = {
  data: {
    data: mockOrganisation,
    object: 'organisation_admin'
  }
}

export const mockClaimedOrg = {
  ...mockOrganisation,
  claimedAt: '2021-11-28T07:55:10.752Z'
}
