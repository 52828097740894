import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { User } from '@percent/admin-dashboard/context/auth/authContext/AuthContext.types'
import { useAuthDispatch, useAuthState } from '@percent/admin-dashboard/common/hooks'
import { SET_AUTHORISED } from '@percent/admin-dashboard/context/auth/authReducer/authReducer'
import { Wizard } from './Wizard/Wizard'
import { SetupQRStepContainer } from './SetupQRStep/SetupQRStepContainer'
import { ConfirmationCodeStepContainer } from './ConfirmationCodeStep/ConfirmationCodeStepContainer'
import { SaveBackupCodeStep } from './SaveBackupCodeStep/SaveBackupCodeStep'
import { SetupComplete } from './SetupComplete/SetupComplete'
import styles from './TwoFactorAuthenticationSetup.module.scss'

export function TwoFactorAuthenticationSetup() {
  const { state } = useLocation()
  const { isAuthorised } = useAuthState()
  const { push } = useHistory()
  const { t } = useTranslation()
  const dispatch = useAuthDispatch()
  const [userData, setUserData] = useState<undefined | User>(undefined)

  const pushToDashboard = () => push('/organizations')

  const logInToApp = () => {
    localStorage.authState = JSON.stringify({
      isAuthorised: true,
      isAuthorising: false
    })

    dispatch({
      type: SET_AUTHORISED,
      user: userData
    })

    pushToDashboard()
  }

  useEffect(() => {
    if (isAuthorised && !state.otpData) {
      pushToDashboard()
    }
  }, [isAuthorised, push, state])

  const steps = [
    {
      title: t('2fa.firstStepTitle'),
      step: <SetupQRStepContainer />
    },
    {
      title: t('2fa.secondStepTitle'),
      step: <ConfirmationCodeStepContainer setUserData={setUserData} />
    },
    {
      title: t('2fa.thirdStepTitle'),
      step: <SaveBackupCodeStep otpCode={userData?.twoFaBackupCode} />
    }
  ]

  useEffect(() => {
    if (!state?.password && !state?.otpData) {
      push('/login')
    }
  }, [state, push])

  return (
    <div className={styles.container}>
      <Wizard steps={steps}>
        <SetupComplete logInToApp={logInToApp} />
      </Wizard>
    </div>
  )
}
