import React, { ReactNode, useMemo, useState } from 'react'

import { BadgeTotalContext } from '../BadgeTotalContext'

const initialState = {
  totalPendingClaims: 0,
  totalUnenrolledNonprofits: 0,
  totalPendingValidations: 0,
  totalPendingActivity: 0,
  totalPendingMonititoring: 0,
  totalPendingAgentVerification: 0,
  totalPendingEligibility: 0,
  totalPendingBankDetails: 0
}

export function BadgeTotalContextController({ children }: { children: ReactNode }) {
  const [totalNumber, setTotalNumber] = useState(initialState)
  const badgeTotalContextValue = useMemo(
    () => ({
      totalNumber,
      setTotalNumber
    }),
    [totalNumber]
  )

  return <BadgeTotalContext.Provider value={badgeTotalContextValue}>{children}</BadgeTotalContext.Provider>
}
