import React, { useState } from 'react'
import { FormikProvider, useFormik } from 'formik'
import { useParams } from 'react-router-dom'
import { TypeOf } from 'yup'
import { useTranslation } from 'react-i18next'

import { validationSchema } from '../ModalHelpers'

import { PatchMilestoneProps } from '@percent/admin-dashboard/api/actions/milestones/milestonesService.types'
import { useMutation } from '@percent/admin-dashboard/common/hooks'
import { floatToIntegerCurrency } from '@percent/admin-dashboard/common/utility/utility'
import { FieldInputCurrency, Loader } from '@percent/admin-dashboard/common/components'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { EditMilestoneModalProps } from './EditMilestoneModal.types'
import { AcknowledgeModal, ActionModal, FormField, Modal, TextArea } from '@percent/lemonade'

export function EditMilestoneModal({
  openModal,
  onClose,
  milestoneId,
  milestoneAmount,
  milestoneReward,
  currencyCode,
  refresh
}: EditMilestoneModalProps) {
  const { id: organisationId } = useParams<{ id: string }>()
  const { milestonesService } = useServices()
  const [successModalState, setSuccessModalState] = useState(false)
  const [errorModalState, setErrorModalState] = useState(false)
  const [{ isLoading }, { apiFunc: patchMilestone }] = useMutation(
    milestonesService.patchMilestone,
    () => {
      setSuccessModalState(true)
    },
    () => {
      setErrorModalState(true)
    }
  )
  const { t } = useTranslation()
  const handleCancelForm = () => {
    setErrorModalState(false)
    onClose()
  }

  const handleCloseForm = () => {
    refresh()
    resetForm()
    onClose()
    setSuccessModalState(false)
  }

  const formik = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: {
      amount: (milestoneAmount / 100).toFixed(2),
      reward: milestoneReward
    },
    validationSchema,
    onSubmit: submitValues => {
      const { amount: inputAmount, reward } = submitValues as unknown as TypeOf<typeof validationSchema>

      const amount = floatToIntegerCurrency(inputAmount)
      patchMilestone(buildShoppingListItem(organisationId as string, amount, reward))
    }
  })

  const { errors, values, setFieldValue, handleSubmit, handleBlur, resetForm, touched, handleChange, isValid, dirty } =
    formik

  const buildShoppingListItem = (orgId: string, amount: number, reward: string): PatchMilestoneProps => {
    return {
      organisationId: orgId,
      id: milestoneId,
      amount,
      reward
    }
  }

  const successModal = successModalState && (
    <AcknowledgeModal
      result="positive"
      title={t('dialog.milestone.success.title', { milestoneAction: 'updated' })}
      buttonText={t('button.close')}
      handleClose={handleCloseForm}
    />
  )

  const errorModal = errorModalState && (
    <AcknowledgeModal
      result="negative"
      title={t('dialog.milestone.error.title', { milestoneAction: 'updated' })}
      description={t('dialog.milestone.error.description', { milestoneAction: 'update' })}
      buttonText={t('button.close')}
      handleClose={handleCancelForm}
    />
  )

  if (isLoading) {
    return <Loader />
  }

  return (
    <Modal open={openModal} onClose={onClose}>
      {successModal || errorModal || (
        <ActionModal
          title={t('typography.editMilestone')}
          primaryButtonText={t('button.saveChanges')}
          secondaryButtonText={t('button.cancel')}
          type="submit"
          variant={isValid && dirty ? 'primary' : 'secondary'}
          disabled={!(isValid && dirty && !isLoading)}
          loading={isLoading}
          handleClose={handleCloseForm}
          handleSubmit={handleSubmit}
          primaryBtnTestId="edit-milestone-submit-button"
          secondaryBtnTestId="edit-milestone-cancel-button"
          aria-labelledby="edit-milestone-form-modal"
        >
          {/* <form onSubmit={handleSubmit}> */}
          <form>
            <FormikProvider value={formik}>
              <FieldInputCurrency
                name="amount"
                label={t('form.itemAmount')}
                placeHolder={t('form.itemAmountPlaceholder')}
                value={values.amount}
                onChange={(a: string) => setFieldValue('amount', a)}
                handleBlur={handleBlur}
                error={errors.amount}
                addOn={currencyCode}
              />
              <FormField
                label="What can be achieved"
                status={touched.reward && errors.reward ? 'danger' : 'default'}
                statusMessage={errors.reward || ''}
                data-testid="reward"
                necessity="required"
              >
                <TextArea
                  name="reward"
                  value={values.reward}
                  placeholder="Enter what can be achieved with the amount you entered above"
                  maxLength={255}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </FormField>
            </FormikProvider>
          </form>
        </ActionModal>
      )}
    </Modal>
  )
}
