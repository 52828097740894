import React from 'react'

import { services } from '@percent/admin-dashboard/api/actions'
import { capabilities } from '@percent/admin-dashboard/api/capabilities'
import { ServiceContext } from './ServiceContext'

export const appServices = services(capabilities)

export type AppServices = Partial<typeof appServices>

type ServiceContextContainerProps = { children: React.ReactNode }

export function ServiceContextContainer({ children }: ServiceContextContainerProps) {
  return <ServiceContext.Provider value={appServices}>{children}</ServiceContext.Provider>
}
