import { useTranslation } from 'react-i18next'

import { AcknowledgeModal, Modal } from '@percent/lemonade'
import { DonationMatchRequestModalMissingOrgInfoProps } from './DonationMatchRequestModalMissingOrgInfo.types'

export function DonationMatchRequestModalMissingOrgInfo({
  open,
  onClose
}: DonationMatchRequestModalMissingOrgInfoProps) {
  const { t } = useTranslation()

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="donation-match-request-missing-org-info-modal">
      <AcknowledgeModal
        result="negative"
        title={t('dialog.missingOrganisationDetails.title')}
        description={t('dialog.missingOrganisationDetails.description')}
        buttonText={t('button.close')}
        handleClose={onClose}
      />
    </Modal>
  )
}
