import {
  adminServiceCapabilities,
  AdminServiceGetPartnerDeveloperListProps,
  AdminServiceGetPartnerListProps,
  AdminServiceGetPartnerScopeListProps,
  GetValidationsRequestProps,
  PatchOrganisationParams,
  FetchOrganisationsParams,
  FetchClaimsParams,
  FetchRegistriesParams,
  FetchVettingEventsParams,
  FetchMonitoringParams,
  PostRejectValidationRequest,
  PatchValidationRequestParams,
  Claim,
  MonitoringList,
  VettingEvent,
  Validation,
  Organisation,
  RegistriesType,
  AdminServiceGetRelatedOrganisationsListProps,
  AdminServiceGetSimilarOrganisationsListProps,
  OrganisationRelationship,
  PostOrganisationLogoProps,
  OrganisationLogoBody,
  CreateOrganizationProps,
  AdminServiceGetValidationSubmissionDocumentsProps,
  ValidationSubmissionDocument,
  GetSignedUrlForDocumentProps,
  GetSignedUrlForDocumentBody,
  SimilarOrganisation,
  PartnerScope,
  SubmitVettingEventsPayload,
  AutomatedVettingEventResults,
  UpdateOrganisationPaymentMethodParams,
  OrganisationsBatchUpdateProps
} from '@percent/admin-dashboard/api/types'
import { config } from '@percent/admin-dashboard/config'
import { CurrencyCode } from '@percent/admin-dashboard/constants/currencyCode'
import { PartnerScopes } from '@percent/admin-dashboard/constants/scopes'
import { PBPGetResponse, PBPListResponse, getDataFromPBPResponse } from '@percent/admin-dashboard/services/pbpResponses'
import { throttleAsync } from '@percent/utility'

export const adminService = ({ percentClient }: adminServiceCapabilities) => ({
  getOrganisation: ({ id }: { id: string }) => percentClient.get(`/v1/admin/organisations/${id}`),
  editOrganisation: ({ payload, id }: PatchOrganisationParams) =>
    percentClient.patch(`/v1/admin/organisations/${id}`, payload),
  getOrganisations: (params: FetchOrganisationsParams) =>
    percentClient.get<PBPListResponse<Organisation>>('/v1/organisations', {
      params,
      withCredentials: false,
      headers: {
        authorization: config.keys.pk
      }
    }),
  getClaims: (params: FetchClaimsParams) => percentClient.get<PBPListResponse<Claim>>('/v1/admin/claims', { params }),
  getOrganisationPaymentMethod: ({ id }: { id: string }) =>
    percentClient.get(`/v1/admin/organisations/${id}/payment-method`, {
      params: {
        timestamp: new Date().getTime()
      }
    }),
  updateOrganisationPaymentMethod: ({ payload, id }: UpdateOrganisationPaymentMethodParams) =>
    percentClient.put(`/v1/admin/organisations/${id}/payment-method`, payload),
  getVettingRequest: ({ id }: { id: string }) => percentClient.get(`/v1/admin/vetting-requests/${id}`),
  getRegistries: (params: FetchRegistriesParams) =>
    percentClient.get<PBPListResponse<RegistriesType>>(`v1/admin/registries`, { params }),
  unreject: ({ id }: { id: string }) => percentClient.patch(`/v1/iam/claim/${id}/unreject`),
  getValidations: throttleAsync(
    (params: GetValidationsRequestProps) =>
      percentClient.get<PBPListResponse<Validation>>('v1/admin/validation-requests', { params }),
    1000,
    { trailing: true, leading: true }
  ),
  getValidationRequest: ({ id }: { id: string }) => percentClient.get(`v1/admin/validation-requests/${id}`),
  editValidationRequest: ({ payload, id }: PatchValidationRequestParams) =>
    percentClient.patch(`/v1/admin/validation-requests/${id}`, payload),
  rejectValidationRequest: ({ payload, id }: { payload: PostRejectValidationRequest; id: string }) =>
    percentClient.post(`v1/admin/validation-requests/${id}/reject`, payload),
  getVettingEvents: (params: FetchVettingEventsParams) =>
    percentClient.get<PBPListResponse<VettingEvent>>('/v1/admin/vetting-events', { params }),
  getVettingEventsResults: ({ organisationId }: { organisationId: string }) =>
    percentClient.get<PBPGetResponse<AutomatedVettingEventResults>>(
      `/v1/admin/vetting-events/results/${organisationId}`
    ),
  submitVettingEvents: ({ organisationId, payload }: { organisationId: string; payload: SubmitVettingEventsPayload }) =>
    percentClient.post(`v1/admin/organisations/${organisationId}/submit-vetting-events`, payload),
  getMonitoringSubscriptions: throttleAsync(
    (params: FetchMonitoringParams) =>
      percentClient.get<PBPListResponse<MonitoringList>>('v1/admin/monitoring', { params }),
    1000,
    { leading: true, trailing: true }
  ),
  getRelatedOrganisations: (params: AdminServiceGetRelatedOrganisationsListProps) =>
    percentClient.get<PBPListResponse<OrganisationRelationship>>(`/v1/admin/validation/related-organisations`, {
      params
    }),
  getSimilarOrganisations: (params: AdminServiceGetSimilarOrganisationsListProps) =>
    percentClient.get<PBPListResponse<SimilarOrganisation>>(`/v1/admin/validation/similar-organisations`, {
      params
    }),
  getValidationSubmissionDocuments: ({
    validationSubmissionId,
    ...params
  }: AdminServiceGetValidationSubmissionDocumentsProps) =>
    percentClient.get<PBPListResponse<ValidationSubmissionDocument>>(
      `/v1/admin/validation-submissions/${validationSubmissionId}/documents`,
      {
        params
      }
    ),
  getSignedUrlForDocument: ({ documentUrl }: GetSignedUrlForDocumentProps) => {
    let url = documentUrl

    /**
     * HACK
     *
     * This is a temporary fix to handle the fact that the backend
     * is returning absolute URLs not matching the current hostname
     * having the effect of breaking the CORS policy.
     */
    if (window.location.hostname.includes('goodstack.io')) {
      url = url.replace('poweredbypercent.com', 'goodstack.io')
    }

    if (window.location.hostname.includes('poweredbypercent.com')) {
      url = url.replace('goodstack.io', 'poweredbypercent.com')
    }

    return percentClient.get<PBPGetResponse<GetSignedUrlForDocumentBody>>(url)
  },
  queries: {
    getPartnerDeveloperList: ({ partnerId, filter, ...params }: AdminServiceGetPartnerDeveloperListProps) =>
      percentClient.get<PBPListResponse<any>>(`/v1/admin/partners/${partnerId}/accounts`, {
        params: {
          ...params,
          ...(filter ? { filter } : {})
        }
      }),
    getPartnerDetail: ({ id }: { id: string }) => percentClient.get(`/v1/admin/partners/${id}`),
    getPartnerList: ({ ...params }: AdminServiceGetPartnerListProps) =>
      percentClient.get<PBPListResponse<any>>('/v1/admin/partners', { params }),
    getPartnerScopeList: ({ partnerId, ...params }: AdminServiceGetPartnerScopeListProps) =>
      percentClient.get<PBPListResponse<PartnerScope>>(`/v1/admin/partners/${partnerId}/scopes`, { params })
  },
  commands: {
    createPartnerDeveloper: ({ payload, partnerId }: { payload: object; partnerId: string }) =>
      percentClient.post(`/v1/admin/partners/${partnerId}/accounts`, payload),
    acceptValidation: ({ payload, validationRequestId }: { payload: object; validationRequestId: string }) =>
      percentClient.post(`v1/admin/validation-requests/${validationRequestId}/accept`, payload),
    deletePartnerDeveloper: ({ id: { partnerId, memberId } }: any) =>
      percentClient.delete(`/v1/admin/partners/${partnerId}/accounts/${memberId}`),
    createPartner: (payload: { name: string; currencyCode: CurrencyCode }) =>
      percentClient.post('/v1/admin/partners', payload),
    deletePartner: ({ id }: { id: string }) => percentClient.delete(`/v1/admin/partners/${id}`),
    addPartnerScope: ({ scope, id }: { scope: PartnerScopes; id: string }) =>
      percentClient.put(`/v1/admin/partners/${id}/scopes/${scope}/add`),
    removePartnerScope: ({ scope, id }: { scope: PartnerScopes; id: string }) =>
      percentClient.put(`/v1/admin/partners/${id}/scopes/${scope}/remove`),
    editPartnerDetail: ({ payload, partnerId }: any) => percentClient.patch(`/v1/admin/partners/${partnerId}`, payload),
    postOrganisationLogo: ({ payload, organisationId }: PostOrganisationLogoProps) =>
      percentClient
        .post<PBPGetResponse<OrganisationLogoBody>>(`/v1/admin/organisations/${organisationId}/logo`, payload.formData)
        .then(getDataFromPBPResponse),
    createOrganization: ({ payload }: CreateOrganizationProps) =>
      percentClient.post(`/v1/admin/validation-requests`, payload.formData),
    unclaimOrganization: (id: string) => percentClient.post(`v1/admin/organisations/${id}/unclaim`),
    organisationsBatchUpdate: ({ payload }: OrganisationsBatchUpdateProps) =>
      percentClient.post(`/v1/admin/organisations/batch-update`, payload)
  }
})
