import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { useBreadcrumbsData } from '../../Layout/breadcrumbsContext/useBreadcrumbsData/useBreadcrumbsData'

import { useQuery, useMutation } from '@percent/admin-dashboard/common/hooks'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { APIErrorHandler } from '@percent/admin-dashboard/common/library/APIErrorHandler'
import { AgentVerificationDetails } from './AgentVerificationDetails'

export function AgentVerificationDetailsContainer({ match }: { match: { params: { id: string } } }) {
  const { agentVerificationService, adminService } = useServices()
  const { id } = match.params
  const [orgData, setOrgData] = useState(null)
  const [partnerData, setPartnerData] = useState(null)
  const [orgPartnerLoader, setOrgPartnerLoader] = useState(true)
  const [orgPartnerError, setOrgPartnerError] = useState('')
  const { setPath } = useBreadcrumbsData()
  const location = useLocation()

  const [{ data, isLoading, errorMessage }, { refresh }] = useQuery(agentVerificationService.getAgentVerification, {
    id
  })

  const [{ isLoading: isApproveLoading, errorMessage: approveErrorMessage }, { apiFunc: aproveEmail }] = useMutation(
    agentVerificationService.approveAgentVerificationEmail
  )

  const getUserData = async (orgId: string, partnerId: string) => {
    setOrgPartnerLoader(true)
    try {
      const {
        data: { data: oData }
      } = await adminService.getOrganisation({ id: orgId })
      const {
        data: { data: pData }
      } = await adminService.queries.getPartnerDetail({ id: partnerId })

      setOrgData(oData)
      setPartnerData(pData)
      setOrgPartnerLoader(false)
    } catch (err) {
      setOrgPartnerError(APIErrorHandler(err?.response?.data?.error))
      setOrgPartnerLoader(false)
    }
  }

  useEffect(() => {
    if (data) {
      getUserData(data.organisationId, data.partnerId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    setPath(location.state || `${data?.status}`)
  }, [location, setPath, data])

  return (
    <AgentVerificationDetails
      orgData={orgData}
      partnerData={partnerData}
      orgPartnerLoader={orgPartnerLoader}
      orgPartnerError={orgPartnerError}
      data={data}
      isLoading={isLoading || isApproveLoading}
      errorMessage={errorMessage || approveErrorMessage}
      refresh={refresh}
      aproveEmail={aproveEmail}
    />
  )
}
