import React, { useEffect } from 'react'
import { useLocation } from 'react-router'

import { useMutation } from '@percent/admin-dashboard/common/hooks'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { SetupQRStep } from './SetupQRStep'

export function SetupQRStepContainer() {
  const { authService } = useServices()
  const { state } = useLocation()

  const [{ isLoading, data }, { apiFunc: generateQRToken }] = useMutation(authService.generateQRCode)
  useEffect(() => {
    if (!state.otpData?.otpKey) generateQRToken({ password: state?.password })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <SetupQRStep
      isLoading={isLoading}
      token={state.otpData?.otpKey ?? data?.data?.data?.otpKey}
      tokenUri={state.otpData?.otpKeyUri ?? data?.data?.data?.otpKeyUri}
    />
  )
}
