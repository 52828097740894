import React, { useState } from 'react'
import { useFormik } from 'formik'
import { useParams } from 'react-router-dom'
import { TypeOf } from 'yup'
import { useTranslation } from 'react-i18next'

import { MilestoneModalProps, validationSchema } from '../ModalHelpers'

import { CreateMilestoneProps } from '@percent/admin-dashboard/api/actions/milestones/milestonesService.types'
import { useMutation } from '@percent/admin-dashboard/common/hooks'
import { floatToIntegerCurrency } from '@percent/admin-dashboard/common/utility/utility'
import { Loader, FieldInputCurrency } from '@percent/admin-dashboard/common/components'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { AcknowledgeModal, ActionModal, FormField, Modal, Text, TextArea } from '@percent/lemonade'
import { Currency, getCurrencySymbol } from '@percent/admin-dashboard/common/currency/CurrencyEnum'

export function CreateMilestoneModal({ currencyCode, openModal, onClose, refresh, countryCode }: MilestoneModalProps) {
  const { id: organisationId } = useParams<{ id: string }>()
  const { milestonesService } = useServices()
  const [successModalState, setSuccessModalState] = useState(false)
  const [errorDialogState, setErrorDialogState] = useState(false)
  const [{ isLoading }, { apiFunc: createMilestone }] = useMutation(
    milestonesService.createMilestone,
    () => {
      setSuccessModalState(true)
    },
    () => {
      setErrorDialogState(true)
    }
  )
  const { t } = useTranslation()
  const handleCancelForm = () => {
    resetForm()
    onClose()
    refresh()
    setSuccessModalState(false)
  }

  const { errors, values, setFieldValue, handleSubmit, handleBlur, resetForm, touched, handleChange, isValid, dirty } =
    useFormik({
      initialValues: {
        amount: '',
        reward: ''
      },
      validationSchema,
      onSubmit: submitValues => {
        const { amount: inputAmount, reward } = submitValues as unknown as TypeOf<typeof validationSchema>

        const amount = floatToIntegerCurrency(inputAmount)
        createMilestone(buildShoppingListItem(organisationId, amount, reward))
      }
    })

  const buildShoppingListItem = (id: string, amount: number, reward: string): CreateMilestoneProps => ({
    id,
    amount,
    reward
  })

  const { amount, reward } = values

  if (isLoading) {
    return <Loader />
  }

  const handleOnErrorClose = () => {
    setErrorDialogState(false)
  }

  const successModal = successModalState && (
    <AcknowledgeModal
      result="positive"
      title={t('dialog.milestone.success.title', { milestoneAction: 'added' })}
      buttonText={t('button.close')}
      handleClose={handleCancelForm}
    />
  )

  const errorModal = errorDialogState && (
    <AcknowledgeModal
      result="negative"
      title={t('dialog.milestone.error.title', { milestoneAction: 'added' })}
      description={t('dialog.milestone.error.description', { milestoneAction: 'add' })}
      buttonText={t('button.close')}
      handleClose={handleOnErrorClose}
    />
  )

  return (
    <Modal open={openModal} onClose={onClose}>
      {successModal || errorModal || (
        <ActionModal
          title={t('typography.addMilestone')}
          primaryButtonText={t('typography.addMilestone')}
          secondaryButtonText={t('button.cancel')}
          type="submit"
          variant={isValid && dirty ? 'primary' : 'secondary'}
          disabled={!(isValid && dirty && !isLoading)}
          loading={isLoading}
          handleClose={handleCancelForm}
          handleSubmit={handleSubmit}
          primaryBtnTestId="add-milestone-submit-button"
          secondaryBtnTestId="add-milestone-cancel-button"
          aria-labelledby="add-milestone-form-modal"
        >
          <form>
            <Text>
              {t('dialog.addMilestone.title', {
                currencyCode: getCurrencySymbol(countryCode as keyof typeof Currency)
              })}
            </Text>
            <FieldInputCurrency
              name="itemAmount"
              label={t('form.itemAmount')}
              placeHolder={t('form.itemAmountPlaceholder')}
              value={amount || ''}
              onChange={(a: string) => setFieldValue('amount', a)}
              handleBlur={handleBlur}
              error={errors.amount}
              addOn={currencyCode}
            />
            <FormField
              label="What can be achieved"
              status={touched.reward && errors.reward ? 'danger' : 'default'}
              statusMessage={errors.reward || ''}
              data-testid="reward"
              necessity="required"
            >
              <TextArea
                name="reward"
                value={reward || ''}
                placeholder="Enter what can be achieved with the amount you entered above"
                maxLength={255}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </FormField>
          </form>
        </ActionModal>
      )}
    </Modal>
  )
}
