import React from 'react'
import { useTranslation } from 'react-i18next'

import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { Dialog, DialogDeleteBody } from '@percent/admin-dashboard/common/components'
import { useMutation } from '@percent/admin-dashboard/common/hooks'

type DeleteMemberDialogProps = {
  openDeleteModal?: boolean
  onClose: () => void
  refresh?: () => void
  memberEmail: string
  memberId: string
  partnerId: string
}

export function DeleteMemberDialog({
  openDeleteModal = false,
  onClose,
  refresh = () => {},
  memberEmail,
  memberId,
  partnerId
}: DeleteMemberDialogProps) {
  const { adminService } = useServices()
  const [{ errorMessage }, { apiFunc }] = useMutation(adminService.commands.deletePartnerDeveloper, () => {
    onClose()
    refresh()
  })
  const { t } = useTranslation()

  const handleSubmit = () => {
    apiFunc({ id: { partnerId, memberId } })
  }

  return (
    <Dialog openModal={openDeleteModal} onClose={onClose} headerTitle={t('typography.removeTeamMember')}>
      <DialogDeleteBody
        bodyText={`${memberEmail} ${t('typography.noLongerHaveAccess')}`}
        errorMessage={errorMessage}
        handleSubmit={handleSubmit}
        buttonTitle={t('button.remove')}
        testId="delete-member-button"
      />
    </Dialog>
  )
}
