export const mockActivityTags = {
  data: [
    {
      createdAt: '2022-02-10T10:33:40.152Z',
      description: null,
      id: 'staging_activitytag_000000CJhePhxrRQ4klXXAMPWIjZ2',
      name: 'Animal, Environment & Conservation',
      subTags: [
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZH', name: 'Farming & Agriculture' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZI' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZJ' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZK' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZL' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZM' }
      ],
      updatedAt: null
    },
    {
      createdAt: '2022-02-10T10:33:40.152Z',
      description: null,
      id: 'staging_activitytag_000000CJhePhxrRQ4klXXAMPWIjZ3',
      name: 'Arts, Cultural & Historic',
      subTags: [
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZN' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZO' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZP' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZQ' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZR' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZS' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZT' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZU' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZV' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZW' }
      ],
      updatedAt: null
    },
    {
      createdAt: '2022-02-10T10:33:40.152Z',
      description: null,
      id: 'staging_activitytag_000000CJhePhxrRQ4klXXAMPWIjZ4',
      name: 'Business, Employment & Professional Associations',
      subTags: [
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZX' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZY' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZZ' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZa' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZb' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZc' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZd' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZe' }
      ],
      updatedAt: null
    },
    {
      createdAt: '2022-02-10T10:33:40.152Z',
      description: null,
      id: 'staging_activitytag_000000CJhePhxrRQ4klXXAMPWIjZ5',
      name: 'Development, Housing & Hunger',
      subTags: [
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZf' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZg' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZh' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZi' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZj' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZk' }
      ],
      updatedAt: null
    },
    {
      createdAt: '2022-02-10T10:33:40.152Z',
      description: null,
      id: 'staging_activitytag_000000CJhePhxrRQ4klXXAMPWIjZ6',
      name: 'Disaster Relief & Public Safety',
      subTags: [
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZl' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZm' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZn' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZp' }
      ],
      updatedAt: null
    },
    {
      createdAt: '2022-02-10T10:33:40.152Z',
      description: null,
      id: 'staging_activitytag_000000CJhePhxrRQ4klXXAMPWIjZ7',
      name: 'Health & Medicine',
      subTags: [
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZq' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZr' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZs' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZt' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZu' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZv' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZw' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZx' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZy' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZz' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIja0' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIja1' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIja2' }
      ],
      updatedAt: null
    },
    {
      createdAt: '2022-02-10T10:33:40.152Z',
      description: null,
      id: 'staging_activitytag_000000CJhePhxrRQ4klXXAMPWIjZ8',
      name: 'International & Foreign Affairs',
      subTags: [
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIja3' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIja4' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIja5' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIja6' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIja7' }
      ],
      updatedAt: null
    },
    {
      createdAt: '2022-02-10T10:33:40.152Z',
      description: null,
      id: 'staging_activitytag_000000CJhePhxrRQ4klXXAMPWIjZ9',
      name: 'Law, Advocacy & Politics',
      subTags: [
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIja8' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIja9' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjaA' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjaB' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjaC' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjaD' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjaE' }
      ],
      updatedAt: null
    },
    {
      createdAt: '2022-02-10T10:33:40.152Z',
      description: null,
      id: 'staging_activitytag_000000CJhePhxrRQ4klXXAMPWIjZA',
      name: 'Military & Veterans',
      subTags: [
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjaF' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjaG' }
      ],
      updatedAt: null
    },
    {
      createdAt: '2022-02-10T10:33:40.152Z',
      description: null,
      id: 'staging_activitytag_000000CJhePhxrRQ4klXXAMPWIjZB',
      name: 'Philanthropy, Volunteering & Grantmaking',
      subTags: [
        {
          id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjaH'
        }
      ],
      updatedAt: null
    },
    {
      createdAt: '2022-02-10T10:33:40.152Z',
      description: null,
      id: 'staging_activitytag_000000CJhePhxrRQ4klXXAMPWIjZC',
      name: 'Religion',
      subTags: [{ id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjaO' }],
      updatedAt: null
    },
    {
      createdAt: '2022-02-10T10:33:40.152Z',
      description: null,
      id: 'staging_activitytag_000000CJhePhxrRQ4klXXAMPWIjZD',
      name: 'School, College & Education',
      subTags: [
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjaU', isEducationStage: true },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjaV', isEducationStage: true },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjaW', isEducationStage: true },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjaX' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjaY' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjaZ' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjaa' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjab' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjac' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjad' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjae' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjaf' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjag' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjah' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjai' }
      ],
      updatedAt: null
    },
    {
      createdAt: '2022-02-10T10:33:40.152Z',
      description: null,
      id: 'staging_activitytag_000000CJhePhxrRQ4klXXAMPWIjZE',
      name: 'Science, Research & Technology',
      subTags: [
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjaj' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjak' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjal' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjam' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjan' }
      ],
      updatedAt: null
    },
    {
      createdAt: '2022-02-10T10:33:40.152Z',
      description: null,
      id: 'staging_activitytag_000000CJhePhxrRQ4klXXAMPWIjZF',
      name: 'Social Services',
      subTags: [
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjao' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjap' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjaq' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjar' }
      ],
      updatedAt: null
    },
    {
      createdAt: '2022-02-10T10:33:40.152Z',
      description: null,
      id: 'staging_activitytag_000000CJhePhxrRQ4klXXAMPWIjZG',
      name: 'Sports, Recreation & Social',
      subTags: [
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjas' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjat' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjau' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjav' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjaw' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjax' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjay' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjaz' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjb0' },
        { id: 'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjb1' }
      ],
      updatedAt: null
    }
  ],
  object: 'activity_tag'
}
