export const mockMonitoringList = {
  data: [
    {
      createdAt: '2022-06-14T09:48:16.177Z',
      id: 'staging_monitoring_000000COg33Qjr251g6uBVd890IrJ',
      organisationId: 'staging_organisation_000000COg33Qjr251g6uBVd890IrI',
      organisationName:
        'Natalia NM AUTOMATED TEST 2bb40a85-9df4-4462-adf3-08e373d5fefd TC008 - Organisation to claim and reject claim',
      partnerId: null,
      partnerName: 'Percent',
      results: {}
    }
  ],
  object: 'monitoring_admin',
  totalResults: 1,
  links: {
    current: 'https://staging-api.poweredbypercent.com/v1/admin/monitoring?pageSize=25&status=pending'
  }
}

export const mockMonitoringListEmpty = {
  data: [],
  totalResults: 0,
  _links: {
    current: 'https://staging-api.poweredbypercent.com/v1/admin/monitoring?pageSize=25&status=pending'
  },
  object: 'monitoring_admin'
}
