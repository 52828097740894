export const mockAccountResponse = {
  data: {
    email: 'test@poweredbypercent.com',
    id: 'staging_account_000000C1CyesOh6cy8AErQJBC1ABM',
    fullName: 'Test Admin',
    preferredName: null,
    phoneNumber: null,
    verifiedAt: null,
    createdAt: '2020-11-06T12:06:23.704Z',
    deletedAt: null,
    partnerId: null,
    organisationId: null,
    admin: null,
    twoFaSecretVerifiedAt: '2020-11-06T12:06:23.704Z',
    twoFaBackupCode: '5b06cada-dd66-44b7-a4dd-0bf69657a9d4'
  },
  object: 'account'
}

export const mockPartnerAccountResponse = {
  ...mockAccountResponse,
  object: 'partner'
}
