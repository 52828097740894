import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useHistory, Link, useLocation } from 'react-router-dom'
import { Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { SET_AUTHORISED } from '../../../../context/auth/authReducer/authReducer'

import { FieldInput, Loader, FieldError, PercentButton } from '@percent/admin-dashboard/common/components'
import { useMutation, useAuthDispatch, useAuthState } from '@percent/admin-dashboard/common/hooks'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { AuthView } from '@percent/admin-dashboard/app/auth/authview/AuthView'
import styles from './LoginWithTwoFA.module.scss'

export function LoginWithTwoFA() {
  const dispatch = useAuthDispatch()
  const { isAuthorised } = useAuthState()
  const { authService } = useServices()
  const { t } = useTranslation()
  const { push } = useHistory()
  const { state } = useLocation()

  useEffect(() => {
    if (!state?.password) {
      push('/login')
    }
  }, [state, push])

  useEffect(() => {
    if (isAuthorised) {
      push('/organizations')
    }
  }, [isAuthorised, push])

  const [{ errorMessage, isLoading }, { apiFunc: loginUser, setErrorMessage }] = useMutation(
    authService.verifyOtpKey,
    ({ data: { data } }) => {
      localStorage.authState = JSON.stringify({
        isAuthorised: true,
        isAuthorising: false
      })

      dispatch({
        type: SET_AUTHORISED,
        user: data
      })
    }
  )

  const { values, errors, handleBlur, handleSubmit, touched, setFieldValue } = useFormik({
    initialValues: {
      token: ''
    },
    validationSchema: () =>
      yup.object().shape({
        token: yup
          .string()
          .required('Required')
          .matches(/^[0-9]+$/, t('errorMessage.onlyDigits'))
          .min(6, t('errorMessage.max6Digit'))
          .max(6, t('errorMessage.max6Digit'))
      }),
    onSubmit: ({ token }: { token: string }) => {
      loginUser({
        token
      })
    }
  })

  useEffect(() => {
    if (values.token) {
      setErrorMessage('')
    }
  }, [values.token, setErrorMessage])

  return (
    <AuthView heading="Two-step verification" handleSubmit={handleSubmit}>
      {isLoading ? (
        <Loader loaderStyle="authLoader" />
      ) : (
        <>
          <Typography className={styles.passwordText} variant="body2">
            {t('2fa.enterSixDigitCode')}
          </Typography>
          <FieldInput
            name="token"
            label={t('form.enterSixDigit')}
            placeHolder={t('form.placeholderSixDigit')}
            value={values.token}
            onChange={(a: string) => setFieldValue('token', a)}
            handleBlur={handleBlur}
            error={errors.token}
            touched={touched.token}
          />
          {errorMessage && <FieldError error={errorMessage} />}
          <PercentButton
            className={styles.loginButton}
            fullWidth
            data-testid="auth-active-button"
            title={t('button.confirm')}
            type="submit"
          />
          <Typography variant="body2" className={styles.loginFooterText}>
            <span>
              <Link
                to={{
                  pathname: '/login/2fa-recovery',
                  state: {
                    ...state
                  }
                }}
              >
                {t('2fa.useRecoveryCode')}
              </Link>
            </span>
          </Typography>
          <Typography variant="body2">
            <span>
              <Link to="/login">{t('2fa.loginDifferentUser')}</Link>
            </span>
          </Typography>
        </>
      )}
    </AuthView>
  )
}
