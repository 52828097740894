import { Variant } from '@percent/admin-dashboard/common/components/status/Status.types'
import { OrganisationTypeForm } from './OrganisationTypeForm'
import { OrganisationTypeList } from './OrganisationTypeList'
import { OrganisationTypeSectionContainer } from './OrganisationTypeSectionContainer'
import { OrganisationTypes } from '@percent/admin-dashboard/constants/organisationTypes'

export function OrganisationTypeSection({
  status,
  validationOrganisationTypes
}: {
  status: Variant
  validationOrganisationTypes?: OrganisationTypes[]
}) {
  switch (status) {
    case Variant.PENDING:
      return (
        <OrganisationTypeSectionContainer>
          <OrganisationTypeForm />
        </OrganisationTypeSectionContainer>
      )
    case Variant.APPROVED:
      return (
        <OrganisationTypeSectionContainer>
          <OrganisationTypeList validationOrganisationTypes={validationOrganisationTypes} />
        </OrganisationTypeSectionContainer>
      )

    default:
      return null
  }
}
