import { useFormikContext } from 'formik'
import { useTranslation } from 'react-i18next'

import { FlagBadge } from '../flagBadge/FlagBadge'
import { filterMutuallyExclusiveOrganisationTypes } from '../utils/filterMutuallyExclusiveOrganisationTypes'

import { Checkbox } from '@percent/lemonade'
import {
  NonConflictingOrganisationTypes,
  OrganisationTypes,
  mutuallyExclusiveOrganisationTypesArray,
  nonConflictingOrganisationTypesArray
} from '@percent/admin-dashboard/constants/organisationTypes'
import styles from './OrganisationTypeForm.module.scss'
import { RadioCheckbox } from './RadioCheckbox'
import { useNonprofitsData } from '@percent/admin-dashboard/app/Layout/nonprofitsContext/useNonprofitsData/useNonprofitsData'

export function OrganisationTypeForm() {
  const { t } = useTranslation()
  const { validation } = useNonprofitsData()
  const { values, setFieldValue, handleChange, errors } = useFormikContext<{
    organisationTypes: OrganisationTypes[]
  }>()

  const handleRadioCheckboxChange = (value: OrganisationTypes) => {
    if (values.organisationTypes?.includes(value)) {
      return setFieldValue(
        'organisationTypes',
        values.organisationTypes.filter(organisationType => organisationType !== value)
      )
    }

    return setFieldValue(
      'organisationTypes',
      filterMutuallyExclusiveOrganisationTypes([value], values.organisationTypes)
    )
  }

  const handleRequiredRadioCheckboxChange = (value: OrganisationTypes) => {
    return setFieldValue(
      'organisationTypes',
      filterMutuallyExclusiveOrganisationTypes([value], values.organisationTypes)
    )
  }

  const radioOptions = mutuallyExclusiveOrganisationTypesArray.map((organisationType: OrganisationTypes) => ({
    value: organisationType,
    label: t(`organisationTypes.${organisationType}`)
  }))

  const checkboxOptions = nonConflictingOrganisationTypesArray.map(
    (organisationType: NonConflictingOrganisationTypes) => ({
      value: organisationType,
      label: t(`organisationTypes.${organisationType}`)
    })
  )
  const renderOrganisationTypeCheckboxes = () => {
    if (
      validation?.configuration?.allowedOrganisations?.types?.length === 1 &&
      validation?.configuration?.allowedOrganisations?.types?.includes(NonConflictingOrganisationTypes.Education)
    ) {
      return checkboxOptions.map(option => (
        <RadioCheckbox
          name="organisationTypes"
          key={option.value}
          value={option.value}
          label={option.label}
          selected={values.organisationTypes?.includes(option.value)}
          onChange={() => handleRequiredRadioCheckboxChange(option.value)}
        />
      ))
    }

    if (
      validation?.configuration?.allowedOrganisations?.types?.length === 1 &&
      !validation?.configuration?.allowedOrganisations?.types?.includes(NonConflictingOrganisationTypes.Education)
    ) {
      return radioOptions.map(option => (
        <RadioCheckbox
          name="organisationTypes"
          key={option.value}
          value={option.value}
          label={option.label}
          selected={values.organisationTypes?.includes(option.value)}
          onChange={() => handleRequiredRadioCheckboxChange(option.value)}
        />
      ))
    }

    return (
      <>
        {radioOptions.map(option => (
          <RadioCheckbox
            name="organisationTypes"
            key={option.value}
            value={option.value}
            label={option.label}
            selected={values.organisationTypes?.includes(option.value)}
            onChange={() => handleRadioCheckboxChange(option.value)}
          />
        ))}
        {checkboxOptions.map(option => (
          <Checkbox
            variant="default"
            name="organisationTypes"
            value={option.value}
            key={option.value}
            onChange={handleChange}
            active={values.organisationTypes?.includes(option.value)}
            label={option.label}
          />
        ))}
      </>
    )
  }

  return (
    <div className={styles.radioCheckboxGroupContainer}>
      {renderOrganisationTypeCheckboxes()}
      {errors?.organisationTypes && (
        <div className={styles.errorContainer}>
          <FlagBadge variant="warning">{t('typography.organisationTypeReviewRequired')}</FlagBadge>
        </div>
      )}
    </div>
  )
}
