import React from 'react'
import { Typography } from '@material-ui/core'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'

import { Dialog, FieldError, FieldInput, Loader, PercentButton } from '@percent/admin-dashboard/common/components'
import { ReactComponent as Close } from '@percent/admin-dashboard/common/assets/images/close.svg'
import { ReactComponent as LockIcon } from '@percent/admin-dashboard/common/assets/images/lock-icon.svg'
import styles from './ChangeTwoFactorDevice.module.scss'
import { ChangeTwoFactorDeviceProps } from './ChangeTwoFactorDevice.types'

export function ChangeTwoFactorDevice({
  isOpened,
  onClose,
  errorMessage,
  isLoading,
  generateQRToken
}: ChangeTwoFactorDeviceProps) {
  const { t } = useTranslation()

  const { values, errors, handleBlur, handleSubmit, touched, setFieldValue, isValid, dirty } = useFormik({
    initialValues: {
      password: ''
    },
    validationSchema: () =>
      yup.object().shape({
        password: yup.string().min(8).max(64).required('Required')
      }),
    onSubmit: ({ password }: { password: string }) => {
      generateQRToken({ password })
    }
  })

  return (
    <Dialog openModal={isOpened} onClose={onClose} headerTitle="Change Two-Factor Device" fullscreen withoutHeader>
      <div className={styles.passwordContainer}>
        <button className={styles.close} type="button" data-testId="close-button" onClick={onClose}>
          <Close />
        </button>
        <div>
          <LockIcon className={styles.lockIcon} />
          <Typography variant="h5" className={styles.headerOne}>
            {t('2fa.enable2fa')}
          </Typography>
          <Typography variant="subtitle2" className={styles.headerTwo}>
            {t('2fa.enable2faTitle')}
          </Typography>
          <form onSubmit={handleSubmit} className={styles.passwordForm}>
            {isLoading ? (
              <Loader />
            ) : (
              <>
                <FieldInput
                  name="password"
                  type="password"
                  label={t('2fa.passwordLabel')}
                  placeHolder={t('2fa.passwordPlaceholder')}
                  value={values.password}
                  onChange={(a: string) => setFieldValue('password', a)}
                  handleBlur={handleBlur}
                  error={errors.password}
                  touched={touched.password}
                />
                {errorMessage && <FieldError error={errorMessage} />}
                <PercentButton
                  title={t('button.setUp')}
                  className={styles.setupButton}
                  type="submit"
                  disabled={!(isValid && dirty)}
                />
              </>
            )}
          </form>
        </div>
      </div>
    </Dialog>
  )
}
