import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { Typography } from '@material-ui/core'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { AuthView } from '../../../auth/authview/AuthView'

import { FieldError, FieldInput, Loader, PercentButton } from '@percent/admin-dashboard/common/components'
import styles from './LoginWithRecoveryCode.module.scss'
import { LoginWithConfirmationCodeProps } from './LoginWithRecoveryCode.types'
import { SaveBackupCode } from './SaveBackupCodeStep/SaveBackupCode'
import { recoveryCodeRegExp } from '@percent/admin-dashboard/common/utility/validation'

export function LoginWithRecoveryCode({
  errorMessage,
  isLoading,
  loginFunc,
  isRecoveryCodeConfirmed,
  otpCode,
  setErrorMessage
}: LoginWithConfirmationCodeProps) {
  const { state } = useLocation()
  const { t } = useTranslation()
  const { values, errors, handleBlur, handleSubmit, touched, setFieldValue } = useFormik({
    initialValues: {
      backupCode: ''
    },
    validationSchema: () =>
      yup.object().shape({
        backupCode: yup
          .string()
          .required('Required')
          .matches(recoveryCodeRegExp, t('errorMessage.invalidRecoveryCodeFormat'))
      }),
    onSubmit: ({ backupCode }: { backupCode: string }) => {
      loginFunc({
        backupCode
      })
    }
  })

  useEffect(() => {
    if (values.backupCode) {
      setErrorMessage('')
    }
  }, [values.backupCode, setErrorMessage])

  return isRecoveryCodeConfirmed ? (
    <SaveBackupCode otpCode={otpCode} />
  ) : (
    <AuthView heading="Two-step verification" handleSubmit={handleSubmit}>
      {isLoading ? (
        <Loader loaderStyle="authLoader" />
      ) : (
        <>
          <Typography className={styles.passwordText} variant="body2">
            {t('form.enterRecoveryCode')}
          </Typography>
          <FieldInput
            name="backupCode"
            label={t('form.enterRecoveryCodeLabel')}
            placeHolder={t('form.placeholderRecoveryCode')}
            value={values.backupCode}
            onChange={(a: string) => setFieldValue('backupCode', a)}
            handleBlur={handleBlur}
            error={errors.backupCode}
            touched={touched.backupCode}
          />
          {errorMessage && <FieldError error={errorMessage} />}
          <PercentButton
            className={styles.loginButton}
            fullWidth
            data-testid="auth-active-button"
            title={t('button.confirm')}
            type="submit"
          />
          <Typography variant="body2" className={styles.loginFooterText}>
            <span>
              <Link
                to={{
                  pathname: '/login/2fa',
                  state: { ...state }
                }}
              >
                {t('2fa.useTwoFACode')}
              </Link>
            </span>
          </Typography>
          <Typography variant="body2">
            <span>
              <Link to="/login">{t('2fa.loginDifferentUser')}</Link>
            </span>
          </Typography>
        </>
      )}
    </AuthView>
  )
}
