import React from 'react'
import { useTranslation } from 'react-i18next'

import { ActivityLogSubTag } from '../activityLogSubtags/ActivityLogSubTags'

import { Table, TableBody, TableCell, TablePagination, TableProvider, TableRow } from '@percent/lemonade'
import { ErrorView } from '@percent/admin-dashboard/common/components'
import { ActivityEventTableProps } from './ActivityLogTable.types'
import { getFormattedDate } from '@percent/admin-dashboard/common/library/utility/date'

export const formatMoney = (num: number) => (num / 100).toFixed(2)

export function ActivityLogTable({
  data,
  isLoading,
  errorMessage,
  totalResults,
  tagsData,
  nextPage,
  previousPage
}: ActivityEventTableProps) {
  const { t } = useTranslation()

  const columns = [
    { id: t('table.eventType') },
    { id: t('table.activity'), props: { width: '40%' } },
    { id: t('table.createdAt') },
    { id: t('table.createdBy') }
  ]

  if (errorMessage) {
    return <ErrorView errorMessage={errorMessage} />
  }

  return (
    <TableProvider
      columns={columns}
      totalResults={totalResults}
      isLoading={isLoading || !data}
      illustration="no-results"
      emptyTableTitle={t('typography.emptyActivityEventsListTitle')}
      emptyTableDescription={t('typography.emptyActivityEventsListDescription')}
    >
      <Table>
        <TableBody>
          {data?.map(activityEvent => (
            <TableRow key={activityEvent.id}>
              <TableCell>{t('typography.eligibility')}</TableCell>
              <TableCell>
                <ActivityLogSubTag activityEvent={activityEvent} tagsData={tagsData} />
              </TableCell>
              <TableCell>{getFormattedDate(activityEvent.createdAt)}</TableCell>
              <TableCell>
                {activityEvent.createdBy
                  ? activityEvent.createdByFullName ?? activityEvent.createdByEmail
                  : t('typography.automatically')}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        totalResultsText={t('table.results', { count: totalResults })}
        previousPage={previousPage}
        nextPage={nextPage}
      />
    </TableProvider>
  )
}
