/* eslint-disable import/prefer-default-export */
export const MuiTableContainer = {
  root: {
    padding: '30px',
    boxSizing: 'border-box'
  }
}

export const MuiTable = {
  root: {
    borderTop: '1px solid rgba(224, 224, 224, 1)'
  }
}
