import { Box, Grid, Paper, TableCell, TableRow } from '@material-ui/core'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { DisbursementsDetailsActionsMenu } from '../DisbursementsDetailsActionsMenu/DisbursementsDetailsActionsMenu'
import { MarkAsPaidModal } from '../../markAsPaidModal/MarkAsPaidModal'
import { MarkAsApprovedOrRemoveFromBatchModal } from '../../markAsApprovedOrRemoveFromBatchModal/MarkAsApprovedOrRemoveFromBatchModal'
import { MarkAsSentModal } from '../../markAsSentModal/MarkAsSentModal'

import { Badge, FlagAvatar, Icon } from '@percent/lemonade'
import { Table } from '@percent/admin-dashboard/common/components'
import { useCurrencies } from '@percent/admin-dashboard/common/hooks'
import { DisbursementDetailsTableHeader } from './DisbursementDetailsTableHeader'
import style from './DisbursementDetailsTable.module.scss'
import {
  DisbursementEnhanced,
  DisbursementStatus,
  DisbursementType
} from '@percent/admin-dashboard/api/actions/disbursements/disbursements.types'
import { DisbursementDetailsTableProps } from './DisbursementDetailsTable.types'
import { formatAmountFromMinorUnits } from '@percent/admin-dashboard/common/utility/formatAmount/formatAmount'
import { dayJS } from '@percent/admin-dashboard/common/library/utility/date'

export enum DisbursementActionModalKind {
  MARK_AS_PAID = 'mark_as_paid',
  MARK_AS_APPROVED = 'mark_as_approved',
  MARK_AS_SENT = 'mark_as_sent',
  CANCEL = 'cancel'
}

export function DisbursementDetailsTable({
  disbursementBatchId,
  disbursementBatchEndDate,
  disbursementBatchFoundationId,
  summary,
  detailedCounts,
  data,
  previousPage,
  nextPage,
  totalResults,
  refreshData
}: DisbursementDetailsTableProps) {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)
  const [selectedDisbursementDetails, setSelectedDisbursementDetails] = useState<DisbursementEnhanced | undefined>(
    undefined
  )
  const { currencyInfo } = useCurrencies()
  const [activeActionModal, setActiveActionModal] = useState<DisbursementActionModalKind | undefined>(undefined)

  const cells = [
    { id: 'organization', isSortable: false, props: { width: '230px' } },
    { id: 'country', isSortable: false, props: { width: '92px' } },
    { id: 'totalDonation', isSortable: false, props: { width: '140px' } },
    { id: 'currency', isSortable: false, props: { width: '92px' } },
    { id: 'status', isSortable: false, props: { width: '190px' } },
    { id: 'paidDate', isSortable: false, props: { width: '190px' } }
  ]

  const handleMenuBtnClick = (event: React.MouseEvent<HTMLElement>, disbursement: DisbursementEnhanced) => {
    setAnchorEl(event.currentTarget)
    setSelectedDisbursementDetails(disbursement)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const closeModal = () => {
    setSelectedDisbursementDetails(undefined)
    setActiveActionModal(undefined)
  }

  const getBadgeVariant = (status: DisbursementStatus) => {
    switch (status) {
      case DisbursementStatus.CANCELLED:
        return (
          <Badge variant="critical" icon="reject">
            {t('status.removed')}
          </Badge>
        )
      case DisbursementStatus.PAID:
        return (
          <Badge variant="positive" icon="approve">
            {t('status.paid')}
          </Badge>
        )
      case DisbursementStatus.PENDING_APPROVAL:
        return (
          <Badge variant="informative" icon="clock">
            {t('status.pendingApproval')}
          </Badge>
        )
      case DisbursementStatus.SENT:
        return (
          <Badge variant="informative" icon="check-badge">
            {t('status.sent')}
          </Badge>
        )
      case DisbursementStatus.PENDING_PAYMENT:
        return (
          <Badge variant="default" icon="check-badge">
            {t('status.pendingPayment')}
          </Badge>
        )
      default:
        return null
    }
  }

  return (
    <Grid container component={Paper} elevation={0} spacing={0}>
      <DisbursementDetailsTableHeader
        disbursementBatchId={disbursementBatchId}
        disbursementBatchEndDate={disbursementBatchEndDate}
        disbursementBatchFoundationId={disbursementBatchFoundationId}
        summary={summary}
        detailedCounts={detailedCounts}
        refreshData={refreshData}
      />
      <Table
        isLoading={false}
        data={data}
        previousPage={previousPage}
        nextPage={nextPage}
        totalResults={totalResults}
        columns={cells}
        emptyTableText={t('table.emptyNonprofits')}
        className={style.table}
        testId="disbursementsTable"
      >
        {data?.map(row => {
          const handleCancel =
            row.status !== DisbursementStatus.PAID && row.status !== DisbursementStatus.CANCELLED
              ? () => setActiveActionModal(DisbursementActionModalKind.CANCEL)
              : undefined
          const handleMarkAsPaid =
            (row.status === DisbursementStatus.PENDING_PAYMENT && row.type !== DisbursementType.CHEQUE) ||
            (row.status === DisbursementStatus.SENT && row.type === DisbursementType.CHEQUE)
              ? () => setActiveActionModal(DisbursementActionModalKind.MARK_AS_PAID)
              : undefined
          const handleMarkAsSent =
            row.status === DisbursementStatus.PENDING_PAYMENT && row.type === DisbursementType.CHEQUE
              ? () => setActiveActionModal(DisbursementActionModalKind.MARK_AS_SENT)
              : undefined
          const handleMarkAsApproved =
            row.status === DisbursementStatus.PENDING_APPROVAL
              ? () => setActiveActionModal(DisbursementActionModalKind.MARK_AS_APPROVED)
              : undefined

          return (
            <TableRow key={row.id}>
              <TableCell>{row.organisationName}</TableCell>
              <TableCell>
                <FlagAvatar key={row.id} code3={row.organisationCountryCode} />
              </TableCell>
              <TableCell>
                {formatAmountFromMinorUnits({
                  currencyCode: row.foundationCurrencyCode,
                  value: row.foundationAmount,
                  currencies: currencyInfo
                })}
              </TableCell>
              <TableCell>{row.foundationCurrencyCode}</TableCell>
              <TableCell>{getBadgeVariant(row.status)}</TableCell>
              {row.status === DisbursementStatus.PENDING_PAYMENT ||
              row.status === DisbursementStatus.PENDING_APPROVAL ||
              row.status === DisbursementStatus.SENT ? (
                <TableCell align="right" className={style.menuBtnWrapper}>
                  <Box
                    onClick={event => handleMenuBtnClick(event, row)}
                    aria-controls="actions-menu"
                    aria-haspopup="true"
                    data-testid="actions-menu-button"
                  >
                    <Icon name="menu-horizontal" size="s" color="neutral400" />
                  </Box>
                  <DisbursementsDetailsActionsMenu
                    anchorEl={anchorEl}
                    isOpen={selectedDisbursementDetails?.id === row.id && !!anchorEl}
                    onClose={handleMenuClose}
                    handleCancel={handleCancel}
                    handleMarkAsPaid={handleMarkAsPaid}
                    handleMarkAsSent={handleMarkAsSent}
                    handleMarkAsApproved={handleMarkAsApproved}
                  />
                </TableCell>
              ) : null}
              {row.paidAt ? <TableCell>{dayJS(row.paidAt).format('DD MMM YYYY, HH:MM')}</TableCell> : null}
            </TableRow>
          )
        })}
      </Table>
      {selectedDisbursementDetails && activeActionModal ? (
        <>
          <MarkAsPaidModal
            isOpen={activeActionModal === DisbursementActionModalKind.MARK_AS_PAID}
            onClose={closeModal}
            disbursementDetails={selectedDisbursementDetails}
            refreshData={refreshData}
          />
          <MarkAsSentModal
            isOpen={activeActionModal === DisbursementActionModalKind.MARK_AS_SENT}
            onClose={closeModal}
            disbursementId={selectedDisbursementDetails.id}
            organisationName={selectedDisbursementDetails.organisationName}
            refreshData={refreshData}
          />
          <MarkAsApprovedOrRemoveFromBatchModal
            isOpen={
              activeActionModal === DisbursementActionModalKind.MARK_AS_APPROVED ||
              activeActionModal === DisbursementActionModalKind.CANCEL
            }
            onClose={closeModal}
            disbursementId={selectedDisbursementDetails.id}
            organisationName={selectedDisbursementDetails.organisationName}
            refreshData={refreshData}
            actionType={activeActionModal}
          />
        </>
      ) : null}
    </Grid>
  )
}
