import { Typography } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons'
import React from 'react'
import { useTranslation } from 'react-i18next'

import style from './PaginationActions.module.scss'
import { PaginationActionsProps } from './PagingActions.types'

export function PaginationActions({ count, previousPage, nextPage }: PaginationActionsProps) {
  const { t } = useTranslation()

  const handlePreviousButtonClick = () => previousPage?.()
  const handleNextButtonClick = () => nextPage?.()

  return (
    <div className={style.container}>
      <Typography id="paginationLabel" className={style.label}>{`${count}  ${t(
        count === 1 ? 'typography.result' : 'typography.results'
      )}`}</Typography>
      <div className={style.buttonsContainer}>
        <IconButton
          onClick={handlePreviousButtonClick}
          disabled={!previousPage}
          aria-label="previous page"
          className={previousPage ? style.paginationButton : style.paginationButtonDisabled}
          data-testid="back-button"
        >
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={!nextPage}
          aria-label="next page"
          className={nextPage ? style.paginationButton : style.paginationButtonDisabled}
          data-testid="next-button"
        >
          <KeyboardArrowRight />
        </IconButton>
      </div>
    </div>
  )
}
