import React from 'react'
import { useTranslation } from 'react-i18next'

import { AcknowledgeModal, ConfirmationModal, Modal, Text } from '@percent/lemonade'
import { useMutation } from '@percent/admin-dashboard/common/hooks'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { DonationMatchRequestModalApproveProps } from './DonationMatchRequestModalApprove.types'

export function DonationMatchRequestModalApprove({
  open,
  onClose,
  refresh,
  donationMatchRequestState
}: DonationMatchRequestModalApproveProps) {
  const { t } = useTranslation()
  const { donationMatchingRequestService } = useServices()
  const [{ isLoading, error, errorMessage, success }, { apiFunc }] = useMutation(
    donationMatchingRequestService.postDonationMatchRequestApprove
  )

  const handleApprove = async () => {
    await apiFunc(donationMatchRequestState)
  }

  const handleOnSuccess = () => {
    onClose()
    refresh()
  }

  const hasResult = success || error || errorMessage

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="donation-match-request-approve-modal">
      {!hasResult ? (
        <ConfirmationModal
          title={t('dialog.donationMatchRequestApprove.title')}
          primaryButtonText={t('button.approve')}
          secondaryButtonText={t('button.cancel')}
          loading={isLoading}
          handleClose={onClose}
          handleSubmit={handleApprove}
          primaryBtnTestId="approve-match-request"
          secondaryBtnTestId="cancel-match-request"
          aria-labelledby="approve-match-request-modal"
        >
          <Text>{t('dialog.donationMatchRequestApprove.description')}</Text>
        </ConfirmationModal>
      ) : success ? (
        <AcknowledgeModal
          result="positive"
          title={t('dialog.donationMatchRequestApprove.success.title')}
          buttonText={t('button.close')}
          handleClose={handleOnSuccess}
        />
      ) : error?.code === 'donation_match_request/unable_to_create_match' ? (
        <AcknowledgeModal
          result="negative"
          title={t('dialog.donationMatchRequestApprove.unableToMatchError.title')}
          description={t('dialog.donationMatchRequestApprove.unableToMatchError.description')}
          buttonText={t('button.close')}
          handleClose={onClose}
        />
      ) : (
        <AcknowledgeModal
          result="negative"
          title={t('dialog.donationMatchRequestApprove.error.title')}
          description={t('dialog.donationMatchRequestApprove.error.description')}
          buttonText={t('button.close')}
          handleClose={onClose}
        />
      )}
    </Modal>
  )
}
