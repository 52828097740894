import { rest } from 'msw'

import { config } from '@percent/admin-dashboard/config'
import { mockValidationSubmissionsResponse } from '@percent/admin-dashboard/fixtures/api/mockValidationSubmissionsResponse'
import { mockValidationSubmissionDetailsResponse } from '@percent/admin-dashboard/fixtures/api/mockValidationSubmissionDetailsResponse'

export const validationSubmissionsHandlers = [
  rest.get(`${config.urls.api}v1/admin/validation-submissions`, async (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockValidationSubmissionsResponse))
  }),
  rest.get(`${config.urls.api}v1/admin/validation-submissions/:id`, async (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockValidationSubmissionDetailsResponse))
  })
]
