export type PaginationProps = {
  pageSize?: number | null
}

export enum QueryStatus {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  LOADING = 'LOADING'
}

type UseQueryListBaseReturnType<B> = {
  isLoading: boolean
  dataOrNull: B[] | null
  errorMessageOrNull: string | null
  queryParams: any
  totalPages: number
  status: number | null
  totalResults: number
  error: boolean
}

export type UseQueryListLoadingReturnType<B> = UseQueryListBaseReturnType<B> & {
  queryStatus: QueryStatus.LOADING
}

export type UseQueryListSuccessReturnType<B> = UseQueryListBaseReturnType<B> & {
  queryStatus: QueryStatus.SUCCESS
  data: B[]
}

export type UseQueryListErrorReturnType<B> = UseQueryListBaseReturnType<B> & {
  queryStatus: QueryStatus.ERROR
  errorMessage: string
}

export type UseQueryListActionsReturnType = {
  query: (newQueryParams: any) => void
  nextPage: (() => void) | null
  previousPage: (() => void) | null
  refresh: () => void
  refreshToFirstPage: () => void
}

export type UseQueryListReturnType<B> =
  | UseQueryListLoadingReturnType<B>
  | UseQueryListSuccessReturnType<B>
  | UseQueryListErrorReturnType<B>
