import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import dayOfYear from 'dayjs/plugin/dayOfYear'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(localizedFormat)
dayjs.extend(dayOfYear)
dayjs.extend(utc)
dayjs.extend(timezone)

export { dayjs as dayJS }

export const getFormattedDate = (date?: string) => {
  return dayjs(date).format('D MMM YYYY, HH:mm')
}

export const getUTCDayStart = (d: Date) => {
  const date = dayjs(d)
  const offset = date.utcOffset()
  const x = offset > 0 ? date.add(offset, 'minutes') : date.subtract(offset, 'minutes')

  return dayjs.utc(x).startOf('day')
}

export const getUTCDayEnd = (d: Date) => {
  const date = dayjs(d)
  const offset = date.utcOffset()
  const x = offset > 0 ? date.add(offset, 'minutes') : date.subtract(offset, 'minutes')

  return dayjs.utc(x).endOf('day')
}
