import { ReactComponent as ExpandLogo } from '@percent/admin-dashboard/common/assets/images/expand.svg'
import { colors } from '@percent/admin-dashboard/identity'
import { ExternalLinkProps } from './ExternalLink.types'
import styles from './ExternalLink.module.scss'

export function ExternalLink({ url, text, children }: ExternalLinkProps) {
  return (
    <a href={url} target="_blank" rel="noopener noreferrer" className={styles.link}>
      <ExpandLogo width="28px" height="28px" fill={colors.secondary} />
      {children ?? (text || url)}
    </a>
  )
}
