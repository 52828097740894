import { Grid } from '@material-ui/core'

import { Loader } from '@percent/admin-dashboard/common/components'
import { useQuery } from '@percent/admin-dashboard/common/hooks'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { DonationRequestsDetails } from './DonationRequestDetails/DonationRequestDetails'

export function DonationRequestDetailContainer({ match }: { match: { params: { id: string } } }) {
  const { donationRequestsService } = useServices()
  const { id } = match.params
  const [{ data, isLoading }, { refresh }] = useQuery(donationRequestsService.getEnhanced, {
    id
  })

  if (isLoading) {
    return <Loader />
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <DonationRequestsDetails data={data} refresh={refresh} />
      </Grid>
    </Grid>
  )
}
