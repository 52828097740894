import { Box, DialogContent, Typography } from '@material-ui/core'
import { FormEvent, useState } from 'react'
import { DatePicker } from '@material-ui/pickers'
import { useTranslation } from 'react-i18next'

import { Dialog, FieldError } from '@percent/admin-dashboard/common/components'
import { MarkAsSettledModalProps } from './MarkAsSettledModal.types'
import { Button, Icon } from '@percent/lemonade'
import styles from './MarkAsSettledModal.module.scss'
import { dayJS } from '@percent/admin-dashboard/common/library/utility/date'
import { useToast } from '@percent/admin-dashboard/containers/toast/ToastContext'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'

const DATE_FORMAT = 'DD MMM YYYY'

export function MarkAsSettledModal({
  isOpen,
  onClose,
  onSuccess,
  donationRequest,
  partnerName
}: MarkAsSettledModalProps) {
  const { t } = useTranslation()
  const { donationRequestsService } = useServices()
  const addToast = useToast()
  const [settledOn, setSettledOn] = useState(dayJS())
  const [isSubmitting, setIsSubmitting] = useState(false)

  const resetValues = () => {
    setSettledOn(dayJS())
  }

  const handleClose = () => {
    resetValues()
    onClose()
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setIsSubmitting(true)

    try {
      // if settledAt is today use current time else use midday
      const settledAt = settledOn.isSame(dayJS(), 'day')
        ? new Date()
        : settledOn.startOf('day').add(12, 'hours').toDate()
      await donationRequestsService.settle({
        id: donationRequest.id,
        settledAt
      })

      addToast('successfully settled donation request', 'success')
      onSuccess()
      handleClose()
    } catch (e) {
      addToast(e.response?.data?.error?.message || t('errorMessage.genericError'), 'error')
    }
    setIsSubmitting(false)
  }

  return (
    <Dialog headerTitle={t('dialog.settleDonationRequest.header')} openModal={isOpen} onClose={handleClose}>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Box className={styles.formFieldWrapper}>
            <Typography className={styles.label} variant="subtitle1">
              {t('dialog.settleDonationRequest.form.settledAt')}
            </Typography>
            <Box className={styles.datePickerWrapper}>
              <DatePicker
                maxDate={new Date()}
                className={styles.datePicker}
                format={DATE_FORMAT}
                value={settledOn.format(DATE_FORMAT)}
                onChange={date => setSettledOn(dayJS(date).endOf('day'))}
                disableFuture
                inputProps={{
                  'data-testid': 'date-picker'
                }}
              />
              <Icon name="chevron-down" size={4} color="gray600" />
            </Box>
            <FieldError
              error={
                dayJS(donationRequest.createdAt).isAfter(settledOn)
                  ? t('dialog.settleDonationRequest.form.settledAtBeforeCreatedAtWarning')
                  : undefined
              }
            />
          </Box>
          <Box className={styles.info}>
            <Icon name="info" color="gray500" size={6} />
            <Typography variant="body2">{t('dialog.settleDonationRequest.info', { partnerName })}</Typography>
          </Box>
          <Box className={styles.buttonsWrapper}>
            <Button size="large" type="submit" disabled={!settledOn || isSubmitting} loading={isSubmitting}>
              {t('button.markAsSettled')}
            </Button>
            <Button onPress={handleClose} variant="secondary" size="large" type="button">
              {t('button.cancel')}
            </Button>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  )
}
