import { Checkbox } from '@percent/lemonade'

type RadioCheckboxProps = {
  name: string
  label: string
  value: any
  selected: any
  onChange: (value: any) => void
}

export function RadioCheckbox({ name, value, label, selected, onChange }: Readonly<RadioCheckboxProps>) {
  return (
    <Checkbox
      variant="default"
      name={name}
      value={value}
      key={value}
      onChange={onChange}
      active={selected}
      label={label}
    />
  )
}
