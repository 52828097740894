import React, { useState } from 'react'
import { FormikProvider, useFormik } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'

import { AcknowledgeModal, ActionModal, FormField, Modal, Spacer, Text, TextInput } from '@percent/lemonade'
import { useMutation } from '@percent/admin-dashboard/common/hooks'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { DonationMatchRequestModalRejectProps } from './DonationMatchRequestModalReject.types'

export function DonationMatchRequestModalReject({
  open,
  onClose,
  refresh,
  donationMatchRequestId
}: DonationMatchRequestModalRejectProps) {
  const { t } = useTranslation()
  const { donationMatchingRequestService } = useServices()
  const [errorModalState, setErrorModalState] = useState(false)
  const [{ isLoading, success }, { apiFunc }] = useMutation(
    donationMatchingRequestService.postDonationMatchRequestReject,
    undefined,
    () => setErrorModalState(true)
  )

  const formik = useFormik({
    initialValues: {
      reason: ''
    },
    validationSchema: () =>
      Yup.object().shape({
        reason: Yup.string()
          .max(255, t('errorMessage.orgFieldCharacterLimit', { fieldName: 'Reason' }))
          .min(1, t('errorMessage.orgFieldCharacterLimit', { fieldName: 'Reason' }))
          .trim()
          .required(t('errorMessage.required'))
      }),
    onSubmit: async ({ reason }) => {
      await apiFunc({
        donationMatchRequestId,
        reason
      })
    }
  })

  const handleOnSuccess = () => {
    onClose()
    refresh()
  }

  const handleOnError = () => {
    setErrorModalState(false)
    onClose()
    resetForm()
  }

  const successOrErrorModal =
    success && !errorModalState ? (
      <AcknowledgeModal
        result="positive"
        title={t('dialog.donationMatchRequestReject.success.title')}
        buttonText={t('button.close')}
        handleClose={handleOnSuccess}
      />
    ) : (
      <AcknowledgeModal
        result="negative"
        title={t('dialog.donationMatchRequestReject.error.title')}
        description={t('dialog.donationMatchRequestReject.error.description')}
        buttonText={t('button.close')}
        handleClose={handleOnError}
      />
    )

  const { values, handleChange, handleBlur, handleSubmit, touched, errors, dirty, isValid, resetForm } = formik

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="donation-match-request-reject-modal">
      {!success && !errorModalState ? (
        <ActionModal
          title={t('dialog.donationMatchRequestReject.title')}
          primaryButtonText={t('button.donationMatchRequest.rejectRequest')}
          secondaryButtonText={t('button.cancel')}
          variant={isValid && dirty ? 'primary' : 'secondary'}
          disabled={!(isValid && dirty && !isLoading)}
          loading={isLoading}
          handleClose={() => {
            onClose()
            resetForm()
          }}
          handleSubmit={handleSubmit}
          primaryBtnTestId="reject-match-request"
          secondaryBtnTestId="cancel-match-request"
          aria-labelledby="reject-match-request-modal"
        >
          <Text>{t('dialog.donationMatchRequestReject.description')}</Text>
          <form onSubmit={handleSubmit}>
            <FormikProvider value={formik}>
              <Spacer size={4} axis="vertical" />
              <FormField
                label={t('dialog.donationMatchRequestReject.form.reason.label')}
                status={touched.reason && errors.reason ? 'danger' : 'default'}
                statusMessage={errors.reason}
                description={t('dialog.donationMatchRequestReject.form.reason.description')}
                data-testid="reason"
                necessity="required"
              >
                <TextInput name="reason" value={values.reason} onBlur={handleBlur} onChange={handleChange} />
              </FormField>
              <Spacer size={4} axis="vertical" />
            </FormikProvider>
          </form>
        </ActionModal>
      ) : (
        successOrErrorModal
      )}
    </Modal>
  )
}
