export const getQueryParamsFromUrl = <
  A extends {
    [k: string]: string | string[]
  }
>(
  apiURL: string
): A => {
  const url = new URL(apiURL)
  const searchParams = new URLSearchParams(url.search)

  return Array.from(searchParams.entries()).reduce<A>((acc, [key, value]) => {
    return {
      ...acc,
      [key]: !acc[key] ? value : Array.isArray(acc[key]) ? [...acc[key], value] : [acc[key], value]
    }
  }, {} as A)
}
