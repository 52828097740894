import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Badge, BadgeVariant, IconRegistry } from '@percent/lemonade'
import { DonationMatchRequestStatus } from '@percent/admin-dashboard/api/actions/donationMatchRequests/donationMatchRequests.types'

export interface DonationMatchRequestStatusBadgeProps {
  status: DonationMatchRequestStatus
}

const variantMap: Record<DonationMatchRequestStatus, BadgeVariant> = {
  processing: 'default',
  validating: 'default',
  approved: 'positive',
  rejected: 'critical'
}

const iconMap: Record<DonationMatchRequestStatus, keyof typeof IconRegistry> = {
  processing: 'clock',
  validating: 'clock',
  approved: 'check-badge',
  rejected: 'reject'
}

export function DonationMatchRequestStatusBadge({ status }: DonationMatchRequestStatusBadgeProps) {
  const { t } = useTranslation()

  const translationMap: Record<DonationMatchRequestStatus, string> = useMemo(
    () => ({
      processing: t('status.processing'),
      validating: t('status.validating'),
      approved: t('status.approved'),
      rejected: t('status.rejected')
    }),
    [t]
  )

  return (
    <Badge variant={variantMap[status]} icon={iconMap[status]}>
      {translationMap[status]}
    </Badge>
  )
}
