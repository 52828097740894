import { Box, FormControl, Paper, Radio, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import classNames from 'classnames/bind'

import styles from './SearchableSelect.module.scss'
import { SearchableSelectProps } from './SearchableSelect.types'
import { SearchableSelectListComponent } from './searchableSelectCustomComponents/SearchableSelectListComponent'
import { SearchableSelectIcon } from './searchableSelectCustomComponents/SearchableSelectIcon'

const cx = classNames.bind(styles)

const FONT_WIDTH_QUANTIFIER = 7
const FIELD_DEFAULT_WIDTH = 90

export function SearchableSelect({
  selectId,
  loading,
  label,
  value,
  selectOptions,
  onChange,
  onInputChange,
  styleAsFilter,
  disableDefaultOption
}: SearchableSelectProps) {
  const isFilled = !!value?.value

  const calcFieldWidth = () => {
    if (value && value.value !== '') {
      return FIELD_DEFAULT_WIDTH + value.label.length * FONT_WIDTH_QUANTIFIER
    }

    return FIELD_DEFAULT_WIDTH + label.length * FONT_WIDTH_QUANTIFIER
  }

  return (
    <FormControl
      className={cx({
        fullWidth: !styleAsFilter
      })}
    >
      <Autocomplete
        data-testid={`${selectId}SearchableSelect`}
        id={selectId}
        value={value}
        disableClearable
        clearOnBlur
        blurOnSelect
        selectOnFocus
        className={cx({
          select: true,
          filter: styleAsFilter,
          filled: styleAsFilter && isFilled
        })}
        loading={loading}
        popupIcon={<SearchableSelectIcon isActive={isFilled} useFilterIcon={styleAsFilter} />}
        options={selectOptions}
        getOptionLabel={option => (option.value === '' ? '' : option.label)}
        filterOptions={x => x}
        onChange={onChange}
        onInputChange={onInputChange}
        renderInput={params => (
          <TextField
            placeholder={label}
            {...params}
            label={false}
            variant="filled"
            className={styles.autocompleteTextField}
            style={{
              width: styleAsFilter ? `${calcFieldWidth()}px` : '100%'
            }}
            inputProps={{
              ...params.inputProps,
              'data-testid': `${selectId}SearchableSelectInput`,
              className: cx({
                autocompleteInput: true,
                filter: styleAsFilter,
                filled: styleAsFilter && isFilled
              })
            }}
          />
        )}
        renderOption={(option, state) => (
          <Box
            key={option.value}
            className={cx({
              option: true,
              optionWithBorder: option.value === '',
              disabled: option.value === '' && disableDefaultOption
            })}
          >
            {styleAsFilter ? (
              <Radio size="small" color="primary" checked={state.selected} className={styles.selectOptionRadio} />
            ) : null}
            {option.label}
          </Box>
        )}
        ListboxComponent={SearchableSelectListComponent}
        PaperComponent={props => <Paper {...props} className={styles.paper} />}
      />
    </FormControl>
  )
}
