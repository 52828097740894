import { useState } from 'react'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

import { useMutation } from '@percent/admin-dashboard/common/hooks'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { FormField, Modal, Select, ActionModal, AcknowledgeModal, TextInput, Alert } from '@percent/lemonade'
import { AddToAutoRejectionListModalProps } from './AddToAutoRejectionListModal.types'
import { emailRegex } from '@percent/utility'
import { AgentVerificationRejectionReasonDropdownCodes } from '@percent/admin-dashboard/api/types'

const rejectionReasons = [{ label: 'Other', value: 'other' }]

export function AddToAutoRejectionListModal({
  isOpen,
  setIsOpen,
  refresh
}: Readonly<AddToAutoRejectionListModalProps>) {
  const { t } = useTranslation()
  const { autoRejectionListService } = useServices()
  const [showConfirmationScreen, setShowConfirmationScreen] = useState(false)

  const [{ isLoading }, { apiFunc: addToAutoRejectionList }] = useMutation(
    autoRejectionListService.addToAutoRejectonList,
    () => {
      setShowConfirmationScreen(true)
      refresh()
    }
  )

  const { values, setFieldValue, handleChange, errors, submitForm } = useFormik<{
    email: string
    rejectionReasonCode: AgentVerificationRejectionReasonDropdownCodes
  }>({
    initialValues: {
      email: '',
      rejectionReasonCode: AgentVerificationRejectionReasonDropdownCodes.Other
    },
    validationSchema: () =>
      Yup.object().shape({
        email: Yup.string()
          .required('Required')
          .matches(emailRegex, t('errorMessage.validEmail'))
          .max(255, t('errorMessage.shouldNotExceed255'))
      }),
    onSubmit: () => {
      addToAutoRejectionList(values)
    }
  })

  const closeModal = () => setIsOpen(false)

  return (
    <Modal open={isOpen} onClose={closeModal}>
      {showConfirmationScreen && (
        <AcknowledgeModal
          handleClose={() => {
            closeModal()
            setShowConfirmationScreen(false)
          }}
          title={t('dialog.addToRejectionListConfirmation.title')}
          description={t('dialog.addToRejectionListConfirmation.description', { agentEmail: values.email })}
          result="positive"
          buttonText={t('button.done')}
        />
      )}

      {!showConfirmationScreen && (
        <ActionModal
          title={t('dialog.addToRejectionList.title')}
          primaryButtonText={t('button.addEmail')}
          primaryBtnTestId="add-to-rejection-list"
          secondaryButtonText={t('button.cancel')}
          handleClose={closeModal}
          handleSubmit={submitForm}
          loading={isLoading}
          disabled={!!errors.email || !values.email}
        >
          <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
            <FormField
              label={t('typography.agentsEmail')}
              statusMessage={errors.email}
              status={errors.email ? 'danger' : 'default'}
              data-testid="agent-email-input"
            >
              <TextInput name="email" onChange={handleChange} />
            </FormField>
            <FormField label={t('dropdown.reject.title')} disabled>
              <>
                <Select
                  placeholder={t('dropdown.reject.placeholder')}
                  defaultValue={rejectionReasons[0]}
                  options={rejectionReasons}
                  onChange={({ value }) => setFieldValue('rejectionReasonCode', value)}
                  disabled
                  data-testid="rejection-reason-select"
                />
                <Alert variant="info">{t('dialog.addToRejectionList.alert')}</Alert>
              </>
            </FormField>
          </div>
        </ActionModal>
      )}
    </Modal>
  )
}
