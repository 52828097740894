import React, { useState } from 'react'
import classNames from 'classnames/bind'

import { SubTag } from '../subTag/SubTag'

import { FieldCheckBox } from '@percent/admin-dashboard/common/components'
import { ReactComponent as DropdownSVG } from '@percent/admin-dashboard/common/assets/images/dropdown.svg'
import styles from './Tag.module.scss'
import { TagProps } from './Tag.types'

const cx = classNames.bind(styles)

export function Tag({ tag, index, active, onChange, subTagsForm, setSubTagsForm, tagsForm, setTagsForm }: TagProps) {
  const [isCollapsed, setIsCollapsed] = useState(true)

  const handleCollapse = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent> | React.KeyboardEvent<HTMLDivElement>
  ) => {
    event.stopPropagation()

    if ((event.target as HTMLElement).id !== 'checkbox') {
      setIsCollapsed(prevState => !prevState)
    }
  }

  const onSubTagChange = (subTagId: string) => {
    if (subTagsForm?.includes(subTagId)) {
      const subTags = tag.subTags.map(subTag => subTag.id).filter(subTag => subTag !== subTagId)

      if (
        tagsForm.includes(tag.id) &&
        !subTags.some(subTag => subTagsForm.filter(subTagForm => subTagForm !== subTagId).includes(subTag))
      ) {
        setTagsForm(prevState => prevState.filter(tagId => tagId !== tag.id))
      }

      setSubTagsForm(prevState => prevState?.filter(el => el !== subTagId))
    } else {
      if (!tagsForm.includes(tag.id)) {
        setTagsForm(prevState => [...prevState, tag.id])
      }

      setSubTagsForm(prevState => [...prevState, subTagId])
    }
  }

  return (
    <li key={tag.id}>
      <div
        className={styles.tagContainer}
        onClick={event => handleCollapse(event)}
        onKeyDown={event => handleCollapse(event)}
        role="button"
        tabIndex={index}
      >
        <FieldCheckBox
          active={active}
          onChange={() => onChange(tag.id)}
          name={tag.name}
          checkboxClass={active ? 'tagCheckbox' : 'checkbox'}
          indeterminate={active && !tag.subTags.map(subTag => subTag.id).every(id => subTagsForm.includes(id))}
        />
        <span className={styles.tagName}>{tag.name}&nbsp;</span>
        <span className={styles.subTagsCount}>({tag.subTags.length})</span>
        <DropdownSVG
          className={cx({
            arrow: true,
            arrowDown: isCollapsed
          })}
        />
      </div>
      <ul
        className={cx({
          hidden: isCollapsed
        })}
      >
        {tag.subTags.map(subTag => (
          <SubTag
            key={subTag.id}
            subTag={subTag}
            onChange={onSubTagChange}
            active={!!subTagsForm?.includes(subTag.id)}
          />
        ))}
      </ul>
    </li>
  )
}
