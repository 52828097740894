import React, { ReactNode } from 'react'
import { Paper, Grid, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { getRegion } from '@percent/admin-dashboard/common/utility/getRegion'
import defaultLogo from '@percent/admin-dashboard/common/assets/images/default-logo.png'
import { getDate } from '@percent/admin-dashboard/common/utility/getDate'
import { getProperHref } from '@percent/admin-dashboard/common/utility/getProperHref'
import { OverviewDetailsProps } from './OverviewDetails.types'
import styles from './OverviewDetails.module.scss'
import { OverviewBankDetailsModal } from './OverviewBankDetailsModal'

function OrganizationDetailListItem({ title, children }: { title: string; children: ReactNode }) {
  return (
    <li>
      <p>{title}</p>
      {children}
    </li>
  )
}

export function OverviewDetails({ organization }: OverviewDetailsProps) {
  const { t } = useTranslation()

  return (
    <Grid container spacing={0} component={Paper} elevation={0} className={styles.detailsContainer}>
      <div className={styles.detailsHeader}>
        <Typography variant="h6">{t('typography.details')}</Typography>
      </div>

      <ul className={styles.detailsList}>
        <OrganizationDetailListItem title={t('form.name')}>
          <span>{organization.name}</span>
        </OrganizationDetailListItem>
        <OrganizationDetailListItem title={t('form.address')}>
          <span>{organization.address || t('typography.nullValue')}</span>
        </OrganizationDetailListItem>
        <OrganizationDetailListItem title={t('form.postcode')}>
          <span>{organization.postcode || t('typography.nullValue')}</span>
        </OrganizationDetailListItem>
        <OrganizationDetailListItem title={t('form.country')}>
          <span>{getRegion(organization?.countryCode) || t('typography.nullValue')}</span>
        </OrganizationDetailListItem>
        <OrganizationDetailListItem title={t('form.website')}>
          {organization.website ? (
            <a href={getProperHref(String(organization.website))} target="_blank" rel="noopener noreferrer">
              {organization.website}
            </a>
          ) : (
            <span>{t('typography.nullValue')}</span>
          )}
        </OrganizationDetailListItem>
        <OrganizationDetailListItem title={t('form.image')}>
          {organization.images && organization.images.length > 0 ? (
            <div className={styles.imagesContainer}>
              {organization.images?.map((image: string) => (
                <img key={image} className={styles.nonprofitImage} src={image || defaultLogo} alt="organization" />
              ))}
            </div>
          ) : (
            <span>{t('typography.nullValue')}</span>
          )}
        </OrganizationDetailListItem>
        <OrganizationDetailListItem title={t('form.registryId')}>
          <span>{organization.registryId}</span>
        </OrganizationDetailListItem>
        <OrganizationDetailListItem title={t('form.percentId')}>
          <span>{organization.id}</span>
        </OrganizationDetailListItem>
        <OrganizationDetailListItem title={t('form.claimed')}>
          <span>{getDate(organization.claimedAt) || t('typography.nullValue')}</span>
        </OrganizationDetailListItem>
        <OrganizationDetailListItem title={t('form.registration')}>
          {organization.registrationActive === true ? (
            <div className={styles.statusBoxActive}>
              <span className={styles.successColor}>{t('status.active')}</span>
            </div>
          ) : (
            <div className={styles.statusBoxInactive}>
              <span className={styles.primaryColor}>{t('status.inactive')}</span>
            </div>
          )}
        </OrganizationDetailListItem>
        {organization.claimedAt && (
          <OverviewBankDetailsModal organizationId={organization.id} organizationName={organization.name} />
        )}
      </ul>
    </Grid>
  )
}
