import { AgentVerificationLog } from '@percent/admin-dashboard/api/types'

export const mockAgentVerificationLogsResponse: { data: AgentVerificationLog[] } = {
  data: [
    {
      id: 'staging_avlog_XXXXXXXXXXXXXXXXXM',
      agentVerificationId: 'staging_agentverification_XXXXXXXXXXXXXXXXXG',
      type: 'inbox_verification_email_sent',
      createdAt: '2024-04-29T07:48:42.673Z'
    },
    {
      id: 'staging_avlog_XXXXXXXXXXXXXXXXXK',
      agentVerificationId: 'staging_agentverification_XXXXXXXXXXXXXXXXXG',
      type: 'domain_not_matched',
      createdAt: '2024-04-29T07:48:42.578Z'
    },
    {
      id: 'staging_avlog_XXXXXXXXXXXXXXXXXI',
      agentVerificationId: 'staging_agentverification_XXXXXXXXXXXXXXXXXG',
      type: 'email_domain_not_matched',
      createdAt: '2024-04-29T07:48:42.574Z'
    },
    {
      id: 'staging_avlog_XXXXXXXXXXXXXXXXXH',
      agentVerificationId: 'staging_agentverification_XXXXXXXXXXXXXXXXXG',
      type: 'agent_verification_created',
      createdAt: '2024-04-29T07:48:42.377Z'
    }
  ]
}

export const mockAgentVerificationEmailSentLog: AgentVerificationLog = {
  id: 'staging_avlog_XXXXXXXXXXXXXXXXXH',
  agentVerificationId: 'staging_agentverification_XXXXXXXXXXXXXXXXXG',
  type: 'agent_verification_document_request_email_sent',
  createdAt: '2024-04-29T07:48:42.377Z'
}
