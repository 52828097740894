import React, { useEffect, useState } from 'react'
import { TableContainer } from '@material-ui/core'
import { FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'
import { v4 } from 'uuid'
import { useTranslation } from 'react-i18next'

import { Badge, Button, Feedback, FormField, Spacer, TextInput } from '@percent/lemonade'
import { ErrorView, Loader } from '@percent/admin-dashboard/common/components'
import { useMutation, useQuery } from '@percent/admin-dashboard/common/hooks'
import styles from './SSOSettings.module.scss'
import { BadgeProps } from 'libs/shared/ui-lemonade/src/components/badge'
import { IconProps } from 'libs/shared/ui-lemonade/src/components/icon'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { SSOSettingsProps } from './SSOSettings.types'

export function SSOSettings({ partnerId }: SSOSettingsProps) {
  const { t } = useTranslation()
  const { authService } = useServices()
  const [ssoConfigStatus, setSSOConfigStatus] = useState<'active' | 'inactive' | 'error' | undefined>(undefined)

  const [{ data: ssoData, isLoading, errorMessage }, { refresh: partnerDetailRefresh }] = useQuery(
    authService.getSSOConfig,
    {
      partnerId
    }
  )

  const formik = useFormik({
    initialValues: {
      metadataUrl: '',
      domains: [{ hash: 'init', value: '' }]
    },
    validationSchema: () =>
      yup.object().shape({
        metadataUrl: yup.string().required('Required').url('You must provide a valid URL'),
        domains: yup.array().of(
          yup.object().shape({
            hash: yup.string(),
            value: yup.string().required('Required')
          })
        )
      }),
    onSubmit: async ({ metadataUrl, domains }) => {
      setSSOConfigStatus(undefined)
      await putSSOConfig({
        partnerId,
        metadataUrl,
        domains: domains.map(domainObj => domainObj.value)
      })
      partnerDetailRefresh()
    }
  })

  const { errors, values, touched, isValid, dirty, handleChange, handleBlur, setFieldValue, handleSubmit } = formik

  const [{ isLoading: isLoadingSaveConfig, errorMessage: saveConfigErrorMessage }, { apiFunc: putSSOConfig }] =
    useMutation(authService.putSSOConfig)

  useEffect(() => {
    if (ssoData?.metadataUrl && !saveConfigErrorMessage) {
      setFieldValue('metadataUrl', ssoData.metadataUrl)
      setFieldValue(
        'domains',
        ssoData.domains.map((domainUrl: string) => ({
          hash: v4(),
          value: domainUrl
        }))
      )
    }
  }, [ssoData, setFieldValue, saveConfigErrorMessage])

  useEffect(() => {
    if (isLoading || !ssoData?.metadataUrl) {
      setSSOConfigStatus(undefined)

      return
    }

    if (ssoData.metadataUrl && ssoData.domains) {
      setSSOConfigStatus('active')

      return
    }
    setSSOConfigStatus('error')
  }, [ssoData, setSSOConfigStatus, isLoading, saveConfigErrorMessage])

  const handleRemoveDomain = (index: number) => {
    const removeDomain = [...values.domains]
    removeDomain.splice(index, 1)
    setFieldValue('domains', removeDomain)
  }

  const handleDomainChange = (event: any, index: number) => {
    const onDomainChange = [...values.domains]
    onDomainChange[index].value = event.target.value
    setFieldValue('domains', onDomainChange)
  }

  const handleAddDomain = () => {
    setFieldValue('domains', [
      ...values.domains,
      {
        hash: v4(),
        value: ''
      }
    ])
  }

  if (isLoading) {
    return <Loader />
  }

  if (!ssoData && errorMessage) {
    return <ErrorView errorMessage={errorMessage} />
  }

  const statusBadgeMap: Record<
    'active' | 'inactive' | 'error',
    {
      variant: BadgeProps['variant']
      icon: IconProps['name']
      text: string
    }
  > = {
    active: {
      variant: 'positive',
      icon: 'check-badge',
      text: 'SSO active'
    },
    inactive: {
      variant: 'warning',
      icon: 'check-badge',
      text: 'SSO inactive'
    },
    error: {
      variant: 'critical',
      icon: 'check-badge',
      text: 'SSO error'
    }
  }

  return (
    <TableContainer className={styles.tableContainer}>
      <FormikProvider value={formik}>
        <div className={styles.contentTitle}>Permissions</div>
        <Spacer size={6} axis="vertical" />
        <div className={styles.container}>
          <span>
            <span>{t('typography.singleSignOn')}</span>
            {ssoConfigStatus && (
              <Badge variant={statusBadgeMap[ssoConfigStatus].variant} icon={statusBadgeMap[ssoConfigStatus].icon}>
                {statusBadgeMap[ssoConfigStatus].text}
              </Badge>
            )}
          </span>
          <Button
            type="submit"
            size="small"
            disabled={!isValid && !dirty}
            loading={isLoadingSaveConfig}
            onPress={() => handleSubmit()}
          >
            {t('typography.saveChanges')}
          </Button>
        </div>
        <Spacer size={4} axis="vertical" />
        <FormField
          label={t('form.metadataProvider')}
          status={touched.metadataUrl && errors.metadataUrl ? 'danger' : 'default'}
          statusMessage={errors.metadataUrl}
          data-testid="metadataUrl"
        >
          <TextInput name="metadataUrl" onChange={handleChange} onBlur={handleBlur} value={values.metadataUrl} />
        </FormField>
        <Spacer size={6} axis="vertical" />
        <div className={styles.acceptedDomainsContainer}>
          {values.domains.map((element, index) => (
            <div className={styles.acceptedDomains} key={element.hash}>
              <FormField
                label={index === 0 ? t('form.acceptedDomains') : ''}
                status={errors.domains ? 'danger' : 'default'}
                data-testid="domains"
                statusMessage={errors.domains && (errors.domains[index] ? 'Required format e.g. domain.com' : '')}
              >
                <TextInput
                  name={element.hash}
                  onChange={(event: any) => handleDomainChange(event, index)}
                  onBlur={handleBlur}
                  value={element.value}
                />
              </FormField>
              {index === 0 ? null : (
                <Button variant="tertiary" onPress={() => handleRemoveDomain(index)}>
                  {t('typography.removeDomain')}
                </Button>
              )}
            </div>
          ))}
        </div>
        <Spacer size={2} axis="vertical" />
        {saveConfigErrorMessage && <Feedback variant="critical">{saveConfigErrorMessage}</Feedback>}
        <Spacer size={4} axis="vertical" />
        <span className={styles.addDomain}>
          <Button variant="tertiary" onPress={handleAddDomain}>
            {t('typography.addDomain')}
          </Button>
        </span>
        <Spacer size={14} axis="vertical" />
      </FormikProvider>
    </TableContainer>
  )
}
