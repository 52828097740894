import { useEffect, useState } from 'react'

import { useQuery } from '@percent/admin-dashboard/common/hooks'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { useBreadcrumbsData } from '@percent/admin-dashboard/app/Layout/breadcrumbsContext/useBreadcrumbsData/useBreadcrumbsData'
import { APIErrorHandler } from '@percent/admin-dashboard/common/library/APIErrorHandler'
import { EligibilityDetailsView } from './EligibilityDetailsView/EligibilityDetailsView'

export function EligibilityDetailsContainer({ match }: { match: { params: { id: string } } }) {
  const { id } = match.params

  const { eligibilityService, adminService } = useServices()
  const { setPath } = useBreadcrumbsData()
  const [orgData, setOrgData] = useState(null)
  const [orgLoader, setOrgLoader] = useState(true)
  const [orgError, setOrgError] = useState('')
  const [partnerName, setPartnerName] = useState(null)

  const [
    { data: subscription, isLoading: isSubscriptionLoading, errorMessage: subscriptionErrorMessage },
    { refresh }
  ] = useQuery(eligibilityService.getSingleEligibilitySubscription, { id })

  const getData = async (orgId: string) => {
    setOrgLoader(true)
    try {
      const {
        data: { data: oData }
      } = await adminService.getOrganisation({ id: orgId })

      const {
        data: { data: pData }
      } = await adminService.queries.getPartnerDetail({ id: subscription.partnerId })

      setOrgData(oData)
      setPartnerName(pData.name)
      setOrgLoader(false)
    } catch (err) {
      setOrgError(APIErrorHandler(err?.response?.data?.error))
      setOrgLoader(false)
    }
  }

  useEffect(() => {
    if (subscription) {
      getData(subscription.organisationId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscription])

  useEffect(() => {
    setPath(`${subscription?.organisationName}`)
  }, [setPath, subscription])

  return (
    <EligibilityDetailsView
      errorMessage={orgError || subscriptionErrorMessage}
      isLoading={orgLoader || isSubscriptionLoading}
      orgData={orgData}
      subscription={subscription}
      refresh={refresh}
      partnerName={partnerName}
    />
  )
}
