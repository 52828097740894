import React, { useMemo, useState, useCallback, KeyboardEvent, EventHandler, useEffect } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { PopperPlacementType, TableCell, TableRow } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { TableSort } from '@percent/admin-dashboard/common/components/table/Table.types'
import {
  DateRangePopper,
  ErrorView,
  Table,
  SearchAndDateFilter,
  TimeLeft
} from '@percent/admin-dashboard/common/components'
import { ReactComponent as DropdownSVG } from '@percent/admin-dashboard/common/assets/images/dropdown.svg'
import { getDate } from '@percent/admin-dashboard/common/utility/getDate'
import { useGetQuery } from '@percent/admin-dashboard/common/hooks/useGetQuery/useGetQuery'
import { useBadgeTotal } from '@percent/admin-dashboard/context/badgeTotal/useBadgeTotal/useBadgeTotal'
import { CountryCodeFlag } from '@percent/admin-dashboard/common/components/countryCodeFlag/CountryCodeFlag'
import { ActivityCheckTableProps, ActivityCheckStatusFilter } from './ActivityCheckTable.types'
import styles from './ActivityCheckTable.module.scss'

export function ActivityCheckTable({
  activities,
  isLoading,
  totalResults,
  nextPage,
  previousPage,
  errorMessage,
  queryParams,
  setQueryParams
}: ActivityCheckTableProps) {
  const { t } = useTranslation()
  const { push } = useHistory()
  const [isDatePickerOpened, setIsDatePickerOpened] = useState(false)
  const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLElement) | null>()
  const [placement, setPlacement] = useState<PopperPlacementType>('bottom-end')
  const { totalNumber, setTotalNumber } = useBadgeTotal()
  const query = useGetQuery()
  const getQueryStatus = query.get('status')

  useEffect(() => {
    if (getQueryStatus === 'pending') {
      setTotalNumber({
        ...totalNumber,
        totalPendingActivity: totalResults ?? 0
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalResults])

  const tabs = useMemo(
    () => [
      t('table.pending'),
      t('table.cancelled'),
      t('table.live'),
      t('table.pendingValidationRequest'),
      t('table.validationRequestFailed')
    ],
    [t]
  )

  const cells = useMemo(() => {
    const commonCells = [
      { id: 'country', isSortable: false },
      {
        id:
          queryParams.status === 'pending_validation_request' || queryParams.status === 'validation_request_failed'
            ? 'validationRequest'
            : 'organization',
        isSortable: false
      },
      { id: 'code', isSortable: false },
      { id: 'partner', isSortable: false },
      { id: 'requestDate', isSortable: true }
    ]

    if (queryParams.status === 'pending_validation_request') {
      commonCells.push({ id: 'validationRequest', isSortable: false })
    }

    if (queryParams.status === 'pending') {
      commonCells.push({ id: 'timeLeft', isSortable: false })
    }

    return commonCells
  }, [queryParams.status])

  const handleRequestSort = () =>
    setQueryParams({ direction: queryParams.direction === 'ASC' ? 'DESC' : 'ASC', sort: 'createdAt' })

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setQueryParams({ status: ActivityCheckStatusFilter[newValue], sort: undefined, direction: undefined })
  }

  const handleClick = useCallback(
    (newPlacement: PopperPlacementType) => (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(anchorEl ? null : event.currentTarget)
      setIsDatePickerOpened(prev => !prev)
      setPlacement(newPlacement)
    },
    [anchorEl]
  )

  const handleRowClick = (id: string) => {
    if (queryParams.status === 'pending' || queryParams.status === 'live') {
      return push(`/activity/${id}`, { status: queryParams.status })
    }

    return null
  }

  const handleKeyPress: EventHandler<KeyboardEvent<HTMLInputElement>> = useCallback(
    event => {
      if (event.key === 'Enter') {
        setQueryParams({
          query: (event.target as HTMLInputElement).value
        })
      }
    },
    [setQueryParams]
  )

  const handleClearDate = useCallback(
    () => setQueryParams({ startDate: undefined, endDate: undefined }),
    [setQueryParams]
  )

  const filters = useMemo(
    () => (
      <SearchAndDateFilter
        handleClick={handleClick}
        handleKeyPress={handleKeyPress}
        handleClearDate={handleClearDate}
        searchPhrase={queryParams.query ?? ''}
        placeholder={t('table.adminACSearchOrganisationPlaceholder')}
      />
    ),
    [handleClick, handleKeyPress, queryParams.query, handleClearDate, t]
  )

  if (errorMessage) {
    return <ErrorView errorMessage={errorMessage} />
  }

  return (
    <>
      <Table
        isLoading={isLoading}
        totalResults={totalResults}
        data={activities}
        value={
          ActivityCheckStatusFilter[queryParams.status as keyof typeof ActivityCheckStatusFilter] ??
          ActivityCheckStatusFilter.pending
        }
        handleChange={handleTabChange}
        nextPage={nextPage}
        previousPage={previousPage}
        columns={cells}
        tabs={tabs}
        order={(queryParams.direction?.toLowerCase() as TableSort) ?? 'asc'}
        orderBy="requestDate"
        handleRequestSort={handleRequestSort}
        emptyTableText={t('table.emptyActivitiesChecks')}
        filtersContent={filters}
      >
        {activities?.map(activity => (
          <TableRow
            key={activity.id}
            className={activity.organisation?.id ? styles.row : undefined}
            onClick={() => activity.organisation?.id && handleRowClick(activity.id)}
          >
            <CountryCodeFlag countryCode={activity.countryCode} />
            <TableCell>
              {queryParams.status === 'pending_validation_request' || queryParams.status === 'validation_request_failed'
                ? activity.validationRequest?.name
                : activity.organisation?.name}{' '}
            </TableCell>
            <TableCell>{activity.organisationActivity?.activity ?? activity.activity}</TableCell>
            <TableCell>{activity.partnerName}</TableCell>
            <TableCell>{getDate(activity.createdAt)}</TableCell>
            {queryParams.status === 'pending' && (
              <TableCell>
                <TimeLeft requestedDate={activity.createdAt} />
              </TableCell>
            )}
            {queryParams.status === 'pending_validation_request' && (
              <TableCell className={styles.validationRequestlink}>
                <Link to={`/validations/${activity.validationRequestId}`}>{t('table.goToRequest')}</Link>
              </TableCell>
            )}
            {(queryParams.status === 'pending' || queryParams.status === 'live') && (
              <TableCell align="right" className={styles.rowIconForward}>
                <DropdownSVG className={styles.arrow} />
              </TableCell>
            )}
          </TableRow>
        ))}
      </Table>
      <DateRangePopper
        open={isDatePickerOpened}
        anchorEl={anchorEl}
        placement={placement}
        setOpen={setIsDatePickerOpened}
        setAnchorEl={setAnchorEl}
        setPlacement={setPlacement}
        setQueryParams={setQueryParams}
      />
    </>
  )
}
