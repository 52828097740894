import { Address } from '@percent/admin-dashboard/api/types'

export const renderAddressString = (address: Address | string) => {
  if (typeof address === 'string') {
    return address
  }

  const addressKeysWithValue = Object.keys(address).filter(addressProp => !!address[addressProp as keyof Address])

  return addressKeysWithValue.map(addressString => address[addressString as keyof Address]).join(', ')
}
