import React from 'react'

import { ReactComponent as Tick } from '@percent/admin-dashboard/common/assets/images/modal/tick.svg'
import { ReactComponent as InnerGreenCircle } from '@percent/admin-dashboard/common/assets/images/modal/inner-green-circle.svg'
import { ReactComponent as OuterGreenCircle } from '@percent/admin-dashboard/common/assets/images/modal/outer-green-circle.svg'
import styles from './SuccessDialogContainer.module.scss'
import { SuccessDialogContainer } from './SuccessDialogContainer'
import { SuccessDialogProps } from './SuccessDialog.types'

export function SuccessDialog({ title, onClose }: SuccessDialogProps) {
  return (
    <SuccessDialogContainer title={title} onClose={onClose}>
      <OuterGreenCircle className={styles.outerGreenCircle} />
      <InnerGreenCircle className={styles.innerGreenCircle} />
      <Tick className={styles.tick} />
    </SuccessDialogContainer>
  )
}
