import { getLoginHeadersForApp } from '@percent/domain/iam'
import { User } from '@percent/admin-dashboard/context/auth/authContext/AuthContext.types'
import { PBPGetResponse } from '@percent/admin-dashboard/services/pbpResponses'
import { AuthServiceCapabilities, OptKeyConfirmResponse, OtpKeyResponse } from './auth.types'

export const authService = ({ percentClient }: AuthServiceCapabilities) => ({
  login: (loginDetails: { email: string; password: string; enforce?: boolean }) =>
    percentClient.post<PBPGetResponse<User>>(
      '/v1/auth/login',
      { ...loginDetails },
      {
        headers: getLoginHeadersForApp('adminDashboard')
      }
    ),
  getAccount: () => percentClient.get('/v1/account'),
  logout: () => percentClient.post('/v1/auth/logout'),
  confirmOtpKey: ({ otpCode }: { otpCode: string }) =>
    percentClient.post<PBPGetResponse<OptKeyConfirmResponse>>('v1/account/otp-key-confirm', { otpCode }),
  generateQRCode: ({ password }: { password: string }) =>
    percentClient.post<OtpKeyResponse>('/v1/account/otp-key', { password }),
  verifyOtpKey: ({ token }: { token: string }) => percentClient.post('v1/auth/otp-verify', { token }),
  loginWithRecoveryCode: ({ backupCode }: { backupCode: string }) =>
    percentClient.post('/v1/auth/recovery', { backupCode }),
  setPassword: ({ password, hash }: { hash: string; password: string }) =>
    percentClient.post('/v1/auth/reset-password', { password, hash }),
  forgotPassword: (email: string) =>
    percentClient.post('/v1/auth/forgot-password', { accountType: 'admin', email: email.trim() }),
  sso: (email: string) => percentClient.post(``, { email: email.trim() }),
  getSSOConfig: ({ partnerId }: { partnerId: string }) =>
    percentClient.get(`/v1/admin/partners/${partnerId}/sso/config`),
  putSSOConfig: (config: { partnerId: string; metadataUrl: string; domains: string[] }) =>
    percentClient.put(`/v1/admin/partners/${config.partnerId}/sso/config`, {
      metadataUrl: config.metadataUrl,
      domains: config.domains
    })
})
