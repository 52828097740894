import { Box, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import styles from '../DonationRequestDetails.module.scss'

import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { IndividualDonationsProps } from './IndividualDonations.types'
import { Loader } from '@percent/admin-dashboard/common/components'
import { useQueryList } from '@percent/admin-dashboard/common/hooks'
import { IndividualDonationsTable } from './IndividualDonationsTable/IndividualDonationsTable'
import { QueryStatus } from '@percent/admin-dashboard/common/hooks/useQueryList/useQueryList.types'

const PAGE_SIZE = 10

export function IndividualDonations({ donationRequestId }: IndividualDonationsProps) {
  const { donationRequestsService } = useServices()
  const { t } = useTranslation()

  const [response, { nextPage, previousPage }] = useQueryList(
    donationRequestsService.getIndividualDonationsEnhancedList(donationRequestId),
    { pageSize: PAGE_SIZE }
  )
  const { queryStatus, totalResults } = response

  return (
    <>
      <Typography variant="h6" className={styles.tableTitle}>
        {t('typography.individualDonations')}
      </Typography>
      {queryStatus === QueryStatus.LOADING ? (
        <Box className={styles.loaderBox}>
          <Loader size={20} />
        </Box>
      ) : queryStatus === QueryStatus.ERROR ? (
        <Typography variant="body1" className={styles.error}>
          {response.errorMessage}
        </Typography>
      ) : (
        <IndividualDonationsTable
          totalResults={totalResults}
          data={response.data}
          previousPage={previousPage}
          nextPage={nextPage}
        />
      )}
    </>
  )
}
