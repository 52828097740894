export const mockAutoRejectionListResponse = {
  data: [
    {
      id: 'staging_autorejectionlist_000000CvowrZ88yZOzbIQzMO5a2aG',
      email: 'dawid.krakowczyk@poweredbypercent.com',
      rejectionReasonCode: 'other'
    },
    {
      id: 'staging_autorejectionlist_000000CvmT0AQoOaJ8wi7U7XpYIWu',
      email: 'natalia+120920242155@poweredbypercent.com',
      rejectionReasonCode: 'other'
    },
    {
      id: 'staging_autorejectionlist_000000CvmSzwiWjy1h4YXUPsdfJ4K',
      email: 'natalia+120920242154@poweredbypercent.com',
      rejectionReasonCode: 'other'
    },
    {
      id: 'staging_autorejectionlist_000000CvcPIn1llUi4BoRCGMPOQXC',
      email: 'ewaryst+test16@poweredbypercent.com',
      rejectionReasonCode: 'other'
    }
  ],
  totalResults: 4,
  object: 'agent_verification_admin_auto_rejection_list_agent',
  _links: {
    current: 'https://staging-api.poweredbypercent.com/v1/admin/agent-verifications-auto-rejection-list?pageSize=25'
  }
}
