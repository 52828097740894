import { useEffect, useState } from 'react'

import { CountriesContext } from '../countriesContext/CountriesContext'

import { Countries } from '@percent/admin-dashboard/api/actions/countries/countriesService.types'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { useAuthState } from '@percent/admin-dashboard/common/hooks'
import { logger } from '@percent/admin-dashboard/common/utility/logger'
import { CountriesProviderProps } from './CountriesProvider.types'

export function CountriesProvider({ children }: CountriesProviderProps) {
  const [countries, setCountries] = useState<Countries[]>([])
  const { countriesService } = useServices()
  const { isAuthorised } = useAuthState()

  useEffect(() => {
    const getCountries = async () => {
      try {
        const response = await countriesService.getCountries()

        if (response?.status) {
          setCountries(response.data.data)
        }
      } catch (e) {
        logger(e)
      }
    }

    if (isAuthorised) {
      getCountries()
    }
  }, [countriesService, isAuthorised])

  return <CountriesContext.Provider value={countries}>{children}</CountriesContext.Provider>
}
