import { ReactNode } from 'react'

import { Badge, BadgeVariant, Spacer } from '@percent/lemonade'

type FlagBadgeProps = {
  children: ReactNode
  variant: BadgeVariant
}

export function FlagBadge({ variant, children }: FlagBadgeProps) {
  return (
    <>
      <Spacer size={2} />
      <Badge variant={variant} icon="flag">
        {children}
      </Badge>
    </>
  )
}
