import React, { useMemo, useState, EventHandler, KeyboardEvent, useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { PopperPlacementType, TableCell, TableRow } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { TableColumn, TableSort } from '../../../common/components/table/Table.types'

import { DateRangePopper } from '@percent/admin-dashboard/common/components/dateRange/dateRangePopper/DateRangePopper'
import { ReactComponent as DropdownSVG } from '@percent/admin-dashboard/common/assets/images/dropdown.svg'
import { getDate } from '@percent/admin-dashboard/common/utility/getDate'
import { ErrorView, SearchAndDateFilter, Table, TimeLeft } from '@percent/admin-dashboard/common/components'
import { useBadgeTotal } from '@percent/admin-dashboard/context/badgeTotal/useBadgeTotal/useBadgeTotal'
import { CountryCodeFlag } from '@percent/admin-dashboard/common/components/countryCodeFlag/CountryCodeFlag'
import { AgentVerificationStateFilter, AgentVerificationTableProps } from './AgentVerificationTable.types'
import styles from './AgentVerificationTable.module.scss'
import { AgentVerificationLog, AgentVerificationStatus } from '@percent/admin-dashboard/api/types'
import { LocaleKey } from '@percent/admin-dashboard/i18n/config'

const mapExternalActionStatus = (
  status: AgentVerificationStatus,
  logs?: AgentVerificationLog[]
): LocaleKey | undefined => {
  const documentRequested = logs?.find(log => log.type === 'agent_verification_document_request_email_sent')
  const documentUploaded = logs?.find(log => log.type === 'agent_verification_document_uploaded')

  if (documentRequested && !documentUploaded) {
    return 'typography.documentRequested'
  }

  if (status === AgentVerificationStatus.PENDING_USER_VERIFICATION) {
    return 'typography.waitingAVClick'
  }

  if ([AgentVerificationStatus.PENDING_REVIEW, AgentVerificationStatus.PENDING].includes(status)) {
    return 'typography.waitingNPReply'
  }

  return undefined
}

export function AgentVerificationTable({
  isLoading,
  validations,
  totalResults,
  nextPage,
  previousPage,
  errorMessage,
  queryParams,
  setQueryParams
}: AgentVerificationTableProps) {
  const { t } = useTranslation()
  const { push } = useHistory()
  const [isDatePickerOpened, setIsDatePickerOpened] = useState(false)
  const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLElement) | null>()
  const [placement, setPlacement] = useState<PopperPlacementType>('bottom-end')
  const { totalNumber, setTotalNumber } = useBadgeTotal()

  const agentVerificationState = queryParams.state

  useEffect(() => {
    if (agentVerificationState === 'percent_actionable') {
      setTotalNumber({
        ...totalNumber,
        totalPendingAgentVerification: totalResults ?? 0
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalResults])

  const tabs = useMemo(
    () => [
      t('table.all'),
      t('table.percentActionNeeded'),
      t('table.externalActionNeeded'),
      t('table.accepted'),
      t('table.rejected')
    ],
    [t]
  )

  const columns = useMemo(() => {
    const commonColumns: Array<TableColumn> = [
      { id: 'country', isSortable: false },
      { id: 'organization', isSortable: false },
      { id: 'agentName', isSortable: false },
      { id: 'agentEmail', isSortable: false },
      { id: 'partner', isSortable: false },
      { id: 'requestDate', isSortable: true }
    ]

    if (agentVerificationState === 'external_actionable') {
      commonColumns.splice(4, 0, { id: 'status', isSortable: false })
    }

    if (agentVerificationState === 'percent_actionable' || agentVerificationState === 'external_actionable') {
      commonColumns.push({ id: 'timeLeft', isSortable: false })
    }

    return commonColumns
  }, [agentVerificationState])

  const handleRequestSort = () =>
    setQueryParams({ direction: queryParams.direction === 'ASC' ? 'DESC' : 'ASC', sort: 'createdAt' })

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    const state = newValue !== AgentVerificationStateFilter.all ? AgentVerificationStateFilter[newValue] : undefined
    setQueryParams({ state, sort: undefined, direction: undefined })
  }

  const handleClick = useCallback(
    (newPlacement: PopperPlacementType) => (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(anchorEl ? null : event.currentTarget)
      setIsDatePickerOpened(prev => !prev)
      setPlacement(newPlacement)
    },
    [anchorEl]
  )

  const handleKeyPress: EventHandler<KeyboardEvent<HTMLInputElement>> = useCallback(
    event => {
      if (event.key === 'Enter') {
        setQueryParams({
          query: (event.target as HTMLInputElement).value
        })
      }
    },
    [setQueryParams]
  )

  const handleClearDate = useCallback(
    () => setQueryParams({ startDate: undefined, endDate: undefined }),
    [setQueryParams]
  )

  const filters = useMemo(
    () => (
      <SearchAndDateFilter
        handleClick={handleClick}
        handleKeyPress={handleKeyPress}
        handleClearDate={handleClearDate}
        searchPhrase={queryParams.query ?? ''}
        placeholder={t('table.adminAVSearchPlaceholder')}
      />
    ),
    [handleClick, handleKeyPress, queryParams.query, handleClearDate, t]
  )

  const handleRowClick = (id: string) => {
    return push(`/agent-verification/${id}`, agentVerificationState)
  }

  if (errorMessage) {
    return <ErrorView errorMessage={errorMessage} />
  }

  return (
    <>
      <Table
        isLoading={isLoading}
        totalResults={totalResults}
        data={validations}
        value={
          AgentVerificationStateFilter[agentVerificationState as keyof typeof AgentVerificationStateFilter] ??
          AgentVerificationStateFilter.all
        }
        handleChange={handleTabChange}
        nextPage={nextPage}
        previousPage={previousPage}
        columns={columns}
        tabs={tabs}
        order={(queryParams.direction?.toLowerCase() as TableSort) ?? 'asc'}
        orderBy="requestDate"
        handleRequestSort={handleRequestSort}
        emptyTableText={t('table.emptyAgentVerifications')}
        filtersContent={filters}
      >
        {validations?.map(agentVerification => {
          const externalActionableStatus =
            agentVerificationState === 'external_actionable'
              ? mapExternalActionStatus(agentVerification.status, agentVerification.agentVerificationLogs)
              : null

          return (
            <TableRow
              key={agentVerification.id}
              data-testid="agent-verification-row"
              className={agentVerification.organisationId ? styles.row : undefined}
              onClick={() => agentVerification.organisationId && handleRowClick(agentVerification.id)}
            >
              <CountryCodeFlag countryCode={agentVerification.organisation?.countryCode} />
              <TableCell>{agentVerification.organisation?.name || agentVerification.validationRequest?.name}</TableCell>
              <TableCell>{`${agentVerification.firstName} ${agentVerification.lastName}`}</TableCell>
              <TableCell>{agentVerification.email.substring(0, agentVerification.email.lastIndexOf('@'))}</TableCell>
              {externalActionableStatus && (
                <TableCell className={styles.agentVerificationStatus}>{t(externalActionableStatus)}</TableCell>
              )}
              <TableCell>{agentVerification?.partner?.name ?? ''}</TableCell>
              <TableCell>{getDate(agentVerification.createdAt)}</TableCell>
              {(agentVerificationState === 'percent_actionable' ||
                agentVerificationState === 'external_actionable') && (
                <TableCell className={styles.nameRow}>
                  <TimeLeft requestedDate={agentVerification.createdAt} />
                </TableCell>
              )}
              {agentVerification.organisationId && (
                <TableCell align="right" style={{ padding: '16px 0' }} className={styles.rowIconForward}>
                  <DropdownSVG className={styles.arrow} />
                </TableCell>
              )}
            </TableRow>
          )
        })}
      </Table>
      <DateRangePopper
        open={isDatePickerOpened}
        anchorEl={anchorEl}
        placement={placement}
        setOpen={setIsDatePickerOpened}
        setAnchorEl={setAnchorEl}
        setPlacement={setPlacement}
        setQueryParams={setQueryParams}
      />
    </>
  )
}
