import { useTranslation } from 'react-i18next'

import { ReactComponent as ExpandLogo } from '@percent/admin-dashboard/common/assets/images/expand.svg'
import { colors } from '@percent/admin-dashboard/identity'
import { getProperHref } from '@percent/admin-dashboard/common/utility/getProperHref'
import { WebsiteCopyLinkProps } from './WebsiteCopyLink.types'
import styles from './WebsiteCopyLink.module.scss'
import { Copy } from '@percent/lemonade'

export function WebsiteCopyLink({ orgWebsite }: WebsiteCopyLinkProps) {
  const { t } = useTranslation()

  if (!orgWebsite) return null

  return (
    <span className={styles.subtext}>
      {orgWebsite}{' '}
      <Copy text={orgWebsite} reversed>
        <p className={styles.websiteText}>{t('typography.copy')}</p>
      </Copy>
      <a href={getProperHref(orgWebsite)} target="_blank" rel="noopener noreferrer">
        <ExpandLogo width="28px" height="28px" fill={colors.secondary} />
        <p className={styles.websiteText}>{t('typography.openInNewTab')}</p>
      </a>
    </span>
  )
}
