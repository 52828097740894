import { Button } from '@material-ui/core'
import React from 'react'

import styles from './PercentButton.module.scss'
import { PercentButtonProps } from './PercentButton.types'

export function PercentButton({
  alignment = 'alignCenter',
  className,
  colour = 'primary',
  disabled,
  fullWidth = false,
  outlined = false,
  onClick,
  testId = '',
  textColour = 'primaryTextColour',
  title,
  type = 'button',
  underline = false,
  endIcon,
  children
}: PercentButtonProps) {
  const finalClassName = className || ''
  const fullWidthClassName = fullWidth ? 'fullWidth' : ''
  const outlinedClassName = outlined ? 'outlined' : ''
  const underlineClassName = underline ? 'underline' : ''
  const classNames = `${styles[alignment]} ${styles[fullWidthClassName]} ${styles[outlinedClassName]} ${styles[textColour]} ${styles[underlineClassName]} ${finalClassName}`

  return (
    <Button
      color={outlined ? undefined : colour}
      data-testid={testId}
      disabled={disabled}
      disableElevation
      className={classNames}
      onClick={onClick}
      type={type}
      variant="contained"
      endIcon={endIcon}
    >
      {title}
      {children}
    </Button>
  )
}
