import { Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { Icon } from '@percent/lemonade'
import styles from './ErrorInfo.module.scss'
import { ErrorInfoProps } from './ErrorInfo.types'

export function ErrorInfo({ header, reasons, footer }: ErrorInfoProps) {
  return (
    <Alert
      severity="error"
      variant="standard"
      icon={<Icon name="reject" size={6} color="alert400" className={styles.infoIcon} />}
      className={styles.wrapper}
    >
      <Typography className={styles.text}>
        <Typography className={styles.header} variant="body2">
          {header}
        </Typography>
        {reasons ? (
          <ul className={styles.list}>
            {reasons.map(reason => (
              <Typography variant="body2" component="li" key={reason}>
                {reason}
              </Typography>
            ))}
          </ul>
        ) : null}
        {footer ? (
          <Typography className={styles.footer} variant="body2">
            {footer}
          </Typography>
        ) : null}
      </Typography>
    </Alert>
  )
}
