export enum Variant {
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected'
}

export type StatusProps = {
  variant: Variant
  text?: string
  className?: string
}

export type StatusVariant = 'positive' | 'critical' | 'default'
