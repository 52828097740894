import { TableCell } from '@material-ui/core'

import { Tooltip } from '../tooltip/Tooltip'

import { getCountryFromAlpha3 } from '@percent/admin-dashboard/common/utility/getCountryFromCountryCode'
import { CountryCodeFlagProps } from './CountryCodeFlag.types'
import { FlagAvatar } from '@percent/lemonade'

export function CountryCodeFlag({ countryCode }: CountryCodeFlagProps) {
  return (
    <Tooltip title={getCountryFromAlpha3(countryCode)}>
      <TableCell>
        <FlagAvatar code3={countryCode} />
      </TableCell>
    </Tooltip>
  )
}
