export const mockActivityChecksList = {
  _links: {
    current:
      'https://staging-api.poweredbypercent.com/v1/admin/activity-checks?pageSize=0&sort=createdAt&status=pending'
  },
  data: [],
  totalResults: 1004,
  pageSize: 0,
  object: 'activity_check_admin_list_item'
}
