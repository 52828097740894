import { rest } from 'msw'

import { config } from '@percent/admin-dashboard/config'
import { mockGetMilestones } from '@percent/admin-dashboard/fixtures/api/milestones/mockGetMilestones'

export const milestonesHandlers = [
  rest.get(`${config.urls.api}v1/organisations/:id/milestones`, (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockGetMilestones))
  }),
  rest.post(`${config.urls.api}v1/organisations/:id/milestones`, (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockGetMilestones))
  }),
  rest.patch(`${config.urls.api}v1/organisations/:id/milestones/:milestoneId`, (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockGetMilestones))
  }),
  rest.delete(`${config.urls.api}v1/organisations/:id/milestones/:milestoneId`, (_, res, ctx) => {
    return res(ctx.status(200))
  })
]
