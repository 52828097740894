import React from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as PendingSVG } from '@percent/admin-dashboard/common/assets/images/tableStatus/pending.svg'
import { ReactComponent as FlagSVG } from '@percent/admin-dashboard/common/assets/images/tableStatus/flag.svg'
import { ReactComponent as TickSVG } from '@percent/admin-dashboard/common/assets/images/tableStatus/tick.svg'
import { ReactComponent as NaSVG } from '@percent/admin-dashboard/common/assets/images/tableStatus/na.svg'
import { StatusBoxProps } from './ComplianceDetails.types'
import styles from './ComplianceDetails.module.scss'

export function StatusBox({ status }: StatusBoxProps) {
  const { t } = useTranslation()

  if (status === 'yes') {
    return (
      <span className={styles.flag}>
        <FlagSVG /> {t('status.yes')}
      </span>
    )
  }

  if (status === 'no') {
    return (
      <span className={styles.clear}>
        <TickSVG /> {t('status.no')}
      </span>
    )
  }

  if (status === 'accepted' || status === 'clear') {
    return (
      <span className={styles.clear}>
        <TickSVG /> {t('status.clear')}
      </span>
    )
  }

  if (status === 'flag') {
    return (
      <span className={styles.flag}>
        <FlagSVG /> {t('status.flag')}
      </span>
    )
  }

  if (status === 'pending' || status === 'pending_review' || status === null) {
    return (
      <span className={styles.pending}>
        <PendingSVG /> {t('status.pending')}
      </span>
    )
  }

  if (status === 'fail') {
    return (
      <span className={styles.flag}>
        <FlagSVG /> {t('status.fail')}
      </span>
    )
  }

  if (status === 'pass') {
    return (
      <span className={styles.clear}>
        <TickSVG /> {t('status.pass')}
      </span>
    )
  }

  return (
    <span className={styles.na}>
      <NaSVG /> {t('status.na')}
    </span>
  )
}
