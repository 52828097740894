import { ChangeEvent, EventHandler, KeyboardEvent, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { NoResultTable } from '@percent/admin-dashboard/common/components'
import { ActivityTagsProps } from './ActivityTags.types'
import styles from './ActivityTags.module.scss'
import { Checkbox, SearchInput } from '@percent/lemonade'
import { useFeatureFlag } from '@percent/admin-dashboard/common/hooks/useFeatureFlag/useFeatureFlag'
import { sortEducationSubtags } from '@percent/admin-dashboard/common/utility/sortEducationSubtags'
import { useActivityTags } from '@percent/admin-dashboard/common/hooks/useActivityTags/useActivityTags'
import { ActivitySubTag as ActivitySubTagType } from '@percent/admin-dashboard/api/types'
import { ActivitySubTag as ActivitySubTagComponent } from '@percent/admin-dashboard/common/components/activitySubTag/ActivitySubTag'
import { DecoratedActivitySubTag } from '@percent/admin-dashboard/common/hooks/useActivityTags/useActivityTags.types'

export function ActivityTags({
  subTagsForm,
  onSubTagChange,
  isFlagged,
  isOnlyEducationOrganisation,
  onEducationSubTagChange,
  isNoneOfTheAbove,
  setIsNoneOfTheAbove,
  setNotDeprecatedSubTags
}: Readonly<ActivityTagsProps>) {
  const [hasOnlyEduStageSubTags, setHasOnlyEduStageSubTags] = useState(subTagsForm.length === 0)
  const handleNewEducationSubTagsFlow = isOnlyEducationOrganisation && hasOnlyEduStageSubTags
  const { activityTags, setSearchActivityTagsValue, mapHitsToTags } = useActivityTags({
    filterByEducation: handleNewEducationSubTagsFlow
  })

  const { educationSubtagsNoneOfTheAboveFeatureFlag } = useFeatureFlag()
  const { t } = useTranslation()
  const [subTagsFormInitialValue] = useState(subTagsForm)
  const [searchValue, setSearchValue] = useState('')
  const [activitySubTags, setActivitySubTags] = useState<DecoratedActivitySubTag[]>([])
  const [filteredActivitySubTags, setFilteredActivitySubTags] = useState<DecoratedActivitySubTag[]>([])
  const [deprecatedSubtags, setDeprecatedSubtags] = useState<ActivitySubTagType[]>([])

  const tags = mapHitsToTags(filteredActivitySubTags)

  useEffect(
    () =>
      setActivitySubTags(
        activityTags?.flatMap(({ id, name, subTags }) =>
          subTags.map(subTag => ({ ...subTag, parentTagName: name, parentTagId: id }))
        )
      ),
    [activityTags]
  )

  useEffect(() => {
    if (subTagsFormInitialValue.length > 0) {
      setHasOnlyEduStageSubTags(
        subTagsFormInitialValue.every(
          initialSubTag => activitySubTags.find(s => s.id === initialSubTag.id)?.isEducationStage
        )
      )
    }

    const filteredSubTags = activitySubTags.filter(
      subTag =>
        !subTag.deprecatedAt ||
        (!!subTag.deprecatedAt && subTagsFormInitialValue.findIndex(s => s.id === subTag.id) !== -1)
    )

    setDeprecatedSubtags(filteredSubTags.filter(subTag => !!subTag.deprecatedAt))
    setNotDeprecatedSubTags(subTagsForm.filter(subTag => !filteredSubTags.find(s => s.id === subTag.id)?.deprecatedAt))

    if (handleNewEducationSubTagsFlow) {
      setFilteredActivitySubTags([...filteredSubTags].sort(sortEducationSubtags))
    } else {
      setFilteredActivitySubTags(filteredSubTags)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activitySubTags, handleNewEducationSubTagsFlow, subTagsFormInitialValue])

  useEffect(() => {
    if (subTagsFormInitialValue.length > 0 && subTagsFormInitialValue.every(subTag => subTag.meetsCriteria === false)) {
      setIsNoneOfTheAbove(true)
    }
  }, [subTagsFormInitialValue, setIsNoneOfTheAbove])

  const isSubTagSelected = (subTagId: string) =>
    subTagsForm.find(subTag => subTag.id === subTagId)?.meetsCriteria ||
    deprecatedSubtags.findIndex(subTag => subTag.id === subTagId) !== -1 ||
    false

  const handleKeyChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value)
  }

  const onSubTagValueChange = (subTagId: string) => {
    if (handleNewEducationSubTagsFlow) {
      const notDeprecatedHits = activitySubTags.filter(subTag => !subTag.deprecatedAt)

      const educationSubTags =
        subTagsForm.length > 0
          ? notDeprecatedHits.map(subTag => ({
              id: subTag.id,
              meetsCriteria: subTagsForm.find(({ id }) => id === subTag.id)?.meetsCriteria || false
            }))
          : notDeprecatedHits.map(subTag => ({ id: subTag.id, meetsCriteria: false }))

      onEducationSubTagChange(educationSubTags, subTagId)
    } else {
      onSubTagChange(subTagId)
    }
  }

  const onNoneOfAboveChange = () => {
    setIsNoneOfTheAbove(!isNoneOfTheAbove)
    onEducationSubTagChange(
      activitySubTags.filter(subTag => !subTag.deprecatedAt).map(subTag => ({ id: subTag.id, meetsCriteria: false }))
    )
  }

  const handleKeyPress: EventHandler<KeyboardEvent<HTMLInputElement>> = useCallback(
    event => {
      if (event.key === 'Enter') {
        setSearchValue((event.target as HTMLInputElement).value)
      }

      if (event.key === 'Escape') {
        setSearchValue('')
      }

      return null
    },
    [setSearchValue]
  )

  const handleClearValue = () => {
    setSearchValue('')
  }

  useEffect(() => {
    setSearchActivityTagsValue(searchValue)
  }, [setSearchActivityTagsValue, searchValue])

  return (
    <div className={styles.container} data-testid="eligibility-activity-tags">
      {!handleNewEducationSubTagsFlow && (
        <SearchInput
          value={searchValue ?? ''}
          handleClearValue={handleClearValue}
          onKeyDown={handleKeyPress}
          onChange={handleKeyChange}
          placeholder={t('typography.findATag')}
          disabled={!activityTags.length && !searchValue}
        />
      )}
      {tags?.length === 0 ? (
        <div className={styles.noResults}>
          <NoResultTable text={t('typography.noResultsFound')} type="default" />
        </div>
      ) : (
        <ul className={styles.tagsList}>
          {tags?.map(tag => (
            <li key={tag.id}>
              <div className={styles.tagTitle}>
                <p>{tag.name.toUpperCase()}</p>
              </div>
              <div className={styles.subTagsContainer}>
                {tag.subTags.map((subTag, index) => (
                  <ActivitySubTagComponent
                    key={subTag.id}
                    active={isSubTagSelected(subTag.id)}
                    disabled={
                      isFlagged || isNoneOfTheAbove || !!deprecatedSubtags.find(s => s.id === subTag.id)?.deprecatedAt
                    }
                    subTag={subTag}
                    searchWords={subTag.highlightedWords || []}
                    index={index}
                    onChange={onSubTagValueChange}
                  />
                ))}
              </div>
            </li>
          ))}
          {educationSubtagsNoneOfTheAboveFeatureFlag && handleNewEducationSubTagsFlow && (
            <li>
              <div className={styles.tagTitle}>
                <p>{t('typography.noneOfTheAbove').toUpperCase()}</p>
              </div>
              <div className={styles.subTagsContainer}>
                <Checkbox
                  variant="default"
                  name="nonOfAbove"
                  value="nonOfAbove"
                  onChange={onNoneOfAboveChange}
                  disabled={isFlagged}
                  active={isNoneOfTheAbove}
                  label={t('typography.noneOfTheAboveMatches')}
                />
              </div>
            </li>
          )}
        </ul>
      )}
    </div>
  )
}
