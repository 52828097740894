import { useMemo } from 'react'

import { useMutation } from '@percent/admin-dashboard/common/hooks'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { AgentVerificationRejectionReasonDropdownCodes } from '@percent/admin-dashboard/api/actions/agentVerification/agentVerification.types'
import { useFeatureFlag } from '@percent/admin-dashboard/common/hooks/useFeatureFlag/useFeatureFlag'

const partnerIdsThisShouldBeAppliedTo = [
  'partner_000000CiL7SvR6AJFXR6pnv6yTr9c', // PROD
  'staging_partner_000000CiJ8pz5hageQdgbzOBZZxaa',
  'sandbox_partner_000000CiJA70gHV0qRp0iNONwz0F6',
  'qa_partner_000000Cj94GwIctWFaWyB99Zmmusi'
]

const REJECTION_CODE = AgentVerificationRejectionReasonDropdownCodes.UserFailedPercentReview

export interface ClaimFlowApproveDomainActionParams {
  applicantEmail: string
  organisationUrl: string
  organisationEmail: string
  agentVerificationId: string
}

export const getEmailDomain = (email: string) => {
  const emailParts = email.split('@')

  if (emailParts.length < 2) return 'email_invalid'

  return emailParts[emailParts.length - 1].toLowerCase()
}

export const getDomainFromUrl = (urlParam: string) => {
  try {
    let url = urlParam

    if (!url.startsWith('http://') && !url.startsWith('https://') && url.includes('.')) url = `https://${url}`
    const asUrl = new URL(url)

    return asUrl.hostname.replace(/^www\./i, '').toLowerCase()
  } catch {
    return 'url_invalid'
  }
}

const checkForDomainMatch = (applicantEmail: string, organisationUrl: string, organisationEmail: string) => {
  const applicantEmailDomain = getEmailDomain(applicantEmail)
  const organisationUrlDomain = getDomainFromUrl(organisationUrl)
  const organisationEmailDomain = getEmailDomain(organisationEmail)

  return applicantEmailDomain === organisationUrlDomain || applicantEmailDomain === organisationEmailDomain
}

export const useClaimApproveDomainTemporaryFix = (partnerId: string, onComplete: () => void) => {
  const { claimsDomainMatchingTemporaryFixFeatureFlag } = useFeatureFlag()

  const shouldApplyFix = useMemo(
    () => claimsDomainMatchingTemporaryFixFeatureFlag && partnerIdsThisShouldBeAppliedTo.includes(partnerId),
    [claimsDomainMatchingTemporaryFixFeatureFlag, partnerId]
  )
  const { agentVerificationService } = useServices()

  const [{ isLoading: isCurrentlyRejecting }, { apiFunc: rejectAgentVerification }] = useMutation(
    agentVerificationService.rejectAgentVerification,
    () => {
      onComplete()
    }
  )

  const [{ isLoading: isCurrentlyApproving }, { apiFunc: approveDomains }] = useMutation(
    agentVerificationService.approveDomain,
    data => {
      evaluateDomainCheckResponse(data?.data?.data)
    }
  )

  const evaluateDomainCheckResponse = (data: any) => {
    if (['approved', 'rejected'].includes(data.status)) {
      onComplete()

      return
    }

    rejectAgentVerification({
      id: data.id as string,
      rejectionReasonCode: REJECTION_CODE
    })
  }

  const applyCustomActions = (claimFlowProps: ClaimFlowApproveDomainActionParams) => {
    if (!shouldApplyFix) return

    const { applicantEmail, organisationUrl, organisationEmail, agentVerificationId } = claimFlowProps

    const isApplicantDomainValid = checkForDomainMatch(applicantEmail, organisationUrl, organisationEmail)

    if (!isApplicantDomainValid) {
      // FAIL
      rejectAgentVerification({
        id: agentVerificationId,
        rejectionReasonCode: REJECTION_CODE
      })

      return
    }

    // In the onComplete callback for approveDomains we check
    // if the application is in a terminal state.
    // If it is not, we reject the application.
    approveDomains({
      id: agentVerificationId
    })
  }

  return {
    shouldApplyFix,
    applyCustomActions,
    isLoading: isCurrentlyApproving || isCurrentlyRejecting
  }
}
