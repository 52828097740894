import { useState } from 'react'

import { VerifySelectedOrganizationError } from '../VerifyOrganizationModal/VerifySelectedOrganizationError'
import { VerifySelectedOrganizationSuccess } from '../VerifyOrganizationModal/VerifySelectedOrganizationSuccess'

import { DonationMatchRequestDetailsOrganisationDetailsView } from './DonationMatchRequestDetailsOrganisationDetailsView'
import { DonationMatchRequestStatus } from '@percent/admin-dashboard/api/actions/donationMatchRequests/donationMatchRequests.types'
import { ErrorView, Loader } from '@percent/admin-dashboard/common/components'
import { useMutation, useQuery } from '@percent/admin-dashboard/common/hooks'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'

type DonationMatchRequestDetailsOrganisationDetailsProps = {
  organisationId: string
  status: DonationMatchRequestStatus
  donationMatchRequestId: string
  refresh?: VoidFunction
}

export function DonationMatchRequestDetailsOrganisationDetails({
  organisationId,
  status,
  donationMatchRequestId,
  refresh
}: DonationMatchRequestDetailsOrganisationDetailsProps) {
  const { adminService, donationMatchingRequestService } = useServices()
  const [modalSuccessState, setModalSuccessState] = useState(false)
  const [modalErrorState, setModalErrorState] = useState(false)
  const handleModalSuccessState = () => {
    setModalSuccessState(prevState => !prevState)
  }
  const handleModalErrorState = () => {
    setModalErrorState(prevState => !prevState)
  }

  const [{ isLoading: isLoadingVerify }, { apiFunc }] = useMutation(
    donationMatchingRequestService.verifyDonationMatchRequestOrganisation,
    () => {
      setModalSuccessState(true)
    },
    () => {
      setModalErrorState(true)
    }
  )

  const handleVerify = async () => {
    await apiFunc({ donationMatchRequestId })
  }

  const handleOnSuccess = () => {
    handleModalSuccessState()
    refresh!()
  }
  const [{ data, isLoading, errorMessage }] = useQuery(adminService.getOrganisation, {
    id: organisationId
  })

  if (isLoading) {
    return <Loader />
  }

  if (errorMessage) {
    return <ErrorView errorMessage={errorMessage} />
  }

  return (
    <>
      <DonationMatchRequestDetailsOrganisationDetailsView
        data={{ type: 'organisation', data }}
        status={status}
        refresh={refresh}
        isLoading={isLoadingVerify}
        handleVerify={handleVerify}
        donationMatchRequestId={donationMatchRequestId}
      />
      {modalErrorState && <VerifySelectedOrganizationError open={modalErrorState} onClose={handleModalErrorState} />}
      {modalSuccessState && (
        <VerifySelectedOrganizationSuccess
          handleOnSuccess={handleOnSuccess}
          open={modalSuccessState}
          onClose={handleModalSuccessState}
          organisationName={data?.name}
        />
      )}
    </>
  )
}
