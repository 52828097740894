import React, { useCallback } from 'react'
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params'

import { useDidMountEffect, useQueryList } from '@percent/admin-dashboard/common/hooks'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { OrganizationsTable } from './organizationsTable/OrganizationsTable'

const PAGE_SIZE = 25

export function OrganizationsContainer() {
  const { adminService } = useServices()

  const [queryParams, setQueryParams] = useQueryParams({
    query: StringParam,
    page: withDefault(NumberParam, 1)
  })

  const [{ totalResults, isLoading, dataOrNull, errorMessageOrNull }, { query, refresh }] = useQueryList(
    adminService.getOrganisations,
    {
      ...queryParams,
      page: queryParams.page || 1
    }
  )

  const handleNextPage = useCallback(() => {
    setQueryParams({
      query: queryParams.query,
      page: queryParams?.page ? queryParams.page + 1 : 1
    })
  }, [queryParams.page, queryParams.query, setQueryParams])

  const handlePreviousPage = useCallback(() => {
    setQueryParams({
      query: queryParams.query,
      page: queryParams?.page && queryParams.page > 1 ? queryParams.page - 1 : 1
    })
  }, [queryParams.page, queryParams.query, setQueryParams])

  useDidMountEffect(() => query(queryParams), [queryParams, query])

  return (
    <OrganizationsTable
      organisations={dataOrNull}
      totalResults={totalResults}
      nextPage={(queryParams?.page || 1) < totalResults / PAGE_SIZE ? handleNextPage : null}
      previousPage={(queryParams?.page || 1) > 1 ? handlePreviousPage : null}
      isLoading={isLoading}
      errorMessage={errorMessageOrNull}
      queryParams={queryParams}
      setQueryParams={setQueryParams}
      refresh={refresh}
    />
  )
}
