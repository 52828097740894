import { Grid, Paper, Typography } from '@material-ui/core'
import React from 'react'

import style from './ErrorView.module.scss'
import { ErrorViewProps } from './ErrorView.types'

export function ErrorView({ errorMessage, errorMessageSize = 'body1' }: ErrorViewProps) {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      className={style.errorContainer}
    >
      <Grid item xs={12} component={Paper}>
        <Typography variant={errorMessageSize} className={style.errorMessage}>
          {errorMessage}
        </Typography>
      </Grid>
    </Grid>
  )
}
