import React from 'react'
import { BooleanParam, NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params'

import { useDidMountEffect, useQueryList } from '@percent/admin-dashboard/common/hooks'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { ClaimsTable } from './claimsTable/ClaimsTable'

export function ClaimsContainer() {
  const { adminService } = useServices()

  const [queryParams, setQueryParams] = useQueryParams({
    query: StringParam,
    direction: StringParam,
    sort: StringParam,
    accepted: withDefault(BooleanParam, false),
    rejected: withDefault(BooleanParam, false),
    pageSize: withDefault(NumberParam, 100)
  })

  const [{ isLoading, totalResults, dataOrNull, errorMessageOrNull }, { query, nextPage, previousPage, refresh }] =
    useQueryList(adminService.getClaims, {
      ...queryParams
    })

  useDidMountEffect(() => query(queryParams), [queryParams, query])

  return (
    <ClaimsTable
      claims={dataOrNull}
      totalResults={totalResults}
      nextPage={nextPage}
      previousPage={previousPage}
      isLoading={isLoading}
      errorMessage={errorMessageOrNull}
      queryParams={queryParams}
      setQueryParams={setQueryParams}
      refresh={refresh}
    />
  )
}
