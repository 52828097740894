import { AgentVerificationRejectionReasonDropdownCodes } from '@percent/admin-dashboard/api/types'
import { OrganisationTypes } from '@percent/admin-dashboard/constants/organisationTypes'

export const educationRejectionReasonCodes = [
  AgentVerificationRejectionReasonDropdownCodes.InvalidDocumentation,
  AgentVerificationRejectionReasonDropdownCodes.BlankCorruptedDocumentation,
  AgentVerificationRejectionReasonDropdownCodes.MismatchedInformation,
  AgentVerificationRejectionReasonDropdownCodes.FakeEmailUsed,
  AgentVerificationRejectionReasonDropdownCodes.FakeDocumentation,
  AgentVerificationRejectionReasonDropdownCodes.SuspiciousApplication
]

export const nonprofitRejectionReasonCodes = [
  AgentVerificationRejectionReasonDropdownCodes.UserFailedPercentReview,
  AgentVerificationRejectionReasonDropdownCodes.Reapply,
  AgentVerificationRejectionReasonDropdownCodes.FakeEmailUsed,
  AgentVerificationRejectionReasonDropdownCodes.NoOnlinePresence,
  AgentVerificationRejectionReasonDropdownCodes.SuspiciousApplication,
  AgentVerificationRejectionReasonDropdownCodes.Other
]

export const googleRejectionReasonCodes = [
  AgentVerificationRejectionReasonDropdownCodes.UserFailedPercentReview,
  AgentVerificationRejectionReasonDropdownCodes.Reapply,
  AgentVerificationRejectionReasonDropdownCodes.FakeEmailUsed,
  AgentVerificationRejectionReasonDropdownCodes.NoOnlinePresence,
  AgentVerificationRejectionReasonDropdownCodes.InvalidDocumentation,
  AgentVerificationRejectionReasonDropdownCodes.BlankCorruptedDocumentation,
  AgentVerificationRejectionReasonDropdownCodes.MismatchedInformation,
  AgentVerificationRejectionReasonDropdownCodes.FakeDocumentation,
  AgentVerificationRejectionReasonDropdownCodes.SuspiciousApplication,
  AgentVerificationRejectionReasonDropdownCodes.Other
]

export const allRejectionReasonCodes = Object.values(AgentVerificationRejectionReasonDropdownCodes)

export const getRejectionReasonCodes = (allowedOrganisations: OrganisationTypes[], isGooglePartner = false) => {
  if (allowedOrganisations.length === 0) return allRejectionReasonCodes

  if (allowedOrganisations.length === 1 && allowedOrganisations.includes(OrganisationTypes.Education)) {
    return educationRejectionReasonCodes
  }

  if (allRejectionReasonCodes.length > 1 && allowedOrganisations.includes(OrganisationTypes.Education)) {
    return allRejectionReasonCodes
  }

  if (isGooglePartner) {
    return googleRejectionReasonCodes
  }

  return nonprofitRejectionReasonCodes
}
