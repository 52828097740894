import { Box } from '@material-ui/core'
import { forwardRef } from 'react'

import styles from '../SearchableSelect.module.scss'

export const SearchableSelectListComponent = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(
  function ListboxComponent(props, ref) {
    const { children, ...other } = props
    // TODO: https://github.com/mui/material-ui/issues/17010
    const allowedProps = { ref }

    return (
      <Box {...allowedProps} {...other} component="ul" className={styles.listBox}>
        {children}
      </Box>
    )
  }
)
