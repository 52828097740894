import React, { useState } from 'react'
import { DialogContent, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { Dialog, PercentButton } from '@percent/admin-dashboard/common/components'
import { ReactComponent as RejectedIcon } from '@percent/admin-dashboard/common/assets/images/rejected.svg'
import styles from './BankDetailsDocument.module.scss'
import { BankDetailsDocumentErrorDialogProps } from '@percent/admin-dashboard/app/bankDetails/bankDetailsView/bankDetailsDocument/BankDetailsDocumentErrorDialog.types'

export function BankDetailsDocumentErrorDialog({ error }: BankDetailsDocumentErrorDialogProps) {
  const { t } = useTranslation()
  const [dialogState, setDialogState] = useState(error)

  const handleClose = () => {
    setDialogState(false)
  }

  return (
    <Dialog withoutHeader openModal={dialogState} onClose={handleClose}>
      <DialogContent className={styles.errorDialogContent}>
        <RejectedIcon className={styles.icon} />
        <Typography variant="h5" align="center">
          {t('errorMessage.errorHasOccurred')}
        </Typography>
        <Typography variant="body1" align="center">
          {t('dialog.bankDetails.documentError')}
        </Typography>
        <div className={styles.singleButtonWrapper}>
          <PercentButton title={t('button.done')} onClick={handleClose} />
        </div>
      </DialogContent>
    </Dialog>
  )
}
