import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { DonationRequestsTable } from './donationRequestsTable/DonationRequestsTable'
import { useQueryList } from '@percent/admin-dashboard/common/hooks'
import { DonationRequestStatus } from '@percent/admin-dashboard/api/actions/donationRequests/donationRequests.types'

export function DonationRequestsContainer() {
  const { donationRequestsService } = useServices()
  const [
    { totalResults, queryParams, isLoading, dataOrNull, errorMessageOrNull },
    { nextPage, query, previousPage, refreshToFirstPage }
  ] = useQueryList(donationRequestsService.getDonationRequestsEnhancedList, { status: DonationRequestStatus.pending })

  return (
    <DonationRequestsTable
      isLoading={isLoading}
      totalResults={totalResults}
      data={dataOrNull}
      previousPage={previousPage}
      nextPage={nextPage}
      errorMessage={errorMessageOrNull}
      refreshToFirstPage={refreshToFirstPage}
      query={query}
      status={queryParams.status}
    />
  )
}
