import { rest } from 'msw'

import { config } from '@percent/admin-dashboard/config'
import { mockEligibilitySubscriptionsList } from '@percent/admin-dashboard/fixtures/api/eligibilitySubscription/mockEligibilitySubscriptionsList'
import { mockSuccessResponse } from '@percent/admin-dashboard/fixtures/api/mockSuccessResponse'
import { mockEligibilitySubscription } from '@percent/admin-dashboard/fixtures/api/eligibilitySubscription/mockEligibilitySubscription'

export const eligibilitySubscriptionsHandlers = [
  rest.get(`${config.urls.api}v1/admin/eligibility-subscriptions`, async (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockEligibilitySubscriptionsList))
  }),
  rest.get(`${config.urls.api}v1/admin/eligibility-subscriptions/:id`, async (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockSuccessResponse(mockEligibilitySubscription)))
  })
]
