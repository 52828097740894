import { VettingEventScope } from '@percent/admin-dashboard/api/types'
import { complyAdvantageEvents } from './sortVettingEvents'
import { getGoogleSearchLink } from '@percent/admin-dashboard/common/utility/getGoogleSearchLink'

const COMPLY_ADVANTAGE_BASE_URL = 'https://app.eu.complyadvantage.com/app/case-management'

export const getComplyAdvantageSearchLink = (complyAdvantageSearchId: string) =>
  `${COMPLY_ADVANTAGE_BASE_URL}/search/${complyAdvantageSearchId}`

export const getComplianceCheckLink = (
  vettingEventScope: VettingEventScope,
  organizationName: string,
  organizationWebsite: string,
  complyAdvantageSearchId?: string
): string | null => {
  if (complyAdvantageEvents.includes(vettingEventScope)) {
    if (!complyAdvantageSearchId) return COMPLY_ADVANTAGE_BASE_URL

    return getComplyAdvantageSearchLink(complyAdvantageSearchId)
  }

  if (vettingEventScope === 'hateSpeech') {
    return getGoogleSearchLink(`${organizationName} hate speech`)
  }

  return organizationWebsite || null
}
