import React, { useState } from 'react'
import styled from 'styled-components'
import { Button } from '../button'
import { Feedback } from '../feedback'
import { Label } from '../label'
import { AvatarInputProps } from './avatar-input.types'
import { Avatar } from '../avatar'
import { Spacer } from '../spacer'

const StyledFileInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.sizes.xs};
`

const StyledFileInput = styled.div`
  margin-left: ${({ theme }) => theme.sizes.s};
`

const StyledFileContainer = styled.div`
  display: flex;
  align-items: start;
`

export const AvatarInput = ({
  label = 'Upload a file',
  buttonTitle,
  onChange,
  errorMessage,
  description,
  image,
  altText,
  avatarSize,
}: AvatarInputProps) => {
  const hiddenFileInput = React.useRef<HTMLInputElement>(null)
  const [fileData, setFileData] = useState<string | null>(null)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.length) {
      const file = event.target.files[0]
      setFileData(URL.createObjectURL(file))
      onChange?.(file)
    }
  }

  return (
    <StyledFileInputContainer>
      <Label htmlFor="file-input">{label}</Label>
      <StyledFileContainer>
        <Avatar image={fileData || image} alt={altText} size={avatarSize} />
        <StyledFileInput>
          {errorMessage || description ? (
            <Feedback
              align="start"
              variant={errorMessage ? 'critical' : 'default'}
            >
              {errorMessage || description}
            </Feedback>
          ) : null}
          <Spacer size={1} axis="vertical" />
          <Button
            variant="secondary"
            onPress={() => hiddenFileInput.current?.click()}
            size="small"
          >
            {buttonTitle}
          </Button>
        </StyledFileInput>
      </StyledFileContainer>

      <input
        type="file"
        id="file-input"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: 'none' }}
      />
    </StyledFileInputContainer>
  )
}
