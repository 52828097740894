import { useControls } from 'react-zoom-pan-pinch'
import { Icon } from '../icon'
import { Tooltip } from '../tooltip'
import styled from 'styled-components'
import { GalleryControlsProps } from './documents-gallery.types'

const ControlsContainer = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;
    gap: ${theme.sizes.xxs};
    position: absolute;
    right: ${theme.sizes.xxs};
    top:${theme.sizes.xs};
    z-index: 10;
    padding: ${theme.sizes.xxs};
    border-radius: ${theme.sizes.xxs};
    background-color: ${theme.colors.white};
    box-sizing: border-box;
    box-shadow: ${theme.boxShadows.medium};
`}
`

const ControlsButton = styled.button`
  ${({ theme }) => `
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${theme.colors.white};
    border: none;
    border-radius: ${theme.sizes.xxs};

    :hover {
     background-color: ${theme.colors.gray70};
    }
`}
`

const openInNewTab = (url: string, previewUrl?: string) =>
  window.open(
    previewUrl ? `${previewUrl}?documentUrl=${encodeURIComponent(url)}` : url,
    '_blank'
  )

export const PdfControls = ({
  url,
  previewUrl,
}: Readonly<Pick<GalleryControlsProps, 'url' | 'previewUrl'>>) => (
  <ControlsContainer data-testid="pdf-controls">
    <Tooltip content="Open in a new tab">
      <ControlsButton onClick={() => openInNewTab(url, previewUrl)}>
        <Icon name="expand" size="s" />
      </ControlsButton>
    </Tooltip>
  </ControlsContainer>
)

export const ImageControls = ({
  url,
  rotate,
  previewUrl,
}: Readonly<GalleryControlsProps>) => {
  const { zoomIn, zoomOut, resetTransform } = useControls()

  return (
    <ControlsContainer data-testid="image-controls">
      <Tooltip content="Zoom in">
        <ControlsButton onClick={() => zoomIn()}>
          <Icon name="add" size="s" />
        </ControlsButton>
      </Tooltip>
      <Tooltip content="Zoom out">
        <ControlsButton onClick={() => zoomOut()}>
          <Icon name="subtract" size="s" />
        </ControlsButton>
      </Tooltip>
      <Tooltip content="Reset zoom">
        <ControlsButton onClick={() => resetTransform()}>
          <Icon name="close" size="s" />
        </ControlsButton>
      </Tooltip>
      <Tooltip content="Rotate left">
        <ControlsButton onClick={() => rotate('anticlockwise')}>
          <Icon name="rotate-left" size="m" />
        </ControlsButton>
      </Tooltip>
      <Tooltip content="Rotate right">
        <ControlsButton onClick={() => rotate('clockwise')}>
          <Icon name="rotate-right" size="m" />
        </ControlsButton>
      </Tooltip>
      <Tooltip content="Open in a new tab">
        <ControlsButton onClick={() => openInNewTab(url, previewUrl)}>
          <Icon name="expand" size="s" />
        </ControlsButton>
      </Tooltip>
    </ControlsContainer>
  )
}
