import { rest } from 'msw'

import { config } from '@percent/admin-dashboard/config'
import { mockActivityChecksList } from '@percent/admin-dashboard/fixtures/api/ActivityCheck/mockActivityChecksList'

export const activityChecksHandlers = [
  rest.get(`${config.urls.api}v1/admin/activity-checks`, async (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockActivityChecksList))
  })
]
