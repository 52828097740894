import { Grid } from '@material-ui/core'
import React from 'react'

import { PercentButton } from '../percentButton/PercentButton'
import { Loader } from '../loader/Loader'
import { ErrorView } from '../errorView/ErrorView'

import style from './DialogCreateBody.module.scss'
import { DialogCreateBodyProps } from './DialogCreateBody.types'

export function DialogCreateBody({
  loading,
  errorMessage,
  handleSubmit,
  buttonTitle,
  testId,
  children
}: DialogCreateBodyProps) {
  return (
    <Grid item xs={12}>
      <form className={style.dialogMargin} onSubmit={handleSubmit}>
        {loading ? (
          <Loader />
        ) : (
          <>
            {children}
            {errorMessage && <ErrorView errorMessage={errorMessage} />}
            <PercentButton type="submit" testId={testId} title={buttonTitle} className={style.sendButton} />
          </>
        )}
      </form>
    </Grid>
  )
}
