import React, { useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { StringParam, useQueryParams } from 'use-query-params'
import { useTranslation } from 'react-i18next'

import { dayJS } from '../../library/utility/date'

import { ReactComponent as ArrowUp } from '@percent/admin-dashboard/common/assets/images/up-arrow.svg'
import { PercentButton, SearchField } from '@percent/admin-dashboard/common/components'
import styles from './SearchAndDateFilter.module.scss'
import { SearchAndDateFilterProps } from './SearchAndDateFilter.types'

export function SearchAndDateFilter({
  handleClick,
  handleClearDate,
  handleKeyPress,
  placeholder,
  searchPhrase
}: SearchAndDateFilterProps) {
  const { t } = useTranslation()
  const [searchValue, setSearchValue] = useState(searchPhrase)
  const [{ startDate, endDate }] = useQueryParams({
    startDate: StringParam,
    endDate: StringParam
  })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value)
  }

  return (
    <>
      <Grid item xs={6}>
        <SearchField
          value={searchValue ?? ''}
          handleChange={handleChange}
          handleKeyPress={handleKeyPress}
          placeholder={placeholder}
        />
      </Grid>
      {handleClick && (
        <Grid item xs={6} className={styles.dateRangeContainer}>
          <PercentButton
            className={styles.openDateSelector}
            onClick={(e: React.MouseEvent<HTMLElement>) => handleClick('bottom-end')(e)}
            testId="show-date-range"
            title={
              (startDate && endDate && `${dayJS(startDate).format('ll')} - ${dayJS(endDate).format('ll')}`) ||
              t('button.dateAll')
            }
            endIcon={<ArrowUp />}
            type="submit"
          />
          {startDate && endDate && (
            <Typography className={styles.clearText} onClick={handleClearDate}>
              {t('typography.clear')}
            </Typography>
          )}
        </Grid>
      )}
    </>
  )
}
