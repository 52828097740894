import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { OrganizationDetailsProps } from '../OverviewDetails.types'

import { Button, CardDashboard, CardContent, Spacer } from '@percent/lemonade'
import { EditContactsModal } from './EditContactsModal'
import { useFeatureFlag } from '@percent/admin-dashboard/common/hooks/useFeatureFlag/useFeatureFlag'
import { ExternalLink } from '@percent/admin-dashboard/common/components/externalLink/ExternalLink'

export function Contacts({ organization, refresh }: OrganizationDetailsProps) {
  const { t } = useTranslation()
  const [dialogState, setDialogState] = useState(false)
  const handleRegistriesDialog = () => {
    setDialogState(prevState => !prevState)
  }
  const { editContactsDetailsFeatureFlag } = useFeatureFlag()

  return (
    <>
      <CardDashboard
        title={t('typography.contacts')}
        action={
          editContactsDetailsFeatureFlag && (
            <Button onPress={handleRegistriesDialog} variant="secondary" data-testid="contact-details-edit-button">
              {t('typography.editContacts')}
            </Button>
          )
        }
      >
        <CardContent title={t('typography.website')} testId="contact-details-website">
          {(organization.website && (
            <ExternalLink url={organization.website} text={organization.website.replace(/^https?:\/\//, '')} />
          )) ||
            t('typography.notAvailable')}
        </CardContent>
        <CardContent title={t('typography.generalEmail')} testId="contact-details-email">
          {organization.email || t('typography.notAvailable')}
        </CardContent>
        <CardContent title={t('typography.phoneNumber')} testId="contact-details-phone">
          {organization.phone || t('typography.notAvailable')}
        </CardContent>
      </CardDashboard>
      <Spacer size={6} axis="vertical" />
      <EditContactsModal
        open={dialogState}
        onClose={handleRegistriesDialog}
        organization={organization}
        refresh={refresh}
      />
    </>
  )
}
