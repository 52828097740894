import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { OrganizationDetailsProps } from '../OverviewDetails.types'

import { Button, CardDashboard } from '@percent/lemonade'
import { useFeatureFlag } from '@percent/admin-dashboard/common/hooks/useFeatureFlag/useFeatureFlag'
import styles from './UnclaimOrganization.module.scss'
import { UnclaimOrganizationModal } from './UnclaimOrganizationModal'

export function UnclaimOrganization({ organization, refresh }: OrganizationDetailsProps) {
  const [dialogState, setDialogState] = useState(false)
  const { t } = useTranslation()
  const handleRegistriesDialog = () => {
    setDialogState(prevState => !prevState)
  }
  const { unclaimOrgFeatureFlag } = useFeatureFlag()

  return (
    <>
      <CardDashboard title={t('typography.dangerZone')}>
        {(organization.claimedAt && unclaimOrgFeatureFlag && (
          <Button variant="secondary" onPress={handleRegistriesDialog} data-testid="open-unclaim-org-modal">
            {t('typography.unclaimOrganization')}
          </Button>
        )) || <p className={styles.noActionsAvailable}>{t('typography.noActionsAvailable')}</p>}
      </CardDashboard>
      <UnclaimOrganizationModal
        open={dialogState}
        onClose={handleRegistriesDialog}
        organization={organization}
        refresh={refresh}
      />
    </>
  )
}
