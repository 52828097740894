import { TableCell, TableRow } from '@material-ui/core'
import { useCallback, EventHandler, KeyboardEvent, useMemo, useState, ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

import { RegistriesDialog } from '../RegistriesDialog/RegistriesDialog'

import { Table } from '@percent/admin-dashboard/common/components'
import { getCountryFromAlpha3 } from '@percent/admin-dashboard/common/utility/getCountryFromCountryCode'
import { RegistriesTableProps } from './RegistriesTable.types'
import styles from './RegistriesTable.module.scss'
import { Button, FlagAvatar, SearchInput, Spacer } from '@percent/lemonade'

export function RegistriesTable({
  data,
  isLoading,
  queryParams,
  setQueryParams,
  nextPage,
  previousPage,
  totalResults,
  errorMessage,
  refresh
}: RegistriesTableProps) {
  const { t } = useTranslation()
  const [dialogState, setDialogState] = useState(false)
  const [searchValue, setSearchValue] = useState<string>(queryParams.search || '')
  const cells = [
    { id: 'country', isSortable: false, props: { width: '300px' } },
    { id: 'englishName', isSortable: false },
    { id: 'localName', isSortable: false },
    { id: 'nameAbbreviation', isSortable: false }
  ]

  const handleRegistriesDialog = () => {
    setDialogState(prevState => !prevState)
  }

  const handleKeyChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value)
  }

  const handleKeyPress: EventHandler<KeyboardEvent<HTMLInputElement>> = useCallback(
    event => {
      const searchTerm = (event.target as HTMLInputElement).value

      return event.key === 'Enter'
        ? searchTerm.trim() === ''
          ? setQueryParams({ search: null })
          : setQueryParams({
              search: searchTerm
            })
        : null
    },
    [setQueryParams]
  )

  const handleClearValue = () => {
    setSearchValue('')
  }

  const filters = useMemo(
    () => (
      <>
        <SearchInput
          value={searchValue ?? ''}
          handleClearValue={handleClearValue}
          onKeyDown={handleKeyPress}
          onChange={handleKeyChange}
          placeholder={t('table.adminNonprofitsSearchPlaceholder')}
        />
        <Spacer size={4} axis="horizontal" />
        <Button onPress={handleRegistriesDialog} variant="secondary" data-testid="add-registry">
          {t('button.addRegistry')}
        </Button>
      </>
    ),
    [handleKeyPress, searchValue, t]
  )

  return (
    <>
      <Table
        isLoading={isLoading}
        totalResults={errorMessage ? 0 : totalResults || 0}
        data={data}
        previousPage={previousPage}
        nextPage={nextPage}
        columns={cells}
        filtersContent={filters}
        emptyTableText={t('table.emptyRegistries')}
      >
        {data?.map(row => (
          <TableRow key={row.id}>
            <TableCell className={styles.countryName}>
              <FlagAvatar code3={row.countryCode} />
              <span>{getCountryFromAlpha3(row.countryCode)}</span>
            </TableCell>
            <TableCell>{row.englishName}</TableCell>
            <TableCell>{row.name}</TableCell>
            <TableCell>{row.code}</TableCell>
          </TableRow>
        ))}
      </Table>
      <RegistriesDialog state={dialogState} onClose={handleRegistriesDialog} refresh={refresh} />
    </>
  )
}
