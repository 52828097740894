import { useCallback, useState } from 'react'
import styled from 'styled-components'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import { ImageControls, PdfControls } from './gallery-controls'
import {
  GalleryPreviewProps,
  RotationDirection,
} from './documents-gallery.types'
import { isPdfDocument } from './documents-gallery'

const PreviewContainer = styled.div`
  position: relative;
  width: 100%;
`

const PdfPreview = styled.iframe`
  width: 100%;
  min-height: 600px;
  height: 100vh;
`

const ImagePreview = styled.img`
  max-width: 100%;
  width: 100%;
  max-height: 100vh;
  object-fit: contain;
`

export function GalleryPreview({
  url,
  previewUrl,
}: Readonly<GalleryPreviewProps>) {
  const [rotation, setRotation] = useState(0)

  const rotate = useCallback(
    (direction: RotationDirection) => {
      setRotation((rotation) => {
        const angle = direction === 'clockwise' ? 90 : -90
        const newRotation = (rotation + angle) % 360
        return newRotation
      })
    },
    [setRotation]
  )

  return (
    <PreviewContainer data-testid="documents-gallery-preview">
      {isPdfDocument(url) ? (
        <>
          <PdfControls url={url} previewUrl={previewUrl} />
          <PdfPreview src={url} data-testid="documents-gallery-pdf-preview" />
        </>
      ) : (
        <TransformWrapper
          initialScale={1}
          centerZoomedOut={true}
          minScale={0.8}
        >
          <ImageControls url={url} rotate={rotate} previewUrl={previewUrl} />
          <TransformComponent
            wrapperStyle={{ width: '100%' }}
            contentStyle={{ width: '100%' }}
          >
            <ImagePreview
              src={url}
              alt="Image preview"
              data-testid="documents-gallery-image-preview"
              style={{ transform: `rotate(${rotation}deg)` }}
            />
          </TransformComponent>
        </TransformWrapper>
      )}
    </PreviewContainer>
  )
}
