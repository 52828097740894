import { Card } from '@material-ui/core'

import { ReactComponent as SuccessIcon } from '@percent/admin-dashboard/common/assets/images/success.svg'
import { PercentButton } from '@percent/admin-dashboard/common/components'
import styles from './UpdateModalInformation.module.scss'
import { UpdateModalInformationProps } from './UpdateModalInformation.types'

export function UpdateModalInformation({
  handleClick,
  setModalOpened,
  title,
  subtitle,
  buttonTitle
}: UpdateModalInformationProps) {
  const handleDoneClick = () => {
    setModalOpened(false)
    handleClick()
  }

  return (
    <Card className={styles.wrapper}>
      <SuccessIcon className={styles.icon} />
      <span className={styles.title}>{title}</span>
      <span className={styles.subtitle}>{subtitle}</span>
      <PercentButton title={buttonTitle} onClick={handleDoneClick} className={styles.button} />
    </Card>
  )
}
