import React, { useState } from 'react'
import { Modal, Card, Divider } from '@material-ui/core'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useAgentVerificationDecisionContext } from '../../../useAgentVerificationDecisionContext/useAgentVerificationDecisionContext'

import { ErrorView, Loader, PercentButton } from '@percent/admin-dashboard/common/components'
import { useMutation } from '@percent/admin-dashboard/common/hooks'
import { ReactComponent as CloseIcon } from '@percent/admin-dashboard/common/assets/images/close.svg'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import styles from './AgentVerificationApproveModal.module.scss'
import { AgentVerificationApprovedInformation } from './agentVerificationApprovedInformation/AgentVerificationApprovedInformation'
import { AgentVerificationApproveModalProps } from './AgentVerificationApproveModal.types'

export function AgentVerificationApproveModal({
  agentFullName,
  organisationName,
  refresh
}: AgentVerificationApproveModalProps) {
  const { t } = useTranslation()
  const { setOpenedModal, isApproveModalOpened } = useAgentVerificationDecisionContext()
  const [isSuccess, setIsSuccess] = useState(false)
  const { agentVerificationService } = useServices()
  const { id } = useParams<{ id: string }>()

  const [{ isLoading, errorMessage }, { apiFunc: approveFunc }] = useMutation(
    agentVerificationService.approveAgentVerification,
    () => {
      setIsSuccess(true)
    }
  )

  const handleApprove = () => {
    approveFunc({ id })
  }

  const handleCloseModalAfterApprove = () => {
    refresh()
    setOpenedModal(undefined)
    setIsSuccess(false)
  }

  return (
    <Modal
      open={isApproveModalOpened}
      onClose={() => setOpenedModal(undefined)}
      aria-labelledby="agent-verification-approve-modal"
    >
      <div className={styles.modal}>
        {isSuccess ? (
          <AgentVerificationApprovedInformation
            agentFullName={agentFullName}
            handleClick={handleCloseModalAfterApprove}
          />
        ) : (
          <Card className={styles.wrapper}>
            <div className={styles.titleWrapper}>
              <span className={styles.title}>{t('typography.approveAgent')}</span>
              <CloseIcon onClick={() => setOpenedModal(undefined)} />
            </div>
            <Divider className={styles.divider} />
            <span className={styles.textWrapper}>
              <strong>{agentFullName}&apos;s </strong>
              {t('typography.approve.partOne')} <strong>{organisationName} </strong> {t('typography.approve.partTwo')}
            </span>
            {errorMessage && <ErrorView errorMessage={errorMessage} />}
            {isLoading ? (
              <Loader />
            ) : (
              <div className={styles.buttonWrapper}>
                <PercentButton title={t('button.approve')} onClick={handleApprove} />
                <PercentButton
                  title={t('button.cancel')}
                  onClick={() => setOpenedModal(undefined)}
                  textColour="secondaryTextColour"
                  outlined
                />
              </div>
            )}
          </Card>
        )}
      </div>
    </Modal>
  )
}
