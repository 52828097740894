import { Alpha3Code } from 'i18n-iso-countries'
import * as Yup from 'yup'
import { RequiredNumberSchema } from 'yup/lib/number'
import { RequiredStringSchema } from 'yup/lib/string'

export type MilestoneModalProps = {
  save?: (success: boolean) => void
  currencyCode: string
  countryCode?: Alpha3Code
  openModal: boolean
  onClose: () => void
  refresh: VoidFunction
}

export const validationSchema = Yup.object().shape({
  amount: Yup.number()
    .moreThan(0)
    .max(15000000)
    .test({
      name: 'amount',
      exclusive: true,
      message: 'Amount should be provided with two decimal places e.g. 5.99',
      test: value => {
        if (value === undefined) {
          return false
        }

        if (!/^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/.test(value.toString())) {
          return false
        }

        return true
      }
    })
    .required('Required') as RequiredNumberSchema<number>,
  reward: Yup.string()
    .trim()
    .required('Required')
    .max(255, 'Should not exceed more than 255 characters') as RequiredStringSchema<string>
})
