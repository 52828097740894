export const environments = {
  LOCAL_SERVER: 'LocalServer',
  LOCAL: 'Local',
  PREPROD: 'Preprod',
  STAGING: 'Staging',
  SANDBOX: 'Sandbox',
  PRODUCTION: 'Production',
  QA: 'QA'
}

export type environmentType = {
  localServer: 'LocalServer'
  local: 'Local'
  preprod: 'Preprod'
  staging: 'Staging'
  sandbox: 'Sandbox'
  production: 'Production'
  qa: 'QA'
}
