export const mockEligibilitySubscription = {
  id: 'staging_eligibilitysubscription_000000CpMZyb0FxJscTTOMsenIdQO',
  organisationId: 'staging_organisation_000000CpMZy9XoplWZ6jhzXK0QyNE',
  validationRequestId: null,
  partnerId: 'staging_partner_000000CgSm3OrQ7wXyqSL5fmmCKx6',
  status: 'pending',
  results: {
    eligibilityStatus: null,
    confirmedActivitySubTags: null,
    rejectedActivitySubTags: null
  },
  suggestedActivitySubTags: null,
  createdAt: '2024-04-09T11:46:48.653Z',
  updatedAt: null,
  configurationId: 'staging_hostedconfiguration_000000CgSm3OrQ7wXyqSL5fmmCKx7',
  organisationName: 'Natalia NM AUTOMATED TEST 69407452-d380-4157-9103-5be9f3310142',
  countryCode: 'ISR',
  configuration: {
    name: null,
    allowedOrganisations: {
      types: ['nonprofit']
    },
    disallowedOrganisations: {
      relationships: []
    }
  }
}
