import React, { useCallback, useMemo, useState } from 'react'

import { Breadcrumbs } from './Breadcrumbs/Breadcrumbs'
import { WizardProps } from './Wizard.types'
import { WizardContext } from './WizardContext/WizardContext'

export function Wizard({ steps, children }: WizardProps) {
  const [currentStep, setCurrentStep] = useState(0)
  const [isAuthenticationComplete, setIsAuthenticationComplete] = useState(false)

  const handleNextStep = useCallback(() => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1)
    }
  }, [currentStep, steps.length])

  const value = useMemo(
    () => ({ currentStep, setNextStep: handleNextStep, steps, isAuthenticationComplete, setIsAuthenticationComplete }),
    [currentStep, handleNextStep, isAuthenticationComplete, steps]
  )

  return (
    <WizardContext.Provider value={value}>
      <Breadcrumbs />
      {!isAuthenticationComplete && steps[currentStep].step}
      {isAuthenticationComplete && children}
    </WizardContext.Provider>
  )
}
