import { Dialog as Modal, Grid } from '@material-ui/core'
import React from 'react'
import classNames from 'classnames/bind'

import styles from './Dialog.module.scss'
import { DialogProps } from './Dialog.types'
import { DialogHeader } from './DialogHeader'

const cx = classNames.bind(styles)

export function Dialog({ openModal, onClose, headerTitle, children, fullscreen, withoutHeader = false }: DialogProps) {
  return (
    <Grid container>
      <Modal open={openModal} onClose={onClose} fullWidth maxWidth={fullscreen ? 'lg' : 'sm'} className={cx('dialog')}>
        {!withoutHeader && <DialogHeader onClose={onClose} headerTitle={headerTitle} />}
        {children}
      </Modal>
    </Grid>
  )
}
