import React, { useState } from 'react'

import { Validation } from '@percent/admin-dashboard/api/types'
import { BadgeTotalContextController } from '@percent/admin-dashboard/context/badgeTotal/badgeTotalContextController/BadgeTotalContextController'
import { TopBar } from './TopBar/TopBar'
import { SideMenu } from './SideMenu/SideMenu'
import style from './Layout.module.scss'
import { Routes } from './routes/Routes'
import { BreadcrumbsContextController } from './breadcrumbsContext/breadcrumbsContextController/BreadcrumbsContextController'
import { NonprofitsContextController } from './nonprofitsContext/nonprofitsContextController/NonprofitsContextController'

export function Layout() {
  const [path, setPath] = useState<string | undefined>(undefined)
  const [validation, setValidation] = useState<Validation | undefined>(undefined)

  return (
    <NonprofitsContextController validation={validation} setValidation={setValidation}>
      <BreadcrumbsContextController path={path} setPath={setPath}>
        <BadgeTotalContextController>
          <SideMenu />
          <TopBar />
          <div className={style.drawer}>
            <Routes />
          </div>
        </BadgeTotalContextController>
      </BreadcrumbsContextController>
    </NonprofitsContextController>
  )
}
