import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as PendingSVG } from '@percent/admin-dashboard/common/assets/images/tableStatus/pending.svg'
import { ReactComponent as FlagSVG } from '@percent/admin-dashboard/common/assets/images/tableStatus/flag.svg'
import { ReactComponent as TickSVG } from '@percent/admin-dashboard/common/assets/images/tableStatus/tick.svg'
import { ReactComponent as NaSVG } from '@percent/admin-dashboard/common/assets/images/tableStatus/na.svg'
import { ReactComponent as InvalidSVG } from '@percent/admin-dashboard/common/assets/images/tableStatus/invalid.svg'
import { ReactComponent as LiveSVG } from '@percent/admin-dashboard/common/assets/images/tableStatus/live.svg'
import { ReactComponent as RejectedSVG } from '@percent/admin-dashboard/common/assets/images/tableStatus/rejected.svg'
import styles from './CellStatus.module.scss'
import { CellStatusProps } from './CellStatus.types'
import { LocaleKey } from '@percent/admin-dashboard/i18n/config'

type styleMap = {
  [key: string]: {
    style: string
    message: LocaleKey
    icon: ReactNode
  }
}

const statusStyleMap: styleMap = {
  cancelled: {
    style: styles.invalid,
    message: 'status.cancelled',
    icon: <InvalidSVG />
  },
  yes: {
    style: styles.flag,
    message: 'status.yes',
    icon: <FlagSVG />
  },
  no: {
    style: styles.clear,
    message: 'status.no',
    icon: <TickSVG />
  },
  rejected: {
    style: styles.flag,
    message: 'status.rejected',
    icon: <RejectedSVG />
  },
  deleted: {
    style: styles.flag,
    message: 'status.deleted',
    icon: <RejectedSVG />
  },
  clear: {
    style: styles.clear,
    message: 'status.clear',
    icon: <TickSVG />
  },
  accepted: {
    style: styles.clear,
    message: 'status.accepted',
    icon: <TickSVG />
  },
  approved: {
    style: styles.clear,
    message: 'status.approved',
    icon: <TickSVG />
  },
  flag: {
    style: styles.flag,
    message: 'status.flag',
    icon: <FlagSVG />
  },
  pending: {
    style: styles.pending,
    message: 'status.pending',
    icon: <PendingSVG />
  },
  pending_user_verification: {
    style: styles.pending,
    message: 'status.pendingUserVerification',
    icon: <PendingSVG />
  },
  pending_review: {
    style: styles.pending,
    message: 'status.pendingReview',
    icon: <PendingSVG />
  },
  live: {
    style: styles.pending,
    message: 'status.live',
    icon: <LiveSVG />
  },
  pass: {
    style: styles.clear,
    message: 'status.pass',
    icon: <TickSVG />
  },
  fail: {
    style: styles.flag,
    message: 'status.fail',
    icon: <FlagSVG />
  },
  admin_pending: {
    style: styles.pending,
    message: 'status.admin',
    icon: <PendingSVG />
  },
  admin_reviewed: {
    style: styles.clear,
    message: 'status.admin',
    icon: <TickSVG />
  },
  admin_rejected: {
    style: styles.flag,
    message: 'status.admin',
    icon: <RejectedSVG />
  },
  user_pending: {
    style: styles.pending,
    message: 'status.userVerification',
    icon: <PendingSVG />
  },
  user_reviewed: {
    style: styles.clear,
    message: 'status.userVerification',
    icon: <TickSVG />
  },
  cannot_define_eligibility: {
    style: styles.na,
    message: 'status.cantDefine',
    icon: <NaSVG />
  },
  pending_validation_request: {
    style: styles.pending,
    message: 'status.pending',
    icon: <PendingSVG />
  },
  validation_request_failed: {
    style: styles.flag,
    message: 'status.fail',
    icon: <FlagSVG />
  },
  percent_action_needed: {
    style: styles.pending,
    message: 'status.percentActionNeeded',
    icon: <PendingSVG />
  },
  external_action_needed: {
    style: styles.pending,
    message: 'status.externalActionNeeded',
    icon: <PendingSVG />
  }
}

export function CellStatus({ status }: CellStatusProps) {
  const { t } = useTranslation()

  if (status) {
    const statusStyle = statusStyleMap[status]

    if (statusStyle !== undefined) {
      const { icon, message } = statusStyle

      return (
        <span className={statusStyle.style}>
          {icon} {t(message)}
        </span>
      )
    }
  }

  return (
    <span className={styles.na}>
      <NaSVG /> {t('status.na')}
    </span>
  )
}
