/* eslint-disable react/destructuring-assignment */
import { Menu, MenuItem } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { DisbursementsDetailsActionsMenuProps } from './DisbursementsDetailsActionsMenu.types'

export function DisbursementsDetailsActionsMenu(props: DisbursementsDetailsActionsMenuProps) {
  const { t } = useTranslation()
  const { anchorEl, isOpen, onClose, handleCancel, handleMarkAsApproved, handleMarkAsPaid, handleMarkAsSent } = props

  return (
    <Menu
      id="actions-menu"
      data-testid="actions-menu"
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      open={isOpen}
      onClose={onClose}
      keepMounted
      style={{
        display: 'flex',
        alignItems: 'flex-start'
      }}
    >
      {handleMarkAsApproved && (
        <MenuItem key="markAsApproved" onClick={props.handleMarkAsApproved}>
          {t('menu.markAsFoundationApproved')}
        </MenuItem>
      )}
      {handleMarkAsPaid && (
        <MenuItem key="markAsPaid" onClick={props.handleMarkAsPaid}>
          {t('menu.markAsPaid')}
        </MenuItem>
      )}
      {handleMarkAsSent && (
        <MenuItem key="markAsSent" onClick={props.handleMarkAsSent} data-testid="mark-as-sent-action">
          {t('menu.markAsSent')}
        </MenuItem>
      )}
      <MenuItem key="removeFromBatch" onClick={handleCancel}>
        {t('menu.removeFromBatch')}
      </MenuItem>
    </Menu>
  )
}
