/* eslint-disable */
import React from 'react'

import { environment } from '@percent/admin-dashboard/config'
import { environments } from '@percent/admin-dashboard/constants/environments'
import styles from './EnvironmentOverlay.module.scss'
import { useTranslation } from 'react-i18next'

export function EnvironmentOverlay() {
  const { t } = useTranslation()

  return (
    <>
      {environment !== environments.PRODUCTION && (
        <div className={styles.container}>
          <div className={styles.bar} />
          <p className={styles.message}>
            {environment === environments.SANDBOX
              ? t('typography.usingPercentSandbox')
              : `${environment} ${t('typography.environment')}`}
          </p>
        </div>
      )}
    </>
  )
}
