import SSO from '@percent/admin-dashboard/common/assets/images/moduleLogos/sso.svg'
import { moduleTitles } from '@percent/admin-dashboard/constants/moduleInformation'
import { PartnerScopes } from '@percent/admin-dashboard/constants/scopes'
import DonationLogo from '@percent/admin-dashboard/common/assets/images/moduleLogos/donation.svg'
import OrganisationLogo from '@percent/admin-dashboard/common/assets/images/moduleLogos/organisation.svg'
import DonationSessionLogo from '@percent/admin-dashboard/common/assets/images/moduleLogos/donation_session.svg'
import ValidationRequestLogo from '@percent/admin-dashboard/common/assets/images/moduleLogos/validation_request.svg'
import WebhookSubscriptionLogo from '@percent/admin-dashboard/common/assets/images/moduleLogos/webhook_subscription.svg'
import AgentVerificationLogo from '@percent/admin-dashboard/common/assets/images/moduleLogos/agent_verification.svg'
import ActivityCheckLogo from '@percent/admin-dashboard/common/assets/images/moduleLogos/activity_check.svg'
import MonitoringSubscriptionLogo from '@percent/admin-dashboard/common/assets/images/moduleLogos/monitoring_subscription.svg'
import EligibilitySubscriptionLogo from '@percent/admin-dashboard/common/assets/images/moduleLogos/eligibility_subscription.svg'
import ValidationInviteLogo from '@percent/admin-dashboard/common/assets/images/moduleLogos/validation_invite.svg'
import AgentUserVerificationLogo from '@percent/admin-dashboard/common/assets/images/moduleLogos/agent_verification_suppression.svg'
import CodeRedemptionLogo from '@percent/admin-dashboard/common/assets/images/moduleLogos/code_redemption.svg'
import AutoNonprofitAssociationLogo from '@percent/admin-dashboard/common/assets/images/moduleLogos/auto_nonprofit_association.svg'
import SingleValidationSubmissionLogo from '@percent/admin-dashboard/common/assets/images/moduleLogos/single_validation_submission.svg'
import styles from './PartnerModuleListTable.module.scss'

export const rowModuleInfo = [
  {
    title: moduleTitles.DONATION_API,
    name: PartnerScopes.Donation,
    logoComponent: <img width="96px" height="108px" alt="donation logo" src={DonationLogo} />,
    active: false
  },
  {
    title: moduleTitles.DONATION_MATCHING,
    name: PartnerScopes.DonationMatching,
    logoComponent: (
      <img width="96px" height="108px" alt="donation matching logo" className={styles.filterBlue} src={DonationLogo} />
    ),
    active: false
  },
  {
    title: moduleTitles.DONATION_WALLET,
    name: PartnerScopes.DonationWallet,
    logoComponent: (
      <img width="96px" height="108px" alt="donation matching logo" className={styles.filterGreen} src={DonationLogo} />
    ),
    active: false
  },
  {
    title: moduleTitles.DONATION_SESSION_API,
    name: PartnerScopes.DonationSession,
    logoComponent: <img width="96px" height="108px" alt="donation session logo" src={DonationSessionLogo} />,
    active: false
  },
  {
    title: moduleTitles.DONATION_LINK,
    name: PartnerScopes.DonationLink,
    logoComponent: (
      <img
        width="96px"
        height="108px"
        alt="donation link logo"
        className={styles.filterGreen}
        src={DonationSessionLogo}
      />
    ),
    active: false
  },
  {
    title: moduleTitles.WORKPLACE_GIVING,
    name: PartnerScopes.WorkplaceGiving,
    logoComponent: (
      <img
        width="96px"
        height="108px"
        alt="workplace giving logo"
        className={styles.filterBlue}
        src={DonationSessionLogo}
      />
    ),
    active: false
  },
  {
    title: moduleTitles.ORGANISATION_API,
    name: PartnerScopes.Organisation,
    logoComponent: <img width="96px" height="108px" alt="organisation logo" src={OrganisationLogo} />,
    active: false
  },
  {
    title: moduleTitles.VALIDATION_REQUEST_API,
    name: PartnerScopes.ValidationRequest,
    logoComponent: <img width="96px" height="108px" alt="validation logo" src={ValidationRequestLogo} />,
    active: false
  },
  {
    title: moduleTitles.WEBHOOK_SUBSCRIPTION_API,
    name: PartnerScopes.WebhookSubscription,
    logoComponent: <img width="96px" height="108px" alt="webhook subscription logo" src={WebhookSubscriptionLogo} />,
    active: false
  },
  {
    title: moduleTitles.AGENT_VERIFICATION_API,
    name: PartnerScopes.AgentVerification,
    logoComponent: <img width="96px" height="108px" alt="agent verification logo" src={AgentVerificationLogo} />,
    active: false
  },
  {
    title: moduleTitles.ACTIVITY_CHECK_API,
    name: PartnerScopes.ActivityCheck,
    logoComponent: <img width="96px" height="108px" alt="activity check logo" src={ActivityCheckLogo} />,
    active: false
  },
  {
    title: moduleTitles.MONITORING_SUBSCRIPTION_API,
    name: PartnerScopes.MonitoringSubscription,
    logoComponent: (
      <img width="96px" height="108px" alt="monitoring subscription logo" src={MonitoringSubscriptionLogo} />
    ),
    active: false
  },
  {
    title: moduleTitles.ELIGIBILITY_SUBSCRIPTION_API,
    name: PartnerScopes.EligibilitySubscription,
    logoComponent: (
      <img width="96px" height="108px" alt="eligibility subscription logo" src={EligibilitySubscriptionLogo} />
    ),
    active: false
  },
  {
    title: moduleTitles.VALIDATION_INVITE_API,
    name: PartnerScopes.ValidationInvite,
    logoComponent: <img width="96px" height="108px" alt="validation invite logo" src={ValidationInviteLogo} />,
    active: false
  },
  {
    title: moduleTitles.AGENT_USER_VERIFICATION_API,
    name: PartnerScopes.AgentUserVerification,
    logoComponent: (
      <img width="96px" height="108px" alt="agent user verification logo" src={AgentUserVerificationLogo} />
    ),
    active: false
  },
  {
    title: moduleTitles.CODE_REDEMPTION_API,
    name: PartnerScopes.CodeRedemption,
    logoComponent: <img width="96px" height="108px" alt="code redemption logo" src={CodeRedemptionLogo} />,
    active: false
  },
  {
    title: moduleTitles.AUTO_NONPROFIT_ASSOCIATION,
    name: PartnerScopes.AutoNonprofitAssociation,
    logoComponent: (
      <img width="96px" height="108px" alt="auto nonprofit association logo" src={AutoNonprofitAssociationLogo} />
    ),
    active: false
  },
  {
    title: moduleTitles.SSO,
    name: PartnerScopes.SingleSignOn,
    logoComponent: <img width="96px" height="108px" alt="single sign-on" src={SSO} />,
    active: false
  },
  {
    title: moduleTitles.SSO_NOT_STRICT,
    name: PartnerScopes.SingleSignOnNotStrict,
    logoComponent: <img width="96px" height="108px" alt="single sign-on" className={styles.filterBlue} src={SSO} />,
    active: false
  },
  {
    title: moduleTitles.SINGLE_VALIDATION_SUBMISSION,
    name: PartnerScopes.SingleValidationSubmission,
    logoComponent: (
      <img width="96px" height="108px" alt="single validations submission" src={SingleValidationSubmissionLogo} />
    ),
    active: false
  },
  {
    title: moduleTitles.VOLUNTEERING,
    name: PartnerScopes.Volunteering,
    logoComponent: (
      <img width="96px" height="108px" alt="donation logo" className={styles.filterBlue} src={DonationLogo} />
    ),
    active: false
  },
  {
    title: moduleTitles.SLACK,
    name: PartnerScopes.Slack,
    logoComponent: <img width="96px" height="108px" alt="donation logo" className={styles.filterGreen} src={SSO} />,
    active: false
  },
  {
    title: moduleTitles.GAMIFICATION,
    name: PartnerScopes.Gamification,
    logoComponent: (
      <img
        width="96px"
        height="108px"
        alt="gamification logo"
        className={styles.filterBlue}
        src={MonitoringSubscriptionLogo}
      />
    ),
    active: false
  }
]
