import { Divider, Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'

import styles from './Footer.module.scss'

export function Footer() {
  const { t } = useTranslation()

  return (
    <footer className={styles.footer}>
      <Divider />
      <Typography variant="body2" className={styles.typography}>
        <span role="img">©</span>
        {` ${new Date().getFullYear()} `}
        {t('typography.wapMadeWith')}
      </Typography>
    </footer>
  )
}
