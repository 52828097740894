import React from 'react'
import { StringParam, useQueryParams } from 'use-query-params'

import { useDidMountEffect, useQueryList } from '@percent/admin-dashboard/common/hooks'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { ValidationsTable } from './validationsTable/ValidationsTable'

export function ValidationsContainer() {
  const { adminService } = useServices()
  const [queryParams, setQueryParams] = useQueryParams({
    status: StringParam,
    query: StringParam,
    direction: StringParam,
    sort: StringParam,
    startDate: StringParam,
    endDate: StringParam
  })

  const [{ totalResults, isLoading, dataOrNull, errorMessageOrNull }, { query, nextPage, previousPage }] = useQueryList(
    adminService.getValidations,
    { ...queryParams }
  )

  useDidMountEffect(() => query(queryParams), [queryParams, query])

  return (
    <ValidationsTable
      validations={dataOrNull}
      totalResults={totalResults}
      nextPage={nextPage}
      previousPage={previousPage}
      isLoading={isLoading}
      errorMessage={errorMessageOrNull}
      queryParams={queryParams}
      setQueryParams={setQueryParams}
    />
  )
}
