import React, { ReactNode } from 'react'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'

import { colors } from '@percent/admin-dashboard/identity'
import '@percent/admin-dashboard/app/App.scss'
import { MuiTable, MuiTableContainer } from './overrides'
import { theme as lemonadeTheme } from '@percent/lemonade'

export const MuiAlert = {
  standardInfo: {
    backgroundColor: colors.secondary,
    color: colors.primary
  }
}

const theme = createTheme({
  palette: {
    primary: { main: colors.primary },
    secondary: { main: colors.secondary },
    text: {
      primary: colors.secondary
    },
    success: { main: colors.success }
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        backgroundColor: 'rgba(14, 15, 22, 0.9)',
        border: '1px solid #0E0F16',
        fontSize: '14px',
        borderRadius: '8px',
        minWidth: 200,
        maxWidth: 368,
        boxShadow: '0px 8px 32px rgba(33, 37, 53, 0.16)'
      }
    },
    MuiTableContainer: MuiTableContainer as any,
    MuiSnackbar: {
      anchorOriginTopCenter: {
        top: '50px !important',
        transform: 'translateX(-50%) !important'
      }
    },
    MuiTable,
    MuiTableCell: {
      body: {
        textOverflow: 'ellipsis',
        overflow: 'hidden'
      },
      footer: {
        border: 'none'
      }
    },
    MuiDrawer: {
      paperAnchorDockedLeft: {
        width: 242,
        borderRight: 'none',
        boxShadow: '0px 2px 16px rgba(5, 0, 56, 0.08)'
      }
    },
    MuiAppBar: {
      root: {
        flexDirection: 'row-reverse',
        backgroundColor: `${colors.gray000} !important`,
        boxShadow: '0px 2px 16px rgba(5, 0, 56, 0.08)'
      }
    },
    MuiMenu: {
      list: {
        maxHeight: '200px'
      }
    },
    MuiMenuItem: {
      root: {
        fontSize: '14px',
        fontFamily: 'MarkProBook',
        color: lemonadeTheme.colors.black
      }
    },
    MuiSelect: {
      root: {
        padding: '14px',
        fontSize: '14px',
        color: lemonadeTheme.colors.black
      },
      select: {
        '&:focus': {
          backgroundColor: colors.gray000
        }
      }
    },
    MuiOutlinedInput: {
      root: {
        '&:hover $notchedOutline': {
          borderColor: colors.gray040
        }
      }
    },
    MuiTabs: {
      root: {
        button: {
          '&$selected': {
            color: 'black',
            border: '1px solid'
          }
        }
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: '20px',
        paddingRight: '14px'
      }
    }
  },
  typography: {
    fontFamily: ['"MarkPro"'].join(','),
    h5: {
      fontWeight: 500
    },
    h6: {
      fontWeight: 400
    },
    subtitle1: {
      fontSize: 16
    },
    subtitle2: {
      fontSize: 14
    },
    button: {
      textTransform: 'none'
    },
    body1: {
      fontFamily: 'MarkProBook'
    }
  }
})

export function Theme({ children }: { children: ReactNode }) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}
