export enum Currency {
  ABW = 'ƒ',
  AFG = '؋',
  AGO = 'Kz',
  AIA = '$',
  ALA = '€',
  ALB = 'L',
  AND = '€',
  ARE = 'د.إ',
  ARG = '$',
  ARM = 'դր.',
  ASM = '$',
  ATA = '$',
  ATF = '€',
  ATG = '$',
  AUS = '$',
  AUT = '€',
  AZE = 'm',
  BDI = 'Fr',
  BEL = '€',
  BEN = 'Fr',
  BES = '$',
  BFA = 'Fr',
  BGD = '৳',
  BGR = 'лв',
  BHR = '.د.ب',
  BHS = '$',
  BIH = 'КМ',
  BLM = '€',
  BLR = 'Br',
  BLZ = '$',
  BMU = '$',
  BOL = 'Bs.',
  BRA = 'R$',
  BRB = '$',
  BRN = 'B$',
  BTN = 'Nu.',
  BVT = 'kr',
  BWA = 'P',
  CAF = 'Fr',
  CAN = '$',
  CCK = '$',
  CHE = 'Fr',
  CHL = '$',
  CHN = '¥',
  CIV = 'Fr',
  CMR = 'Fr',
  COD = 'Fr',
  COG = 'Fr',
  COK = '$',
  COL = '$',
  COM = 'Fr',
  CPV = '$',
  CRI = '₡',
  CUB = '$',
  CUW = 'ƒ',
  CXR = '$',
  CYM = '$',
  CYP = '€',
  CZE = 'Kč',
  DEU = '€',
  DJI = 'Fr',
  DMA = '$',
  DNK = 'kr',
  DOM = '$',
  DZA = 'د.ج',
  ECU = '$',
  EGY = 'ج.م',
  ERI = 'Nfk',
  ESH = 'د.م.',
  ESP = '€',
  EST = '€',
  ETH = 'Br',
  FIN = '$',
  FJI = '£',
  FLK = '£',
  FRA = '€',
  FRO = 'kr',
  FSM = '$',
  GAB = 'Fr',
  GBR = '£',
  GEO = 'ლ',
  GGY = '£',
  GHA = '₵',
  GIB = '£',
  GIN = 'Fr',
  GLP = '€',
  GMB = 'D',
  GNB = 'Fr',
  GNQ = 'Fr',
  GRC = '€',
  GRD = '$',
  GRL = 'kr',
  GTM = 'Q',
  GUF = '€',
  GUM = '$',
  GUY = '$',
  HKG = '$',
  HMD = '$',
  HND = 'L',
  HRV = 'kn',
  HTI = 'G',
  HUN = 'Ft',
  IDN = 'Rp',
  IMN = '£',
  IND = '₹',
  IOT = '$',
  IRL = '€',
  IRN = '﷼',
  IRQ = 'ع.د',
  ISL = 'kr',
  ISR = '₪',
  ITA = '€',
  JAM = '$',
  JEY = '£',
  JOR = 'د.ا',
  JPN = '¥',
  KAZ = '₸',
  KEN = 'Sh',
  KGZ = 'лв',
  KHM = '៛',
  KIR = '$',
  KNA = '$',
  KOR = '₩',
  KWT = 'د.ك',
  LAO = '₭',
  LBN = 'ل.ل',
  LBR = '$',
  LBY = 'ل.د',
  LCA = '$',
  LIE = 'Fr',
  LKA = 'Rs',
  LSO = 'L',
  LTU = '€',
  LUX = '€',
  LVA = '€',
  MAC = 'MOP$',
  MAF = '€',
  MAR = 'د.م.',
  MCO = '€',
  MDA = 'L',
  MDG = 'Ar',
  MDV = '.ރ',
  MEX = '$',
  MHL = '$',
  MKD = 'ден',
  MLI = 'Fr',
  MLT = '€',
  MMR = 'Ks',
  MNE = '€',
  MNG = '₮',
  MNP = '$',
  MOZ = 'MT',
  MRT = 'UM',
  MSR = '$',
  MTQ = '€',
  MUS = '₨',
  MWI = 'MK',
  MYS = 'RM',
  MYT = '€',
  NAM = '$',
  NCL = 'Fr',
  NER = 'Fr',
  NFK = '$',
  NGA = '₦',
  NIC = 'C$',
  NIU = '$',
  NLD = '€',
  NOR = 'kr',
  NPL = '₨',
  NRU = '$',
  NZL = '$',
  OMN = 'ر.ع.',
  PAK = '₨',
  PAN = 'B/.',
  PCN = '$',
  PER = 'S/.',
  PHL = '₱',
  PLW = '$',
  PNG = 'K',
  POL = 'zł',
  PRI = '$',
  PRK = '₩',
  PRT = '€',
  PRY = '₲',
  PSE = '₪',
  PYF = 'Fr',
  QAT = 'ر.ق',
  REU = '€',
  ROU = '€',
  RUS = 'L',
  RWA = 'руб.',
  SAU = 'Fr',
  SDN = 'ر.س',
  SEN = '£',
  SGP = '$',
  SGS = '£',
  SHN = '£',
  SJM = 'kr',
  SLB = '$',
  SLE = 'Le',
  SLV = '$',
  SMR = '€',
  SOM = 'Sh',
  SPM = '€',
  SRB = 'дин.',
  SSD = '£',
  STP = 'Db',
  SUR = '$',
  SVK = '€',
  SVN = '€',
  SWE = 'kr',
  SWZ = 'L',
  SXM = 'Kz',
  SYC = '₨',
  SYR = '£S',
  TCA = '$',
  TCD = 'Fr',
  TGO = 'Fr',
  THA = '฿',
  TJK = 'ЅМ',
  TKL = '$',
  TKM = 'm',
  TLS = '$',
  TON = 'T$',
  TTO = '$',
  TUN = 'د.ت',
  TUR = '₺',
  TUV = '$',
  TWN = '$',
  TZA = 'Sh',
  UGA = 'Sh',
  UKR = '₴',
  UMI = '$',
  URY = '$',
  USA = '$',
  UZB = 'лв',
  VAT = '€',
  VCT = '$',
  VEN = 'Bs.',
  VGB = '$',
  VIR = '$',
  VNM = '₫',
  VUT = 'Vt',
  WLF = 'Fr',
  WSM = 'T',
  YEM = '﷼',
  ZAF = 'R',
  ZMB = 'ZK',
  ZWE = '$',
  USD = '$',
  EUR = '€',
  GBP = '£'
}

export const getCurrencySymbol = (currencyCode: keyof typeof Currency) => {
  const currency = Currency[currencyCode]

  return currency
}
