import React, { useState } from 'react'
import { FormikProvider, useFormik } from 'formik'
import * as Yup from 'yup'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { EditOrganizationDetailsModalProps } from '../OverviewDetails.types'

import { useMutation } from '@percent/admin-dashboard/common/hooks'
import { AcknowledgeModal, ActionModal, Modal, FormField, Select, Spacer, Text, TextInput } from '@percent/lemonade'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { getCountryFromAlpha3 } from '@percent/admin-dashboard/common/utility/getCountryFromCountryCode'
import { USStates } from '@percent/admin-dashboard/constants/usStates'
import { getChangedValues } from '@percent/utility'

export function EditLocationModal({ open, onClose, organization, refresh }: EditOrganizationDetailsModalProps) {
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const { adminService } = useServices()
  const [errorDialogState, setErrorDialogState] = useState(false)

  const [{ isLoading, success }, { apiFunc }] = useMutation(adminService.editOrganisation, undefined, () => {
    setErrorDialogState(true)
  })

  const initialValues = {
    addressLine1: organization.addressLine1,
    addressLine2: organization.addressLine2,
    city: organization.city,
    state: organization.state,
    postal: organization.postcode
  }

  const formik = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues,
    validationSchema: () => {
      return Yup.object().shape({
        addressLine1: Yup.string()
          .min(1, t('errorMessage.orgFieldCharacterLimit', { fieldName: 'Address line 1' }))
          .max(255, t('errorMessage.orgFieldCharacterLimit', { fieldName: 'Address line 1' }))
          .nullable()
          .trim(),
        addressLine2: Yup.string()
          .min(1, t('errorMessage.orgFieldCharacterLimit', { fieldName: 'Address line 2' }))
          .max(255, t('errorMessage.orgFieldCharacterLimit', { fieldName: 'Address line 2' }))
          .nullable()
          .trim(),
        city: Yup.string()
          .min(1, t('errorMessage.orgFieldCharacterLimit', { fieldName: 'City' }))
          .max(255, t('errorMessage.orgFieldCharacterLimit', { fieldName: 'City' }))
          .nullable()
          .trim(),
        state: Yup.string()
          .min(
            1,
            organization.countryCode !== 'USA'
              ? t('errorMessage.orgFieldCharacterLimit', { fieldName: 'County or state' })
              : t('errorMessage.orgFieldCharacterLimit', { fieldName: 'State' })
          )
          .max(
            255,
            organization.countryCode !== 'USA'
              ? t('errorMessage.orgFieldCharacterLimit', { fieldName: 'County or state' })
              : t('errorMessage.orgFieldCharacterLimit', { fieldName: 'State' })
          )
          .nullable()
          .trim(),
        postal: Yup.string()
          .min(1, t('errorMessage.orgFieldCharacterLimit', { fieldName: 'Postal' }))
          .max(255, t('errorMessage.orgFieldCharacterLimit', { fieldName: 'Postal' }))
          .nullable()
          .trim()
      })
    },
    onSubmit: async values => {
      await apiFunc({
        payload: getChangedValues(values, initialValues),
        id
      })
    }
  })

  const { values, dirty, errors, handleChange, handleBlur, handleSubmit, isValid, resetForm, setFieldValue, touched } =
    formik

  const handleCloseModal = () => {
    onClose()
    refresh()
    resetForm()
  }

  const handleOnErrorClose = () => {
    setErrorDialogState(false)
  }

  const showLocationSuccessOrErrorModal =
    success && !errorDialogState ? (
      <AcknowledgeModal
        result="positive"
        title={t('dialog.editOrganizationDetails.success.title', { organizationCard: 'Location' })}
        description={t('dialog.editOrganizationDetails.success.description', { organizationCard: 'location' })}
        buttonText="Close"
        handleClose={handleCloseModal}
      />
    ) : (
      <AcknowledgeModal
        result="negative"
        title={t('dialog.editOrganizationDetails.error.title', { organizationCard: 'Location' })}
        description={t('dialog.editOrganizationDetails.error.description', { organizationCard: 'location' })}
        buttonText="Close"
        handleClose={handleOnErrorClose}
      />
    )

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="bank-details-form-modal">
      {!success && !errorDialogState ? (
        <ActionModal
          title={t('dialog.editLocation.title')}
          primaryButtonText={t('button.saveChanges')}
          secondaryButtonText={t('button.cancel')}
          type="submit"
          variant={isValid && dirty ? 'primary' : 'secondary'}
          disabled={!(isValid && dirty && !isLoading)}
          loading={isLoading}
          handleClose={() => {
            onClose()
            resetForm()
          }}
          handleSubmit={handleSubmit}
          primaryBtnTestId="location-details-submit-button"
          secondaryBtnTestId="location-details-cancel-button"
        >
          <Text size="small">
            {t('dialog.editLocation.description', { country: getCountryFromAlpha3(organization.countryCode) })}
          </Text>
          <form onSubmit={handleSubmit}>
            <FormikProvider value={formik}>
              <Spacer size={4} axis="vertical" />
              <>
                <FormField
                  label={t('typography.addressLine1')}
                  data-testid="addressLineOne"
                  status={touched.addressLine1 && errors.addressLine1 ? 'danger' : 'default'}
                  statusMessage={errors.addressLine1}
                >
                  <TextInput
                    name="addressLine1"
                    value={values.addressLine1 || ''}
                    placeholder="Add address line 1"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </FormField>
                <Spacer size={4} axis="vertical" />
                <FormField
                  label={t('typography.addressLine2')}
                  data-testid="addressLineTwo"
                  status={touched.addressLine2 && errors.addressLine2 ? 'danger' : 'default'}
                  statusMessage={errors.addressLine2}
                >
                  <TextInput
                    name="addressLine2"
                    value={values.addressLine2 || ''}
                    placeholder="Add address line 2"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </FormField>
                <Spacer size={4} axis="vertical" />
                <FormField
                  label={t('typography.city')}
                  data-testid="city"
                  status={touched.city && errors.city ? 'danger' : 'default'}
                  statusMessage={errors.city}
                >
                  <TextInput
                    name="city"
                    value={values.city || ''}
                    placeholder="Add city"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </FormField>
                <Spacer size={4} axis="vertical" />
                {organization.countryCode !== 'USA' ? (
                  <FormField
                    label={t('typography.countyOrState')}
                    data-testid="state"
                    status={touched.state && errors.state ? 'danger' : 'default'}
                    statusMessage={errors.state}
                  >
                    <TextInput
                      name="state"
                      value={values.state || ''}
                      placeholder="Add county or state"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </FormField>
                ) : (
                  <FormField
                    label={t('typography.state')}
                    status={errors.state ? 'danger' : 'default'}
                    statusMessage={errors.state}
                    data-testid="state"
                  >
                    <Select
                      placeholder="Select state"
                      searchable={false}
                      options={Object.values(USStates).map(key => ({ label: key, value: key }))}
                      onChange={event => setFieldValue('state', event.value)}
                      defaultValue={Object.values(USStates)
                        .map(key => ({ label: key, value: key }))
                        .find(el => el.value === values.state)}
                    />
                  </FormField>
                )}
                <Spacer size={4} axis="vertical" />
                <FormField
                  label={t('typography.postalCode')}
                  data-testid="postcode"
                  status={touched.postal && errors.postal ? 'danger' : 'default'}
                  statusMessage={errors.postal}
                >
                  <TextInput
                    name="postal"
                    value={values.postal || ''}
                    placeholder="Add postcode"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </FormField>
              </>
            </FormikProvider>
          </form>
        </ActionModal>
      ) : (
        showLocationSuccessOrErrorModal
      )}
    </Modal>
  )
}
