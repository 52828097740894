import { DecodedValueMap, SetQuery } from 'use-query-params'

import { BankDetailsListView } from '@percent/admin-dashboard/api/actions/bankDetails/bankDetails.types'
import { BankDetailsQueryParams } from '@percent/admin-dashboard/common/queryParams/queryParams.types'

export enum BankDetailsStatusFilter {
  pending,
  approved,
  rejected,
  deleted
}

export type BankDetailsTableProps = {
  data: BankDetailsListView[] | null
  isLoading: boolean
  totalResults?: number
  errorMessage: string | null
  nextPage: (() => void) | null
  previousPage: (() => void) | null
  queryParams: DecodedValueMap<BankDetailsQueryParams>
  setQueryParams: SetQuery<BankDetailsQueryParams>
}
