import React, { useEffect, useState } from 'react'
import { FormikProvider, useFormik } from 'formik'
import * as Yup from 'yup'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { EditOrganizationDetailsModalProps } from '../OverviewDetails.types'

import { useMutation } from '@percent/admin-dashboard/common/hooks'
import {
  Modal,
  AcknowledgeModal,
  ActionModal,
  AvatarInput,
  FormField,
  Spacer,
  TextArea,
  TextInput
} from '@percent/lemonade'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { getChangedValues } from '@percent/utility'

export function EditBrandModal({ open, onClose, organization, refresh }: EditOrganizationDetailsModalProps) {
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const { adminService } = useServices()
  const [errorDialogState, setErrorDialogState] = useState(false)

  const [{ isLoading, success }, { apiFunc }] = useMutation(adminService.editOrganisation, undefined, () => {
    setErrorDialogState(true)
  })

  const handleImageChange = (imageFile: any) => {
    const formData = new FormData()
    formData.append('file', imageFile)
    apiFuncLogo({
      payload: {
        formData
      },
      organisationId: id
    })
  }

  const initialValues = {
    logo: organization.logo,
    displayName: organization.displayName || '',
    description: organization.description || ''
  }

  const formik = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues,
    validationSchema: () =>
      Yup.object().shape({
        logo: Yup.string().nullable(),
        displayName: Yup.string()
          .min(1, t('errorMessage.orgFieldCharacterLimit', { fieldName: 'Display name' }))
          .max(255, t('errorMessage.orgFieldCharacterLimit', { fieldName: 'Display name' }))
          .trim()
          .nullable()
          .required(t('errorMessage.required')),
        description: Yup.string()
          .min(1, t('errorMessage.orgFieldCharacterLimit', { fieldName: 'Misson' }))
          .max(1000, t('errorMessage.orgFieldCharacterLimit', { fieldName: 'Misson' }))
          .trim()
          .nullable()
          .required(t('errorMessage.required'))
      }),
    onSubmit: async ({ logo, displayName, description }) => {
      await apiFunc({
        payload: getChangedValues(
          {
            logo,
            displayName,
            description
          },
          initialValues
        ),
        id
      })
    }
  })

  const {
    values,
    setFieldValue,
    setFieldError,
    dirty,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    resetForm,
    errors,
    touched
  } = formik

  const [{ data, errorMessage: logoErrorMessage }, { apiFunc: apiFuncLogo }] = useMutation(
    adminService.commands.postOrganisationLogo
  )

  useEffect(() => {
    if (data) {
      setFieldValue('logo', data.url)
    }

    if (logoErrorMessage) {
      setFieldError('logo', logoErrorMessage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, logoErrorMessage])

  const handleCloseModal = () => {
    onClose()
    refresh()
    resetForm()
  }

  const handleOnErrorClose = () => {
    setErrorDialogState(false)
  }

  const successOrErrorModal =
    success && !errorDialogState ? (
      <AcknowledgeModal
        result="positive"
        title={t('dialog.editOrganizationDetails.success.title', { organizationCard: 'Brand' })}
        description={t('dialog.editOrganizationDetails.success.description', { organizationCard: 'brand' })}
        buttonText={t('button.close')}
        handleClose={handleCloseModal}
      />
    ) : (
      <AcknowledgeModal
        result="negative"
        title={t('dialog.editOrganizationDetails.error.title', { organizationCard: 'Brand' })}
        description={t('dialog.editOrganizationDetails.error.description', { organizationCard: 'brand' })}
        buttonText={t('button.close')}
        handleClose={handleOnErrorClose}
      />
    )

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="brand-details-form-modal">
      {!success && !errorDialogState ? (
        <ActionModal
          title={t('typography.editBrand')}
          primaryButtonText={t('button.saveChanges')}
          secondaryButtonText={t('button.cancel')}
          type="submit"
          variant={isValid && dirty ? 'primary' : 'secondary'}
          disabled={!(isValid && dirty && !isLoading)}
          loading={isLoading}
          handleClose={() => {
            onClose()
            resetForm()
          }}
          handleSubmit={handleSubmit}
          primaryBtnTestId="brand-details-submit-button"
          secondaryBtnTestId="brand-details-cancel-button"
        >
          <form onSubmit={handleSubmit}>
            <FormikProvider value={formik}>
              <>
                <AvatarInput
                  altText="organization logo"
                  image={organization.logo}
                  label="Logo"
                  buttonTitle="Choose image"
                  onChange={file => {
                    handleImageChange(file)
                  }}
                  description={t('typography.logoDescriptionText')}
                  errorMessage={logoErrorMessage}
                  avatarSize="large"
                  data-testid="brand-details-edit-logo"
                />
                <Spacer size={4} axis="vertical" />
                <FormField
                  label="Display name"
                  data-testid="displayName"
                  status={touched.displayName && errors.displayName ? 'danger' : 'default'}
                  statusMessage={errors.displayName || ''}
                  necessity="required"
                >
                  <TextInput
                    name="displayName"
                    value={values?.displayName}
                    placeholder="Add display name"
                    onBlur={handleBlur}
                    maxLength={255}
                    onChange={handleChange}
                  />
                </FormField>
                <Spacer size={4} axis="vertical" />
                <FormField
                  label="Misson"
                  data-testid="mission"
                  status={touched.description && errors.description ? 'danger' : 'default'}
                  statusMessage={errors.description || ''}
                  necessity="required"
                >
                  <TextArea
                    name="description"
                    value={values.description || ''}
                    placeholder=""
                    maxLength={1000}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </FormField>
              </>
            </FormikProvider>
          </form>
        </ActionModal>
      ) : (
        successOrErrorModal
      )}
    </Modal>
  )
}
