import React from 'react'

import { FieldCheckBox } from '@percent/admin-dashboard/common/components'
import styles from './SubTag.module.scss'
import { SubTagProps } from './SubTag.types'

export function SubTag({ subTag, active, onChange }: SubTagProps) {
  return (
    <li key={subTag.id}>
      <div className={styles.subTagContainer}>
        <FieldCheckBox
          active={active}
          onChange={() => onChange(subTag.id)}
          name={subTag.name}
          checkboxClass={active ? 'subTagCheckbox' : 'checkbox'}
        />
        <span className={styles.subTagName}>{subTag.name}</span>
      </div>
    </li>
  )
}
