import { ReactElement, useMemo } from 'react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'

import { config } from '@percent/admin-dashboard/config'
import { useAuthDispatch } from '@percent/admin-dashboard/common/hooks/useAuthDispatch/useAuthDispatch'
import { SET_LOGOUT } from '@percent/admin-dashboard/context/auth/authReducer/authReducer'

export const percentClient = axios.create({
  baseURL: config.urls.api,
  withCredentials: true
})

export const WithAxios = ({ children }: { children: ReactElement }) => {
  const dispatch = useAuthDispatch()
  const history = useHistory()

  useMemo(() => {
    percentClient.interceptors.response.use(
      responseConfig => responseConfig,
      error => {
        if (error?.response?.status === 401) {
          localStorage.removeItem('authState')

          dispatch({
            type: SET_LOGOUT
          })

          return history.push('/expired-session')
        }
        throw error
      }
    )
  }, [dispatch, history])

  return children
}
