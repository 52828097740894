import { useFormik } from 'formik'
import {
  BankDetail,
  BankDetailStatus,
  PostBankDetailsProps,
} from '../types/bankDetails.types'
import { bankDetailsViewSchema } from '../components/bankDetailsView/BankDetailsViewSchema'

type UseAddBankDetailsFormProps = {
  bankDetailsData: BankDetail | null
  t: (id: string) => string
  apiFunc: (payload: PostBankDetailsProps) => Promise<() => void> | (() => void)
  organizationId?: string
  selectedFile?: File
}

export const useAddBankDetailsForm = ({
  bankDetailsData,
  t,
  apiFunc,
  organizationId,
  selectedFile,
}: UseAddBankDetailsFormProps) => {
  const showIbanDetails =
    bankDetailsData?.requiredBankAccountDetails?.type === 'IBAN'
  const firstField = showIbanDetails ? 'bic' : 'bankCode'
  const secondField = showIbanDetails ? 'iban' : 'accountNumber'

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      holderName:
        bankDetailsData?.status !== BankDetailStatus.REJECTED
          ? bankDetailsData?.bankAccountDetails?.holderName
          : '',
      [firstField]:
        bankDetailsData?.status !== BankDetailStatus.REJECTED
          ? bankDetailsData?.bankAccountDetails?.wireTransferDetails?.[
              firstField
            ]
          : '',
      [secondField]:
        bankDetailsData?.status !== BankDetailStatus.REJECTED
          ? bankDetailsData?.bankAccountDetails?.wireTransferDetails?.[
              secondField
            ]
          : '',
    },
    validationSchema: () =>
      bankDetailsViewSchema({
        t,
        firstField,
        secondField,
        bankDetailsData,
      }),
    onSubmit: async (value) => {
      const formData = new FormData()
      const bankAccountDetails = {
        holderName: value.holderName,
        paymentDetails: {
          type: showIbanDetails ? 'iban' : 'Domestic',
          [firstField]: value[firstField],
          [secondField]: value[secondField],
        },
      }
      if (selectedFile) {
        formData.append('proofOfBankDetails', selectedFile)
        formData.append(
          'bankAccountDetails',
          JSON.stringify(bankAccountDetails)
        )
      }

      if (organizationId) {
        formData.append('organisationId', organizationId)
      }

      await apiFunc({
        payload: {
          formData,
        },
      })
    },
  })

  return {
    formik,
    firstField,
    secondField,
  }
}
