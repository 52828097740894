import React from 'react'
import { Redirect, RouteProps, Route } from 'react-router-dom'

import { Loader } from '@percent/admin-dashboard/common/components'
import { useAuthState } from '@percent/admin-dashboard/common/hooks'

export function AuthorisedRoute({ ...props }: RouteProps) {
  const { isAuthorised, isAuthorising } = useAuthState()

  if (isAuthorising) {
    return <Loader />
  }

  if (isAuthorised) {
    return <Route {...props} />
  }

  return <Redirect to="/login" />
}
