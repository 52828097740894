import { Grid, Paper, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { Loader } from '@percent/admin-dashboard/common/components'
import styles from './Documents.module.scss'
import { DocumentsProps } from './Documents.types'
import { DocumentsGallery } from '@percent/lemonade'

export function Documents({ isLoading, documents, withPreview = false, children }: Readonly<DocumentsProps>) {
  const { t } = useTranslation()

  const hasDocuments = Array.isArray(documents) && documents?.length > 0

  return (
    <Grid container spacing={0} component={Paper} elevation={0} className={styles.container} data-testid="documents">
      <Grid item xs={12} className={styles.title}>
        <Typography variant="h6">{t('typography.documents')}</Typography>
      </Grid>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {children}
          <Typography className={styles.subTitle}>
            {documents?.length === 0 && <>{t('typography.noDocumentsOrganisation')}</>}
            {!withPreview && documents?.length > 0 && (
              <>
                {t('typography.organisationAttached')} {documents?.length} {t('typography.document')}
                {documents?.length !== 1 ? 's ' : ' '}
                {t('typography.forReview')}
              </>
            )}
          </Typography>
          {hasDocuments && (
            <DocumentsGallery files={documents} withPreview={withPreview} previewUrl="/document-preview" />
          )}
        </>
      )}
    </Grid>
  )
}
