import React from 'react'
import { Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { AuthView } from '@percent/admin-dashboard/app/auth/authview/AuthView'
import LoggedInAnotherDevice from '@percent/admin-dashboard/common/assets/images/logged-another-device.png'
import { PercentButton } from '@percent/admin-dashboard/common/components'
import styles from './ExpiredSessionHandle.module.scss'

export function ExpiredSessionHandle() {
  const { t } = useTranslation()
  const history = useHistory()
  const redirectToLogin = () => history.push('/login')

  return (
    <AuthView adminLogo>
      <>
        <img className={styles.sessionImage} alt="auth-world-screen" src={LoggedInAnotherDevice} />
        <Typography variant="h5" color="secondary" className={styles.loggedInText}>
          {t('typography.sessionHasExpired')}
        </Typography>
        <Typography className={styles.loggedOutText} variant="body2">
          {t('typography.loggedOutFromThisSession')}
        </Typography>
        <PercentButton
          className={styles.loginButton}
          fullWidth
          data-testid="auth-active-button"
          title={t('button.logIn')}
          onClick={redirectToLogin}
        />
      </>
    </AuthView>
  )
}
