import React from 'react'
import { Card } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { useAgentVerificationDecisionContext } from '../../../../useAgentVerificationDecisionContext/useAgentVerificationDecisionContext'

import { ReactComponent as SuccessIcon } from '@percent/admin-dashboard/common/assets/images/success.svg'
import { PercentButton } from '@percent/admin-dashboard/common/components'
import styles from './AgentVerificationApprovedInformation.module.scss'
import { AgentVerificationApprovedInformationProps } from './AgentVerificationApprovedInformation.types'

export function AgentVerificationApprovedInformation({
  handleClick,
  agentFullName
}: AgentVerificationApprovedInformationProps) {
  const { t } = useTranslation()
  const { setOpenedModal } = useAgentVerificationDecisionContext()

  const handleDoneClick = () => {
    setOpenedModal(undefined)
    handleClick()
  }

  return (
    <Card className={styles.wrapper}>
      <SuccessIcon className={styles.icon} />
      <span className={styles.title}>{t('typography.approve.information')}</span>
      <span className={styles.subtitle}>
        {agentFullName} {t('typography.approve.partialInformation')}
      </span>
      <PercentButton title={t('button.done')} onClick={handleDoneClick} className={styles.button} />
    </Card>
  )
}
