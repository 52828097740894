import React from 'react'
import { useTranslation } from 'react-i18next'

import { PercentButton } from '@percent/admin-dashboard/common/components'
import { ReactComponent as SetupCompleteIcon } from '@percent/admin-dashboard/common/assets/images/setup-complete.svg'
import styles from './SetupComplete.module.scss'
import { SetupCompleteProps } from './SetupComplete.types'

export function SetupComplete({ logInToApp }: SetupCompleteProps) {
  const { t } = useTranslation()

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <SetupCompleteIcon className={styles.icon} />
        <h1 className={styles.header}>{t('2fa.completeHeader')}</h1>
        <p className={styles.subtext}>{t('2fa.completeSubtext')}</p>
        <PercentButton className={styles.doneButton} onClick={logInToApp} title={t('button.done')} type="button" />
      </div>
    </div>
  )
}
