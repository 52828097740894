import { Box, DialogContent, Typography } from '@material-ui/core'
import { FormEvent } from 'react'
import { useTranslation } from 'react-i18next'

import { Dialog } from '@percent/admin-dashboard/common/components'
import { Button } from '@percent/lemonade'
import { MarkAllChequesAsSentModalProps } from './MarkAllChequesAsSentModal.types'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { useToast } from '@percent/admin-dashboard/containers/toast/ToastContext'
import styles from './MarkAllChequesAsSentModal.module.scss'

export function MarkAllChequesAsSentModal({
  isOpen,
  onClose,
  disbursementBatchId,
  numberOfDisbursements,
  refreshData
}: Readonly<MarkAllChequesAsSentModalProps>) {
  const { t } = useTranslation()
  const { disbursementsService } = useServices()
  const addToast = useToast()

  const markAllChequesAsSent = async () => {
    try {
      await disbursementsService.markApprovedChequeDisbursementsAsSent(disbursementBatchId)
      addToast(t('toast.markChequesAsSentDisbursementBatchSuccess', { numberOfDisbursements }), 'success')
      refreshData?.()
      onClose()
    } catch {
      addToast(t('toast.markChequesAsSentDisbursementBatchError', { numberOfDisbursements }), 'error')
    }
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    await markAllChequesAsSent()
  }

  return (
    <Dialog
      headerTitle={t('dialog.markAllChequesAsSent.header', { numberOfDisbursements })}
      openModal={isOpen}
      onClose={onClose}
    >
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Typography variant="body2">{t('dialog.markAllChequesAsSent.info', { numberOfDisbursements })}</Typography>
          <Box className={styles.buttonsWrapper}>
            <Button size="large" type="submit" data-testid="mark-all-as-sent-modal-button">
              {t('button.markChequesAsSent')}
            </Button>
            <Button onPress={onClose} variant="secondary" size="large" type="button">
              {t('button.cancel')}
            </Button>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  )
}
