import { Redirect, useLocation } from 'react-router-dom'

import { GalleryPreview } from '@percent/lemonade'
import styles from './DocumentPreview.module.scss'

export function DocumentPreview() {
  const { search } = useLocation()
  const documentUrl = new URLSearchParams(search).get('documentUrl')

  if (!documentUrl) return <Redirect to="/" />

  return (
    <div className={styles.documentPreviewContainer}>
      <GalleryPreview url={documentUrl} />
    </div>
  )
}
