import React from 'react'
import { CircularProgress } from '@material-ui/core'

import style from './Loader.module.scss'
import { LoaderProps } from './Loader.types'

export function Loader({ loaderStyle = 'loader', size, ...rest }: LoaderProps) {
  return (
    <div className={style[loaderStyle]}>
      <CircularProgress color="secondary" thickness={5} size={size} {...rest} data-testid="loading" />
    </div>
  )
}
