import { PBPGetResponse } from '@percent/admin-dashboard/services/pbpResponses'
import { CountryList, countriesServiceCapabilities } from './countriesService.types'
import { config } from '@percent/admin-dashboard/config'

export const countriesService = ({ percentClient }: countriesServiceCapabilities) => ({
  getCountries: () =>
    percentClient.get<PBPGetResponse<CountryList>>('v1/countries', {
      headers: {
        Authorization: config.keys.pk
      },
      params: {
        hasRegistry: true
      },
      withCredentials: true
    })
})
