export const mockOrganisationDonationsList = {
  data: [
    {
      id: 'staging_organisation_000000CTmMjZD5OKgltYxrpraTest',
      name: 'Test Organisation',
      displayName: 'Test',
      description: 'Test Organisation - Organisation to claim and reject claim 111 222',
      countryCode: 'AUS',
      milestones: [],
      categories: [],
      type: 'nonprofit',
      logo: null,
      images: [],
      registry: 'staging_registry_000000C6l0tEstM1bREbtiQmIT3ST',
      registryId: 'nm_automated_ri7803e531-e580-461b-97ac-9f54ab7te2345',
      website: 'https://www.poweredbypercent.com',
      websites: ['https://www.poweredbypercent.com'],
      address: '',
      addressLine1: null,
      addressLine2: null,
      city: null,
      state: null,
      postcode: null,
      registrationDate: null,
      currencyCode: 'AUD',
      trustees: null,
      createdAt: '2022-10-19T13:27:19.579Z',
      claimedAt: null,
      countrySpecificProperties: {},
      email: null,
      donated: 25500,
      registrationActive: true
    }
  ],
  totalResults: 1,
  object: 'organisation',
  _links: {
    current: 'https://staging-api.poweredbypercent.com/v1/admin/organisations/donated?pageSize=0&status=unenrolled'
  }
}
