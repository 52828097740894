import { useState } from 'react'
import { Modal, Card, Divider } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { ErrorView, Loader, PercentButton, UpdateModalInformation } from '@percent/admin-dashboard/common/components'
import { useMutation } from '@percent/admin-dashboard/common/hooks'
import { ReactComponent as CloseIcon } from '@percent/admin-dashboard/common/assets/images/close.svg'
import styles from './ApproveDomainModal.module.scss'
import { ApproveDomainModalProps } from './ApproveDomainModal.types'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { getProperHref } from '@percent/admin-dashboard/common/utility/getProperHref'
import { ReactComponent as AlertIcon } from '@percent/admin-dashboard/common/assets/images/alert.svg'
import { useClaimApproveDomainTemporaryFix } from '@percent/admin-dashboard/app/agentVerification/agentVerificationDetails/components/agentVerificationOrganisationDetails/approveDomain/hooks/useClaimApproveDomainTemporaryFix'

export function ApproveDomainModal({
  refresh,
  agentVerificationId,
  isModalOpened,
  setModalOpened,
  organisation,
  agentVerificationWebsite,
  claimsFlowTempFixData
}: ApproveDomainModalProps) {
  const { t } = useTranslation()
  const [isSent, setIsSent] = useState(false)
  const { agentVerificationService } = useServices()
  const { push } = useHistory()

  const [{ isLoading, errorMessage }, { apiFunc: approveFunc }] = useMutation(
    agentVerificationService.approveDomain,
    () => {
      setIsSent(true)
    }
  )

  const claimsFlowTemporaryFix = useClaimApproveDomainTemporaryFix(claimsFlowTempFixData.partnerId ?? '', () => {
    setIsSent(true)
  })

  const handleUpdate = () => {
    // NIL 486: Temporary custom fix logic.
    if (claimsFlowTemporaryFix.shouldApplyFix) {
      claimsFlowTemporaryFix.applyCustomActions({
        agentVerificationId,
        applicantEmail: claimsFlowTempFixData.agentVerification?.email ?? '',
        organisationEmail: organisation?.email ?? '',
        organisationUrl: agentVerificationWebsite ?? organisation?.website ?? ''
      })

      return
    }

    approveFunc({ id: agentVerificationId })
  }

  const handleCloseModalAfterUpdate = () => {
    refresh()
    setModalOpened(false)
    setIsSent(false)
    push(`/agent-verification/${agentVerificationId}`)
  }

  return (
    <Modal
      open={isModalOpened}
      onClose={() => setModalOpened(false)}
      data-testid={claimsFlowTemporaryFix.shouldApplyFix ? 'modal-with-fix' : ''}
    >
      <div className={styles.modal}>
        {isSent ? (
          <UpdateModalInformation
            handleClick={handleCloseModalAfterUpdate}
            setModalOpened={setModalOpened}
            title={t('dialog.approveDomain.domainApproved')}
            subtitle={<p>{t('dialog.approveDomain.domainStored')}</p>}
            buttonTitle={t('button.goBackToDetails')}
          />
        ) : (
          <Card className={styles.wrapper}>
            <div className={styles.titleWrapper}>
              <span className={styles.title}>{t('dialog.approveDomain.title')}</span>
              <CloseIcon onClick={() => setModalOpened(false)} />
            </div>
            <Divider className={styles.divider} />
            <div>{t('dialog.approveDomain.body')}</div>
            <ul className={styles.sources}>
              <li>{organisation?.email}</li>
              <li>
                <a
                  href={getProperHref(String(organisation?.website ?? agentVerificationWebsite))}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {organisation?.website ?? agentVerificationWebsite}
                </a>
              </li>
            </ul>
            <div className={styles.textWrapper}>
              <span>
                <AlertIcon />
              </span>
              <span>{t('dialog.approveDomain.bodyMore')}</span>
            </div>
            {errorMessage && <ErrorView errorMessage={errorMessage} />}
            {isLoading ? (
              <Loader />
            ) : (
              <div className={styles.buttonWrapper}>
                <PercentButton testId="approve-domains-btn" title={t('button.approveDomains')} onClick={handleUpdate} />
                <PercentButton
                  title={t('button.cancel')}
                  onClick={() => setModalOpened(false)}
                  textColour="secondaryTextColour"
                  outlined
                />
              </div>
            )}
          </Card>
        )}
      </div>
    </Modal>
  )
}
