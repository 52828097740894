import { TableCell, TableRow, Typography } from '@material-ui/core'
import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { StatusBadge } from '../../statusBadge/StatusBadge'

import { getDate } from '@percent/admin-dashboard/common/utility/getDate'
import { Table } from '@percent/admin-dashboard/common/components'
import {
  BankDetailsTableProps,
  BankDetailsStatusFilter
} from '@percent/admin-dashboard/app/bankDetails/bankDetailsContainer/bankDetailsTable/BankDetailsTable.types'
import styles from './BankDetailsTable.module.scss'
import { ReactComponent as DropdownSVG } from '@percent/admin-dashboard/common/assets/images/dropdown.svg'
import { FlagAvatar } from '@percent/lemonade'

export function BankDetailsTable({
  data,
  isLoading,
  nextPage,
  previousPage,
  totalResults,
  errorMessage,
  queryParams,
  setQueryParams
}: BankDetailsTableProps) {
  const { t } = useTranslation()
  const { push } = useHistory()
  const cells = [
    { id: 'country', isSortable: false, props: { width: '80px' } },
    { id: 'organization', isSortable: false, props: { width: '344px' } },
    { id: 'contactMail', isSortable: false, props: { width: '240px' } },
    { id: 'status', isSortable: false, props: { width: '200px' } },
    { id: 'requestDate', isSortable: false, props: { width: '200px' } }
  ]

  const tabs = useMemo(() => [t('table.pending'), t('table.approved'), t('table.rejected'), t('table.deleted')], [t])

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setQueryParams({ statusFilter: BankDetailsStatusFilter[newValue] })
  }

  return (
    <Table
      value={
        BankDetailsStatusFilter[queryParams.statusFilter as keyof typeof BankDetailsStatusFilter] ??
        BankDetailsStatusFilter.pending
      }
      tabs={tabs}
      handleChange={handleTabChange}
      isLoading={isLoading}
      totalResults={errorMessage ? 0 : totalResults || 0}
      data={data}
      previousPage={previousPage}
      nextPage={nextPage}
      title={<Typography variant="h6">{t('table.bankDetailsHeader')}</Typography>}
      columns={cells}
      emptyTableText={t('table.bankDetailsEmptyTable')}
    >
      {data?.map(row => (
        <TableRow className={styles.row} key={row.organisationId} onClick={() => push(`/bank-details/${row.id}`)}>
          <TableCell>
            <FlagAvatar code3={row.countryCode} />
          </TableCell>
          <TableCell>{row.organisationName}</TableCell>
          <TableCell>{row.email}</TableCell>
          <TableCell>
            <StatusBadge status={row.status} />
          </TableCell>
          <TableCell>{getDate(row.requestDate)}</TableCell>
          <TableCell align="right" style={{ padding: '16px 0' }} className={styles.rowIconForward}>
            <DropdownSVG className={styles.arrow} />
          </TableCell>
        </TableRow>
      ))}
    </Table>
  )
}
