import { Grid, Paper, Typography } from '@material-ui/core'
import { FormikErrors, FormikValues, useFormikContext } from 'formik'
import { useEffect, useState } from 'react'
import { useDebounce } from 'usehooks-ts'
import { useTranslation } from 'react-i18next'

import { createRelationshipType } from '../dialogs'
import sectionStyles from '../common/Section.module.scss'

import { Alert, Badge, Select, AsyncSelect, FormField, Button } from '@percent/lemonade'
import { Loader } from '@percent/admin-dashboard/common/components'
import styles from './RelatedOrganisations.module.scss'
import {
  RelatedOrganisationsProps,
  RelationshipDropdownOptions,
  RelationshipDropdownValues,
  RelationshipFormValues
} from './RelatedOrganisations.types'
import { renderAddressString } from '@percent/admin-dashboard/common/utility/renderAddressString'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { useQueryList } from '@percent/admin-dashboard/common/hooks'
import { SelectOptionGroup } from 'libs/shared/ui-lemonade/src/components/select/option.types'

export function RelatedOrganisations({
  relatedOrganisations,
  isLoading,
  hasCorrectRegistryId,
  isApproved,
  registry,
  registryId
}: RelatedOrganisationsProps) {
  const { t } = useTranslation()
  const { adminService } = useServices()
  const { values, errors, touched, setFieldValue, setValues } = useFormikContext<RelationshipFormValues>()
  const [isOpen, setIsOpen] = useState(false)
  const [query, setQuery] = useState('')

  const [{ dataOrNull: searchOrganisations, isLoading: isLoadingSearchOrganisations }, { query: searchQuery }] =
    useQueryList(
      adminService.getRelatedOrganisations,
      {
        registry,
        registryId
      },
      false
    )

  const organisationOptions = relatedOrganisations?.length
    ? relatedOrganisations.map(relatedOrganisation => ({
        label: relatedOrganisation.result.name,
        value: relatedOrganisation.result.id,
        description: renderAddressString(relatedOrganisation.result.address)
      }))
    : []

  const fiscalSponsorshipRelationships = [
    {
      label: t('form.relatedOrganisations.option.fiscalSponsorshipChild'),
      value: RelationshipDropdownValues.FISCAL_SPONSORSHIP_CHILD
    },
    {
      label: t('form.relatedOrganisations.option.fiscalSponsorshipParent'),
      value: RelationshipDropdownValues.FISCAL_SPONSORSHIP_PARENT
    }
  ]

  const options: RelationshipDropdownOptions = [
    ...(relatedOrganisations?.length
      ? [
          {
            label: t('form.relatedOrganisations.option.merge'),
            value: RelationshipDropdownValues.MERGE
          }
        ]
      : []),
    {
      label: t('form.relatedOrganisations.option.branchChild'),
      value: RelationshipDropdownValues.BRANCH_CHILD
    },
    {
      label: t('form.relatedOrganisations.option.branchParent'),
      value: RelationshipDropdownValues.BRANCH_PARENT
    },
    ...fiscalSponsorshipRelationships
  ]

  const parentOptionsWithSearchResults: SelectOptionGroup[] = [
    ...(values.type !== RelationshipDropdownValues.FISCAL_SPONSORSHIP_CHILD
      ? [
          {
            title: t('form.relatedOrganisations.cantFindParentSection'),
            options: [
              {
                label: t('form.relatedOrganisations.parentNotInDatabase'),
                value: 'parent_not_in_database'
              }
            ]
          }
        ]
      : []),
    ...(relatedOrganisations?.length
      ? [
          {
            title: t('form.relatedOrganisations.matchingRegistrySection'),
            options: relatedOrganisations.map(relatedOrganisation => ({
              label: relatedOrganisation.result.name,
              value: relatedOrganisation.result.id,
              description: renderAddressString(relatedOrganisation.result.address)
            }))
          }
        ]
      : []),
    ...(searchOrganisations?.length
      ? [
          {
            title: t('form.relatedOrganisations.searchResultsSection'),
            options: searchOrganisations.map(searchOrganisation => ({
              label: searchOrganisation.result.name,
              value: searchOrganisation.result.id,
              description: renderAddressString(searchOrganisation.result.address)
            }))
          }
        ]
      : [])
  ]

  const showAlert = (formErrors: FormikErrors<RelationshipFormValues>, formValues: FormikValues) => {
    if (relatedOrganisations?.length && !formValues?.type) {
      return true
    }

    if (Object.keys(formErrors).length) {
      return true
    }

    return false
  }

  const debouncedQuery = useDebounce(query, 1000)

  const relationshipValue = () => {
    if (!values.type) return undefined

    return [RelationshipDropdownValues.BRANCH_CHILD, RelationshipDropdownValues.FISCAL_SPONSORSHIP_CHILD].includes(
      values.type
    )
      ? 'child'
      : 'parent'
  }

  const canSeach = query.length >= 3

  const fullOrganisationRelationshipList = [
    ...(searchOrganisations?.length ? searchOrganisations : []),
    ...(relatedOrganisations?.length ? relatedOrganisations : [])
  ]

  useEffect(() => {
    canSeach &&
      registry &&
      registryId &&
      searchQuery({
        query: debouncedQuery,
        registry,
        registryId,
        relationshipType: createRelationshipType(values.type),
        relationship: relationshipValue()
      })
  }, [debouncedQuery])

  return (
    <Grid
      container
      spacing={0}
      component={Paper}
      elevation={0}
      className={sectionStyles.container}
      data-testid="related-organisations"
    >
      <Grid item xs={12} className={sectionStyles.title}>
        <Typography variant="h6">{t('typography.relatedOrganisations')}</Typography>
      </Grid>
      {isLoading && hasCorrectRegistryId ? (
        <Loader />
      ) : (
        <>
          {!isApproved && showAlert(errors, values) && (
            <Grid item xs={12} className={sectionStyles.alertContainer}>
              <Alert variant="warning" title={t('typography.registryIdHasMultipleOrganisations')} />
            </Grid>
          )}
          {!relatedOrganisations?.length && !isApproved ? (
            !isOpen ? (
              <Button
                variant="reject"
                data-testid="btn-add-relationship"
                onPress={() => {
                  setIsOpen(!isOpen)
                }}
              >
                {t('form.relatedOrganisations.addRelationship')}
              </Button>
            ) : null
          ) : (
            <>
              <Grid item xs={12} className={sectionStyles.sectionsContainer}>
                <Typography variant="subtitle1">
                  {relatedOrganisations?.length} {t('typography.relatedOrganisationsFound')}
                </Typography>
              </Grid>
              <Grid item xs={12} className={sectionStyles.sectionsListContainer}>
                {relatedOrganisations?.map(({ relationship, result }) => (
                  <Grid container className={sectionStyles.sectionContainer} key={result.id}>
                    <Grid xs={5}>
                      <span>{result.name}</span>
                      {isApproved && relationship && (
                        <span className={styles.relationshipBadge}>
                          <Badge variant="informative">{t(`typography.branchType.${relationship}`)}</Badge>
                        </span>
                      )}
                    </Grid>
                    <Grid xs={5}>
                      <span>{`${renderAddressString(result.address)}`}</span>
                    </Grid>
                    <Grid xs={2}>
                      <Badge variant="default">{t(`organisationTypes.${result.organisationTypes[0]}`)}</Badge>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </>
          )}

          {(!!relatedOrganisations?.length || isOpen) && !isApproved && (
            <>
              <Grid item xs={12} className={sectionStyles.sectionsInputContainer}>
                <FormField
                  label={t('form.relatedOrganisations.selectRelationship')}
                  status={errors.type && touched.type ? 'danger' : 'default'}
                  statusMessage={errors.type}
                  data-testid="selectRelationship"
                >
                  <Select
                    placeholder={t('form.relatedOrganisations.selectRelationship')}
                    searchable={false}
                    options={options}
                    onChange={({ value }) => {
                      if (value === values.type) {
                        setFieldValue('type', value)
                      } else {
                        setValues({
                          ...values,
                          [`type`]: value as RelationshipDropdownValues,
                          [`organisationId`]: ''
                        })
                      }
                    }}
                  />
                </FormField>
              </Grid>

              {(values.type === RelationshipDropdownValues.BRANCH_CHILD ||
                values.type === RelationshipDropdownValues.FISCAL_SPONSORSHIP_CHILD) && (
                <Grid item xs={12} className={sectionStyles.sectionsInputContainer}>
                  <FormField
                    label={t('form.relatedOrganisations.selectParent')}
                    status={errors.organisationId && touched.organisationId ? 'danger' : 'default'}
                    statusMessage={errors.organisationId}
                    data-testid="selectParent"
                  >
                    <AsyncSelect
                      placeholder={t('form.relatedOrganisations.selectParent')}
                      options={parentOptionsWithSearchResults}
                      loading={canSeach && isLoadingSearchOrganisations}
                      setQuery={setQuery}
                      defaultValue={parentOptionsWithSearchResults
                        .flatMap(group => group.options)
                        .find(({ value }) => values.organisationId === value)}
                      onChange={event => {
                        setValues({
                          ...values,
                          organisationId: event?.value,
                          organisation: fullOrganisationRelationshipList.find(
                            ({ result }) => result.id === event?.value
                          )
                        })
                      }}
                    />
                  </FormField>
                </Grid>
              )}

              {values.type === RelationshipDropdownValues.MERGE && (
                <Grid item xs={12} className={sectionStyles.sectionsInputContainer}>
                  <FormField
                    label={t('form.relatedOrganisations.selectMergeExistingOrganisation')}
                    status={errors.organisationId && touched.organisationId ? 'danger' : 'default'}
                    statusMessage={errors.organisationId}
                    data-testid="selectMergeExistingOrganisation"
                  >
                    <Select
                      placeholder={t('form.relatedOrganisations.selectMergeExistingOrganisation')}
                      searchable={false}
                      options={organisationOptions}
                      defaultValue={organisationOptions.find(({ value }) => values.organisationId === value)}
                      onChange={event =>
                        setValues({
                          ...values,
                          organisationId: event.value,
                          organisation: fullOrganisationRelationshipList.find(({ result }) => result.id === event.value)
                        })
                      }
                    />
                  </FormField>
                </Grid>
              )}
            </>
          )}
        </>
      )}
    </Grid>
  )
}
