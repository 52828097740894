import { useContext } from 'react'

import { FoundationsContext } from '@percent/admin-dashboard/context/foundations'

export const useFoundations = () => {
  const context = useContext(FoundationsContext)

  if (context === undefined) {
    throw new Error('useFoundations must be used within an FoundationsContextController')
  }

  return context
}
