import { rest } from 'msw'

import { config } from '@percent/admin-dashboard/config'
import { mockActivityTags } from '@percent/admin-dashboard/fixtures/api/mockCompliance/mockActivityTags'
import { mockActivityEventsList } from '@percent/admin-dashboard/fixtures/api/mockCompliance/mockAcitivityEvents'

export const activityHandlers = [
  rest.get(`${config.urls.api}v1/admin/activity-events/:id`, async (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockActivityEventsList))
  }),
  rest.get(`${config.urls.api}v1/admin/activity-tags`, async (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockActivityTags))
  }),
  rest.post(`${config.urls.api}v1/admin/activity-events`, async (_, res, ctx) => {
    return res(ctx.status(200))
  })
]
