import { TableCell, TableRow } from '@material-ui/core'
import { ChangeEvent, EventHandler, useCallback, useMemo, useState, KeyboardEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { ReactComponent as DropdownSVG } from '@percent/admin-dashboard/common/assets/images/dropdown.svg'
import { Table } from '@percent/admin-dashboard/common/components'
import { Badge, SearchInput } from '@percent/lemonade'
import { ValidationSubmissionsTableProps } from './ValidationSubmissionsTable.types'
import { CountryCodeFlag } from '@percent/admin-dashboard/common/components/countryCodeFlag/CountryCodeFlag'
import { statusMap } from '@percent/admin-dashboard/constants/statusMap'
import { getDate } from '@percent/admin-dashboard/common/utility'
import styles from './ValidationSubmissionsTable.module.scss'

export function ValidationSubmissionsTable({
  data,
  isLoading,
  nextPage,
  previousPage,
  totalResults,
  errorMessage,
  queryParams,
  setQueryParams
}: Readonly<ValidationSubmissionsTableProps>) {
  const { t } = useTranslation()
  const { push } = useHistory()

  const [searchValue, setSearchValue] = useState<string>(queryParams.query || '')

  const cells = [
    { id: 'country', isSortable: false },
    { id: 'organization', isSortable: false },
    { id: 'agentEmail', isSortable: false },
    { id: 'status', isSortable: false },
    { id: 'applicationDate', isSortable: false }
  ]

  const handleRowClick = (id: string) => {
    return push(`/validation-submissions/${id}`)
  }

  const handleKeyChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value)
  }

  const handleKeyPress: EventHandler<KeyboardEvent<HTMLInputElement>> = useCallback(
    event => {
      if (event.key === 'Enter') {
        setQueryParams({
          query: (event.target as HTMLInputElement).value.trim()
        })
      }
    },
    [setQueryParams]
  )

  const handleClearValue = () => {
    setSearchValue('')
    setQueryParams({ query: undefined })
  }

  const filters = useMemo(
    () => (
      <SearchInput
        value={searchValue ?? ''}
        handleClearValue={handleClearValue}
        onKeyDown={handleKeyPress}
        onChange={handleKeyChange}
        placeholder={t('table.searchByAgentsEmail')}
      />
    ),
    [t, handleKeyPress, searchValue]
  )

  return (
    <Table
      isLoading={isLoading}
      totalResults={errorMessage ? 0 : totalResults || 0}
      data={data}
      previousPage={previousPage}
      nextPage={nextPage}
      columns={cells}
      filtersContent={filters}
      emptyTableText={t('typography.noSearchResults')}
    >
      {data?.map(row => (
        <TableRow className={styles.row} key={row.id} onClick={() => handleRowClick(row.id)}>
          <CountryCodeFlag countryCode={row.organisation.countryCode ?? row.validationRequest.countryCode} />
          <TableCell>{row.organisation.name ?? row.validationRequest.name}</TableCell>
          <TableCell className={styles.email}>{row.agentVerification.email || t('status.na')}</TableCell>
          <TableCell>
            <Badge variant={statusMap[row.status].variant} icon={statusMap[row.status].icon}>
              {t(statusMap[row.status].text)}
            </Badge>
          </TableCell>
          <TableCell>{getDate(row.createdAt)}</TableCell>
          <TableCell>
            <DropdownSVG className={styles.arrow} />
          </TableCell>
        </TableRow>
      ))}
    </Table>
  )
}
