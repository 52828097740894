import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { useQuery } from '@percent/admin-dashboard/common/hooks'
import { EligibilitySettings } from './EligibilitySettings'
import { EligibilitySettingsContainerProps } from './EligibilitySettingsContainer.types'

export function EligibilitySettingsContainer({ partner, refreshPartnerApi }: EligibilitySettingsContainerProps) {
  const { eligibilityService } = useServices()

  const [{ data, isLoading, errorMessage }, { refresh }] = useQuery(eligibilityService.getActivityTags, {})

  return (
    <EligibilitySettings
      tagsData={data}
      isLoading={isLoading}
      errorMessage={errorMessage}
      partner={partner}
      refresh={refresh}
      refreshPartnerApi={refreshPartnerApi}
    />
  )
}
