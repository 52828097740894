import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Toolbar,
  Typography
} from '@material-ui/core'
import React, { useState, EventHandler, KeyboardEvent, ChangeEvent } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { ErrorView, Loader, PaginationActions, PercentButton } from '@percent/admin-dashboard/common/components'
import { useQueryList } from '@percent/admin-dashboard/common/hooks'
import style from './MemberListTable.module.scss'
import { CreateMemberDialog } from './createMemberDialog/CreateMemberDialog'
import { MemberTableMenu } from './memberTableMenu/MemberTableMenu'
import { Badge, SearchInput, Spacer } from '@percent/lemonade'
import { AssignRoleDialog } from '@percent/admin-dashboard/app/member/AssignRoleDialog/AssignRoleDialog'
import { MemberListTableAccountDetails, MemberListTableProps } from './MemberListTable.types'

export function MemberListTable({ ssoLoginOnly, queryParams }: MemberListTableProps) {
  const { id: partnerId } = useParams<{ id: string }>()
  const [open, setOpen] = useState(false)
  const [assignRoleToAccount, setAssignRoleToAccount] = useState<MemberListTableAccountDetails | undefined>(undefined)
  const [searchValue, setSearchValue] = useState<string>(queryParams?.filter ?? '')
  const { adminService } = useServices()
  const [{ isLoading, totalResults, errorMessageOrNull, dataOrNull }, { refresh, nextPage, previousPage, query }] =
    useQueryList(adminService.queries.getPartnerDeveloperList, {
      partnerId,
      filter: queryParams?.filter ?? ''
    })

  const { t } = useTranslation()

  const handleCreateMember = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleClearValue = () => {
    setSearchValue('')
    query({ partnerId })
  }

  const handleKeyPress: EventHandler<KeyboardEvent<HTMLInputElement>> = event => {
    if (event.key === 'Enter') {
      query({
        partnerId,
        filter: (event.target as HTMLInputElement).value.trim()
      })
    }

    if (event.key === 'Escape') {
      handleClearValue()
    }
  }

  const handleKeyChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value)
  }

  if (isLoading) {
    return <Loader />
  }

  if (errorMessageOrNull) {
    return <ErrorView errorMessage={errorMessageOrNull} />
  }

  return (
    <TableContainer className={style.tableContainer}>
      <Toolbar className={style.toolbar}>
        <Typography variant="h6">{t('typography.members')}</Typography>
        <PercentButton
          title={`+ ${t('button.addNewMember')}`}
          onClick={handleCreateMember}
          className={style.addButton}
          disabled={ssoLoginOnly}
          testId="add-new-member"
        />
        <CreateMemberDialog onClose={handleClose} open={open} refresh={refresh} partnerId={partnerId} />
      </Toolbar>
      <SearchInput
        name="search-input"
        value={searchValue}
        handleClearValue={handleClearValue}
        onKeyDown={handleKeyPress}
        onChange={handleKeyChange}
        placeholder={t('table.adminPartnersMemberListSearchPlaceholder')}
      />
      <Spacer size={4} axis="horizontal" />
      <Table className={style.table}>
        <TableHead>
          <TableRow>
            <TableCell>{t('typography.email')}</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {dataOrNull?.map(({ id, email, role }) => (
            <TableRow key={`member-list-${id}`}>
              <TableCell>{email}</TableCell>
              <TableCell>
                {' '}
                <Badge variant="default">{role?.displayName}</Badge>
              </TableCell>
              <TableCell align="right" className={style.tableCell}>
                <MemberTableMenu
                  memberEmail={email}
                  memberId={id}
                  partnerId={partnerId}
                  refresh={refresh}
                  onAssignRoleClick={() =>
                    setAssignRoleToAccount({ id, email, currentRoleName: role?.displayName || 'Member' })
                  }
                  ssoLoginOnly={ssoLoginOnly}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan={3}>
              <PaginationActions
                pageCount={totalResults}
                count={totalResults}
                firstPage={null}
                lastPage={null}
                nextPage={nextPage}
                previousPage={previousPage}
              />
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
      <AssignRoleDialog
        open={!!assignRoleToAccount}
        account={assignRoleToAccount}
        partnerId={partnerId}
        refresh={refresh}
        onClose={() => setAssignRoleToAccount(undefined)}
      />
    </TableContainer>
  )
}
