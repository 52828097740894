export const mockActivityEvents = {
  data: [],
  object: 'activity_event',
  totalResults: 0,
  _links: {
    current:
      'https://staging-api.poweredbypercent.com/v1/admin/activity-events?pageSize=25&organisationId=staging_organisation_000000COdAU01EbPhmUNah1FMwyht'
  }
}
export const mockActivityEventsList = {
  data: [
    {
      createdAt: '2022-10-11T11:42:53.061Z',
      createdBy: 'staging_account_000000C4G3mktrJsUSs0iPUAOwtBA',
      createdByEmail: 'test@test.test',
      createdByFullName: 'Test Name',
      expiresAt: '2023-10-11T11:42:53.086Z',
      id: 'staging_activityevent_000000CTSHe22y86CM27yWFjAwgIC',
      organisationId: 'staging_organisation_000000C6pZ3jKMdNUr0hMtgoYuDnf',
      organisationName: 'Oxfam',
      subTags: [
        'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZg',
        'staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZm'
      ]
    },
    {
      createdAt: '2022-10-11T11:42:53.061Z',
      createdBy: 'staging_account_000000C4G3mktrJsUSs0iPUAOwtBA',
      createdByEmail: 'test2@test.test',
      createdByFullName: 'TestTwo Name',
      expiresAt: '2023-10-11T11:42:53.086Z',
      id: 'staging_activityevent_000000CTSHe22y86CM27yWFjAwgIC',
      organisationId: 'staging_organisation_000000C6pZ3jKMdNUr0hMtgoYuDnf',
      organisationName: 'Oxfam',
      subTags: ['staging_activitysubtag_000000CJhePhxrRQ4klXXAMPWIjZH']
    }
  ],
  object: 'activity_event'
}
