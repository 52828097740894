import classNames from 'classnames/bind'
import Highlighter from 'react-highlight-words'
import kebabCase from 'lodash/kebabCase'
import { useTranslation } from 'react-i18next'

import { Tooltip } from '../tooltip/Tooltip'
import { FieldCheckBox } from '../fieldCheckBox/FieldCheckBox'

import { ReactComponent as AlertIcon } from '@percent/admin-dashboard/common/assets/images/alert.svg'
import styles from './ActivitySubTag.module.scss'
import { SubTagProps } from './ActivitySubTag.types'

const cx = classNames.bind(styles)

export function ActivitySubTag({ active, onChange, disabled, subTag, index, searchWords }: SubTagProps) {
  const { t } = useTranslation()

  const handleChange = () => {
    if (!disabled) {
      onChange(subTag.id)
    }
  }

  return (
    <Tooltip title={subTag.isSensitive ? t('typography.tagPlaceholder') : ''} placement="top-start">
      <div className={styles.subTag}>
        <div className={styles.box}>
          <div
            className={cx({
              container: true,
              active,
              disabled,
              sensitive: subTag.isSensitive
            })}
            onClick={handleChange}
            onKeyDown={handleChange}
            role="button"
            tabIndex={index}
          >
            <FieldCheckBox
              active={active}
              onChange={() => {}}
              name={subTag.id}
              checkboxClass="subtag"
              disabled={disabled}
            >
              <>
                <span className={styles.subTagTitle}>
                  <div>
                    <Highlighter
                      highlightClassName={styles.highlighted}
                      searchWords={searchWords}
                      autoEscape
                      textToHighlight={subTag.name || ''}
                    />
                  </div>
                </span>
                {subTag.isSensitive && (
                  <AlertIcon data-testid={`sensitive-tag-${kebabCase(subTag.name)}`} className={styles.alert} />
                )}
              </>
            </FieldCheckBox>
          </div>
        </div>
        <span className={styles.description}>
          <Highlighter
            highlightClassName={styles.highlighted}
            searchWords={searchWords}
            autoEscape
            textToHighlight={subTag.description || ''}
          />
        </span>
      </div>
    </Tooltip>
  )
}
