import React, { useMemo } from 'react'

import { NonprofitsContext } from '../NonprofitsContext'

import { NonprofitsContextControllerProps } from './NonprofitsContextController.types'

export function NonprofitsContextController({ children, validation, setValidation }: NonprofitsContextControllerProps) {
  const nonprofitsContextValue = useMemo(
    () => ({
      validation,
      setValidation
    }),
    [validation, setValidation]
  )

  return <NonprofitsContext.Provider value={nonprofitsContextValue}>{children}</NonprofitsContext.Provider>
}
