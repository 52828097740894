import { ClickAwayListener, Container, Popper, PopperPlacementType } from '@material-ui/core'
// eslint-disable-next-line import/order
import React, { useEffect, useState } from 'react'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

import { useTranslation } from 'react-i18next'

import { DateRangePopperType } from '../DateRange.types'
import { PercentButton } from '../../percentButton/PercentButton'

import { CustomDateRange } from '@percent/admin-dashboard/common/components/dateRange/CustomDateRange'
import { dayJS } from '@percent/admin-dashboard/common/library/utility/date'
import styles from './DateRangePopper.module.scss'

export function DateRangePopper({
  open,
  anchorEl,
  placement,
  setOpen,
  setAnchorEl,
  setPlacement,
  setQueryParams,
  maxDate,
  startDate,
  endDate
}: DateRangePopperType) {
  const { t } = useTranslation()

  const [selectionRange, setSelectionRange] = useState([
    {
      startDate: startDate || dayJS().utc().toDate(),
      endDate: endDate || dayJS().endOf('day').endOf('day').toDate(),
      key: 'selection'
    }
  ])

  useEffect(() => {
    setSelectionRange([
      {
        startDate: startDate || dayJS().utc().toDate(),
        endDate: endDate || dayJS().endOf('day').endOf('day').toDate(),
        key: 'selection'
      }
    ])
  }, [startDate, endDate])

  const handleApply = (e: any) => {
    e?.preventDefault()
    setQueryParams?.({
      startDate: dayJS(selectionRange[0]?.startDate).format('YYYY-MM-DDTHH:mm:ssZ'),
      endDate: dayJS(selectionRange[0]?.endDate).endOf('day').format('YYYY-MM-DDTHH:mm:ssZ')
    })
    setOpen(false)
    setAnchorEl?.(null)
  }

  const handleClickAway = (newPlacement: PopperPlacementType) => (event: any) => {
    setAnchorEl?.(anchorEl ? null : event.currentTarget)
    setOpen(false)
    setPlacement?.(newPlacement)
  }

  return (
    <Popper open={open} anchorEl={anchorEl} placement={placement} className={styles.popperContainer} transition>
      <ClickAwayListener onClickAway={handleClickAway('bottom-end')}>
        <Container className={styles.dateRangePopper}>
          <>
            <CustomDateRange selectionRange={selectionRange} setSelectionRange={setSelectionRange} maxDate={maxDate} />
            <div>
              <PercentButton
                className={styles.applyButton}
                onClick={handleApply}
                testId="apply-date"
                title={t('button.apply')}
                type="submit"
              />
            </div>
          </>
        </Container>
      </ClickAwayListener>
    </Popper>
  )
}
