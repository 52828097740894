import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useMutation } from '@percent/admin-dashboard/common/hooks'
import { Loader } from '@percent/admin-dashboard/common/components'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { DeleteMilestoneModalProps } from './DeleteMilestoneModal.types'
import { AcknowledgeModal, ConfirmationModal, Modal, Text } from '@percent/lemonade'

export function DeleteMilestoneModal({ open, onClose, id, refresh, milestoneName }: DeleteMilestoneModalProps) {
  const { id: organisationId } = useParams<{ id: string }>()
  const { t } = useTranslation()
  const { milestonesService } = useServices()
  const [successModalState, setSuccessModalState] = useState(false)
  const [errorModalState, setErrorModalState] = useState(false)
  const [{ isLoading }, { apiFunc: deleteMilestone }] = useMutation(
    milestonesService.deleteMilestone,
    () => {
      setSuccessModalState(true)
    },
    () => {
      setErrorModalState(true)
    }
  )

  const handleDelete = () => {
    deleteMilestone({ organisationId, id })
  }

  const successModal = successModalState && (
    <AcknowledgeModal
      result="positive"
      title={t('dialog.milestone.success.title', { milestoneAction: 'deleted' })}
      buttonText={t('button.close')}
      handleClose={() => {
        refresh()
        onClose()
        setSuccessModalState(false)
      }}
    />
  )

  const errorModal = errorModalState && (
    <AcknowledgeModal
      result="negative"
      title={t('dialog.milestone.error.title', { milestoneAction: 'deleted' })}
      description={t('dialog.milestone.error.description', { milestoneAction: 'delete' })}
      buttonText={t('button.close')}
      handleClose={() => {
        setErrorModalState(false)
        onClose()
      }}
    />
  )

  if (isLoading) {
    return <Loader />
  }

  return (
    <Modal open={open} onClose={onClose}>
      {successModal || errorModal || (
        <ConfirmationModal
          title={t('typography.deleteMilestone')}
          primaryButtonText={t('typography.deleteMilestone')}
          secondaryButtonText={t('button.cancel')}
          loading={isLoading}
          handleClose={onClose}
          handleSubmit={handleDelete}
          primaryBtnTestId="delete-milestone-submit-button"
          secondaryBtnTestId="delete-milestone-cancel-button"
          aria-labelledby="delete-milestone-modal"
        >
          <Text>{t('dialog.deleteMilestone.title.one', { milestoneName })}</Text>
          <br />
          <br />
          <Text>{t('dialog.deleteMilestone.title.two')}</Text>
        </ConfirmationModal>
      )}
    </Modal>
  )
}
