import React from 'react'
import { Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'

export function AuthSuccessText({ text }: { text: string }) {
  return (
    <Typography color="primary">
      {text}
      <Link to="/login">Now login here</Link>
    </Typography>
  )
}
