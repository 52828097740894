import { Paper, Grid, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { getComplyAdvantageSearchLink } from '../complianceModal/utils/getComplianceCheckLink'

import styles from './ComplianceDetails.module.scss'
import { StatusBox } from './StatusBox'
import { DetailsProps } from './ComplianceDetails.types'
import { Button } from '@percent/lemonade'

export function ComplianceDetails({ vettingEventsResults }: Readonly<DetailsProps>) {
  const { t } = useTranslation()

  const { complyAdvantageSearchId, results } = vettingEventsResults

  const redirectToComplyAdvantage = () =>
    window.open(getComplyAdvantageSearchLink(complyAdvantageSearchId || ''), '_blank', 'noreferrer')

  return (
    <Grid container spacing={0} component={Paper} elevation={0} className={styles.complianceDetailsContainer}>
      <div className={styles.detailsHeader}>
        <Typography variant="h6">{t('typography.details')}</Typography>
        {complyAdvantageSearchId && (
          <Button size="small" onPress={redirectToComplyAdvantage}>
            {t('typography.openComplyAdvantage')}
          </Button>
        )}
      </div>
      <ul className={styles.complianceList} data-testid="compliance-details-list">
        <li>
          <p>{t('form.adverseMedia')}</p>
          <StatusBox status={results?.adverseMedia?.status} />
        </li>
        <li>
          <p>{t('form.commercial')}</p>
          <StatusBox status={results?.commercial?.status} />
        </li>
        <li>
          <p>{t('form.controversial')}</p>
          <StatusBox status={results?.controversial?.status} />
        </li>
        <li>
          <p>{t('form.hateSpeech')}</p>
          <StatusBox status={results?.hateSpeech?.status} />
        </li>
        <li>
          <p>{t('form.sanction')}</p>
          <StatusBox status={results?.sanction?.status} />
        </li>
        <li>
          <p>{t('form.warning')}</p>
          <StatusBox status={results?.warning?.status} />
        </li>
      </ul>
    </Grid>
  )
}
