import React, { useMemo, useState, EventHandler, KeyboardEvent, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { PopperPlacementType, TableCell, TableRow } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { DateRangePopper } from '@percent/admin-dashboard/common/components/dateRange/dateRangePopper/DateRangePopper'
import { ReactComponent as DropdownSVG } from '@percent/admin-dashboard/common/assets/images/dropdown.svg'
import { getDate } from '@percent/admin-dashboard/common/utility/getDate'
import { ErrorView, SearchAndDateFilter, Table, TimeLeft } from '@percent/admin-dashboard/common/components'
import { TableColumn, TableSort } from '@percent/admin-dashboard/common/components/table/Table.types'
import { CountryCodeFlag } from '@percent/admin-dashboard/common/components/countryCodeFlag/CountryCodeFlag'
import styles from './EligibilityTable.module.scss'
import { EligibilityStatusFilter, EligibilityTableProps } from './EligibilityTable.types'

export function EligibilityTable({
  isLoading,
  subscriptions,
  totalResults,
  nextPage,
  previousPage,
  errorMessage,
  queryParams,
  setQueryParams
}: EligibilityTableProps) {
  const { t } = useTranslation()
  const { push } = useHistory()
  const [isDatePickerOpened, setIsDatePickerOpened] = useState(false)
  const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLElement) | null>()
  const [placement, setPlacement] = useState<PopperPlacementType>('bottom-end')

  const tabs = useMemo(() => [t('table.pending'), t('table.cantDefine'), t('table.live'), t('table.cancelled')], [t])

  const columns = useMemo(() => {
    const commonColumns: Array<TableColumn> = [
      { id: 'country', isSortable: false },
      { id: 'organization', isSortable: false },
      { id: 'partner', isSortable: false },
      { id: 'requestDate', isSortable: true, props: { width: '120px' } }
    ]

    if (queryParams.status === 'pending') {
      commonColumns.push({ id: 'timeLeft', isSortable: false })
    }

    return commonColumns
  }, [queryParams.status])

  const handleRequestSort = () =>
    setQueryParams({ direction: queryParams.direction === 'ASC' ? 'DESC' : 'ASC', sort: 'id' })

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setQueryParams({ status: EligibilityStatusFilter[newValue], sort: undefined, direction: undefined })
  }

  const handleClick = useCallback(
    (newPlacement: PopperPlacementType) => (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(anchorEl ? null : event.currentTarget)
      setIsDatePickerOpened(prev => !prev)
      setPlacement(newPlacement)
    },
    [anchorEl]
  )

  const handleKeyPress: EventHandler<KeyboardEvent<HTMLInputElement>> = useCallback(
    event => {
      if (event.key === 'Enter') {
        setQueryParams({
          query: (event.target as HTMLInputElement).value
        })
      }
    },
    [setQueryParams]
  )

  const handleClearDate = useCallback(
    () => setQueryParams({ startDate: undefined, endDate: undefined }),
    [setQueryParams]
  )

  const filters = useMemo(
    () => (
      <SearchAndDateFilter
        handleClick={handleClick}
        handleKeyPress={handleKeyPress}
        handleClearDate={handleClearDate}
        searchPhrase={queryParams.query ?? ''}
        placeholder={t('table.eligibilitySearchPlaceholder')}
      />
    ),
    [handleClick, handleKeyPress, queryParams.query, handleClearDate, t]
  )

  const handleRowClick = (id: string) => push(`/eligibility/${id}`)

  if (errorMessage) {
    return <ErrorView errorMessage={errorMessage} />
  }

  return (
    <>
      <Table
        isLoading={isLoading}
        totalResults={totalResults}
        data={subscriptions}
        value={
          EligibilityStatusFilter[queryParams.status as keyof typeof EligibilityStatusFilter] ??
          EligibilityStatusFilter.pending
        }
        handleChange={handleTabChange}
        nextPage={nextPage}
        previousPage={previousPage}
        columns={columns}
        tabs={tabs}
        order={(queryParams.direction?.toLowerCase() as TableSort) ?? 'asc'}
        orderBy="requestDate"
        handleRequestSort={handleRequestSort}
        emptyTableText={t('table.emptyEligibilitySubscriptions')}
        filtersContent={filters}
      >
        {subscriptions?.map(subscription => (
          <TableRow
            key={subscription.id}
            className={subscription.organisationId ? styles.row : undefined}
            onClick={() => subscription.organisationId && handleRowClick(subscription.id)}
          >
            <CountryCodeFlag countryCode={subscription.countryCode} />
            <TableCell>{subscription.organisationName}</TableCell>
            <TableCell>{subscription.partnerName}</TableCell>
            <TableCell align="right" style={{ padding: '16px 50px 16px 0', textAlign: 'left', whiteSpace: 'nowrap' }}>
              {getDate(subscription.createdAt)}
            </TableCell>
            {queryParams.status === 'pending' && (
              <TableCell className={styles.nameRow}>
                <TimeLeft requestedDate={subscription.createdAt} />
              </TableCell>
            )}
            {subscription.id && (
              <TableCell align="right" style={{ padding: '16px 0' }} className={styles.rowIconForward}>
                <DropdownSVG className={styles.arrow} />
              </TableCell>
            )}
          </TableRow>
        ))}
      </Table>
      <DateRangePopper
        open={isDatePickerOpened}
        anchorEl={anchorEl}
        placement={placement}
        setOpen={setIsDatePickerOpened}
        setAnchorEl={setAnchorEl}
        setPlacement={setPlacement}
        setQueryParams={setQueryParams}
      />
    </>
  )
}
