import { useTranslation } from 'react-i18next'

import { Badge } from '@percent/lemonade'
import styles from './OrganisationTypeList.module.scss'
import { OrganisationTypes } from '@percent/admin-dashboard/constants/organisationTypes'

export function OrganisationTypeList({
  validationOrganisationTypes
}: {
  validationOrganisationTypes?: OrganisationTypes[]
}) {
  const { t } = useTranslation()

  return (
    <div className={styles.listContainer}>
      {validationOrganisationTypes?.map(organisationType => (
        <Badge variant="informative" icon="categories" key={organisationType}>
          {t(`organisationTypes.${organisationType}`)}
        </Badge>
      ))}
    </div>
  )
}
