import { rest } from 'msw'

export const splitIOHandlers = [
  rest.post('*.split.io*', async (_, res, ctx) => {
    return res(ctx.status(200))
  }),
  rest.get('*.split.io*', async (_, res, ctx) => {
    return res(ctx.status(200))
  })
]
