import { PBPListResponse } from '@percent/admin-dashboard/services/pbpResponses'
import {
  eligibilityServiceCapabilities,
  GetActivityEventsProps,
  PostActivityEventProps,
  GetEligibilitySubscriptionsProps,
  EligibilitySubscription,
  ActivityEvent,
  ActivityTag
} from './eligibility.types'
import { throttleAsync } from '@percent/utility'

export const eligibilityService = ({ percentClient }: eligibilityServiceCapabilities) => ({
  getActivityEvents: (params: GetActivityEventsProps) =>
    percentClient.get<PBPListResponse<ActivityEvent>>('v1/admin/activity-events', { params }),
  getActivityTags: () => percentClient.get<PBPListResponse<ActivityTag>>('v1/admin/activity-tags'),
  getActivityTag: ({ id }: { id: string }) => percentClient.get(`v1/admin/activity-tags/${id}`),
  submitActivityEvent: (params: PostActivityEventProps) => percentClient.post('v1/admin/activity-events', params),
  getSingleEligibilitySubscription: ({ id }: { id: string }) =>
    percentClient.get(`v1/admin/eligibility-subscriptions/${id}`),
  getEligibilitySubscriptions: throttleAsync(
    (params: GetEligibilitySubscriptionsProps) =>
      percentClient.get<PBPListResponse<EligibilitySubscription>>('v1/admin/eligibility-subscriptions', { params }),
    1000,
    { leading: true, trailing: true }
  )
})
