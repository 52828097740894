import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import _, { identity } from 'lodash'
import { useTranslation } from 'react-i18next'
import { Box, DialogContent } from '@mui/material'
import { AxiosError } from 'axios'

import { ReassignDonationModalProps } from './ReassignDonationModal.types'
import { useMutation, useQueryList } from '@percent/admin-dashboard/common/hooks'
import { AcknowledgeModal, AsyncSelect, Button, FormField, Radio, Spacer, TextInput } from '@percent/lemonade'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import styles from './ReassignDonationModal.module.scss'
import { FieldDropDown } from '@percent/admin-dashboard/common/components/fieldDropDown/FieldDropDown'
import { Dialog } from '@percent/admin-dashboard/common/components'
import countries from '@percent/admin-dashboard/i18n/data/countries'
import { createShortLink } from '@percent/utility'
import i18n from '@percent/admin-dashboard/i18n/config'
import { SelectOption } from 'libs/shared/ui-lemonade/src/components/select/option.types'
import { Organisation } from '@percent/admin-dashboard/api/types'
import { ReassignmentKind } from '@percent/admin-dashboard/api/actions/donations/donations.types'
import { getApiErrorMessage } from '@percent/admin-dashboard/common/library/getApiErrorMessage'

const THROTTLING_TIME = 500
const reasons = {
  [ReassignmentKind.Unpayable]: [
    'Undeliverable - bank transfer fail',
    'Undeliverable - card payment fail',
    'Undeliverable - cheque returned',
    'Undeliverable - compliance fail',
    'Organisation was set incorrectly'
  ],
  [ReassignmentKind.Merge]: ['Duplicate organisation'],
  other: 'Other'
}

const createOptions = (items?: string[]) => {
  const allReasons = [...(items || []), reasons.other]

  return allReasons.map(reason => ({
    title: reason,
    value: reason
  }))
}

export function ReassignDonationModal({ open, onClose, refresh, donationId }: ReassignDonationModalProps) {
  const { t } = useTranslation()
  const { donationsService, adminService } = useServices()
  const [reassignDonationError, setReassignDonationError] = useState<string | undefined>(undefined)
  const [errorDialogState, setErrorDialogState] = useState(false)
  const [query, setQuery] = useState<string>('')
  const [options, setOptions] = useState(createOptions())
  const shortLanguage = i18n.language.split('-')[0]

  const [{ success }, { apiFunc }] = useMutation(donationsService.reassignDonation, undefined, error => {
    if (error instanceof AxiosError) {
      setReassignDonationError(getApiErrorMessage(error))
    }

    setErrorDialogState(true)
  })

  const [{ dataOrNull: organisationsData, isLoading: isOrganisationsLoading }, { query: organisationsQuery }] =
    useQueryList(adminService.getOrganisations, {
      pageSize: 25
    })

  const formik = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: {
      reassignmentKind: '',
      reassignedReason: '',
      customReason: '',
      organisationId: ''
    },
    validationSchema: () =>
      Yup.object().shape({
        reassignmentKind: Yup.string().oneOf(Object.values(ReassignmentKind)).required(t('errorMessage.required')),
        reassignedReason: Yup.string().oneOf(Object.values(reasons).flat()).required(t('errorMessage.required')),
        organisationId: Yup.string().trim().required(t('errorMessage.required')),
        customReason: Yup.string()
          .nullable()
          .optional()
          .when('reassignedReason', {
            is: 'Other',
            then: Yup.string()
              .trim()
              .nullable()
              .min(1, t('errorMessage.orgFieldCharacterLimit', { fieldName: 'Custom reason' }))
              .max(100, t('errorMessage.orgFieldCharacterLimit', { fieldName: 'Custom reason' }))
              .required('Custom reason is required if "Other" is selected')
          })
      }),
    onSubmit: async ({ reassignedReason, organisationId, reassignmentKind, customReason }) => {
      await apiFunc({
        payload: {
          reason: reassignedReason === reasons.other ? customReason : reassignedReason,
          organisationId,
          reassignmentKind: reassignmentKind as ReassignmentKind
        },
        donationId
      })
    }
  })

  const {
    dirty,
    touched,
    errors,
    setFieldTouched,
    setFieldValue,
    handleChange,
    handleBlur,
    setValues,
    initialValues,
    handleSubmit,
    isValid,
    resetForm,
    isSubmitting,
    values
  } = formik

  const handleCloseModal = () => {
    onClose()
    refresh()
    resetForm()
  }

  const handleOnErrorClose = () => {
    setErrorDialogState(false)
  }

  const resetReason = () => {
    if (!options.find(val => val.value === values.reassignedReason)) {
      setFieldValue('reassignedReason', initialValues.reassignedReason)
    }
  }

  const getOrganisationDescription = useCallback(
    (organisation: Organisation): string => {
      return [
        organisation.website ? createShortLink(organisation.website) : undefined,
        `${t('typography.id')}: ${organisation.registryId}`,
        countries.getName(organisation.countryCode, shortLanguage)
      ]
        .filter(identity)
        .join(' | ')
    },
    [shortLanguage, t]
  )

  const searchResults: SelectOption[] = useMemo(() => {
    return organisationsData
      ? organisationsData.map(organisation => ({
          value: organisation.id,
          label: organisation.name,
          description: getOrganisationDescription(organisation)
        }))
      : []
  }, [organisationsData, getOrganisationDescription])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOrganisationsQuery = useCallback(
    _.debounce<any>((filter: string) => {
      organisationsQuery({ query: filter })
    }, THROTTLING_TIME),
    []
  )
  useEffect(() => {
    if (query) {
      debouncedOrganisationsQuery(query)
    }
  }, [query, debouncedOrganisationsQuery])

  useEffect(() => {
    setOptions(createOptions(reasons[values.reassignmentKind as ReassignmentKind]))
  }, [values.reassignmentKind])

  const successOrErrorModal =
    success && !errorDialogState ? (
      <AcknowledgeModal
        result="positive"
        title={t('dialog.reassignDonation.success.title')}
        description={t('dialog.reassignDonation.success.description')}
        buttonText={t('button.close')}
        handleClose={handleCloseModal}
        viewTestId="success-donation-reassign-modal"
      />
    ) : (
      <AcknowledgeModal
        result="negative"
        title={reassignDonationError || t('dialog.reassignDonation.error.title')}
        description={t('dialog.reassignDonation.error.description')}
        buttonText={t('button.close')}
        handleClose={handleOnErrorClose}
        viewTestId="error-donation-reassign-modal"
      />
    )

  return (
    <Dialog
      withoutHeader={success || errorDialogState}
      headerTitle={t('dialog.reassignDonation.title')}
      openModal={open}
      onClose={handleCloseModal}
    >
      {!success && !errorDialogState ? (
        <DialogContent className={styles.dialogContent}>
          <form onSubmit={handleSubmit}>
            <FormField label="Type of reassignment" data-testid="unpayable-button" necessity="required">
              <Radio
                name="kind"
                value={ReassignmentKind.Unpayable}
                label={t('typography.reassignmentKind.unpayable')}
                checked={values.reassignmentKind === ReassignmentKind.Unpayable}
                onChange={e => {
                  setFieldValue('reassignmentKind', e.target.value)
                  resetReason()
                }}
              />
            </FormField>
            <Spacer size={4} axis="vertical" />
            <FormField data-testid="merge-button">
              <Radio
                name="kind"
                value={ReassignmentKind.Merge}
                label={t('typography.reassignmentKind.merge')}
                checked={values.reassignmentKind === ReassignmentKind.Merge}
                onChange={e => {
                  setFieldValue('reassignmentKind', e.target.value)
                  resetReason()
                }}
              />
            </FormField>
            <Spacer size={4} axis="vertical" />
            <FormField label={t('typography.newOrganisation')} data-testid="organisationId" necessity="required">
              <AsyncSelect
                name="organisationId"
                placeholder={t('typography.search.organisation')}
                onChange={e => {
                  setValues({
                    ...values,
                    organisationId: e?.value || ''
                  })
                }}
                options={searchResults}
                setQuery={e => {
                  setQuery(e)
                  setFieldTouched('organisationId')
                }}
                query={query}
                loading={isOrganisationsLoading}
                loadingText="loading"
                noResultsFoundText={t('errorMessage.noResult')}
                data-testid="organisationSearch"
              />
            </FormField>
            {values.reassignmentKind && (
              <>
                <Spacer size={4} axis="vertical" />
                <FormField
                  disabled={!values.reassignmentKind}
                  label={t('dialog.reassignDonation.reassignedReason')}
                  data-testid="reassignedReason"
                  necessity="required"
                >
                  <FieldDropDown
                    placeholder={t('dialog.reassignDonation.reassignedReason')}
                    valueArray={options}
                    initialValue=""
                    onClick={e => {
                      setFieldValue('reassignedReason', e.target.value)
                    }}
                    isClearable
                  />
                </FormField>
              </>
            )}
            {values.reassignedReason === reasons.other && (
              <>
                <Spacer size={4} axis="vertical" />
                <FormField
                  label={t('dialog.reassignDonation.customReason')}
                  necessity="required"
                  status={touched.customReason && errors.customReason ? 'danger' : 'default'}
                  statusMessage={errors.customReason || ''}
                  data-testid="customReason"
                >
                  <TextInput
                    name="customReason"
                    value={values.customReason || ''}
                    placeholder={t('dialog.reassignDonation.customReason')}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </FormField>
              </>
            )}
            <Spacer size={8} axis="vertical" />
            <Box className={styles.buttonsWrapper}>
              <Button
                data-testid="reassign-donation-submit-button"
                size="large"
                type="submit"
                disabled={!(isValid && dirty) || isSubmitting}
                loading={isSubmitting}
              >
                {t('button.reassign')}
              </Button>
              <Button
                data-testid="reassign-donation-cancel-button"
                onPress={handleCloseModal}
                variant="secondary"
                size="large"
                type="button"
              >
                {t('button.cancel')}
              </Button>
            </Box>
          </form>
        </DialogContent>
      ) : (
        successOrErrorModal
      )}
    </Dialog>
  )
}
