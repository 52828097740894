import { Icon } from '../icon'
import { Listbox } from '@headlessui/react'
import {
  StyledSelect,
  StyledSelectTrigger,
  StyledSelectTriggerDropdown,
  StyledNonSearchable,
  StyledSelectList,
  StyledSelectOptions,
} from './components'
import { MultiSelectProps } from './multi-select.types'
import { useMultiSelect } from './hooks/useMultiSelect'
import { useRef, useState } from 'react'
import { useClickOutside } from '../../util/useClickOutside'

const SelectBody = ({
  selectOptions,
  selectProps,
  disabled,
}: {
  selectOptions: ReturnType<typeof useMultiSelect>
  selectProps: MultiSelectProps
  disabled: boolean
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const { renderElements, value, triggerProps } = selectOptions
  const { placeholder, status = 'default', orientation = 'down' } = selectProps

  const clickRef = useRef(null)
  useClickOutside(clickRef, () => setIsOpen(false))

  return (
    <StyledSelect ref={clickRef}>
      <Listbox.Button
        style={{
          background: 'none',
          border: 'none',
          width: '100%',
          padding: '0',
          margin: '0',
        }}
        disabled={disabled}
        data-testid="toggle-multi-select"
        onClick={() => setIsOpen(true)}
      >
        <StyledSelectTrigger
          {...triggerProps}
          $disabled={disabled}
          $status={status}
        >
          <StyledNonSearchable>
            {value?.map((v) => v.label).join(', ') || placeholder}
          </StyledNonSearchable>

          <StyledSelectTriggerDropdown
            style={{
              transform: isOpen ? 'rotate(-180deg)' : '',
            }}
          >
            <Icon name="dropdown-arrow-down" size={8} color="gray600" />
          </StyledSelectTriggerDropdown>
          <StyledSelectList $orientation={orientation}>
            {isOpen && (
              <StyledSelectOptions>
                <Listbox.Options
                  style={{
                    margin: 0,
                    padding: 0,
                  }}
                  static
                >
                  {renderElements}
                </Listbox.Options>
              </StyledSelectOptions>
            )}
          </StyledSelectList>
        </StyledSelectTrigger>
      </Listbox.Button>
    </StyledSelect>
  )
}

export const MultiSelect: React.FC<MultiSelectProps> = ({ ...props }) => {
  const {
    placeholder,
    name,
    options,
    onChange,
    defaultValue,
    disabled,
    noResultsFoundText,
  } = props

  const select = useMultiSelect({
    options,
    placeholder,
    defaultValue,
    noResultsFoundText,
  })

  return (
    <Listbox
      name={name}
      value={select.value}
      defaultValue={defaultValue}
      onChange={(e) => {
        select.setValue(e)

        if (onChange) {
          onChange(e)
        }
      }}
      disabled={disabled}
      multiple
    >
      {({ disabled, open }) => (
        <SelectBody
          selectOptions={select}
          selectProps={props}
          disabled={disabled}
        />
      )}
    </Listbox>
  )
}
