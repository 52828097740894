import React from 'react'
import { Typography } from '@material-ui/core'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'

import { FieldInput, Loader, PercentButton } from '@percent/admin-dashboard/common/components'
import styles from './ConfirmationCodeStep.module.scss'
import { ConfirmationCodeViewProps } from './ConfirmationCodeStep.types'

export function ConfirmationCodeStep({ errorMessage, isLoading, verifyFunc }: ConfirmationCodeViewProps) {
  const { t } = useTranslation()

  const { values, errors, handleBlur, handleSubmit, touched, setFieldValue } = useFormik({
    initialValues: {
      confirmationCode: ''
    },
    validationSchema: () =>
      yup.object().shape({
        confirmationCode: yup
          .string()
          .required('Required')
          .matches(/^[0-9]+$/, t('errorMessage.onlyDigits'))
          .min(6, t('errorMessage.max6Digit'))
          .max(6, t('errorMessage.max6Digit'))
      }),
    onSubmit: ({ confirmationCode }: { confirmationCode: string }) => {
      verifyFunc({
        otpCode: confirmationCode
      })
    }
  })

  return (
    <div className={styles.confirmationCodeContainer}>
      <Typography variant="h5" className={styles.headerOne}>
        {t('2fa.enterConfirmationCode')}
      </Typography>
      <Typography variant="subtitle2" className={styles.headerTwo}>
        {t('2fa.enterConfirmationCodePart2')}
      </Typography>
      <form onSubmit={handleSubmit} className={styles.confirmationCodeForm}>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <FieldInput
              name="confirmationCode"
              label={t('form.enterSixDigit')}
              placeHolder={t('form.placeholderSixDigit')}
              value={values.confirmationCode}
              onChange={(a: string) => setFieldValue('confirmationCode', a)}
              handleBlur={handleBlur}
              error={errors.confirmationCode || errorMessage}
              touched={touched.confirmationCode}
            />
            <PercentButton title={t('button.verify')} className={styles.verifyButton} type="submit" />
          </>
        )}
      </form>
    </div>
  )
}
