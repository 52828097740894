import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { PercentButton } from '@percent/admin-dashboard/common/components'
import styles from './SuccessDialogContainer.module.scss'
import { SuccessDialogProps } from './SuccessDialog.types'

export function SuccessDialogContainer({ title, onClose, children }: SuccessDialogProps) {
  const { t } = useTranslation()

  return (
    <Grid className={styles.container}>
      <Grid container alignItems="center" direction="column" className={styles.successContainer}>
        {children}
      </Grid>
      <Grid container alignItems="center" direction="column" className={styles.titleContainer}>
        <Typography variant="h5">{title}</Typography>
      </Grid>
      <Grid container alignItems="center" direction="column" className={styles.doneButtonContainer}>
        <PercentButton
          className={styles.doneButton}
          onClick={onClose}
          testId="modal-add-item-success-button"
          title={t('button.done')}
        />
      </Grid>
    </Grid>
  )
}
