import {
  OrganisationTypes,
  mutuallyExclusiveOrganisationTypesArray
} from '@percent/admin-dashboard/constants/organisationTypes'

export const filterMutuallyExclusiveOrganisationTypes = (
  submittedOrganisationTypesArray: OrganisationTypes[],
  mergeRelationshipOrgaisationTypes: OrganisationTypes[]
) => {
  const uniqueConcatSubmitedOrganisationTypesArray = [
    ...new Set([...submittedOrganisationTypesArray, ...mergeRelationshipOrgaisationTypes])
  ]

  const firstExclusiveIndex = uniqueConcatSubmitedOrganisationTypesArray.findIndex(orgType =>
    mutuallyExclusiveOrganisationTypesArray.includes(orgType)
  )

  // Include only non-exclusive options + the first found exclusive option
  return uniqueConcatSubmitedOrganisationTypesArray.filter(
    (orgType, index) => !mutuallyExclusiveOrganisationTypesArray.includes(orgType) || index === firstExclusiveIndex
  )
}
