import React from 'react'
import QRCode from 'qrcode.react'
import { useTranslation } from 'react-i18next'

import { useWizard } from '../Wizard/useWizard/useWizard'

import { PercentButton, Loader } from '@percent/admin-dashboard/common/components'
import styles from './SetupQRStep.module.scss'
import { SetupQRStepProps } from './SetupQRStep.types'
import { Copy } from '@percent/lemonade'

export function SetupQRStep({ token = '', isLoading, tokenUri }: SetupQRStepProps) {
  const { t } = useTranslation()
  const { setNextStep } = useWizard()

  return isLoading ? (
    <Loader />
  ) : (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <h1 className={styles.header}>{t('2fa.setupAuthentication')}</h1>
        <p className={styles.text}>
          {t('2fa.scanAppPart1')} {t('2fa.authenticatorApp')}
          {t('2fa.scanAppPart2')}
        </p>
        <QRCode renderAs="svg" className={styles.qrCode} value={tokenUri ?? ''} />
        <p className={styles.subtext}>{t('2fa.cantScanQR')}</p>
        <div className={styles.copyWrapper}>
          <PercentButton className={styles.button} title={token ?? ''} disabled />
          <Copy text={token} />
        </div>
        <PercentButton className={styles.nextButton} onClick={setNextStep} title={t('button.next')} type="button" />
      </div>
    </div>
  )
}
