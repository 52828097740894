import * as Yup from 'yup'
import { sanitizeStringToRegex } from '@percent/utility'
import { BankDetail } from '../../types/bankDetails.types'

type bankDetailsViewSchemaProps = {
  t: (id: string) => string
  firstField: string
  secondField: string
  bankDetailsData?: BankDetail | null
}

export const bankDetailsViewSchema = ({
  t,
  firstField,
  secondField,
  bankDetailsData,
}: bankDetailsViewSchemaProps) =>
  Yup.object().shape({
    holderName: Yup.string()
      .max(50, t('errorMessage.holderName'))
      .min(2, t('errorMessage.holderName'))
      .required(t('errorMessage.required')),
    [firstField]: Yup.string()
      .matches(
        sanitizeStringToRegex(
          bankDetailsData?.requiredBankAccountDetails?.fields?.[firstField]
            ?.regex || ''
        ),
        bankDetailsData?.requiredBankAccountDetails?.fields?.[firstField]
          ?.errorMessage
      )
      .required(t('errorMessage.required')),
    [secondField]: Yup.string()
      .matches(
        sanitizeStringToRegex(
          bankDetailsData?.requiredBankAccountDetails?.fields?.[secondField]
            ?.regex || ''
        ),
        bankDetailsData?.requiredBankAccountDetails?.fields?.[secondField]
          ?.errorMessage
      )
      .required(t('errorMessage.required'))
      .uppercase(),
  })
