import { useContext } from 'react'

import { NonprofitsContext } from '../NonprofitsContext'

export const useNonprofitsData = () => {
  const context = useContext(NonprofitsContext)

  if (context === undefined) {
    throw new Error('useNonprofitsData must be used within an NonprofitsContextController')
  }

  return context
}
