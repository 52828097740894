import React, { useState } from 'react'
import { Grid } from '@material-ui/core'
import { useParams } from 'react-router-dom'

import { useQuery, useQueryList } from '@percent/admin-dashboard/common/hooks'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { CreateMilestoneModal } from './modal/createMilestone/CreateMilestoneModal'
import { MilestonesTable } from './milestonesTable/MilestonesTable'

export function MilestonesContainer() {
  const { id } = useParams<{ id: string }>()
  const { milestonesService, adminService } = useServices()
  const [{ totalResults, dataOrNull, isLoading, errorMessageOrNull }, { nextPage, previousPage, refresh }] =
    useQueryList(milestonesService.getMilestones(id), {})

  const [{ data: organisationData, isLoading: isOrganisationLoading, errorMessage: organisationErrorMessage }] =
    useQuery(adminService.getOrganisation, {
      id
    })

  const [open, setOpen] = useState(false)

  const handleModalOpen = () => {
    setOpen(true)
  }
  const handleModalClose = () => {
    setOpen(false)
  }

  return (
    <Grid>
      <MilestonesTable
        data={dataOrNull}
        isLoading={isLoading || isOrganisationLoading}
        errorMessage={errorMessageOrNull || organisationErrorMessage}
        totalResults={totalResults}
        openAddMilestoneModal={handleModalOpen}
        nextPage={nextPage}
        previousPage={previousPage}
        refresh={refresh}
      />
      <CreateMilestoneModal
        currencyCode={organisationData?.currencyCode}
        openModal={open}
        onClose={handleModalClose}
        refresh={refresh}
        countryCode={organisationData?.countryCode}
      />
    </Grid>
  )
}
