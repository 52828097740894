import React from 'react'
import { useTranslation } from 'react-i18next'

import { CardContent } from '@percent/lemonade'
import { PaymentMethod, UnableToPayReasons } from '@percent/admin-dashboard/api/types'
import { PaymentTypeWithButton } from './PaymentTypeWithButton'

export function UnableToPayView({ paymentMethod, refresh }: { paymentMethod: PaymentMethod; refresh: VoidFunction }) {
  const { t } = useTranslation()

  return (
    <>
      <PaymentTypeWithButton
        title={t('typography.paymentMethod')}
        text={t('typography.unableToPay')}
        paymentMethod={paymentMethod}
        refresh={refresh}
      />
      <CardContent title={t('typography.reason')} testId="unable-to-pay-details-reason">
        {t(`dropdown.unpayableOrganisation.${paymentMethod.reason as UnableToPayReasons}`) ||
          t('typography.noneSupplied')}
      </CardContent>
    </>
  )
}
