import { useContext } from 'react'

import { AgentVerificationDecisionContext } from '../agentVerificationDecisionContext/AgentVerificationDecisionContext'

export const useAgentVerificationDecisionContext = () => {
  const context = useContext(AgentVerificationDecisionContext)

  if (context === undefined) {
    throw new Error(
      ' useAgentVerificationDecisionContext must be used within a AgentVerificationDecisionContextProvider!'
    )
  }

  return context
}
