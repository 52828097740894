import { DecodedValueMap, SetQuery } from 'use-query-params'

import { ActivityCheck } from '@percent/admin-dashboard/api/types'
import { TableQueryParams } from '@percent/admin-dashboard/common/queryParams/queryParams.types'

export enum ActivityCheckStatusFilter {
  pending,
  cancelled,
  live,
  'pending_validation_request',
  'validation_request_failed'
}

export type ActivityCheckTableProps = {
  activities: ActivityCheck[] | null
  totalResults: number
  nextPage: (() => void) | null
  previousPage: (() => void) | null
  isLoading: boolean
  errorMessage: string | null
  queryParams: DecodedValueMap<TableQueryParams>
  setQueryParams: SetQuery<TableQueryParams>
}
