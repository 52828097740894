import React, { ChangeEvent, useRef, useState } from 'react'
import { Typography } from '@material-ui/core'
import { Button, Label } from '@percent/lemonade'
import styled from 'styled-components'
import { UploadDocumentProps } from './UploadDocument.types'

const StyledLabel = styled.div`
  display: flex;
  border: 1px solid var(--colors-gray-80) !important;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
  justify-content: space-between;
  margin-top: 5px;

  p {
    margin-bottom: 4;
    color: var(--colors-black);
  }
  button {
    height: 32px !important;
    color: var(--colors-primary-600) !important;
    padding: 0 24px;
    &:focus-visible {
      outline: 1px var(--colors-gray-70);
    }
  }
`

const StyledLabelDisabled = styled(StyledLabel)`
  background-color: var(--colors-gray-80);
`

const StyledFieldError = styled(Typography)`
  color: var(--colors-primary-400);
  display: block;
`

const StyledHelperText = styled(Typography)`
  margin-top: 8px;
`

export function UploadDocument({
  helperText,
  uploadButton,
  labelText,
  fileFieldText,
  setSelectedFile,
  buttonFlag,
  disableUploadBtn,
  inputDisableStyle,
}: UploadDocumentProps) {
  const [errorFileType, setErrorFileType] = useState<string | null>()
  const hiddenFileInput = useRef<HTMLInputElement>(null)
  const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files
    if (!fileList) return
    if (!fileList?.[0]?.type?.match(/(png|jpeg|pdf)/i)) {
      setErrorFileType('File format is not valid')
      return
    }
    if (fileList?.[0]?.size > 10e6) {
      setErrorFileType(
        'File size is too large. Maximum file size allowed is 10 MB.'
      )
      return
    }
    setSelectedFile(fileList?.[0])
    setErrorFileType('')
  }

  const handleClick = () => {
    hiddenFileInput?.current?.click()
  }

  const LabelToDisplay = inputDisableStyle ? StyledLabelDisabled : StyledLabel

  return (
    <div>
      <input
        accept=".png, .jpg, .pdf"
        onChange={changeHandler}
        type="file"
        style={{ display: 'none' }}
        ref={hiddenFileInput}
        data-testid="fileUpload"
      />
      <Label htmlFor="uploadDocument" necessity="required">
        {labelText}
      </Label>
      <LabelToDisplay>
        <Typography>{fileFieldText}</Typography>
        {buttonFlag && (
          <Button
            variant="secondary"
            disabled={disableUploadBtn}
            onPress={handleClick}
          >
            {uploadButton}
          </Button>
        )}
      </LabelToDisplay>
      <StyledFieldError variant="body2" color="primary">
        {errorFileType}
      </StyledFieldError>
      {buttonFlag && <StyledHelperText>{helperText}</StyledHelperText>}
    </div>
  )
}
