import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { PartnerModuleActiveDialog } from '../partnerModuleActiveDialog/PartnerModuleActiveDialog'
import { PartnerModuleInactiveDialog } from '../partnerModuleInactiveDialog/PartnerModuleInactiveDialog'

import { PartnerScopes } from '@percent/admin-dashboard/constants/scopes'
import { ReactComponent as HorizontalIcon } from '@percent/admin-dashboard/common/assets/images/horizontal_icon.svg'
import styles from './PartnerModuleMenu.module.scss'

type TableMenuProps = {
  refresh: () => void
  moduleName: PartnerScopes
  partnerId: string
  moduleTitle: string
}

export function TableMenu({ refresh, moduleName, partnerId, moduleTitle }: TableMenuProps) {
  const [anchorEl, setAnchorEl] = useState(null)
  const [open, setOpen] = useState(false)
  const [openInactiveModal, setOpenInactiveModal] = useState(false)
  const { t } = useTranslation()

  const handleClick = (event: any) => setAnchorEl(event.currentTarget)

  const handleClose = () => setAnchorEl(null)

  const handleActivePartner = () => {
    setAnchorEl(null)
    setOpen(true)
  }

  const handleCloseActivePartner = () => setOpen(false)

  const handleInactivePartner = () => {
    setAnchorEl(null)
    setOpenInactiveModal(true)
  }

  const handleCloseInactivePartner = () => {
    setAnchorEl(null)
    setOpenInactiveModal(false)
  }

  return (
    <div>
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} className={styles.menu}>
        <HorizontalIcon />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{
          display: 'flex',
          alignItems: 'flex-start'
        }}
        data-testid="menu-option"
      >
        <MenuItem onClick={handleActivePartner}>{t('menu.active')}</MenuItem>
        <MenuItem onClick={handleInactivePartner}>{t('menu.inactive')}</MenuItem>
      </Menu>
      <PartnerModuleInactiveDialog
        onClose={handleCloseInactivePartner}
        openInactiveModal={openInactiveModal}
        id={partnerId}
        scope={moduleName}
        refresh={refresh}
        moduleTitle={moduleTitle}
      />
      <PartnerModuleActiveDialog
        onClose={handleCloseActivePartner}
        openActiveModal={open}
        partnerId={partnerId}
        scope={moduleName}
        moduleTitle={moduleTitle}
        refresh={refresh}
      />
    </div>
  )
}
