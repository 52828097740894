import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { BankDetail, BankDetailsUploadView, BankDetailsView, useAddBankDetailsForm } from '@percent/domain/bank-details'
import { useMutation } from '@percent/admin-dashboard/common/hooks'
import { AcknowledgeModal, ActionModal, Modal } from '@percent/lemonade'
import { Card } from '@percent/admin-dashboard/common/components/card/Card'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'

type AddBankDetailsModalProps = {
  open: boolean
  onClose: VoidFunction
  refresh: VoidFunction
  bankDetails: BankDetail
}

export function AddBankDetailsModal({ open, onClose, refresh, bankDetails }: AddBankDetailsModalProps) {
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const { bankDetailsService } = useServices()
  const [selectedFile, setSelectedFile] = useState<File>()
  const [{ isLoading, success, errorMessage }, { apiFunc }] = useMutation(bankDetailsService.postBankDetails)

  const translations = {
    helperBankAccountText: t('typography.helperBankAccountText'),
    proofBankAccount: t('typography.bankAccountProof'),
    uploadDocument: t('button.uploadDocument'),
    submitBankDocuments: t('button.submitBankDocuments')
  }

  const adjustedBankDetailsDataObject = {
    ...bankDetails,
    requiredBankAccountDetails: bankDetails.addBankDetailsFormLabels
  }

  const { formik, firstField, secondField } = useAddBankDetailsForm({
    bankDetailsData: adjustedBankDetailsDataObject,
    t,
    apiFunc,
    selectedFile,
    organizationId: id
  })

  const { handleSubmit, isValid, dirty, resetForm } = formik

  const handleCloseModal = () => {
    onClose()
    refresh()
  }

  const handleUploadDocumentOnly = () => {
    const formData = new FormData()

    if (selectedFile) {
      formData.append('proofOfBankDetails', selectedFile)
      formData.append('organisationId', id)
    }

    apiFunc({
      payload: {
        formData
      }
    })
  }

  const successModal = success && (
    <AcknowledgeModal
      result="positive"
      title={t('dialog.proofBankSuccessModal.title')}
      description={t('dialog.proofBankSuccessModal.subTitle')}
      buttonText={t('button.done')}
      handleClose={handleCloseModal}
      viewTestId="success-modal"
      buttonTestId="close-acknowledge-modal"
    />
  )
  const errorModal = errorMessage && (
    <AcknowledgeModal
      result="negative"
      title={t('dialog.proofBankErrorModal.title')}
      description={t('dialog.proofBankErrorModal.subTitle')}
      buttonText={t('button.done')}
      handleClose={handleCloseModal}
      viewTestId="error-modal"
      buttonTestId="close-acknowledge-modal"
    />
  )

  return (
    <Modal
      width={successModal || errorModal ? 560 : 1000}
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="brand-details-form-modal"
    >
      {successModal || errorModal || (
        <ActionModal
          title={t('typography.bankDetails')}
          primaryButtonText={t('button.submitBankDocuments')}
          secondaryButtonText={t('button.cancel')}
          type="submit"
          variant={
            (adjustedBankDetailsDataObject?.requiredBankAccountDetails && isValid && dirty) ||
            (!adjustedBankDetailsDataObject?.requiredBankAccountDetails && selectedFile)
              ? 'primary'
              : 'secondary'
          }
          disabled={
            !(
              (!isLoading &&
                adjustedBankDetailsDataObject?.requiredBankAccountDetails &&
                selectedFile &&
                isValid &&
                dirty) ||
              (!isLoading && !adjustedBankDetailsDataObject?.requiredBankAccountDetails && selectedFile)
            )
          }
          loading={isLoading}
          handleClose={() => {
            onClose()
            resetForm()
          }}
          handleSubmit={
            adjustedBankDetailsDataObject?.requiredBankAccountDetails ? handleSubmit : handleUploadDocumentOnly
          }
          primaryBtnTestId="add-bank-details-submit-button"
          secondaryBtnTestId="add-bank-details-cancel-button"
        >
          <div
            style={{
              overflowY: 'auto',
              maxHeight: '28rem'
            }}
          >
            <Card size="auto">
              {adjustedBankDetailsDataObject?.requiredBankAccountDetails ? (
                <BankDetailsView
                  setSelectedFile={setSelectedFile}
                  buttonFlag={false}
                  uploadButtonFlag
                  bankDetailsData={adjustedBankDetailsDataObject}
                  disableUploadBtn={false}
                  disabledField={false}
                  selectedFile={selectedFile}
                  isPostLoading={isLoading}
                  fileFieldText={selectedFile?.name}
                  translations={translations}
                  formik={formik}
                  firstField={firstField}
                  secondField={secondField}
                />
              ) : (
                <BankDetailsUploadView
                  setSelectedFile={setSelectedFile}
                  buttonFlag={false}
                  uploadButtonFlag
                  disableUploadBtn={false}
                  disabledField={false}
                  bankDetailsData={adjustedBankDetailsDataObject}
                  selectedFile={selectedFile}
                  apiFunc={apiFunc}
                  isPostLoading={isLoading}
                  fileFieldText={selectedFile?.name}
                  translations={translations}
                />
              )}
            </Card>
          </div>
        </ActionModal>
      )}
    </Modal>
  )
}
