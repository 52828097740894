import { InputLabel, MenuItem, Select } from '@material-ui/core'
import { useFormik } from 'formik'
import React from 'react'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'

import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { CurrencyCode } from '@percent/admin-dashboard/constants/currencyCode'
import { useMutation } from '@percent/admin-dashboard/common/hooks'
import { Dialog, DialogCreateBody, FieldError, FieldInput } from '@percent/admin-dashboard/common/components'
import styles from './CreatePartnerDialog.module.scss'

const validationSchema = Yup.object().shape({
  name: Yup.string().max(40, 'Must be 40 characters or less').required('Required'),
  currencyCode: Yup.string().required('Required')
})

type CreatePartnerDialogProps = {
  open: boolean
  onClose: () => void
  refresh: () => void
}

export function CreatePartnerDialog({ open = false, onClose, refresh }: CreatePartnerDialogProps) {
  const { adminService } = useServices()
  const [{ errorMessage, isLoading }, { apiFunc }] = useMutation(adminService.commands.createPartner, () => {
    onClose()
    refresh()
  })
  const { t } = useTranslation()

  const { values, errors, handleBlur, handleSubmit, touched, setFieldValue } = useFormik({
    initialValues: {
      name: '',
      currencyCode: ''
    },
    validationSchema,
    onSubmit: ({ name, currencyCode }) => {
      if (!isLoading) {
        apiFunc({ name, currencyCode: currencyCode as CurrencyCode })
      }
    }
  })

  return (
    <Dialog openModal={open} onClose={onClose} headerTitle={t('typography.addNewPartner')}>
      <DialogCreateBody
        loading={isLoading} // TODO: L.N debug why breaking test
        errorMessage={errorMessage}
        handleSubmit={handleSubmit}
        buttonTitle={t('button.addPartner')}
        testId="create-partner-button"
      >
        <FieldInput
          name="name"
          label={t('form.organisationAccount')}
          placeHolder={t('form.enterOrganisationAccount')}
          value={values.name}
          onChange={a => setFieldValue('name', a)}
          error={errors.name}
          touched={touched.name}
          handleBlur={handleBlur}
        />
        <InputLabel className={styles.CurrencyCodeLabel} id="currency-code-label">
          {t('form.currencyCode')}
        </InputLabel>
        <Select
          labelId="currency-code-label"
          color="secondary"
          className={styles.selectCurrencyCode}
          inputProps={{ 'data-testid': 'select-data' }}
          name="currencyCode"
          id="currencyCode"
          value={values.currencyCode}
          onChange={e => setFieldValue('currencyCode', e.target.value)}
        >
          {Object.keys(CurrencyCode).map(item => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
        <FieldError error={errors.currencyCode} />
      </DialogCreateBody>
    </Dialog>
  )
}
