import { DecodedValueMap, SetQuery } from 'use-query-params'

import { AgentVerificationTableQueryParams } from '@percent/admin-dashboard/common/queryParams/queryParams.types'
import { AgentVerificationList } from '@percent/admin-dashboard/api/types'

export enum AgentVerificationStateFilter {
  all,
  'percent_actionable',
  'external_actionable',
  approved,
  rejected
}

export type AgentVerificationTableProps = {
  validations: AgentVerificationList[] | null
  totalResults: number
  nextPage: (() => void) | null
  previousPage: (() => void) | null
  isLoading: boolean
  errorMessage: string | null
  queryParams: DecodedValueMap<AgentVerificationTableQueryParams>
  setQueryParams: SetQuery<AgentVerificationTableQueryParams>
}
