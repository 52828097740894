import { TableCell, TableRow, Typography, Grid } from '@material-ui/core'
import React, { useCallback, EventHandler, KeyboardEvent, useMemo, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useMutation } from '@percent/admin-dashboard/common/hooks'
import {
  Dialog,
  Loader,
  ErrorView,
  TabPanel,
  PercentButton,
  EmailTooltip as Tooltip,
  Table,
  SearchAndDateFilter
} from '@percent/admin-dashboard/common/components'
import { ListItemButton } from '@percent/admin-dashboard/common/components/listItemButton/ListItemButton'
import { ReactComponent as SuccessIcon } from '@percent/admin-dashboard/common/assets/images/success.svg'
import { ReactComponent as CloseIcon } from '@percent/admin-dashboard/common/assets/images/close.svg'
import { Claim } from '@percent/admin-dashboard/api/types'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { TableSort } from '@percent/admin-dashboard/common/components/table/Table.types'
import { useBadgeTotal } from '@percent/admin-dashboard/context/badgeTotal/useBadgeTotal/useBadgeTotal'
import { useGetQuery } from '@percent/admin-dashboard/common/hooks/useGetQuery/useGetQuery'
import { ReactComponent as DropdownSVG } from '@percent/admin-dashboard/common/assets/images/dropdown.svg'
import { CountryCodeFlag } from '@percent/admin-dashboard/common/components/countryCodeFlag/CountryCodeFlag'
import { ClaimsTableProps, ClaimsTableRowProps } from './ClaimsTable.types'
import styles from './ClaimsTable.module.scss'
import { useFeatureFlag } from '@percent/admin-dashboard/common/hooks/useFeatureFlag/useFeatureFlag'

const getTabValue = (accepted: boolean, rejected: boolean) => {
  if (!rejected) {
    if (!accepted) {
      return 0
    }

    return 1
  }

  return 2
}

export enum ClaimAcceptedRejectedFilter {
  false,
  true
}

export function ClaimsTableRow({
  row,
  organizationRowClick,
  setUnrejectModalOpened,
  setSelectedRow,
  showUnrejectAction
}: ClaimsTableRowProps) {
  const onClick = () => {
    setUnrejectModalOpened(true)
    setSelectedRow(row)
  }

  return (
    <TableRow key={row.id}>
      <CountryCodeFlag countryCode={row.organisation.countryCode} />
      <TableCell className={styles.nonProfitRow} onClick={() => organizationRowClick(row)}>
        {row.organisation.name}
      </TableCell>
      <Tooltip title={row.organisationId}>
        <TableCell className={styles.organisationIdRow}>{row.organisationId}</TableCell>
      </Tooltip>
      <TableCell className={styles.nameRow}>{row.account.fullName}</TableCell>
      <Tooltip title={row.account.email}>
        <TableCell className={styles.emailRow}>{row.account.email}</TableCell>
      </Tooltip>
      <TableCell>{row.account.phoneNumber}</TableCell>
      {showUnrejectAction && (
        <TableCell>
          <ListItemButton
            testId="unreject-action"
            className={styles.unrejectButton}
            title="Unreject"
            onClick={() => onClick()}
          />
        </TableCell>
      )}
      <TableCell
        align="right"
        className={styles.rowIconForward}
        style={{ padding: '0' }}
        data-testid="organization-details-link"
        onClick={() => organizationRowClick(row)}
      >
        <DropdownSVG className={styles.arrow} />
      </TableCell>
    </TableRow>
  )
}

export function ClaimsTable({
  claims,
  totalResults,
  isLoading,
  previousPage,
  nextPage,
  refresh,
  queryParams,
  setQueryParams,
  errorMessage
}: ClaimsTableProps) {
  const { t } = useTranslation()
  const [isUnrejectModalOpened, setUnrejectModalOpened] = useState(false)
  const [isUnrejectDialogOpened, setUnrejectDialogOpened] = useState(false)
  const [dialogErrorMessage, setErrorMessage] = useState('')
  const [selectedRow, setSelectedRow] = useState<Claim>()
  const { adminService } = useServices()
  const { totalNumber, setTotalNumber } = useBadgeTotal()
  const query = useGetQuery()
  const getQueryAccepted = query.get('accepted')
  const getQueryRejected = query.get('rejected')
  const { push } = useHistory()

  const { unrejectClaimFeatureFlag } = useFeatureFlag()

  useEffect(() => {
    if (getQueryAccepted === 'false' && getQueryRejected === 'false') {
      setTotalNumber({
        ...totalNumber,
        totalPendingClaims: totalResults ?? 0
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalResults])

  const onUnrejectClaim = (data: { id: string }) => {
    return adminService
      .unreject(data)
      .then(() => {
        setErrorMessage('')
        refresh()
      })
      .catch(() => {
        setErrorMessage('Something went wrong, please contact engineering')
      })
  }
  const [{ isLoading: isUnrejectLoading }, { apiFunc: unreject }] = useMutation(onUnrejectClaim)

  const handleRequestSort = () =>
    setQueryParams({ direction: queryParams.direction === 'ASC' ? 'DESC' : 'ASC', sort: 'name' })

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    if (newValue === 0) {
      return setQueryParams({ accepted: false, rejected: false, sort: undefined, direction: undefined })
    }

    if (newValue === 1) {
      return setQueryParams({ accepted: true, rejected: false, sort: undefined, direction: undefined })
    }

    if (newValue === 2) {
      return setQueryParams({ accepted: false, rejected: true, sort: undefined, direction: undefined })
    }

    return setQueryParams({
      accepted: undefined,
      rejected: undefined,
      sort: undefined,
      direction: undefined
    })
  }

  const handleUnreject = () => {
    setUnrejectDialogOpened(true)
    setUnrejectModalOpened(false)
    unreject({ id: String(selectedRow?.id) })
  }

  const claimValue = getTabValue(queryParams.accepted, queryParams.rejected)

  const header = <Typography variant="h6">{t('table.claims')}</Typography>

  const tabs = [t('table.pending'), t('table.approved'), t('table.rejected')]
  const columns = [
    { id: 'country', isSortable: false },
    { id: 'organization', isSortable: true },
    { id: 'organizationId', isSortable: false },
    { id: 'name', isSortable: false },
    { id: 'email', isSortable: false },
    { id: 'phoneNumber', isSortable: false },
    { id: 'actions', isSortable: false }
  ]

  const handleKeyPress: EventHandler<KeyboardEvent<HTMLInputElement>> = useCallback(
    event => {
      if (event.key === 'Enter') {
        setQueryParams({
          query: (event.target as HTMLInputElement).value
        })
      }
    },
    [setQueryParams]
  )

  const organizationRowClick = (row: Claim) => {
    push(`/organizations/${row.organisationId}`)
  }

  const filters = useMemo(
    () => (
      <SearchAndDateFilter
        handleKeyPress={handleKeyPress}
        searchPhrase={queryParams.query ?? ''}
        placeholder={t('table.adminClaimsSearchPlaceholder')}
      />
    ),
    [handleKeyPress, queryParams.query, t]
  )

  if (errorMessage) {
    return <ErrorView errorMessage={errorMessage} />
  }

  return (
    <>
      <Table
        isLoading={isLoading}
        totalResults={totalResults}
        data={claims}
        previousPage={previousPage}
        nextPage={nextPage}
        value={claimValue}
        handleChange={handleChange}
        columns={columns}
        order={(queryParams.direction?.toLowerCase() as TableSort) ?? 'desc'}
        orderBy="organization"
        handleRequestSort={handleRequestSort}
        tabs={tabs}
        title={header}
        emptyTableText={t('table.emptyClaims')}
        filtersContent={filters}
      >
        <TabPanel value={claimValue} index={0}>
          {claims?.map(row => (
            <ClaimsTableRow
              key={row.id}
              row={row}
              organizationRowClick={organizationRowClick}
              setUnrejectModalOpened={setUnrejectModalOpened}
              setSelectedRow={setSelectedRow}
            />
          ))}
        </TabPanel>
        <TabPanel value={claimValue} index={1}>
          {claims?.map(row => (
            <ClaimsTableRow
              key={row.id}
              row={row}
              organizationRowClick={organizationRowClick}
              setUnrejectModalOpened={setUnrejectModalOpened}
              setSelectedRow={setSelectedRow}
            />
          ))}
        </TabPanel>
        <TabPanel value={claimValue} index={2}>
          {claims?.map(row => (
            <ClaimsTableRow
              key={row.id}
              row={row}
              organizationRowClick={organizationRowClick}
              setUnrejectModalOpened={setUnrejectModalOpened}
              setSelectedRow={setSelectedRow}
              showUnrejectAction={unrejectClaimFeatureFlag}
            />
          ))}
        </TabPanel>
      </Table>
      {isUnrejectModalOpened && (
        <Dialog
          openModal={isUnrejectModalOpened}
          onClose={() => setUnrejectModalOpened(false)}
          headerTitle="Unreject claim"
        >
          {/* Need this invisible span because Dialog does not pass testid */}
          <span data-testid="unreject-dialog" />
          <Grid item xs={12} className={styles.modalBody}>
            <Typography paragraph>You are about to unreject the following claim:</Typography>
            <Typography paragraph>
              <span style={{ fontFamily: 'MarkProBold' }}>Organisation name:</span>
              {` ${selectedRow?.organisation?.name}`}
              <br />
              <span style={{ fontFamily: 'MarkProBold' }}>Email:</span> {`${selectedRow?.account.email} `}
            </Typography>
            <Typography>
              This will delete the existing claim and create a new one. The email will be added to the allow list so it
              will be auto-approved. If you are sure you want to do this, click &lsquo;Unreject&rsquo; below.
            </Typography>
          </Grid>
          <Grid item xs={12} className={styles.dialogFooter}>
            <PercentButton
              title={t('button.cancel')}
              onClick={() => setUnrejectModalOpened(false)}
              textColour="secondaryTextColour"
              outlined
            />
            <PercentButton title="Unreject" onClick={handleUnreject} />
          </Grid>
        </Dialog>
      )}
      {isUnrejectDialogOpened && (
        <Dialog
          data-testid="unreject-dialog-error"
          openModal={isUnrejectDialogOpened}
          onClose={() => setUnrejectDialogOpened(false)}
          headerTitle=""
          withoutHeader
        >
          <div className={styles.dialogBody}>
            {isUnrejectLoading ? (
              <Loader />
            ) : dialogErrorMessage ? (
              <>
                <CloseIcon className={styles.icon} />
                <Typography variant="h5">Organization couldn&apos;t be unrejected</Typography>
                <Typography className={styles.caption}>
                  Something went wrong. Try again or reach out to engineering for help.
                </Typography>
                <PercentButton
                  title={t('button.done')}
                  onClick={() => setUnrejectDialogOpened(false)}
                  className={styles.centerButton}
                />
              </>
            ) : (
              <>
                <SuccessIcon className={styles.icon} />
                <Typography variant="h5">Organization successfully unrejected</Typography>
                <Typography className={styles.caption}>
                  The rejected claim for
                  <span style={{ fontFamily: 'MarkProBold' }}>{` ${selectedRow?.organisation?.name} `}</span> has been
                  deleted, and a new one has been created and added to the allowlist. You can check on the Agent
                  Verifications page.
                </Typography>
                <PercentButton
                  title={t('button.done')}
                  onClick={() => setUnrejectDialogOpened(false)}
                  className={styles.centerButton}
                />
              </>
            )}
          </div>
        </Dialog>
      )}
    </>
  )
}
