import { rest } from 'msw'

import { config } from '@percent/admin-dashboard/config'
import { mockAccountResponse } from '@percent/admin-dashboard/fixtures/api/mockAccountResponse'

export const authHandlers = [
  rest.post(`${config.urls.api}v1/auth/login`, async (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockAccountResponse))
  }),
  rest.post(`${config.urls.api}v1/auth/logout`, async (_, res, ctx) => {
    return res(ctx.status(200), ctx.json({ message: 'success' }))
  })
]
