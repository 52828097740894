import { rest } from 'msw'

import { config } from '@percent/admin-dashboard/config'
import { mockAutoRejectionListResponse } from '@percent/admin-dashboard/fixtures/api/mockAutoRejectionListResponse'

export const autoRejectionListHandlers = [
  rest.get(`${config.urls.api}v1/admin/agent-verifications-auto-rejection-list`, async (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockAutoRejectionListResponse))
  }),
  rest.put(`${config.urls.api}v1/admin/agent-verifications-auto-rejection-list`, async (req, res, ctx) => {
    return res(ctx.status(200), ctx.json({}))
  }),
  rest.delete(`${config.urls.api}v1/admin/agent-verifications-auto-rejection-list/:id`, async (req, res, ctx) => {
    return res(ctx.status(200), ctx.json({}))
  })
]
