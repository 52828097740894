import React from 'react'
import { TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { TableHeadSorterProps } from './TableHeadSorter.types'
import styles from './TableHeadSorter.module.scss'
import { LocaleKey } from '@percent/admin-dashboard/i18n/config'

export function TableHeadSorter({ onRequestSort, order, orderBy, cells }: TableHeadSorterProps) {
  const { t } = useTranslation()
  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    if (onRequestSort) {
      onRequestSort(event, property)
    }
  }

  return (
    <TableHead>
      <TableRow>
        {cells.map(cell =>
          cell.isSortable ? (
            <TableCell
              {...cell.props}
              style={cell.style}
              key={cell.id}
              sortDirection={orderBy === cell.id ? order : false}
            >
              <TableSortLabel
                className={styles.tableSortLabel}
                active={orderBy === cell.id}
                direction={orderBy === cell.id ? order : 'asc'}
                onClick={createSortHandler(cell.id)}
              >
                {t(`table.${cell.id}` as LocaleKey)}
              </TableSortLabel>
            </TableCell>
          ) : (
            <TableCell {...cell.props} style={cell.style} key={cell.id}>
              {t(`table.${cell.id}` as LocaleKey)}
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  )
}
