import { Grid, Typography } from '@material-ui/core'
import React from 'react'

import { Loader } from '../loader/Loader'
import { ErrorView } from '../errorView/ErrorView'
import { PercentButton } from '../percentButton/PercentButton'

import style from './DialogDeleteBody.module.scss'
import { DialogDeleteBodyProps } from './DialogDeleteBody.types'

export function DialogDeleteBody({
  bodyText,
  errorMessage,
  handleSubmit,
  testId,
  buttonTitle,
  isLoading
}: DialogDeleteBodyProps) {
  return (
    <div className={style.dialogMargin}>
      <Typography>{bodyText}</Typography>
      <Grid item xs={12}>
        {isLoading && <Loader />}
        {errorMessage && <ErrorView errorMessage={errorMessage} />}
        <PercentButton onClick={handleSubmit} testId={testId} title={buttonTitle} className={style.activeButton} />
      </Grid>
    </div>
  )
}
