import { TableCell, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { CreatePartnerDialog } from '../createPartnerDialog/CreatePartnerDialog'

import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { ErrorView, ClickableTableRow, Table, PercentButton } from '@percent/admin-dashboard/common/components'
import { useQueryList } from '@percent/admin-dashboard/common/hooks'
import { ReactComponent as DropdownSVG } from '@percent/admin-dashboard/common/assets/images/dropdown.svg'
import style from './PartnerListTable.module.scss'

export function PartnerListTable() {
  const history = useHistory()
  const [open, setOpen] = useState(false)
  const { adminService } = useServices()
  const [{ totalResults, errorMessageOrNull, dataOrNull, isLoading }, { nextPage, previousPage, refresh }] =
    useQueryList(adminService.queries.getPartnerList, {})

  const { t } = useTranslation()

  const handleCreatePartnerClick = () => {
    setOpen(true)
  }

  const handleClose = () => setOpen(false)

  const rowPartnerClicked = (partnerId: string) => history.push(`/partners/partner/${partnerId}`)

  if (errorMessageOrNull) {
    return <ErrorView errorMessage={errorMessageOrNull} />
  }

  const header = (
    <>
      <Typography variant="h6">{t('typography.partners')}</Typography>
      <PercentButton
        className={style.addPartnerButton}
        title={`+ ${t('button.addAPartner')}`}
        onClick={handleCreatePartnerClick}
        alignment="alignRight"
      />
      {open && <CreatePartnerDialog onClose={handleClose} open={open} refresh={refresh} />}
    </>
  )

  const emptyTableActions = (
    <PercentButton
      className={style.actionsAddPartnerButton}
      title={`+ ${t('button.addAPartner')}`}
      onClick={handleCreatePartnerClick}
      alignment="alignRight"
    />
  )

  return (
    <Table
      isLoading={isLoading}
      totalResults={totalResults}
      data={dataOrNull}
      previousPage={previousPage}
      nextPage={nextPage}
      value={0}
      handleChange={() => {}}
      handleRequestSort={() => {}}
      orderBy=""
      title={header}
      columns={[{ id: 'name', isSortable: false, props: { width: '300px' } }]}
      emptyTableText={t('table.emptyPartners')}
      emptyTableImageType="partners"
      emptyTableChildren={emptyTableActions}
    >
      {dataOrNull ? (
        <>
          {dataOrNull.map(({ id, name }) => (
            <ClickableTableRow key={`partner-list-${id}`} onClick={() => rowPartnerClicked(id)}>
              <TableCell className={style.row}>{name}</TableCell>
              <TableCell />
              <TableCell />
              <TableCell className={style.row} align="right" style={{ padding: '16px 0' }}>
                <DropdownSVG className={style.arrow} />
              </TableCell>
            </ClickableTableRow>
          ))}
        </>
      ) : null}
    </Table>
  )
}
