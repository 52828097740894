import { useEffect, useMemo, useState } from 'react'
import { Stack } from '@mui/material'
import { useParams } from 'react-router-dom'

import { Documents } from '@percent/admin-dashboard/common/components/documents/Documents'
import { ErrorView, Loader } from '@percent/admin-dashboard/common/components'
import { useQuery, useQueryList } from '@percent/admin-dashboard/common/hooks'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { AgentDetails } from './components/agentDetails/AgentDetails'
import { AgentVerificationOrganisationDetails } from './components/agentVerificationOrganisationDetails/AgentVerificationOrganisationDetails'
import { AgentVerificationDecision } from './components/agentVerificationDecision/AgentVerificationDecision'
import {
  AgentVerificationDetailsProps,
  AgentVerificationModal,
  AgentVerificationPartner
} from './AgentVerificationDetails.types'
import { AgentVerificationDecisionContext } from './agentVerificationDecisionContext/AgentVerificationDecisionContext'
import { AgentVerificationStatus } from '@percent/admin-dashboard/api/types'
import { AgentDetailsTopBar } from './components/agentDetailsTopBar/AgentDetailsTopBar'
import { AgentVerificationDocuments } from './components/agentVerificationDocuments/AgentVerificationDocuments'
import { useFeatureFlag } from '@percent/admin-dashboard/common/hooks/useFeatureFlag/useFeatureFlag'
import { MatchingDocumentsDetails } from './components/matchingDocumentsDetails/MatchingDocumentsDetails'
import { OrganisationTypes } from '@percent/admin-dashboard/constants/organisationTypes'

export function AgentVerificationDetails({
  orgData,
  partnerData,
  orgPartnerLoader,
  orgPartnerError,
  data,
  isLoading,
  errorMessage,
  refresh,
  aproveEmail
}: Readonly<AgentVerificationDetailsProps>) {
  const { agentVerificationTriggeredDocsFeatureFlag } = useFeatureFlag()
  const { id } = useParams<{ id: string }>()
  const [openedModal, setOpenedModal] = useState<AgentVerificationModal | undefined>(undefined)
  const { adminService, agentVerificationService } = useServices()
  const agentVerificationContextValue = useMemo(
    () => ({
      isApproveModalOpened: openedModal === AgentVerificationModal.APPROVE,
      isRejectModalOpened: openedModal === AgentVerificationModal.REJECT,
      isApproveEmailModalOpened: openedModal === AgentVerificationModal.APPROVE_EMAIL,
      setOpenedModal
    }),
    [openedModal]
  )

  const [
    { dataOrNull: validationSubmissionDocuments, isLoading: isLoadingValidationSubmissionDocuments },
    { query: refreshValidationSubmissionDocuments }
  ] = useQueryList(
    adminService.getValidationSubmissionDocuments,
    {
      validationSubmissionId: data?.validationSubmissionId
    },
    false
  )

  const [
    { data: agentVerificationLogs, isLoading: isLoadingAgentVerificationLogs },
    { refresh: refreshAgentVerificationLogs }
  ] = useQuery(agentVerificationService.getAgentVerificationLogs, { id })

  useEffect(() => {
    if (data?.validationSubmissionId) {
      refreshValidationSubmissionDocuments({ validationSubmissionId: data?.validationSubmissionId })
    }
  }, [data, refreshValidationSubmissionDocuments])

  if (errorMessage || orgPartnerError) {
    return <ErrorView errorMessage={errorMessage || orgPartnerError} />
  }

  if (isLoading || orgPartnerLoader) {
    return <Loader />
  }

  const showDocumentsWithPreview =
    data?.status &&
    [
      AgentVerificationStatus.PENDING,
      AgentVerificationStatus.PENDING_REVIEW,
      AgentVerificationStatus.PENDING_USER_VERIFICATION
    ].includes(data?.status)

  const isPending =
    data?.status && [AgentVerificationStatus.PENDING, AgentVerificationStatus.PENDING_REVIEW].includes(data?.status)

  const isGooglePartner = partnerData?.name?.toLowerCase() === AgentVerificationPartner.GOOGLE
  const isPendingCanvaEdu =
    isPending &&
    partnerData?.name?.toLowerCase() === AgentVerificationPartner.CANVA &&
    data?.configuration?.allowedOrganisations?.types?.includes(OrganisationTypes.Education)

  return (
    <>
      <Stack direction="column" gap={3}>
        <AgentVerificationDecisionContext.Provider value={agentVerificationContextValue}>
          <AgentVerificationDecision
            organisationName={orgData?.displayName || orgData?.name}
            agentFullName={`${data?.firstName} ${data?.lastName}`}
            refresh={refresh}
            agentStatus={data?.status}
            isLoading={isLoading}
            errorMessage={errorMessage}
            aproveEmail={aproveEmail}
            fraudFlagsLength={data?.fraudulentFlags?.length ?? 0}
            allowedOrganisations={data?.configuration?.allowedOrganisations?.types || []}
            partnerName={partnerData?.name}
          />
        </AgentVerificationDecisionContext.Provider>
        <AgentDetailsTopBar
          agentVerification={data}
          agentVerificationLogs={agentVerificationLogs}
          partnerName={partnerData?.name}
        />
      </Stack>
      <Stack
        data-testid={showDocumentsWithPreview && 'documents-preview-layout'}
        sx={{
          flexDirection: {
            xs: 'column',
            lg: showDocumentsWithPreview ? 'row' : 'column'
          },
          gap: showDocumentsWithPreview ? 4 : 2,
          marginTop: 3
        }}
      >
        <Stack flex={1}>
          <AgentDetails
            agentVerification={data}
            agentVerificationStatus={data?.status}
            fraudFlags={data?.fraudulentFlags?.filter(f => f.scope === 'agent') ?? []}
          />
          <AgentVerificationOrganisationDetails
            agentVerificationWebsite={data?.organisationWebsite}
            agentVerification={data}
            organisation={orgData}
            refresh={refresh}
            partnerId={data?.partnerId}
            fraudFlags={
              data?.fraudulentFlags?.filter(
                f => f.scope === 'organisation_email' || f.scope === 'organisation_website'
              ) ?? []
            }
            agentVerificationLogs={agentVerificationLogs}
          />
        </Stack>
        <Stack flex={1}>
          {agentVerificationTriggeredDocsFeatureFlag && isGooglePartner && isPending ? (
            <AgentVerificationDocuments
              agentVerificationId={data?.id}
              agentVerificationLogs={agentVerificationLogs}
              isLoadingAgentVerificationLogs={isLoadingAgentVerificationLogs}
              refreshAgentVerificationLogs={refreshAgentVerificationLogs}
              isLoading={isLoading || (!!data?.validationSubmissionId && isLoadingValidationSubmissionDocuments)}
              documents={validationSubmissionDocuments || []}
              agentEmail={data?.email}
            />
          ) : (
            <Documents
              isLoading={isLoading || (!!data?.validationSubmissionId && isLoadingValidationSubmissionDocuments)}
              documents={validationSubmissionDocuments || []}
              withPreview={showDocumentsWithPreview}
            >
              {isPendingCanvaEdu && <MatchingDocumentsDetails documents={validationSubmissionDocuments || []} />}
            </Documents>
          )}
        </Stack>
      </Stack>
    </>
  )
}
