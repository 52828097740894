import React, { useState } from 'react'
import { FormikProvider, useFormik } from 'formik'
import * as Yup from 'yup'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useMutation } from '@percent/admin-dashboard/common/hooks'
import { AcknowledgeModal, FormField, Modal, ActionModal, Select } from '@percent/lemonade'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { UnableToPayReasons } from '@percent/admin-dashboard/api/types'

export const unpayableOrganisationReasons = [
  'does_not_accept_card',
  'card_payment_failed',
  'cheque_returned',
  'cheque_not_cashed',
  'bank_transfer_failed'
] as const

type MarkOrganisationAsUnpayableModalProps = {
  open: boolean
  onClose: VoidFunction
  refresh: VoidFunction
}

export function MarkOrganisationAsUnpayableModal({ open, onClose, refresh }: MarkOrganisationAsUnpayableModalProps) {
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const { adminService } = useServices()
  const [errorDialogState, setErrorDialogState] = useState(false)

  const [{ success, isLoading }, { apiFunc }] = useMutation(
    adminService.updateOrganisationPaymentMethod,
    undefined,
    () => {
      setErrorDialogState(true)
    }
  )

  const initialValues = {
    reason: ''
  }

  const formik = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues,
    validationSchema: () => {
      return Yup.object().shape({
        reason: Yup.string()
          .oneOf(unpayableOrganisationReasons.map(reason => reason))
          .required(t('errorMessage.required'))
      })
    },
    onSubmit: async ({ reason }) => {
      await apiFunc({
        payload: { method: 'unable_to_pay', reason: reason as UnableToPayReasons },
        id
      })
    }
  })

  const { dirty, setFieldValue, handleSubmit, isValid, resetForm } = formik

  const handleCloseModalAfterSuccess = () => {
    handleCloseModal()
    refresh()
  }

  const handleCloseModal = () => {
    onClose()
    resetForm()
  }

  const handleOnErrorClose = () => {
    setErrorDialogState(false)
  }

  const successOrErrorModal =
    success && !errorDialogState ? (
      <AcknowledgeModal
        result="positive"
        title={t('dialog.markOrganisationAsUnpayable.success.title')}
        description={t('dialog.markOrganisationAsUnpayable.success.description')}
        buttonText="Close"
        handleClose={handleCloseModalAfterSuccess}
        viewTestId="success-mark-organisation-as-unpayable-modal"
        buttonTestId="update-mark-organisation-as-unpayable-success-button"
      />
    ) : (
      <AcknowledgeModal
        result="negative"
        title={t('dialog.markOrganisationAsUnpayable.error.title')}
        description={t('dialog.markOrganisationAsUnpayable.error.description')}
        buttonText="Close"
        handleClose={handleOnErrorClose}
        viewTestId="error-mark-organisation-as-unpayable-modal"
        buttonTestId="update-mark-organisation-as-unpayable-error-button"
      />
    )

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="mark-organisation-as-unable-to-pay-modal">
      {!success && !errorDialogState ? (
        <ActionModal
          title={t('dialog.markOrganisationAsUnpayable.title')}
          primaryButtonText={t('dialog.markOrganisationAsUnpayable.form.markAsUnableToPay')}
          secondaryButtonText={t('button.cancel')}
          type="submit"
          variant={isValid && dirty ? 'primary' : 'secondary'}
          disabled={!(isValid && dirty && !isLoading)}
          loading={isLoading}
          handleClose={handleCloseModal}
          handleSubmit={handleSubmit}
          primaryBtnTestId="mark-as-unpayable-submit-button"
          secondaryBtnTestId="mark-as-unpayable-cancel-button"
        >
          <form onSubmit={handleSubmit}>
            <FormikProvider value={formik}>
              <FormField label={t('typography.reason')} data-testid="reason" necessity="required">
                <Select
                  placeholder={t('dialog.markOrganisationAsUnpayable.form.reason.placeholder')}
                  options={unpayableOrganisationReasons.map(reason => ({
                    label: t(`dropdown.unpayableOrganisation.${reason}`),
                    value: reason
                  }))}
                  onChange={({ value }) => setFieldValue('reason', value)}
                />
              </FormField>
            </FormikProvider>
          </form>
        </ActionModal>
      ) : (
        successOrErrorModal
      )}
    </Modal>
  )
}
