import React from 'react'
import { Paper, Grid, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { Status, Tooltip } from '@percent/admin-dashboard/common/components'
import { Variant } from '@percent/admin-dashboard/common/components/status/Status.types'
import styles from './NonProfitDetailHeader.module.scss'
import { Alert, Badge, Button, Spacer } from '@percent/lemonade'
import { statusMap } from '@percent/admin-dashboard/constants/statusMap'

type NonProfitDetailHeaderProps = {
  nonprofitName: string
  status: Variant
  setIsRejectModalOpened: (isOpened: boolean) => void
  setIsApproveModalOpened: (isOpened: boolean) => void
  notValidForProgram?: boolean
  disableConfirm?: boolean
  isApproved?: boolean
  isRejected?: boolean
}

export function NonProfitDetailHeader({
  nonprofitName,
  status,
  setIsRejectModalOpened,
  setIsApproveModalOpened,
  notValidForProgram,
  disableConfirm,
  isApproved,
  isRejected
}: NonProfitDetailHeaderProps) {
  const { t } = useTranslation()

  const isPending = !isApproved && !isRejected

  return (
    <Grid container spacing={0} component={Paper} elevation={0} className={styles.container}>
      <Grid item xs={12} className={styles.nonprofitDetail}>
        {isPending && disableConfirm && (
          <Grid item xs={12} className={styles.alertContainer}>
            <Alert variant="info" title={t('typography.flagsNeedToBeResolved')} />
          </Grid>
        )}
        <div className={styles.ellipsisParent}>
          <div className={styles.ellipsisContainer}>
            <div className={styles.ellipsisText}>
              <Tooltip interactive arrow={false} placement="top-start" title={nonprofitName}>
                <Typography variant="h6" className={styles.heading} data-testid="validation-request-organisation-name">
                  {nonprofitName}
                </Typography>
              </Tooltip>
              <div className={styles.badgesContainer}>
                <Badge variant={statusMap[status].variant} icon={statusMap[status].icon}>
                  {t(statusMap[status].text)}
                </Badge>
                {notValidForProgram ? (
                  <Badge variant="critical" icon="reject">
                    {t('typography.notValidForProgram')}
                  </Badge>
                ) : null}
              </div>
            </div>
            {status === Status.variants.PENDING ? (
              <div className={styles.buttonsContainer}>
                <Button onPress={() => setIsRejectModalOpened(true)} variant="reject" data-testid="reject-btn">
                  {t('button.reject')}
                </Button>
                <Spacer size={2} />
                <Button
                  onPress={() => setIsApproveModalOpened(true)}
                  data-testid="submit-btn"
                  disabled={disableConfirm}
                >
                  {t('button.submit')}
                </Button>
              </div>
            ) : null}
          </div>
        </div>
      </Grid>
    </Grid>
  )
}
