import React, { useEffect, useState } from 'react'
import classNames from 'classnames/bind'
import { useTranslation } from 'react-i18next'

import { FieldInput } from '../fieldInput/FieldInput'

import { FieldPasswordProps } from './FieldPassword.types'
import styles from './FieldPassword.module.scss'

const hasNumber = (password: string) => /[0-9]/.test(password)

const hasMixed = (password: string) => /[a-z]/.test(password) && /[A-Z]/.test(password)

const hasSpecial = (password: string) => /[!@#$%^&*]/.test(password)

const hasRepeated = (password: string) => /(.)\1/g.test(password)

const getPasswordStrength = (password: string) => {
  let strengthMeter = 0

  if (password.length < 8) {
    return 0
  }

  if (password.length > 7) {
    strengthMeter += 1
  }

  if (hasNumber(password)) {
    strengthMeter += 1
  }

  if (hasSpecial(password)) {
    strengthMeter += 1
  }

  if (hasMixed(password)) {
    strengthMeter += 1
  }

  if (hasRepeated(password)) {
    strengthMeter -= 1
  }

  return strengthMeter
}

const cx = classNames.bind(styles)

export function FieldPassword({
  password,
  setFieldValue,
  handleBlur,
  errors,
  touched,
  errorMessage
}: FieldPasswordProps) {
  const [passwordStrength, setPasswordStrength] = useState(getPasswordStrength(password))
  const { t } = useTranslation()

  const messages = [
    t('password.veryWeak'),
    t('password.weak'),
    t('password.medium'),
    t('password.strong'),
    t('password.veryStrong')
  ]

  useEffect(() => {
    setPasswordStrength(getPasswordStrength(password))
  }, [password])

  return (
    <>
      <FieldInput
        name="password"
        label={t('form.password')}
        placeHolder={t('form.createAPassword')}
        type="password"
        value={password}
        onChange={(a: string) => setFieldValue('password', a)}
        handleBlur={handleBlur}
        error={errors.password}
        touched={touched.password}
        errorMessage={errorMessage}
      />
      {password && (
        <>
          <div className={styles.strengthBarContainer}>
            <div
              className={cx({
                strengthBar: true,
                veryWeak: passwordStrength === 0,
                weak: passwordStrength === 1,
                medium: passwordStrength === 2,
                strong: passwordStrength === 3,
                veryStrong: passwordStrength === 4
              })}
            />
            <div
              className={cx({
                strengthBar: true,
                weak: passwordStrength === 1,
                medium: passwordStrength === 2,
                strong: passwordStrength === 3,
                veryStrong: passwordStrength === 4
              })}
            />
            <div
              className={cx({
                strengthBar: true,
                medium: passwordStrength === 2,
                strong: passwordStrength === 3,
                veryStrong: passwordStrength === 4
              })}
            />
            <div
              className={cx({
                strengthBar: true,
                strong: passwordStrength === 3,
                veryStrong: passwordStrength === 4
              })}
            />
            <div
              className={cx({
                strengthBar: true,
                veryStrong: passwordStrength === 4
              })}
            />
          </div>
          <div className={styles.strengthText}>
            <p>{t('password.strength')}</p>
            <p>{messages[passwordStrength]}</p>
          </div>
        </>
      )}
    </>
  )
}
