import React, { useState } from 'react'
import { FormikProvider, useFormik } from 'formik'
import * as Yup from 'yup'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { EditOrganizationDetailsModalProps } from '../OverviewDetails.types'

import { useMutation } from '@percent/admin-dashboard/common/hooks'
import { AcknowledgeModal, FormField, Spacer, TextInput, Modal, ActionModal } from '@percent/lemonade'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { emailRegex, getChangedValues, isValidWebsiteURL, phoneNumberRegex } from '@percent/utility'

export function EditContactsModal({ open, onClose, organization, refresh }: EditOrganizationDetailsModalProps) {
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const { adminService } = useServices()
  const [errorDialogState, setErrorDialogState] = useState(false)

  const [{ isLoading, success }, { apiFunc }] = useMutation(adminService.editOrganisation, undefined, () => {
    setErrorDialogState(true)
  })

  const initialValues = {
    website: organization.website,
    email: organization.email,
    phone: organization.phone
  }

  const formik = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues,
    validationSchema: () => {
      return Yup.object().shape({
        website: isValidWebsiteURL()
          .nullable()
          .trim()
          .min(1, t('errorMessage.orgFieldCharacterLimit', { fieldName: 'Website' }))
          .max(255, t('errorMessage.orgFieldCharacterLimit', { fieldName: 'Website' })),
        email: Yup.string()
          .nullable()
          .trim()
          .matches(emailRegex, t('errorMessage.validEmail'))
          .min(1, t('errorMessage.orgFieldCharacterLimit', { fieldName: 'Email' }))
          .max(255, t('errorMessage.orgFieldCharacterLimit', { fieldName: 'Email' })),
        phone: Yup.string()
          .nullable()
          .trim()
          .matches(phoneNumberRegex, t('errorMessage.validPhoneNumber'))
          .min(1, t('errorMessage.charLimitPhoneNumber'))
          .max(20, t('errorMessage.charLimitPhoneNumber'))
      })
    },
    onSubmit: async ({ website, email, phone }) => {
      await apiFunc({
        payload: getChangedValues(
          {
            website: website?.length === 0 ? null : website,
            email: email?.length === 0 ? null : email,
            phone: phone?.length === 0 ? null : phone
          },
          initialValues
        ),
        id
      })
    }
  })

  const { errors, values, touched, dirty, handleChange, handleBlur, handleSubmit, isValid, resetForm } = formik

  const handleCloseModal = () => {
    onClose()
    refresh()
    resetForm()
  }

  const handleOnErrorClose = () => {
    setErrorDialogState(false)
  }

  const successOrErrorModal =
    success && !errorDialogState ? (
      <AcknowledgeModal
        result="positive"
        title={t('dialog.editOrganizationDetails.success.title', { organizationCard: 'Contacts' })}
        description={t('dialog.editOrganizationDetails.success.description', { organizationCard: 'contacts' })}
        buttonText="Close"
        handleClose={handleCloseModal}
      />
    ) : (
      <AcknowledgeModal
        result="negative"
        title={t('dialog.editOrganizationDetails.error.title', { organizationCard: 'Contacts' })}
        description={t('dialog.editOrganizationDetails.error.description', { organizationCard: 'contacts' })}
        buttonText="Close"
        handleClose={handleOnErrorClose}
      />
    )

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="contact-details-form-modal">
      {!success && !errorDialogState ? (
        <ActionModal
          title={t('dialog.editContacts.title')}
          primaryButtonText={t('button.saveChanges')}
          secondaryButtonText={t('button.cancel')}
          type="submit"
          variant={isValid && dirty ? 'primary' : 'secondary'}
          disabled={!(isValid && dirty && !isLoading)}
          loading={isLoading}
          handleClose={() => {
            onClose()
            resetForm()
          }}
          handleSubmit={handleSubmit}
          primaryBtnTestId="contact-details-submit-button"
          secondaryBtnTestId="contact-details-cancel-button"
        >
          <form onSubmit={handleSubmit}>
            <FormikProvider value={formik}>
              <>
                <FormField
                  label={t('typography.website')}
                  status={touched.website && errors.website ? 'danger' : 'default'}
                  statusMessage={errors.website || ''}
                  data-testid="website"
                >
                  <TextInput
                    name="website"
                    value={values.website || ''}
                    placeholder={t('dialog.editContacts.form.website.placeholder')}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </FormField>
                <Spacer size={4} axis="vertical" />
                <FormField
                  label={t('typography.generalEmail')}
                  status={touched.email && errors.email ? 'danger' : 'default'}
                  statusMessage={errors.email || ''}
                  data-testid="generalEmail"
                >
                  <TextInput
                    name="email"
                    value={values.email || ''}
                    placeholder={t('dialog.editContacts.form.generalEmail.placeholder')}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </FormField>
                <Spacer size={4} axis="vertical" />
                <FormField
                  label={t('typography.phoneNumber')}
                  status={touched.phone && errors.phone ? 'danger' : 'default'}
                  statusMessage={errors.phone || ''}
                  data-testid="phone"
                >
                  <TextInput
                    name="phone"
                    value={values.phone || ''}
                    placeholder={t('dialog.editContacts.form.phoneNumber.placeholder')}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </FormField>
              </>
            </FormikProvider>
          </form>
        </ActionModal>
      ) : (
        successOrErrorModal
      )}
    </Modal>
  )
}
