import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Loader, ErrorView, PercentButton } from '@percent/admin-dashboard/common/components'
import { EligibilitySettingsProps } from './EligibilitySettings.types'
import { Tag } from './components/tag/Tag'
import styles from './EligibilitySettings.module.scss'
import { SaveModal } from './components/saveModal/SaveModal'

export function EligibilitySettings({
  errorMessage,
  isLoading,
  tagsData,
  partner,
  refresh,
  refreshPartnerApi
}: EligibilitySettingsProps) {
  const [tagsForm, setTagsForm] = useState(tagsData?.map(tag => tag.id) || [])
  const [subTagsForm, setSubTagsForm] = useState(partner.allowedActivitySubTags || [])

  useEffect(() => {
    if (partner.allowedActivitySubTags) {
      setTagsForm(
        tagsData
          ?.filter(tag =>
            tag.subTags.map(subTag => subTag.id).some(subTagId => partner.allowedActivitySubTags?.includes(subTagId))
          )
          .map(tag => tag.id)
      )
    }
  }, [tagsData, partner])

  const { t } = useTranslation()
  const [isSaveModalOpened, setIsSaveModalOpened] = useState(false)

  const onTagChange = (tagId: string) => {
    if (tagsForm?.includes(tagId)) {
      if (
        !tagsData
          .filter(tag => tag.id === tagId)[0]
          .subTags.map(subTag => subTag.id)
          .every(id => subTagsForm.includes(id))
      ) {
        setSubTagsForm(prevState => {
          const singleTagSubTags = tagsData.filter(tag => tag.id === tagId)[0].subTags.map(subTag => subTag.id)

          return [...prevState.filter(subTag => !singleTagSubTags.includes(subTag)), ...singleTagSubTags]
        })

        return
      }
      setTagsForm(prevState => prevState?.filter(el => el !== tagId))
      setSubTagsForm(prevState => {
        const singleTagSubTags = tagsData.filter(tag => tag.id === tagId)[0].subTags.map(subTag => subTag.id)

        return prevState.filter(subTag => !singleTagSubTags.includes(subTag))
      })
    } else {
      setSubTagsForm(prevState => {
        const singleTagSubTags = tagsData.filter(tag => tag.id === tagId)[0].subTags.map(subTag => subTag.id)

        return [...prevState, ...singleTagSubTags]
      })
      setTagsForm(prevState => [...prevState, tagId])
    }
  }

  const getSaveButtonDisabledValue = () => {
    if (partner.allowedActivitySubTags && partner.allowedActivitySubTags?.length === subTagsForm.length) {
      const sortedPartnerSubTags = partner.allowedActivitySubTags.slice().sort()
      const sortedFormSubTags = subTagsForm.slice().sort()

      if (sortedPartnerSubTags.every((value, index) => value === sortedFormSubTags[index])) {
        return true
      }
    }

    if (!partner.allowedActivitySubTags && subTagsForm?.length === 0) {
      return true
    }

    return false
  }

  if (errorMessage) {
    return <ErrorView errorMessage={errorMessage} />
  }

  if (isLoading) {
    return <Loader />
  }

  return (
    <>
      <div className={styles.settingsContainer}>
        <div className={styles.header}>
          <span className={styles.headerText}>
            {t('typography.eligibilitySettingsFor')} <span className={styles.partnerName}>{partner.name}</span>.{' '}
            {t('typography.allTheCheckedTags')}
          </span>
          <PercentButton
            title={t('button.saveChanges')}
            className={styles.saveButton}
            onClick={() => setIsSaveModalOpened(true)}
            disabled={getSaveButtonDisabledValue()}
          />
        </div>
        <ul className={styles.tagsList}>
          {tagsData.map((tag, index) => (
            <Tag
              key={tag.id}
              tag={tag}
              index={index}
              onChange={onTagChange}
              active={!!tagsForm?.includes(tag.id)}
              subTagsForm={subTagsForm}
              setSubTagsForm={setSubTagsForm}
              tagsForm={tagsForm}
              setTagsForm={setTagsForm}
            />
          ))}
        </ul>
      </div>
      {isSaveModalOpened && (
        <SaveModal
          isOpened={isSaveModalOpened}
          setIsOpened={setIsSaveModalOpened}
          partner={partner}
          refreshApi={refresh}
          subTagsForm={subTagsForm}
          refreshPartnerApi={refreshPartnerApi}
        />
      )}
    </>
  )
}
