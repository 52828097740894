import { useMemo, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import CurrencyInputField from 'react-currency-input-field'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames/bind'

import { AcknowledgeModal, ActionModal, FormField, Modal, Select, Spacer } from '@percent/lemonade'
import { useCurrencies, useMutation } from '@percent/admin-dashboard/common/hooks'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { Currency, CurrencyCode, allCurrencies, formatMoney } from '@percent/utility'
import { formatAmountFromMinorUnits } from '@percent/admin-dashboard/common/utility/formatAmount/formatAmount'
import styles from './UpdateDonationAmountModal.module.scss'
import { UpdateDonationAmountModalProps } from './UpdateDonationAmountModal.types'

const cx = classNames.bind(styles)

export function UpdateDonationAmountModal({
  open,
  onClose,
  donationMatchRequestId,
  amount,
  refresh
}: UpdateDonationAmountModalProps) {
  const { t, i18n } = useTranslation()
  const { donationMatchingRequestService } = useServices()
  const [errorModal, setErrorModal] = useState(false)
  const intlConfigLang = i18n.language
  const { currencyInfo } = useCurrencies()
  const [{ isLoading, success }, { apiFunc }] = useMutation(
    donationMatchingRequestService.editDonationMatchRequestAmount,
    undefined,
    () => setErrorModal(true)
  )

  const currenciesOptions = useMemo(() => {
    return (allCurrencies ?? []).map((currency: Currency) => ({
      label: `${currency.name} (${currency.code})`,
      value: currency.code
    }))
  }, [])

  const formik = useFormik({
    initialValues: { amount: '', currency: amount.currency },
    validationSchema: () =>
      Yup.object().shape({ amount: Yup.number().required().positive(), currency: Yup.string().required() }),
    onSubmit: async ({ amount: newAmount, currency }) => {
      await apiFunc({
        donationMatchRequestId,
        amount: {
          amount: parseFloat(newAmount),
          currency
        }
      })
    }
  })

  const { handleSubmit, setFieldValue, touched, errors, dirty, isValid, resetForm, values, handleBlur } = formik

  const handleOnSuccess = () => {
    onClose()
    refresh()
  }

  const handleOnError = () => {
    setErrorModal(false)
    onClose()
    resetForm()
  }

  const positiveAmount = useMemo(() => {
    if (values?.amount && isNaN(parseFloat(values?.amount || ''))) {
      return 0
    }

    return values?.amount
  }, [values])

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="update-donation-match-request-amount">
      {errorModal ? (
        <AcknowledgeModal
          result="negative"
          title={t('dialog.donationMatchRequestDonationAmount.error.title')}
          description={t('dialog.donationMatchRequestDonationAmount.error.description')}
          buttonText={t('button.done')}
          handleClose={handleOnError}
        />
      ) : success ? (
        <AcknowledgeModal
          result="positive"
          title={t('dialog.donationMatchRequestDonationAmount.success.title')}
          buttonText={t('button.done')}
          handleClose={handleOnSuccess}
        />
      ) : (
        <ActionModal
          title={t('dialog.donationMatchRequestDonationAmount.title')}
          primaryButtonText={t('button.donationMatchRequest.updateAmount')}
          secondaryButtonText={t('button.cancel')}
          variant={isValid && dirty ? 'primary' : 'secondary'}
          disabled={!(isValid && dirty && !isLoading)}
          loading={isLoading}
          handleClose={() => {
            onClose()
            resetForm()
          }}
          handleSubmit={handleSubmit}
          primaryBtnTestId="update-donation-amount"
          secondaryBtnTestId="cancel-update-donation-amount"
          aria-labelledby="update-donation-amount-modal"
        >
          <form onSubmit={handleSubmit}>
            <FormField
              label={t('dialog.donationMatchRequestDonationAmount.form.amount.label')}
              data-testid="amount"
              status={errors.amount && touched.amount ? 'danger' : 'default'}
              statusMessage={errors.amount}
              description={`Current: ${formatAmountFromMinorUnits({
                currencyCode: amount.currency,
                value: amount.amount,
                currencies: currencyInfo
              })} ${amount.currency}`}
              necessity="required"
            >
              <CurrencyInputField
                id="amount"
                name="amount"
                value={positiveAmount}
                onValueChange={value => setFieldValue('amount', value?.replace(/-/g, ''))}
                onBlur={handleBlur}
                placeholder={formatMoney({
                  amount: 0,
                  currency: (values?.currency as CurrencyCode) || amount.currency
                })}
                data-testid="amountInput"
                intlConfig={{
                  locale: values.currency ?? amount.currency === 'USD' ? 'en-US' : intlConfigLang,
                  currency: values.currency ?? amount.currency
                }}
                disableAbbreviations
                className={cx({
                  currencyInputWrapper: true,
                  danger: touched.amount && errors.amount
                })}
              />
            </FormField>
            <Spacer size={4} axis="vertical" />
            <FormField
              label={t('dialog.donationMatchRequestDonationAmount.form.currency.label')}
              data-testid="select-currency"
              status={errors.currency && touched.currency ? 'danger' : 'default'}
              statusMessage={errors.currency}
              necessity="required"
            >
              <Select
                placeholder={t('dialog.donationMatchRequestDonationAmount.form.currency.placeholder')}
                defaultValue={currenciesOptions.find(
                  ({ value, label }) =>
                    value === values?.currency && {
                      label,
                      value
                    }
                )}
                searchable
                options={currenciesOptions}
                onChange={event => {
                  setFieldValue('currency', event?.value ?? '')
                }}
              />
            </FormField>
            <Spacer size={4} axis="vertical" />
          </form>
        </ActionModal>
      )}
    </Modal>
  )
}
