/* eslint-disable react/destructuring-assignment */
import { Menu, MenuItem } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { DonationsActionsMenuProps } from './DonationsActionsMenu.types'
import { Icon } from '@percent/lemonade'
import { useFeatureFlag } from '@percent/admin-dashboard/common/hooks/useFeatureFlag/useFeatureFlag'

export function DonationsActionsMenu({
  anchorEl,
  isOpen,
  onClose,
  handleViewRaw,
  handleReassignDonation
}: DonationsActionsMenuProps) {
  const { reassignDonationFeatureFlag } = useFeatureFlag()
  const { t } = useTranslation()

  return (
    <Menu
      id="actions-menu"
      data-testid="actions-menu"
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      open={isOpen}
      onClose={onClose}
      keepMounted
      style={{
        display: 'flex',
        alignItems: 'flex-start'
      }}
    >
      <MenuItem key="viewRaw" onClick={handleViewRaw}>
        <Icon name="code-file" size={8} color="gray500" /> {t('button.viewRaw')}
      </MenuItem>
      {reassignDonationFeatureFlag && (
        <MenuItem key="reassignDonation" onClick={handleReassignDonation}>
          <Icon name="rotate-right" size={8} color="gray500" /> {t('button.reassignDonation')}
        </MenuItem>
      )}
    </Menu>
  )
}
