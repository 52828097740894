import { Divider, Grid, Paper, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { useTranslation } from 'react-i18next'

import { ActivityCheckSuccessModal } from '../ActivityCheckDialog/ActivityCheckSuccessModal'
import styles from '../../agentVerification/agentVerificationDetails/AgentVerificationDetails.module.scss'
import { useBreadcrumbsData } from '../../Layout/breadcrumbsContext/useBreadcrumbsData/useBreadcrumbsData'
import { AgentVerificationDetailsListItem } from '../../agentVerification/agentVerificationDetails/components/agentVerificationDetailsListItem/AgentVerificationDetailsListItem'

import { CellStatus, ErrorView, Loader, PercentButton, Dialog } from '@percent/admin-dashboard/common/components'
import { getDate } from '@percent/admin-dashboard/common/utility/getDate'
import { ActivityCheckStatus, Organisation } from '@percent/admin-dashboard/api/types'
import { ReactComponent as InfoIcon } from '@percent/admin-dashboard/common/assets/images/info-icon.svg'
import { orgSubActivities } from '@percent/admin-dashboard/constants/organisationActivities'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { useQuery } from '@percent/admin-dashboard/common/hooks'
import { APIErrorHandler } from '@percent/admin-dashboard/common/library/APIErrorHandler'
import { SuccessDialog } from '@percent/admin-dashboard/common/components/dialog/SuccessDialog/SuccessDialog'
import { ActivityCheckSubmitCard } from './ActivityCheckSubmitCard'
import { ActivityCheckSearchDropdown } from './ActivityCheckSearchDropdown'

export function ActivityCheckDetailView() {
  const { t } = useTranslation()
  const { activityCheckService, adminService } = useServices()
  const { setPath } = useBreadcrumbsData()
  const [isActivityCheckModalOpened, setIsActivityCheckModalOpened] = useState<boolean | undefined>(false)
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const { id } = useParams<{ id: string }>()
  const [orgData, setOrgData] = useState<Organisation>()
  const [orgLoader, setOrgLoader] = useState(true)
  const [orgError, setOrgError] = useState('')
  const [updatedCode, setUpdatedCode] = useState({
    primary: '',
    sub: '',
    definition: ''
  })

  const [{ data, isLoading, errorMessage }, { refresh }] = useQuery(activityCheckService.getActivityCheck, {
    id
  })

  const getOrgData = async (orgId: string) => {
    setOrgLoader(true)
    try {
      const {
        data: { data: oData }
      } = await adminService.getOrganisation({ id: orgId })

      setOrgData(oData)
      setOrgLoader(false)
    } catch (err) {
      setOrgError(APIErrorHandler(err?.response?.data?.error))
      setOrgLoader(false)
    }
  }

  useEffect(() => {
    if (data) {
      getOrgData(data.organisationId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    setPath(`${data?.status}`)
  }, [setPath, data])

  const activityCheckStatus = data?.status

  const currentActivityCode =
    activityCheckStatus === ActivityCheckStatus.LIVE ? data?.organisationActivity?.activity : data?.activity

  const primaryCode = orgSubActivities[currentActivityCode as keyof typeof orgSubActivities]?.primaryActivityCode
  const subCode = orgSubActivities[currentActivityCode as keyof typeof orgSubActivities]?.subActivityCode
  const primaryDescription =
    orgSubActivities[currentActivityCode as keyof typeof orgSubActivities]?.primaryActivityDescription
  const subDescription = orgSubActivities[currentActivityCode as keyof typeof orgSubActivities]?.subActivityDescription
  const definition = orgSubActivities[currentActivityCode as keyof typeof orgSubActivities]?.definition

  if (errorMessage || orgError) {
    return <ErrorView errorMessage={errorMessage || orgError} />
  }

  if (isLoading || orgLoader) {
    return <Loader />
  }

  return (
    <>
      <ActivityCheckSubmitCard
        setIsOpened={setIsActivityCheckModalOpened}
        orgName={orgData?.name}
        orgWebsite={orgData?.website}
      />
      <Grid container spacing={0} component={Paper} elevation={0} className={styles.generalInfoWrapper}>
        <span className={styles.text}>{t('typography.status')}</span>
        {data?.status && <CellStatus status={data?.status} />}
        <Divider orientation="vertical" flexItem className={styles.divider} />
        <span className={styles.text}>{t('typography.requestDate')}</span>
        <span className={styles.subtext}>{getDate(data?.createdAt)}</span>
      </Grid>
      <Grid container spacing={0} component={Paper} elevation={0} className={styles.listContainer}>
        <Grid item xs={12} className={styles.missionTitle}>
          <Typography variant="subtitle2">{t('typography.missionStatement')}</Typography>
          <Typography variant="subtitle1" className={styles.titleSubText}>
            {data?.missionStatement}
          </Typography>
        </Grid>
        <ul className={styles.detailsList}>
          <AgentVerificationDetailsListItem
            title={t(
              activityCheckStatus === ActivityCheckStatus.LIVE ? 'typography.currentCodes' : 'typography.submittedCodes'
            )}
          />
          <AgentVerificationDetailsListItem title={t('typography.primary')}>
            <PercentButton
              title={primaryCode && primaryDescription ? `${primaryCode} - ${primaryDescription}` : ''}
              disabled
            />
          </AgentVerificationDetailsListItem>
          <AgentVerificationDetailsListItem title={t('typography.sub')}>
            <PercentButton title={subCode && subDescription ? `${subCode} - ${subDescription}` : ''} disabled />
          </AgentVerificationDetailsListItem>
          <li className={styles.searchActivityCheck}>
            <Typography variant="subtitle2">{t('typography.code')}</Typography>
            <ActivityCheckSearchDropdown
              subCode={subCode}
              subDescription={subDescription}
              primaryCode={primaryCode}
              primaryDescription={primaryDescription}
              setUpdatedCode={setUpdatedCode}
              definition={definition}
            />
            <Typography variant="subtitle2" className={styles.activityCheckCodeText}>
              <InfoIcon /> <span>{primaryDescription}</span> {subDescription}
            </Typography>
          </li>
        </ul>
      </Grid>
      {isActivityCheckModalOpened && (
        <ActivityCheckSuccessModal
          isOpened={isActivityCheckModalOpened}
          setIsOpened={setIsActivityCheckModalOpened}
          primaryCode={primaryCode}
          subCode={subCode}
          primaryDescription={primaryDescription}
          subDescription={subDescription}
          updatedCode={updatedCode}
          orgName={orgData?.name}
          orgId={data?.organisationId}
          refresh={refresh}
          setIsSuccess={setIsSuccess}
        />
      )}
      <Dialog openModal={isSuccess} onClose={() => setIsSuccess(false)} withoutHeader headerTitle="">
        {isSuccess && <SuccessDialog title={t('dialog.activityCodeChanged')} onClose={() => setIsSuccess(false)} />}
      </Dialog>
    </>
  )
}
