import React from 'react'
import { Link } from 'react-router-dom'
import { FormControl, Input, Typography } from '@material-ui/core'

import { FieldMeta } from '../fieldMeta/FieldMeta'

import style from './FieldInput.module.scss'
import { FieldInputProps } from './FiledInput.types'

export function FieldInput({
  value,
  label,
  error,
  onChange,
  placeHolder,
  handleBlur,
  name,
  disabled,
  addonLabel,
  addonLabelText,
  addonLabelLink,
  type,
  touched,
  readOnly,
  errorMessage
}: FieldInputProps) {
  const errorMsg = touched && error ? error : undefined

  return (
    <FormControl className={style.field}>
      <label className={style.fieldLabel} htmlFor={name}>
        {label}
        <span>
          {addonLabel && addonLabelLink && (
            <Link to={addonLabelLink}>
              <Typography className={style.addonLabelClassname}>{addonLabelText}</Typography>
            </Link>
          )}
        </span>
      </label>
      <Input
        readOnly={readOnly}
        id={label}
        type={type || 'text'}
        disabled={disabled}
        name={name}
        value={value}
        placeholder={placeHolder}
        onBlur={handleBlur}
        onChange={e => onChange?.(e.target.value)}
        inputProps={{
          'data-testid': name,
          className: (errorMsg || errorMessage) && style.fieldInput
        }}
        disableUnderline
      />
      <FieldMeta error={errorMsg} />
    </FormControl>
  )
}
