import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { getDate } from '@percent/admin-dashboard/common/utility/getDate'
import { AgentVerificationLog, AgentVerificationStatus } from '@percent/admin-dashboard/api/types'
import { AgentDetailsTopBarItemProps, AgentDetailsTopBarProps } from './AgentDetailsTopBar.types'
import { Badge, IconRegistry, CardDashboard } from '@percent/lemonade'
import { StatusVariant } from '@percent/admin-dashboard/common/components/status/Status.types'
import { LocaleKey } from '@percent/admin-dashboard/i18n/config'
import styles from './AgentDetailsTopBar.module.scss'

const statusMap: {
  [key in string]: {
    variant: StatusVariant
    message: LocaleKey
    icon: keyof typeof IconRegistry
  }
} = {
  rejected: {
    variant: 'critical',
    message: 'status.rejected',
    icon: 'reject'
  },
  approved: {
    variant: 'positive',
    message: 'status.approved',
    icon: 'check-badge'
  },
  percent_action_needed: {
    variant: 'default',
    message: 'status.percentActionNeeded',
    icon: 'clock'
  },
  external_action_needed: {
    variant: 'default',
    message: 'status.externalActionNeeded',
    icon: 'clock'
  }
}

export const getExternalActionStatuses = (
  status?: AgentVerificationStatus,
  logs?: AgentVerificationLog[]
): LocaleKey[] => {
  const statuses: LocaleKey[] = []
  const documentRequested = logs?.find(log => log.type === 'agent_verification_document_request_email_sent')
  const associationEmailSent = logs?.find(log => log.type === 'association_email_sent')

  if (!status) return ['status.externalActionNeeded']

  if (status === AgentVerificationStatus.PENDING_USER_VERIFICATION) return ['typography.waitingAVClick']

  if (status === AgentVerificationStatus.PENDING) statuses.push('typography.waitingAVClick')

  if (documentRequested) statuses.push('typography.documentRequested')

  if (associationEmailSent) statuses.push('typography.waitingNPReply')

  if (statuses.length === 0) statuses.push('status.externalActionNeeded')

  return statuses
}

function TopBarItem({ title, children }: AgentDetailsTopBarItemProps) {
  return (
    <div className={styles.topBarItem}>
      <span>{title}</span>
      {children}
    </div>
  )
}

export function AgentDetailsTopBar({
  agentVerification,
  agentVerificationLogs,
  partnerName
}: Readonly<AgentDetailsTopBarProps>) {
  const { t } = useTranslation()

  const getAVStatus = (status?: AgentVerificationStatus, externalActionRequired?: boolean) => {
    if ((status === 'pending' || status === 'pending_review') && externalActionRequired === false) {
      return 'percent_action_needed'
    }

    if (
      status === 'pending_user_verification' ||
      ((status === 'pending' || status === 'pending_review') && externalActionRequired === true)
    ) {
      return 'external_action_needed'
    }

    if (status === 'approved') {
      return 'approved'
    }

    return 'rejected'
  }

  const aVStatus = getAVStatus(agentVerification?.status, agentVerification?.externalActionRequired)
  const externalActionStatuses = useMemo(
    () =>
      aVStatus === 'external_action_needed'
        ? getExternalActionStatuses(agentVerification?.status, agentVerificationLogs)
        : [],
    [agentVerification?.status, aVStatus, agentVerificationLogs]
  )
  const isAutoRejected = agentVerificationLogs?.find(log => log.type === 'email_found_on_auto_reject_list')

  return (
    <CardDashboard>
      <div className={styles.container}>
        <TopBarItem title={t('typography.status')}>
          {agentVerification?.status && (
            <>
              <Badge variant={statusMap[aVStatus].variant} icon={statusMap[aVStatus].icon}>
                {aVStatus !== 'external_action_needed' && t(statusMap[aVStatus].message)}
                {externalActionStatuses.map(status => t(status)).join(', ')}
              </Badge>
              {agentVerification?.rejectionReasonCode && (
                <span className={styles.identityCheckFailed}>
                  {`(${t(
                    isAutoRejected
                      ? 'typography.suspiciousEmail'
                      : `dropdown.reject.${agentVerification?.rejectionReasonCode}`
                  )})`}
                </span>
              )}
            </>
          )}
        </TopBarItem>
        <TopBarItem title={t('typography.created')}>
          <span className={styles.topBarDetail}>{getDate(agentVerification?.createdAt)}</span>
        </TopBarItem>
        <TopBarItem title={t('typography.partner')}>
          <span className={styles.topBarDetail}>{partnerName}</span>
        </TopBarItem>
        <TopBarItem title={t('table.programName')}>
          <span className={styles.topBarDetail}>{agentVerification?.configurationName || t('status.na')}</span>
        </TopBarItem>
      </div>
    </CardDashboard>
  )
}
