import { StatusVariant } from '../common/components/status/Status.types'
import { LocaleKey } from '../i18n/config'

import { IconRegistry } from '@percent/lemonade'

export const statusMap: {
  [key in string]: {
    variant: StatusVariant
    icon: keyof typeof IconRegistry
    text: LocaleKey
  }
} = {
  approved: {
    variant: 'positive',
    icon: 'check-badge',
    text: 'status.approved'
  },
  rejected: {
    variant: 'critical',
    icon: 'reject',
    text: 'status.rejected'
  },
  pending: {
    variant: 'default',
    icon: 'clock',
    text: 'status.pending'
  },
  processed: {
    variant: 'default',
    icon: 'clock',
    text: 'status.processed'
  },
  succeeded: {
    variant: 'positive',
    icon: 'check-badge',
    text: 'status.approved'
  },
  failed: {
    variant: 'critical',
    icon: 'reject',
    text: 'status.rejected'
  }
}
