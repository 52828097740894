import React, { useEffect, useMemo, useState } from 'react'
import { Paper, Grid, Typography, TableCell, TableRow } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { ComplianceModal } from '../complianceModal/ComplianceModal'
import { EditComplianceModal } from '../editComplianceModal/EditComplianceModal'

import { useQueryList } from '@percent/admin-dashboard/common/hooks'
import { ErrorView, Table, PercentButton, CellStatus } from '@percent/admin-dashboard/common/components'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { getDate } from '@percent/admin-dashboard/common/utility/getDate'
import styles from './History.module.scss'
import { HistoryProps } from './History.types'
import { VettingEvent } from '@percent/admin-dashboard/api/types'

const columns = [
  { id: 'event', isSortable: false },
  { id: 'status', isSortable: false },
  { id: 'eventDate', isSortable: false },
  { id: 'createdBy', isSortable: false }
]

export function History({
  nonprofitName,
  organisationId,
  vettingEventsResults,
  refreshVettingEventsResults
}: HistoryProps) {
  const { t } = useTranslation()
  const [order, setOrder] = useState<'asc' | 'desc'>('asc')
  const [orderBy, setOrderBy] = useState<string>('')
  const [isComplianceModalOpened, setIsComplianceModalOpened] = useState(false)
  const [isEditComplianceModalOpened, setIsEditComplianceModalOpened] = useState(false)
  const { adminService } = useServices()

  const [
    { dataOrNull: vettingEventsHistory, totalResults, isLoading, errorMessageOrNull },
    { query, nextPage, previousPage, refresh }
  ] = useQueryList(adminService.getVettingEvents, { organisationId })

  const { results: vettingResults } = vettingEventsResults

  const isComplianceReviewRequired = useMemo(
    () =>
      (vettingResults &&
        Object.keys(vettingResults).some(
          key => vettingResults[key].status === null || vettingResults[key].status === 'pending_review'
        )) ||
      false,
    [vettingResults]
  )

  useEffect(() => {
    setIsComplianceModalOpened(isComplianceReviewRequired)
  }, [isComplianceReviewRequired])

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)

    const newQueryParams = {
      direction: isAsc ? 'DESC' : 'ASC',
      sort: 'name',
      completed: true
    }
    query(newQueryParams)
  }

  const refreshResults = () => {
    refreshVettingEventsResults()
    refresh()
  }

  if (errorMessageOrNull) {
    return <ErrorView errorMessage={errorMessageOrNull} />
  }

  const emptyTableActions = isComplianceReviewRequired && (
    <PercentButton
      className={styles.actionsButton}
      title={`+ ${t('button.createVettingEvent')}`}
      onClick={() => setIsComplianceModalOpened(true)}
      alignment="alignRight"
    />
  )

  const getCreatedBy = (row: VettingEvent) => {
    if (row.createdBy) {
      return row.account?.fullName || row.account?.email
    }

    return t('typography.automatically')
  }

  return (
    <Grid container spacing={0} component={Paper} elevation={0} className={styles.historyContainer}>
      <div className={styles.detailsHeader}>
        <Typography variant="h6">{t('typography.history')}</Typography>
        <PercentButton
          title={isComplianceReviewRequired ? `+ ${t('button.createVettingEvent')}` : t('button.editComplianceEvent')}
          onClick={() => {
            isComplianceReviewRequired ? setIsComplianceModalOpened(true) : setIsEditComplianceModalOpened(true)
          }}
          alignment="alignRight"
        />
      </div>
      <Table
        isLoading={isLoading}
        totalResults={totalResults}
        data={vettingEventsHistory}
        previousPage={previousPage}
        nextPage={nextPage}
        value={0}
        handleRequestSort={handleRequestSort}
        order={order}
        orderBy={orderBy}
        columns={columns}
        emptyTableText={`${nonprofitName} ${t('table.emptyVettingEvents')}`}
        emptyTableImageType="vettingEvents"
        emptyTableChildren={emptyTableActions}
        className={styles.historyTable}
        testId="compliance-history-table"
      >
        {vettingEventsHistory?.map(row => (
          <TableRow key={row.id} data-testid={`${row.scope}-history-row`}>
            <TableCell>{row.scope}</TableCell>
            <TableCell>
              <CellStatus status={row.result?.status} />
            </TableCell>
            <TableCell>{getDate(row.createdAt)}</TableCell>
            <TableCell>{getCreatedBy(row)}</TableCell>
          </TableRow>
        ))}
      </Table>
      {isComplianceModalOpened && (
        <ComplianceModal
          refreshVettingEventsResults={refreshResults}
          refreshVettingEvents={refresh}
          organisationId={organisationId}
          isOpen={isComplianceModalOpened}
          setIsOpen={setIsComplianceModalOpened}
          vettingEventsResults={vettingEventsResults}
          vettingEvents={vettingEventsHistory}
        />
      )}
      {isEditComplianceModalOpened && (
        <EditComplianceModal
          isOpened={isEditComplianceModalOpened}
          setIsOpened={setIsEditComplianceModalOpened}
          vettingEventsResults={vettingEventsResults}
          refreshVettingEventsResults={refreshVettingEventsResults}
          refresh={refresh}
          organisationId={organisationId}
        />
      )}
    </Grid>
  )
}
