import { Card, Modal } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import { ReactComponent as SuccessIcon } from '@percent/admin-dashboard/common/assets/images/success.svg'
import { PercentButton, Loader, ErrorView } from '@percent/admin-dashboard/common/components'
import { useAgentVerificationDecisionContext } from '@percent/admin-dashboard/app/agentVerification/agentVerificationDetails/useAgentVerificationDecisionContext/useAgentVerificationDecisionContext'
import styles from './AgentVerificationApprovedInformation.module.scss'
import { AgentVerificationApproveEmailModalProps } from './AgentVerificationApproveEmailModal.types'

export function AgentVerificationApproveEmailModal({
  isLoading,
  errorMessage,
  refresh
}: AgentVerificationApproveEmailModalProps) {
  const { t } = useTranslation()
  const { setOpenedModal, isApproveEmailModalOpened } = useAgentVerificationDecisionContext()

  const handleDoneClick = () => {
    setOpenedModal(undefined)
    refresh()
  }

  return (
    <Modal
      open={isApproveEmailModalOpened}
      onClose={() => setOpenedModal(undefined)}
      aria-labelledby="agent-verification-approve-modal"
    >
      <div className={styles.modal}>
        {isLoading ? (
          <Loader />
        ) : errorMessage ? (
          <ErrorView errorMessage={errorMessage} />
        ) : (
          <Card className={styles.wrapper}>
            <SuccessIcon className={styles.icon} />
            <span className={styles.title}>{t('typography.triggerEmail')}</span>
            <PercentButton title={t('button.done')} onClick={handleDoneClick} className={styles.button} />
          </Card>
        )}
      </div>
    </Modal>
  )
}
