import { rest } from 'msw'

import { config } from '@percent/admin-dashboard/config'
import { mockAccountResponse } from '@percent/admin-dashboard/fixtures/api/mockAccountResponse'

export const accountsHandlers = [
  rest.get(`${config.urls.api}v1/account`, async (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockAccountResponse))
  })
]
