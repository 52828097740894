import { AxiosInstance } from 'axios'
import { Alpha3Code } from 'i18n-iso-countries'

import { DisbursementStatusAndTotal } from '@percent/admin-dashboard/app/disbursements/disbursementDetails/DisbursementDetailsTable/DisbursementDetailsTable.types'

export type disbursementsServiceCapabilities = {
  percentClient: AxiosInstance
}

export type GetDisbursementBatchesListProps = {
  pageSize?: number
  cursor?: string
  search?: string
  foundationId?: string | null
  allDisbursementsWithStatus?: (string | null)[] | null
  anyDisbursementsWithStatus?: (string | null)[] | null
}

export type GetDisbursementsInBatchListProps = {
  pageSize?: number
  cursor?: string
}

export type DetailedDisbursementsCount = {
  type: DisbursementPaymentMethod
  status: DisbursementStatusAndTotal
  count: number
}

export type DisbursementBatch = {
  id: string
  createdAt: string
  foundationId: string
  createdBy: string
  endDate: string
  counts: {
    total: number
    approvalRequired: number
    approved: number
    inTransit: number
    paid: number
    cancelled: number
  }
  detailedCounts: DetailedDisbursementsCount[]
}

export enum DisbursementType {
  INTERNAL = 'internal',
  EXTERNAL = 'external',
  CHEQUE = 'cheque'
}

export type DisbursementPaymentMethod = 'bank_transfer' | 'card' | 'cheque'

export type CreateDisbursementBatchProps = {
  type: DisbursementType
  paymentMethodTypes?: DisbursementPaymentMethod[]
  foundationId: string
  endDate: string
}

export type GetDisbursementBatchDetailsProps = {
  id: string
}

export enum DisbursementStatus {
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  PENDING_PAYMENT = 'PENDING_PAYMENT',
  PAID = 'PAID',
  CANCELLED = 'CANCELLED',
  SENT = 'SENT'
}

export type Disbursement = {
  id: string
  approvedAt?: string
  approvedBy?: string
  paidAt?: string
  paidBy?: string
  createdAt: string
  createdBy: string
  disbursementBatchId: string
  foundationAmount: number
  foundationCurrencyCode: string
  foundationId: string
  status: DisbursementStatus
  organisationId: string
  paidCurrencyCode?: string
  amount: number
  paidAmount?: number
  actualOrganisationCurrencyCode: string
  actualOrganisationAmount: number
  type: DisbursementType
}

export type DisbursementEnhanced = Disbursement & {
  organisationName: string
  organisationCountryCode: Alpha3Code
}

export type MarkDisbursementAsPaidProps = {
  id: string
  paidAt: string
  paidAmount: number
  paidCurrency: string
}
