import { Grid, Typography } from '@material-ui/core'
import classNames from 'classnames/bind'
import { useTranslation } from 'react-i18next'

import { Dialog, Loader, PercentButton } from '@percent/admin-dashboard/common/components'
import { ReactComponent as CheckMark } from '@percent/admin-dashboard/common/assets/images/check_mark.svg'
import { useMutation } from '@percent/admin-dashboard/common/hooks'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { PostActivityEventProps } from '@percent/admin-dashboard/api/types'
import { ReactComponent as AlertIcon } from '@percent/admin-dashboard/common/assets/images/alert.svg'
import { SubmitModalProps } from './SubmitModal.types'
import styles from './SubmitModal.module.scss'

const cx = classNames.bind(styles)

export function SubmitModal({
  isOpened,
  setIsOpened,
  organisation,
  subTagsForm,
  isFlagged,
  refresh,
  tagsData,
  isNoneOfTheAbove
}: SubmitModalProps) {
  const { t } = useTranslation()
  const { eligibilityService } = useServices()

  const [{ isLoading, errorMessage, success }, { apiFunc: submitFunc }] = useMutation(
    eligibilityService.submitActivityEvent
  )

  const handleSubmit = () => {
    submitFunc({
      organisationId: organisation?.id,
      subTags: subTagsForm && subTagsForm?.length > 0 ? subTagsForm : null
    } as PostActivityEventProps)
  }

  const handleFinishAction = () => {
    setIsOpened(false)
    refresh()
  }

  return (
    <Dialog
      openModal={isOpened}
      onClose={() => setIsOpened(false)}
      withoutHeader={success}
      headerTitle={t('dialog.submitTags.title')}
    >
      {isLoading ? (
        <Loader />
      ) : errorMessage ? (
        <div className={styles.mainContainer}>{errorMessage}</div>
      ) : success ? (
        <div className={styles.dialogBody}>
          <div className={styles.checkMark}>
            <CheckMark />
          </div>
          <Typography variant="h5">
            {t(isFlagged ? 'dialog.submitTags.haveBeenSubmitted' : 'dialog.submitTags.haveBeenAssigned')}
          </Typography>
          <PercentButton title={t('button.done')} onClick={handleFinishAction} className={styles.centerButton} />
        </div>
      ) : (
        <>
          <Grid item xs={12} className={styles.modalBody}>
            <Typography>
              {isFlagged && t('dialog.submitTags.flagOrganisation')}
              {isNoneOfTheAbove && t('dialog.submitTags.noneOfAbove')}
              {!isFlagged && !isNoneOfTheAbove && t('dialog.submitTags.assignTags')}
              {!isNoneOfTheAbove && (
                <span style={{ fontFamily: 'MarkProMedium' }}> {organisation?.displayName ?? organisation?.name}.</span>
              )}
            </Typography>
          </Grid>
          <Grid item xs={12} className={styles.mainContainer}>
            <ul className={styles.tagsList}>
              {tagsData
                .filter(tag =>
                  tag.subTags
                    .map(subTag => subTag.id)
                    .some(subTagId => subTagsForm?.find(sub => sub.id === subTagId)?.meetsCriteria)
                )
                .map(tag => (
                  <li key={tag.id}>
                    <div className={styles.tagTitle}>
                      <p>{tag.name}</p>
                    </div>
                    <div className={styles.subTagsContainer}>
                      {tag.subTags
                        .filter(subTag => subTagsForm?.find(sub => sub.id === subTag.id)?.meetsCriteria)
                        .map(subTag => (
                          <div
                            key={subTag.id}
                            className={cx({
                              subTag: true,
                              sensitive: subTag.isSensitive
                            })}
                          >
                            {subTag.name}
                          </div>
                        ))}
                    </div>
                    {tag.subTags.find(
                      subTag =>
                        subTagsForm?.findIndex(sub => sub.id === subTag.id && sub.meetsCriteria) !== -1 &&
                        subTag.isSensitive
                    ) && (
                      <span className={styles.textSensitive}>
                        <AlertIcon /> {t('dialog.submitTags.mayCauseRejection')}
                      </span>
                    )}
                  </li>
                ))}
            </ul>
            <p className={styles.submitText}>
              {t(isFlagged || isNoneOfTheAbove ? 'dialog.submitTags.continue' : 'dialog.submitTags.willBeSubmitted')}
            </p>
          </Grid>
          <Grid item xs={12} className={styles.dialogFooter}>
            <PercentButton title={t('button.submitTags')} onClick={handleSubmit} testId="submit-tags" />
            <PercentButton
              title={t('button.cancel')}
              onClick={() => setIsOpened(false)}
              textColour="secondaryTextColour"
              outlined
            />
          </Grid>
        </>
      )}
    </Dialog>
  )
}
