import { Box, DialogContent, Typography } from '@material-ui/core'
import { FormEvent } from 'react'
import { useTranslation } from 'react-i18next'

import { Dialog } from '@percent/admin-dashboard/common/components'
import { Button } from '@percent/lemonade'
import { MarkAllAsApprovedModalProps } from './MarkAllAsApprovedModal.types'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { useToast } from '@percent/admin-dashboard/containers/toast/ToastContext'
import styles from './MarkAllAsApprovedModal.module.scss'

export function MarkAllAsApprovedModal({
  isOpen,
  onClose,
  disbursementBatchId,
  numberOfDisbursements,
  refreshData
}: MarkAllAsApprovedModalProps) {
  const { t } = useTranslation()
  const { disbursementsService } = useServices()
  const addToast = useToast()

  const markAllAsApproved = async () => {
    try {
      await disbursementsService.markPendingDisbursementsAsApproved(disbursementBatchId)
      addToast(t('toast.approveDisbursementBatchSuccess', { numberOfDisbursements }), 'success')
      refreshData?.()
      onClose()
    } catch {
      addToast(t('toast.approveDisbursementBatchError', { numberOfDisbursements }), 'error')
    }
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    await markAllAsApproved()
  }

  return (
    <Dialog
      headerTitle={t('dialog.markAllAsApproved.header', { numberOfDisbursements })}
      openModal={isOpen}
      onClose={onClose}
    >
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Typography variant="body2">{t('dialog.markAllAsApproved.info', { numberOfDisbursements })}</Typography>
          <Box className={styles.buttonsWrapper}>
            <Button size="large" type="submit">
              {t('button.markPendingAsApproved')}
            </Button>
            <Button onPress={onClose} variant="secondary" size="large" type="button">
              {t('button.cancel')}
            </Button>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  )
}
