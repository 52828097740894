import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { NonProfitDetailListItem } from '../nonProfitDetailListItem/NonProfitDetailListItem'

export function OrganisationTypeSectionContainer({ children }: { children: ReactNode }) {
  const { t } = useTranslation()

  return <NonProfitDetailListItem title={t('table.organisationType')}>{children}</NonProfitDetailListItem>
}
