import { StringParam, useQueryParams } from 'use-query-params'

import { ValidationSubmissionsTable } from './validationSubmissionsTable/ValidationSubmissionsTable'
import { useDidMountEffect, useQueryList } from '@percent/admin-dashboard/common/hooks'
import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'

export function ValidationSubmissionsContainer() {
  const { validationSubmissionsService } = useServices()

  const [queryParams, setQueryParams] = useQueryParams({
    query: StringParam
  })

  const [{ totalResults, isLoading, dataOrNull, errorMessageOrNull }, { nextPage, previousPage, query, refresh }] =
    useQueryList(validationSubmissionsService.getValidationSubmissionsList, {
      ...queryParams
    })

  useDidMountEffect(() => query(queryParams), [queryParams, query])

  return (
    <ValidationSubmissionsTable
      data={dataOrNull}
      isLoading={isLoading}
      errorMessage={errorMessageOrNull}
      nextPage={nextPage}
      previousPage={previousPage}
      totalResults={totalResults}
      queryParams={queryParams}
      setQueryParams={setQueryParams}
      refresh={refresh}
    />
  )
}
