import React, { useState } from 'react'

import { SET_AUTHORISED } from '../../../../context/auth/authReducer/authReducer'

import { useServices } from '@percent/admin-dashboard/containers/service/ServiceContext'
import { useAuthDispatch, useMutation } from '@percent/admin-dashboard/common/hooks'
import { LoginWithRecoveryCode } from './LoginWithRecoveryCode'

export function LoginWithRecoveryCodeContainer() {
  const dispatch = useAuthDispatch()
  const { authService } = useServices()
  const [isRecoveryCodeConfirmed, setIsRecoveryCodeConfirmed] = useState(false)
  const [{ errorMessage, isLoading, data: response }, { apiFunc: loginFunc, setErrorMessage }] = useMutation(
    authService.loginWithRecoveryCode,
    ({ data: { data } }) => {
      localStorage.authState = JSON.stringify({
        isAuthorised: true,
        isAuthorising: false
      })

      dispatch({
        type: SET_AUTHORISED,
        user: data
      })

      setIsRecoveryCodeConfirmed(true)
    }
  )

  return (
    <LoginWithRecoveryCode
      otpCode={response?.data.data.twoFaBackupCode}
      errorMessage={errorMessage}
      isLoading={isLoading}
      loginFunc={loginFunc}
      isRecoveryCodeConfirmed={isRecoveryCodeConfirmed}
      setErrorMessage={setErrorMessage}
    />
  )
}
