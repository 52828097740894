import { Services } from '@percent/admin-dashboard/api/actions'
import {
  DonationRequestEnhanced,
  DonationRequestStatus
} from '@percent/admin-dashboard/api/actions/donationRequests/donationRequests.types'

export enum DonationRequestFilter {
  pending,
  settled,
  cancelled
}

export type DonationRequestTableProps = {
  isLoading: boolean
  totalResults: number
  data: DonationRequestEnhanced[] | null
  previousPage: (() => void) | null
  nextPage: (() => void) | null
  refreshToFirstPage: () => void
  errorMessage: string | null
  query: (..._: Parameters<Services['donationRequestsService']['getDonationRequestsEnhancedList']>) => void
  status: DonationRequestStatus
}
